import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { useNavigate, useParams } from "react-router-dom";
import HtDataManager from "../../components/HtDataManager";
import { thaiDate, roundDateToDate } from "../../util";
import { getThaiDateFormat } from "../../constants";
import styled from "styled-components";
import themes from "../../themes";
import { Label, Flex } from "../../components";
import { Table } from "reactstrap";
import * as R from "ramda";

const UserPrize = () => {
  const { callApi } = Context();
  const { userId } = useParams();
  const dataRef = useRef(null);
  const [data, setData] = useState({});
  const [userData, setUserData] = useState();

  const getUserData = async () => {
    setData({});
    callApi("userPrizes-getUserPrize", { userId }).then((user) => {
      setUserData(user);
      setData({
        personal: {
          boxes: [
            {
              sections: [
                {
                  id: userId,
                  updateChange: async (body) => {
                    return await callApi("users-edit", body);
                  },
                  header: "ข้อมูลบัญชี",
                  size: 1,
                  card: {
                    size: 5,
                    cards: [
                      {
                        title: "ชื่อจริง",
                        value: {
                          component: "Text",
                          value: user.firstName || "-",
                          field: "firstName",
                        },
                      },
                      {
                        title: "นามสกุล",
                        value: {
                          component: "Text",
                          value: user.lastName || "-",
                          field: "lastName",
                        },
                      },
                      {
                        title: "ยืนยันเบอร์มือถือ",
                        value: {
                          component: "Text",
                          value: user.activated ? "Yes" : "No",
                          field: "activated",
                        },
                      },
                      {
                        title: "เบอร์มือถือ",
                        value: {
                          component: "Text",
                          value: user.phone || "-",
                          field: "phone",
                        },
                      },
                      {
                        title: "อีเมล์",
                        value: {
                          component: "Text",
                          value: user.email || "-",
                          field: "email",
                        },
                      },
                      {
                        title: "ไลน์ไอดี",
                        value: {
                          component: "Text",
                          value: user.lineId || "-",
                          field: "lineid",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
        bankInfo: {
          boxes: [
            {
              sections: [
                {
                  id: userId,
                  updateChange: async (body) => {
                    return await callApi("users-edit", body);
                  },
                  header: "ข้อมูลบัญชีรับเงินรางวัล",
                  size: 1,
                  card: {
                    size: 5,
                    cards: [
                      {
                        title: "ชื่อบัญชี",
                        value: {
                          component: "Text",
                          value: user.accName || "-",
                          field: "accName",
                        },
                      },
                      {
                        title: "ชื่อธนาคาร",
                        value: {
                          component: "Text",
                          value: user.bankName || "-",
                          field: "bankName",
                        },
                      },
                      {
                        title: "เลขที่บัญชี",
                        value: {
                          component: "Text",
                          value: user.accNo || "-",
                          field: "accNo",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      });
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  if (!userData) {
    return <> </>;
  }
  const getKeyTranslated = (key) => {
    switch (key.prizeType) {
      case "first":
        return { ...key, name: "ที่ 1", sequence: 1 };
      case "nearBy":
        return { ...key, name: "ไกล้เคียงรางวัลที่ 1", sequence: 2 };
      case "second":
        return { ...key, name: "ที่ 2", sequence: 3 };
      case "third":
        return { ...key, name: "ที่ 3", sequence: 4 };
      case "forth":
        return { ...key, name: "ที่ 4", sequence: 5 };
      case "fith":
        return { ...key, name: "ที่ 5", sequence: 6 };
      case "firstThree":
        return { ...key, name: "เลขหน้า 3 ตัว", sequence: 7 };
      case "lastThree":
        return { ...key, name: "เลขท้าย 3 ตัว", sequence: 8 };
      case "lastTwo":
        return { ...key, name: "เลขท้าย 2 ตัว", sequence: 9 };
      default:
        break;
    }
  };

  return (
    <>
      <PanelContainer>
        <Label.H4 weight={300}>รหัสสมาชิก {userId}</Label.H4>
      </PanelContainer>
      <br />
      <PanelContainer ref={dataRef}>
        <div
          style={{
            width: "100%",
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          {data.personal && <HtDataManager data={data.personal} />}
        </div>
      </PanelContainer>
      <br />
      <PanelContainer ref={dataRef}>
        <div
          style={{
            width: "100%",
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          {data.bankInfo && <HtDataManager data={data.bankInfo} />}
        </div>
      </PanelContainer>
      <br />
      <PanelContainer>
        <Title>
          รายการรางวัล งวดวันที่{" "}
          {new Date(userData.roundDate).toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Title>
        {R.isEmpty(userData.prizes) ? (
          <Flex minHeight="300px" justify="center" align="center">
            <Label.H3>ไม่มีรางวัลที่ถูก</Label.H3>
          </Flex>
        ) : (
          <Table striped responsive>
            <thead>
              <tr>
                <th>รางวัล</th>
                <th>หมายเลขสลาก</th>
                <th>เลขที่ได้รางวัล</th>
                <th>เงินรางวัล</th>
              </tr>
            </thead>
            <tbody>
              {userData.prizes
                .map((prize) => getKeyTranslated(prize))
                .sort((a, b) => a.sequence - b.sequence)
                .map((each, index) => (
                  <tr key={index}>
                    <td className="align-center">{each.name}</td>
                    <td>{each.number}</td>
                    <td>{each.winner}</td>
                    <td>{each.prizeAmount.toLocaleString()} บาท</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </PanelContainer>
    </>
  );
};
const Title = styled.div`
  margin: 15px 0;
  font-size: 1.3rem;
  font-weight: 600;
`;

const PanelContainer = styled.div`
  /* margin: 5px; */
  border-radius: 8px;
  border: 1px solid ${themes.colors.border};
  box-sizing: unset;
  /* max-width: 300px; */
  padding: 10px;
  font-size: 1.5rem;
  background: ${themes.colors.white};
`;
export default UserPrize;
