import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { Container } from "../../components";
const ReferralPoints = () => {
  const { callApi } = Context();
  return (
    <Container>
      <HtTablePaging
        title={"คะแนนแนะนำเพิื่อน"}
        limit={20}
        getData={(body) => callApi("referralPoints-getAll", body)}
        updateApi={async (body) => {
          return await callApi("referralPoints-edit", {
            ...body,
            collectionName: "Users",
          });
        }}
        hideColumns={["id"]}
      />
    </Container>
  );
};
export default ReferralPoints;
