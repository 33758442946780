import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import ButtonLoading from "../../components/ButtonLoading";
import { Table, Collapse } from "reactstrap";
import { Flex, Label } from "../../components";
import { thaiDate } from "../../util";
import { isNil, isEmpty } from "ramda";
import styled from "styled-components";
import themes from "../../themes";

const ManageLotteries = () => {
  const { callApi, profileState } = Context();
  const [unimported, setUnimported] = useState([]);
  const [isOpenProcessing, setIsOpenProcessing] = useState(true);
  const [isOpenBack, setIsOpenBack] = useState(true);
  const [isOpenFront, setIsOpenFront] = useState(true);
  const getData = async () => {
    callApi("lotteries-getZipUnimported").then((result) => {
      console.log(result.result.length);
      const tem = {};
      result.result.map((x) => {
        tem[x.createdAt] = { ...x, checked: false };
      });
      setUnimported(tem);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const deletesZip = async (createdRawAt) => {
    window.confirm("ยืนยันการลบ") &&
      callApi("lotteries-deletesZip", { createdRawAt }).then(() =>
        window.location.reload()
      );
  };

  return (
    <>
      <button onClick={getData}>Refresh</button>
      {/* จำนวนเล่มทั้งหมด{" "}
      {Object.values(unimported).filter((x) => x.newLotteries !== 0).length}{" "}
      เล่ม (
      {Object.values(unimported).reduce((acc, x) => {
        return acc + x.newLotteries;
      }, 0)}
      ) ใบ */}
      <Flex margin="3em 0" align="center" width="100%">
        <Button onClick={() => setIsOpenBack(!isOpenBack)}>
          {isOpenBack ? "ย่อ" : "แสดง"}
        </Button>
        <Flex direction="column">
          <div style={{ width: "100%", display: "flex" }}>
            <Label.H2 margin="0 1em 0 0" weight={500}>
              จำนวนเลขในระบบ (ไม่พร้อมขาย)
            </Label.H2>
            {profileState.role === "dev" && (
              <>
                <Button
                  margin="0 1em 0 0"
                  onClick={() => {
                    if (
                      Object.values(unimported).filter(
                        (x) => x.imported !== x.newLotteries
                      ).length
                    ) {
                      callApi("blocks-pushAllToBlock").then((result) => {
                        setUnimported((old) => {
                          Object.keys(result.data).forEach((createdAt) => {
                            old[createdAt].imported += result.data[createdAt];
                          });
                          return old;
                        });
                      });
                    }
                  }}
                >
                  เปลี่ยนเป็นพร้อมขาย
                </Button>
                <Button
                  onClick={() => {
                    if (
                      Object.values(unimported).filter((x) => x.checked).length
                    ) {
                      callApi("blocks-pushToBlock", {
                        isActive: true,
                        createdAts: Object.values(unimported)
                          .filter((x) => x.checked)
                          .map((x) => x.createdAt),
                      }).then((result) => {
                        setUnimported((old) => {
                          Object.keys(result.data).forEach((createdAt) => {
                            old[createdAt].imported += result.data[createdAt];
                          });
                          return old;
                        });
                      });
                    }
                  }}
                >
                  เปลี่ยนเลขที่เลือกเป็นพร้อมขาย
                </Button>
              </>
            )}
          </div>{" "}
          {/* <Label.H5>
            จำนวนเลขในระบบ (ไม่พร้อมขาย){" "}
            {
              Object.values(unimported).filter(
                (x) =>
                  isNil(x.isProcessing) &&
                  x.imported === 0 &&
                  x.chunkProcess >= x.chunk && // Done processing
                  x.newLotteries !== 0 // No new lotto
              ).length
            }{" "}
            เล่ม (
            {Object.values(unimported)
              .filter(
                (x) =>
                  isNil(x.isProcessing) &&
                  x.imported === 0 &&
                  x.chunkProcess >= x.chunk &&
                  x.newLotteries !== 0
              )
              .reduce((acc, x) => {
                return acc + x.newLotteries;
              }, 0)}
            ) ใบ
          </Label.H5> */}
        </Flex>
      </Flex>
      <Collapse isOpen={isOpenBack}>
        <FullScrollBar>
          <Table striped hover>
            <thead>
              <tr>
                <th>เลือก</th>
                <th>ลำดับที่</th>
                <th>ชื่อไฟล์</th>
                <th>เล่ม</th>

                <th>เวลานำเข้า</th>
                <th>นำเข้าโดย</th>
                {/* <th /> */}
              </tr>
            </thead>
            <tbody>
              {!isEmpty(
                Object.values(unimported).filter(
                  (x) =>
                    isNil(x.isProcessing) &&
                    x.imported === 0 &&
                    x.chunkProcess >= x.chunk
                )
              ) ? (
                Object.values(unimported)
                  .filter(
                    (x) =>
                      isNil(x.isProcessing) &&
                      x.imported === 0 &&
                      x.chunkProcess >= x.chunk &&
                      x.newLotteries !== 0
                  )
                  .sort((a, b) =>
                    a.fileNameId.localeCompare(b.fileNameId, undefined, {
                      numeric: true,
                    })
                  )
                  .map((uimp, index) => (
                    <tr>
                      <td>
                        <input
                          type={"checkbox"}
                          id={uimp.createdAt}
                          style={{
                            margin: "4px",
                            marginRight: "1em",
                            transform: "scale(2)",
                          }}
                          defaultChecked={uimp.checked}
                          onChange={(e) =>
                            setUnimported((olds) => {
                              olds[uimp.createdAt].checked =
                                !olds[uimp.createdAt].checked;
                              return olds;
                            })
                          }
                          disabled={uimp.imported >= uimp.newLotteries}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <th> {uimp.fileNameId}</th>
                      <td>
                        <ScrollBar>
                          {uimp.bookId
                            ? uimp.bookId.map(
                                (x, i) =>
                                  x +
                                  `${i < uimp.bookId.length - 1 ? "\n" : ""}`
                              )
                            : "-"}
                        </ScrollBar>
                      </td>

                      <td> {thaiDate(new Date(parseInt(uimp.createdAt)))}</td>
                      <td> {uimp.uploader}</td>
                      <td>
                        {/* {" "}
                      <Button onClick={() => deletesZip(uimp.createdAt)}>
                        ลบ
                      </Button> */}
                      </td>
                    </tr>
                  ))
              ) : (
                <Label.H3 margin="2em">ไม่พบข้อมูล</Label.H3>
              )}
            </tbody>
          </Table>
        </FullScrollBar>
      </Collapse>
    </>
  );
};

const Button = styled.button`
  background: ${themes.colors.primary};
  border: 3px solid ${themes.colors.primary};
  border-radius: 0.375rem;
  transition-duration: 0.1s;
  margin: 0 1em;
  :hover {
    background: ${themes.colors.white};
    color: ${themes.colors.primary};
  }
`;
const ImportCard = styled.div`
  /* margin: 5px; */
  border-radius: 0.375rem;
  /* border: 1px solid ${themes.colors.border}; */
  box-sizing: unset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 300px;
  /* max-width: 300px; */
  padding: 10px;
  font-size: 1.5rem;
  text-align: center;
  background: ${(props) => props.bgColor};
  transition: transform 0.2s;
`;

const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const FullScrollBar = styled(ScrollBar)`
  width: 100% !important;
  max-width: 100% !important;
  max-height: 50vh;
`;
export default ManageLotteries;
