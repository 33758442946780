import React from "react";
import styled from "styled-components";

const ButtonLoading = ({ text, title, percent, handleClick, inputs = [] }) => {
  return (
    <>
      <ButtonLoadingSc>
        <button title={title || text} onClick={handleClick}>
          {text}
        </button>
        {inputs.map((x, i) => (
          <div key={i}>
            <>{x.text}</>
            <input {...x} />
          </div>
        ))}
        <div
          style={{
            margin: "5px 0 5px 5px",
            flex: 1,
            opacity: percent < 1 ? 0 : 1,
          }}
        >
          <div className="w3-light-grey w3-round-xlarge">
            <div
              className="w3-container w3-blue w3-round-xlarge"
              style={{ width: `${percent}%` }}
            >{`${percent}%`}</div>
          </div>
        </div>
      </ButtonLoadingSc>
    </>
  );
};
export default ButtonLoading;

const ButtonLoadingSc = styled.div`
  display: flex;
  margin: 5px 0;
`;
