import React, { useEffect, useState, useRef } from "react";
import Context from "../Context";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import text from "../constants";
import StackImages from "./StackImages";
import { boolean2YN } from "../util";

const HtDataManager = ({ data }) => {
  return (
    <>
      {data.boxes &&
        data.boxes.map((x, i) => <Box key={i} sections={x.sections} />)}
    </>
  );
};
export default HtDataManager;

const Box = ({ sections }) => {
  return (
    <BoxDiv size={1}>
      {sections.map((section, i) => {
        const InjectComponent = section.Component;
        return (
          <Section key={i} size={section.size}>
            {section.disabled && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                  background: "rgba(0,0,0,0.7)",
                }}
              ></div>
            )}
            {InjectComponent ? (
              <InjectComponent {...section.props} />
            ) : (
              <div>
                {section.header && (
                  <Header>{section.header || text.zWspc}</Header>
                )}
                {section.card.cards.map((card, j) => (
                  <Card
                    key={j}
                    {...section}
                    size={section.card.size}
                    title={card.title}
                    value={card.value}
                    cards={section.card.cards}
                  />
                ))}
              </div>
            )}
          </Section>
        );
      })}
    </BoxDiv>
  );
};

const BoxDiv = styled.div`
  position: relative;
  border-radius: 10px;
  display: inline-block;
  width: ${({ size }) => 100 / size}%;
  margin-top: 25px;
  background-color: white;

  :first-child {
    margin-top: unset;
  }
  :last-child {
    margin-bottom: unset;
  }
`;
const Card = ({ title, value, size, updateChange, ...rests }) => {
  const upc = async (body) => {
    if (window.confirm("ต้องการแก้ไขข้อมูลใช่หรือไม่")) {
      return await updateChange(body);
    } else {
      return { status: false };
    }
  };
  return (
    <CardDiv size={size / (value?.extend || 1)}>
      {<Title>{title || text.zWspc}</Title>}
      <Compoenent {...value} {...rests} updateChange={upc} />
    </CardDiv>
  );
};
const CardDiv = styled.div`
  position: relative;

  vertical-align: top;
  border-collapse: collapse;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  margin-bottom: 10px;
  width: ${({ size }) => (1 / size) * 100}%;
`;
const Compoenent = ({ component, ...rests }) => {
  const Comp = {
    Text: Text,
    List: List,
    Hide: Hide,
    Image: Image,
    Input: Input,
    InputList: InputList,
    Password: Password,
    Button: Button,
    Choice: Choice,
    Collumn: Collumn,
    CheckBox: CheckBox,
    TextArea: TextArea,
    StackImage: StackImage,
    UploadImage: UploadImage,
    DateTimePicker: DateTimePicker,
    DatePicker: DatePicker,
  }[component];
  return <Comp {...rests} />;
};
const Section = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: ${({ size }) => (1 / size) * 100}%;
`;
const Header = styled.div`
  margin: 15px 0;
  font-size: 1.3rem;
  font-weight: 600;
`;
const Title = styled.div`
  color: #ee6600;
  font-weight: bold;
  font-size: 1.2rem;
`;
const Text = ({ value, style = {} }) => {
  return (
    <pre style={{ margin: "unset", maxHeight: "140px", ...style }}>
      {value > 1500000000000 ? new Date(value).toLocaleString("th") : value}
    </pre>
  );
};
const List = ({ value, style = {} }) => {
  return (
    <pre style={{ margin: "unset", maxHeight: "140px", ...style }}>
      {value.map((x) => (
        <div>{x}</div>
      ))}
    </pre>
  );
};
const Hide = () => {
  return <div></div>;
};
const Collumn = ({ value, style = {} }) => {
  return (
    <pre
      style={{
        position: "absolute",
        marginTop: "unset",
        maxHeight: "140px",
        overflow: "scroll",
        ...style,
      }}
    >
      {value}
    </pre>
  );
};
const Input = ({ id, field, value, updateChange, type, style = {} }) => {
  const [oldValue, setOldValue] = useState("");
  return (
    <input
      style={{
        width: "100%",
        marginBottom: "15px",
        ...style,
      }}
      type={type || "text"}
      onFocus={(e) => setOldValue(e.target.value)}
      onBlur={(e) =>
        e.target.value !== oldValue &&
        updateChange({
          id,
          key: field,
          value: e.target.value,
        }).then(
          (result) => result.status !== true && (e.target.value = oldValue)
        )
      }
      defaultValue={value}
    />
  );
};
const InputList = ({
  id,
  field,
  value = [],
  updateChange,
  type,
  style = {},
}) => {
  const [values, setVales] = useState(value);
  return (
    <>
      {values.map((x) => (
        <div>{x}</div>
      ))}
      <input
        style={{
          width: "100%",
          marginBottom: "15px",
          ...style,
        }}
        type={type || "text"}
        onKeyDown={(e) => e.code === "Enter" && e.target.blur()}
        onBlur={(e) => {
          if (e.target.value) {
            const newValues = [...values, e.target.value];
            updateChange({
              id,
              key: field,
              value: newValues,
            }).then((result) => {
              if (result) {
                e.target.value = "";
                setVales(newValues);
              }
            });
          }
        }}
      />
    </>
  );
};
const Password = ({ id, field, value, updateChange, style = {} }) => {
  const [oldValue, setOldValue] = useState("");
  return (
    <input
      style={{
        width: "100%",
        marginBottom: "15px",
        ...style,
      }}
      type="password"
      onFocus={(e) => setOldValue(e.target.value)}
      onBlur={(e) =>
        e.target.value !== oldValue &&
        updateChange({
          id,
          key: field,
          value: e.target.value,
        }).then(
          (result) => result.status !== true && (e.target.value = oldValue)
        )
      }
      defaultValue={value}
    />
  );
};
const Button = ({ value, style = {} }) => {
  return (
    <button
      style={{
        width: "100%",
        marginBottom: "15px",
        ...style,
      }}
      onClick={(e) => {
        const arr = [];
        e.target.parentElement.parentElement.childNodes.forEach((x) =>
          arr.push(x.c)
        );
      }}
      defaultValue={value}
    >
      {value}
    </button>
  );
};
const TextArea = ({ id, field, value, updateChange, style = {} }) => {
  const [oldValue, setOldValue] = useState("");
  return (
    <textarea
      style={{
        width: "100%",
        marginBottom: "15px",
        ...style,
      }}
      onFocus={(e) => setOldValue(e.target.value)}
      onBlur={(e) =>
        e.target.value !== oldValue &&
        updateChange({
          id,
          key: field,
          value: e.target.value,
        }).then(
          (result) =>
            result.status !== true && (e.currentTarget.value = oldValue)
        )
      }
      defaultValue={value}
    />
  );
};
const Choice = ({
  id,
  field,
  value,
  choices = [],
  updateChange,
  texts = [],
  style = {},
}) => {
  const [oldValue, setOldValue] = useState("");
  return (
    <select
      style={{
        width: "100%",
        padding: "1px 2px",
        margin: "2px 2px",
        ...style,
      }}
      onClick={(e) => setOldValue(e.target.value)}
      onChange={(e) => {
        const value = ["Yes", "No"].includes(e.currentTarget.value)
          ? e.currentTarget.value === "Yes"
          : e.currentTarget.value;
        updateChange({
          id,
          key: field,
          value,
        }).then(
          (result) => result.status !== true && (e.target.value = oldValue)
        );
      }}
      defaultValue={boolean2YN(value)}
    >
      {choices.map((x, i) => {
        return (
          <option key={i} value={boolean2YN(x)}>
            {texts[i] || boolean2YN(x)}
          </option>
        );
      })}
    </select>
  );
};
const CheckBox = ({
  id,
  field,
  value = [],
  choices = [],
  updateChange,
  style = {},
}) => {
  const [boxes, setBoxes] = useState(value);
  const handleClick = (e) => {
    setBoxes((x) => {
      if (e.target.checked) {
        return [...x, e.target.value];
      } else {
        return x.filter((y) => y !== e.target.value);
      }
    });
  };
  return (
    <div
      style={{
        width: "100%",
        padding: "1px 2px",
        margin: "2px 2px",
        ...style,
      }}
    >
      {choices.map(({ key, value, text }) => {
        return (
          <div key={key}>
            <input
              type="checkbox"
              id={value}
              name={value}
              value={value}
              defaultChecked={boxes.includes(value)}
              onChange={handleClick}
            />
            <label htmlFor={value}> {text}</label>
            <br />
          </div>
        );
      })}
      <button
        onClick={() =>
          updateChange({
            id,
            key: field,
            value: boxes,
          }).then((result) => {
            if (result.status !== true) {
              alert("ทำรายการไม่สำเร็จ");
              window.location.reload();
            }
          })
        }
      >
        บันทึก
      </button>
    </div>
  );
};
const Image = ({ value = [], style = {} }) => {
  return (
    <div style={{ overflow: "scroll", ...style }}>
      {value.map((x, i) => (
        <img
          key={i}
          src={x}
          alt=""
          style={{ position: "relative", width: "100%" }}
        />
      ))}
    </div>
  );
};
const StackImage = ({ value = [] }) => {
  return <StackImages urls={value} />;
};
const UploadImage = ({ id, field, value, updateChange, style = {} }) => {
  return (
    <div style={{ textAlign: "center", ...style }}>
      <label
        style={{
          width: "100% !important",
          marginBottom: "15px",
          border: "1px solid black",
          margin: "4px",
          padding: "8px",
          cursor: "pointer",
          backgroundColor: "lightgray",
          borderRadius: "4px",
          verticalAlign: "middel",
        }}
        htmlFor="files-slips"
      >
        {value}
      </label>
      <input
        style={{
          width: "100%",
          marginBottom: "15px",
          visibility: "hidden",
          position: "absolute",
        }}
        id="files-slips"
        type="file"
        onChange={async (e) => {
          const file = e.target.files[0];
          const base64 = await resizeImage(file);
          if (base64) {
            updateChange({
              id,
              key: field,
              value: { fileName: file.name, base64 },
            }); //.then(() => window.location.reload())
          } else {
            alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
          }
        }}
      />
    </div>
  );
};
const DateTimePicker = ({ id, field, value, updateChange }) => {
  value = new Date(value + 1000 * 60 * 60 * 7).toISOString().slice(0, -8);
  return Picker({ id, field, value, updateChange, type: "datetime-local" });
};
const DatePicker = ({ id, field, value, updateChange }) => {
  value = new Date(value).toISOString().split("T")[0];
  return Picker({ id, field, value, updateChange, type: "date" });
};
const Picker = ({ id, field, value, updateChange, type, style = {} }) => {
  const [oldValue, setOldValue] = useState("");
  return (
    <input
      style={{
        width: "100%",
        marginBottom: "15px",
        ...style,
      }}
      type={type}
      onClick={(e) => setOldValue(e.target.value)}
      onBlur={(e) =>
        e.target.value !== oldValue &&
        updateChange({
          id,
          key: field,
          value: +new Date(e.target.value),
        }).then(
          (result) => result.status !== true && (e.target.value = oldValue)
        )
      }
      defaultValue={value}
    />
  );
};

const resizeImage = (file) =>
  new Promise((resolve, reject) => {
    if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type))
      reject(false);
    const maxW = 600; //กำหนดความกว้าง
    const maxH = 1200; //กำหนดความสูง
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = document.createElement("img");
    img.onload = function () {
      const iw = img.width;
      const ih = img.height;
      const scale = Math.min(maxW / iw, maxH / ih);
      const iwScaled = iw * scale;
      const ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      context.drawImage(img, 0, 0, iwScaled, ihScaled);
      resolve(canvas.toDataURL("image/jpeg", 1)); //0.5 คือ คุณภาพของรูป ที่ Resize
    };
    img.src = URL.createObjectURL(file);
  });
