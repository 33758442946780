import React, { useState } from "react";
import Context from "../../Context";
import constant from "../../constants";
import styled from "styled-components";
import { Container } from "../../components";
const CreateUser = () => {
  const { callApi } = Context();

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationality, setNationality] = useState("TH");
  const [bankName, setBankName] = useState({});
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <Container style={{ verticalAlign: "top", height: "100%" }}>
      <div
        style={{ width: "100%", display: "inline-block", verticalAlign: "top" }}
      >
        <GridContainer>
          <GridItem>โทรศัพท์:</GridItem>
          <GridItem>
            <Input onChange={(e) => setPhone(e.target.value)} />
          </GridItem>
          <GridItem>รหัสเข้าระบบ:</GridItem>
          <GridItem>
            <Input onChange={(e) => setPassword(e.target.value)} />
          </GridItem>
          <GridItem>ชื่อจริง:</GridItem>
          <GridItem>
            <Input onChange={(e) => setFirstName(e.target.value)} />
          </GridItem>
          <GridItem>นามสกุล:</GridItem>
          <GridItem>
            <Input onChange={(e) => setLastName(e.target.value)} />
          </GridItem>
          <GridItem>สัญชาติ</GridItem>
          <GridItem>
            <Select
              className="border-1 border-black"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            >
              <option value="TH">ไทย</option>
              <option value="LAO">ลาว</option>
              <option value="KOR">เกาหลี</option>
            </Select>
          </GridItem>
          <GridItem>ชื่อธนาคาร:</GridItem>
          <GridItem>
            <Select
              className="border-1 border-black"
              type="select"
              onChange={(e) => {
                setBankName(JSON.parse(e.target.value));
              }}
            >
              <option></option>
              <optgroup label="Thai Bank">
                {constant.bankList
                  .filter((bank) => bank.currency === "THB")
                  .map((x) => (
                    <option value={JSON.stringify(x)}>{x.bankName}</option>
                  ))}
              </optgroup>
              <optgroup label="Lao Bank">
                {constant.bankList
                  .filter((bank) => bank.currency === "LAK")
                  .map((x) => (
                    <option value={JSON.stringify(x)}>{x.bankName}</option>
                  ))}
              </optgroup>
              <optgroup label="Korean Bank">
                {constant.bankList
                  .filter((bank) => bank.currency === "KRW")
                  .map((x) => (
                    <option key={x.bankName} value={JSON.stringify(x)}>
                      {x.bankName}
                    </option>
                  ))}
              </optgroup>
            </Select>
          </GridItem>
          <GridItem>ชื่อบัญชี:</GridItem>
          <GridItem>
            <Input onChange={(e) => setAccountName(e.target.value)} />
          </GridItem>
          <GridItem>เลขบัญชี:</GridItem>
          <GridItem>
            <Input onChange={(e) => setAccountNumber(e.target.value)} />
          </GridItem>
          <GridItem>
            <button
              className="border-1 px-4 py1"
              onClick={async () => {
                if (!phone || !firstName || !lastName || !password) {
                  return window.alert("กรุณากรอกข้อมูลให้ครบถ้วน");
                }
                // if (/^\d{10}$/.test(username)) {
                //   return window.alert("ชื่อบัญชีต้องไม่ใช่ตัวเลข 10 หลัก");
                // }
                const result = await callApi("users-createUserByAdmin", {
                  newUser: {
                    password,
                    phone,
                    firstName,
                    lastName,
                    nationality,
                    bank: {
                      name: bankName.bankName,
                      currency: bankName.bankCurrency,
                      accName: accountName,
                      accNo: accountNumber,
                    },

                    activated: true,
                  },
                });
                if (result.status) {
                  window.alert(
                    `การสร้างบัญชี ${phone} เสร็จสิ้น ด้วยรหัส ${password}`
                  );
                  window.location.reload();
                } else {
                  window.alert("การสร้างบัญชีผิดพลาด โปรดลองใหม่ภายหลัง");
                }
              }}
            >
              สร้างบัญชีใหม่
            </button>
          </GridItem>
        </GridContainer>
      </div>
    </Container>
  );
};
const Select = styled.select`
  width: 100%;
  max-width: 300px;
`;
const Input = styled.input`
  width: 100%;
  max-width: 300px;
  border: 1px solid black;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
`;
const GridItem = styled.div`
  padding: 5px;
  &:nth-child(odd) {
    text-align: right;
  }
  &:nth-child(even) {
    text-align: left;
  }
`;
export default CreateUser;
