import { parsePath } from "react-router-dom";

export const tsToStr = () =>
  parseInt(
    new Date()
      .toISOString()
      .slice(-22)
      .replace(/(\.|-|:|T|Z)/g, "")
  ).toString(36);
export const sumPxQ = (arr, basePrice) => {
  const result = arr.reduce(
    (r, x) => (r += x.quantity * (x.price + basePrice)),
    0
  );
  if (parseInt(result)) {
    return result;
  } else {
    return "xxx";
  }
};
export const sum = (arr) => arr.reduce((r, x) => (r += x), 0);
export const addressToHtmlString = ({
  address,
  subDistrict,
  district,
  province,
  zipcode,
}) => {
  return `${address}<br>
  ${subDistrict}${district}<br/>
  ${province}${zipcode}`;
};
export const thaiDate = (date) => {
  return (
    (date || new Date()).toLocaleString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }) + " น."
  );
};
// export const thaiRoundDate = (roundDate) => {
//   if (!roundDate) return ""
//   let [year, month, date] = roundDate?.split("-")
//   month = ["มกราคม",
//     "กุมภาพันธ์",
//     "มีนาคม",
//     "เมษายน",
//     "พฤษภาคม",
//     "มิถุนายน",
//     "กรกฎาคม",
//     "สิงหาคม",
//     "กันยายน",
//     "ตุลาคม",
//     "พฤศจิกายน",
//     "ธันวาคม",][parseInt(month) - 1]
//   return `${date} ${month} ${year}`
// }
export const roundDateToDate = (roundDate) => {
  if (!roundDate) return "";
  return new Date(roundDate).toLocaleDateString("th-TH", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};
export const boolean2YN = (value) =>
  value === true
    ? "Yes"
    : value === false
    ? "No"
    : value === undefined
    ? "No"
    : value;
export const yN2Boolean = (value) =>
  value === "Yes" ? true : value === "No" ? false : value;

export const bankTranslater = (value) => {
  const bankForAccountant = {
    ธนาคารกรุงเทพฯ: "002 กรุงเทพ",
    ธนาคารไทยพาณิชย์: "014 ไทยพาณิชย์",
    ธนาคารกสิกรไทย: "004 กสิกรไทย",
    ธนาคารกรุงศรีอยุธยา: "025 กรุงศรีอยุธยา",
    ธนาคารกรุงไทย: "006 กรุงไทย",
    ธนาคารธนชาต: "065 ธนชาต",
    ธนาคารทิสโก้: "067 ทิสโก้",
    ธนาคารออมสิน: "030 ออมสิน",
    ธนาคารอาคารสงเคราะห์: "033 อาคารสงเคราะห์",
    ธนาคารอิสลามแห่งประเทศไทย: "066 อิสลามแห่งประเทศไทย",
    ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร: "034 เพื่อการเกษตรและสหกรณ์",
    ธนาคารทหารไทยธนชาต: "011 ทหารไทยธนชาต จำกัด (มหาชน)",
    ธนาคารซีไอเอ็มบีไทย: "022 ซีไอเอ็มบี",
    ธนาคารยูโอบี: "024 ยูโอบี",
    "ธนาคารแลนด์ แอนด์ เฮ้าส์": "073 แลนด์แอนด์เฮ้าส์",
    ธนาคารเกียรตินาคินภัทร: "069 เกียรตินาคิน",
  };
  return bankForAccountant[value];
};

export const unixToDate = (unix) => {
  return new Date(unix).toJSON().slice(0, 10);
};

export const unixToDateAndTime = (unix) => {
  return new Date(unix).toJSON().slice(0, 16);
};

export const dateToUnix = (date) => {
  return new Date(date).valueOf();
};

export const getThaiDateAndTimeFormat = (date) => {
  return date.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export const role = {
  admin: "Admin",
  superAdmin: "Team Lead Admin",
  dev: "Developer",
  scanner: " Scanner",
  superScanner: "Team Lead Scanner",
};
export const resizeImage = (file) =>
  new Promise((resolve, reject) => {
    if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type))
      reject(false);
    const maxW = 600; //กำหนดความกว้าง
    const maxH = 1200; //กำหนดความสูง
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = document.createElement("img");
    img.onload = function () {
      const iw = img.width;
      const ih = img.height;
      const scale = Math.min(maxW / iw, maxH / ih);
      const iwScaled = iw * scale;
      const ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      context.drawImage(img, 0, 0, iwScaled, ihScaled);
      resolve(canvas.toDataURL("image/jpeg", 1)); //0.5 คือ คุณภาพของรูป ที่ Resize
    };
    img.src = URL.createObjectURL(file);
  });
