import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";

import {
  Sheet,
  SheetContent,
  SheetTrigger,
  Switch,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";

import constansts from "../../constants";

export const ReportSale = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [selectedRoundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    let keepGoing = true;

    const arr = [];
    let startAfterId = "";

    while (keepGoing) {
      const result = await callApi("orders-getAll", {
        roundDate: selectedRoundDate,
        queries: [
          ["status", "==", constansts.statuses.APPROVE],
          ["paymentChannel", "==", "bank"],
        ],

        limit: 5000,
        startAfter: startAfterId,
        orderBy: ["createdAt", "desc"],
      });

      arr.push(...result);
      startAfterId = result.slice(-1)[0].id;
      console.log("result.length", result.length);
      if (result.length < 4999) {
        keepGoing = false;
      }
    }

    console.log(arr);
    const sumPrice = arr.reduce((acc, couponOrder) => {
      acc += couponOrder.totalPrice;
      return acc;
    }, 0);
    setTotalPrice(sumPrice);
    setData(arr);
  };

  const handleDownload = async () => {
    const acc = [];
    acc.push([
      "ลำดับที่",
      "วันที่ซื้อ",
      "รหัสออเดอร์",
      "ราคา",
      "ชื่อ-นามสกุล",
      "เลขอ้างอิง",
    ]);

    await data.map((order, index) => {
      acc.push([
        index + 1,
        order.createdAtDate,
        order.shortId,
        order.totalPrice?.toLocaleString(),
        order?.firstName + " " + order?.lastName,
        order.slipId || "-",
      ]);
    });

    setCsvData(acc);
    setCsvFileName(`รายงานยอดขาย​ (โอนเงิน) ${selectedRoundDate}.csv`);
  };

  return (
    <div className="bg-white max-w-[1200px] mb-24  p-8 rounded-2xl drop-shadow-xl">
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>
      <p className="text-3xl font-semibold">รายงานการขาย (โอนเงิน)</p>
      <div className="flex items-center gap-4 mt-4 max-w-80">
        <p className="text-lg font-bold">งวด</p>
        <Select
          value={selectedRoundDate}
          onValueChange={(e) => setRoundDate(e)}
        >
          <SelectTrigger className="my-1 text-md font-medium">
            <SelectValue placeholder="-- โปรดเลือก --" />
          </SelectTrigger>
          <SelectContent className="bg-black">
            {configs.roundDates.map((roundDate) => {
              return (
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value={roundDate}
                >
                  {roundDate}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <button
          className="h-12 my-4 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
          onClick={handleSearch}
        >
          ค้นหา
        </button>

        <button
          className={`h-12 my-4 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50 ${
            data.length === 0 && "hidden"
          }`}
          onClick={() => handleDownload()}
        >
          ดาวน์โหลด
        </button>
      </div>

      <br />
      {data.length > 0 && (
        <>
          <div className="my-12">
            <p className="text-xl mb-12 font-bold">
              จำนวนออเดอร์ทั้งหมด {data.length} ออเดอร์
            </p>

            <p className="text-xl font-bold">
              จำนวนยอดขายทั้งหมด {totalPrice.toLocaleString()} บาท
            </p>
          </div>

          <br />

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="">ลำดับที่</TableHead>
                <TableHead className="text-center">วันที่ซื้อ</TableHead>
                <TableHead className="text-center">รหัสออเดอร์</TableHead>
                <TableHead className="text-center">ชื่อ - นามสกุล</TableHead>
                <TableHead className="text-center">ราคา</TableHead>
                <TableHead className="">เลขอ้างอิง</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((order, index) => {
                return (
                  <TableRow key={order.shortId}>
                    <TableCell className="text-center font-medium">
                      {index + 1}
                    </TableCell>
                    <TableCell className="font-medium">
                      {new Date(parseInt(order.createdAt)).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <div
                        className={`
                       flex items-center mx-auto bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                        onClick={() =>
                          window.open(
                            `https://admin.hongthongofficial.com/orders/working/${order.id}`,
                            "__blank"
                          )
                        }
                      >
                        {order.shortId}
                      </div>
                    </TableCell>
                    <TableCell className="text-center">
                      {order?.firstName + " " + order?.lastName}
                    </TableCell>
                    <TableCell className="text-center">
                      {order.totalPrice?.toLocaleString()}
                    </TableCell>
                    <TableCell className="">
                      <div
                        className={`
                       flex items-center bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold`}
                      >
                        {order.slipId || "-"}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};

const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 250px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const Button = styled.button``;
