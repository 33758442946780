import React, { useEffect, useState } from "react";
import { collection, doc, onSnapshot } from "firebase/firestore";
import Context from "../../Context";
import { Chart } from "react-google-charts"; //https://www.react-google-charts.com/examples/pie-chart#read-more
import styled from "styled-components";
import themes from "../../themes";
import { Flex, Label } from "../../components";
import { FiRefreshCw } from "react-icons/fi";
import { Table } from "reactstrap";
import { FaSync } from "react-icons/fa";
import CountUp from "react-countup";

const Overall = () => {
  const { callApi, configs, db, profileState } = Context();

  const [updatedAt, setUpdatedAt] = useState(0);
  const [amountDate, setAmountDate] = useState([]);
  const [twoDigitLeft, setTwoDigitLeft] = useState([]);
  const [userRegistHistory, setUserRegistHistory] = useState([]);
  const [state, setState] = useState({
    countSet: {},
    totalImported: 0,
    availableLotteries: 0,
    lotteriesInCart: 0,

    approvedOrders: 0,
    approvedLotteries: 0,

    paidLotteries: 0,
    paidCount: 0,

    pendingLotteries: 0,
    pendingCount: 0,

    usersCount: 0,
    userConnectedLine: 0,
    revenue: 0,
  });

  const setupDashBoard = () => {
    callApi("utils-getDashboard").then(({ data }) => {
      if (!data.updatedAt) return;
      setState((prevState) => ({
        ...prevState,

        countSet: data["set"],

        totalImported: data["lottery-imported"]?.toLocaleString(),

        availableLotteries: data["lottery-available"]?.toLocaleString(),

        //checked cart
        lotteriesInCart: data["cart-count"]?.toLocaleString(),

        //approved
        approvedLotteries: Object.values(
          data["daily-lotteries-sales"] || {}
        ).reduce((acc, item) => {
          acc += item;
          return acc;
        }, 0),
        approvedOrders: Object.values(data["daily-order-sales"] || {}).reduce(
          (acc, item) => {
            acc += item;
            return acc;
          },
          0
        ),

        //pending
        pendingLotteries: data["pending-lottery"]?.toLocaleString() || "0",
        pendingCount: data["pending-order"]?.toLocaleString() || "0",
        //paid
        paidLotteries: data["paid-lottery"]?.toLocaleString() || "0",
        paidCount: data["paid-order"]?.toLocaleString() || "0",

        usersCount: data["user-count"]?.toLocaleString(),
        userConnectedLine: data["user-line-count"]?.toLocaleString(),
        revenue: Object.values(data["daily-total-sales"] || {}).reduce(
          (acc, value) => {
            acc += Number(value);
            return acc;
          },
          0
        ),
      }));

      setUpdatedAt(data.updatedAt || 0);

      //calculate and format two digit
      const twoDigit = getTopTwoDigit(data.lastTwo || {});
      setTwoDigitLeft(twoDigit);

      // calculated and format day sale graph

      const lottoSale = Object.entries(data["daily-lotteries-sales"] || {}).map(
        ([date, value]) => [date, Number(value)]
      );
      lottoSale.unshift(["วันที่", "จำนวน"]);
      setAmountDate(lottoSale);

      // format daily user
      const registHistory = data["user-regist-history"].map((date) => [
        date.registerdate,
        Number(date.registertotal),
      ]);
      registHistory.unshift(["วันที่", "จำนวน"]);
      setUserRegistHistory(registHistory);
    });
  };

  useEffect(() => {
    setupDashBoard();
  }, []);

  const getTopTwoDigit = (lastTwo) => {
    return Object.keys(lastTwo).map((key) => {
      return [key, lastTwo[key]];
    });
  };

  const GridContent = () => {
    return (
      <>
        <PanelContainer>
          <GridContainer>
            <GridItem
              topLabel="จำนวนสลากในระบบหน้าบ้านทั้งหมด"
              bottomLabel="ใบ"
              value={state.totalImported}
              color={themes.colors.fadeBlue}
            />

            <GridItem
              topLabel={"จำนวนสลากที่ยังไม่ขาย"}
              bottomLabel="ใบ"
              value={state.availableLotteries}
              color={themes.colors.fadeRed}
              clickAble={true}
              callApi={callApi}
              setupDashBoard={setupDashBoard}
            />
            <GridItem
              topLabel="ลูกค้าทั้งหมด"
              bottomLabel="คน"
              value={state.usersCount}
              color={themes.colors.fadeBlue}
            />
            <GridItem
              topLabel="ลูกค้าที่เชื่อมไลน์"
              bottomLabel="คน"
              value={state.userConnectedLine}
              color={themes.colors.fadeGreen}
            />
            <GridItem
              topLabel="จำนวนสลากที่อยู่ในตะกร้า"
              bottomLabel="ใบ"
              value={state.lotteriesInCart}
              color={themes.colors.fadeOrange}
            />
            <GridItem
              topLabel="คำสั่งซื้อที่รอชำระเงิน"
              bottomLabel="ใบ | ออเดอร์"
              value={`${state.pendingLotteries} | ${state.pendingCount}`}
              color={themes.colors.fadeBlue}
            />
            <GridItem
              topLabel="คำสั่งซื้อที่จ่ายเงินแล้ว (รออนุมัติ)"
              bottomLabel="ใบ | ออเดอร์"
              value={`${state.paidLotteries} | ${state.paidCount}`}
              color={themes.colors.fadeGreen}
            />
            <GridItem
              topLabel="จำนวนสลากที่อนุมัติแล้ว"
              bottomLabel="ใบ | ออเดอร์"
              value={`${state.approvedLotteries} | ${state.approvedOrders}`}
              color={themes.colors.success}
            />
          </GridContainer>
        </PanelContainer>
      </>
    );
  };

  const Header = () => {
    return (
      <div style={{ margin: "1em 0" }}>
        <Flex align="center">
          <Label size={themes.fontSize.large} weight={600} color="black">
            ยอดนับงวดประจำวันที่
            {" " +
              new Date(configs.roundDate).toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
          </Label>
          {/* 
          <Button
            onClick={async () => {
              if (window.confirm("ต้องการนับใหม่หรือไม่")) {
                const res = await Promise.all([
                  // callApi("utils-countOrderPending"),
                  // callApi("utils-countOrderPaid"),
                  // callApi("utils-countOrderApproved"),
                  // callApi("utils-countOrderCancel"),
                  // callApi("utils-countLotterStatus"),
                  // callApi("utils-countLotterAll"),
                  // callApi("utils-countUser"),
                  // callApi("utils-countCart"),
                  callApi("utils-countLastTwo"),
                ]);
                if (!res.every((r) => r.status)) {
                  window.alert("การนับมีปัญหา โปรดแจ้งโปรแกรมเมอร์");
                } else {
                  setupDashBoard();
                }
              }
            }}
          >
            <FiRefreshCwStyle /> นับใหม่
          </Button> */}
        </Flex>
        <Label size="small">
          อัพเดทล่าสุดเมื่อ{" "}
          {new Date(updatedAt).toLocaleString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </Label>
      </div>
    );
  };
  return (
    <>
      <div>
        <Header />
        <GridContent />
      </div>
      <br />
      <div>
        <PanelContainer>
          <Flex direction="column">
            <Label.H3 className="mb-3" textAlign="left" weight={600}>
              เลขท้าย 2 ตัวคงเหลือ (20 อันดับ)
            </Label.H3>
            {twoDigitLeft.length > 0 ? (
              twoDigitLeft
                .filter((digit) => digit[1] !== 0)
                .sort((a, b) => b[1] - a[1])
                .slice(0, 20)
                .map((item, index) => (
                  <Flex
                    key={index}
                    className="my-1"
                    width="100%"
                    justify="space-between"
                  >
                    <Label.H4
                      color={themes.colors.lightBlack}
                      textAlign="left"
                      weight={500}
                    >
                      เลขท้าย {item[0]}
                    </Label.H4>
                    <Label.H4
                      color={themes.colors.lightBlack}
                      textAlign="left"
                      weight={500}
                    >
                      {item[1]} ใบ
                    </Label.H4>
                  </Flex>
                ))
            ) : (
              <Flex minHeight="150px" align="center" justify="center">
                <Label.H4>ไม่พบข้อมูล</Label.H4>
              </Flex>
            )}
          </Flex>
        </PanelContainer>
      </div>
      <br />
      <PanelContainer>
        <Flex direction="column">
          <Label.H3 className="mb-3" textAlign="left" weight={600}>
            เลขท้าย 2 ตัวไกล้หมด (40 อันดับ)
          </Label.H3>

          {twoDigitLeft.length > 0 ? (
            <Table striped hover>
              <thead>
                <tr>
                  <th>อันดับ</th>
                  <th>เลขท้าย</th>
                  <th>เหลือ</th>
                </tr>
              </thead>
              <tbody>
                {twoDigitLeft
                  .sort((a, b) => a[1] - b[1])
                  .slice(0, 40)
                  .map((item, index) => (
                    <tr>
                      <th>{index + 1}</th>
                      <th>เลขท้าย {item[0]}</th>
                      <th>{item[1]} ใบ</th>
                    </tr>

                    // <Flex className="my-1" width="100%" justify="space-between">
                    //   <Label.H4
                    //     color={themes.colors.lightBlack}
                    //     textAlign="left"
                    //     weight={500}
                    //   >
                    //     เลขท้าย {item[0]}
                    //   </Label.H4>
                    //   <Label.H4
                    //     color={themes.colors.lightBlack}
                    //     textAlign="left"
                    //     weight={500}
                    //   >
                    //     {item[1]} ใบ
                    //   </Label.H4>
                    // </Flex>
                  ))}
              </tbody>
            </Table>
          ) : (
            <Flex minHeight="150px" align="center" justify="center">
              <Label.H4>ไม่พบข้อมูล</Label.H4>
            </Flex>
          )}
        </Flex>
      </PanelContainer>
      <br />
      <PanelContainer>
        <Label.H3 weight={600} className="mb-3">
          สรุปจำนวนเลขชุด
        </Label.H3>
        {Object.values(state.countSet).length > 0 ? (
          Object.entries(state.countSet).map(([key, value], index) => {
            if (value > 2)
              return (
                <Label.H4
                  color={themes.colors.lightBlack}
                  textAlign="left"
                  weight={500}
                  className="my-2"
                >
                  เลขชุด {key} ใบ {value} ชุด
                </Label.H4>
              );
          })
        ) : (
          <Flex minHeight="150px" align="center" justify="center">
            <Label.H4>ไม่พบข้อมูล</Label.H4>
          </Flex>
        )}
      </PanelContainer>
      <br />

      <PanelContainer>
        <GridItem
          topLabel="ยอดขายทั้งงวด"
          bottomLabel="บาท"
          value={`${state.revenue}`}
          color={themes.colors.success}
        />
        <br />
        <Label.H3 weight={600}>ยอดขายรายวัน (ใบ)</Label.H3>
        {Object.values(amountDate).length > 0 ? (
          <>
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={amountDate}
              options={{
                chart: {
                  title: " ",
                },
                colors: ["green"],
                legend: "none",
                seriesType: "bars",
              }}
            />
          </>
        ) : (
          <Flex minHeight="150px" align="center" justify="center">
            <Label.H4>ไม่พบข้อมูล</Label.H4>
          </Flex>
        )}
      </PanelContainer>
      <br />
      <PanelContainer>
        <Label.H3 weight={600}>ยอดการสมัครรายวัน</Label.H3>
        {console.log(userRegistHistory)}
        {userRegistHistory.length > 0 ? (
          <Chart
            chartType="ColumnChart" //Old Bar
            width="100%"
            height="400px"
            data={userRegistHistory}
            options={{
              chart: {
                title: " ",
              },
              colors: ["green"],
              legend: "none", //Old remove this
              seriesType: "bars", //Old remove this
            }}
          />
        ) : (
          <Flex minHeight="150px" align="center" justify="center">
            <Label.H4>ไม่พบข้อมูล</Label.H4>
          </Flex>
        )}
      </PanelContainer>
    </>
  );
};

export const GridItem = ({
  topLabel,
  bottomLabel,
  value,
  color,
  clickAble,
  callApi,
  setupDashBoard,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const getNewUnsoldLotto = async () => {
    await callApi("utils-countUnsoldLottery");
    setupDashBoard();
    setIsMouseOver(false);
  };
  return (
    <GridItemStyle
      bgColor={color}
      clickAble={clickAble}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {clickAble && isMouseOver ? (
        <Flex
          onClick={() => getNewUnsoldLotto()}
          width="100%"
          height="100%"
          justify="center"
          align="center"
        >
          <FiRefreshCwStyle /> นับใหม่
        </Flex>
      ) : (
        <>
          {topLabel && (
            <Flex align="center">
              <Label weight={600} size={themes.fontSize.xSmall}>
                {topLabel}
              </Label>
              {clickAble && (
                <FaSync style={{ fontSize: "0.5em", margin: "0 1em" }} />
              )}
            </Flex>
          )}
          {value && (
            <Label className="value" weight={300} size={themes.fontSize.large}>
              {value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
            </Label>
          )}
          {bottomLabel && (
            <Flex justify="flex-end">
              <Label weight={600} size={themes.fontSize.xSmall}>
                {bottomLabel}
              </Label>
            </Flex>
          )}
        </>
      )}
    </GridItemStyle>
  );
};

const Button = styled.button`
  margin: 0 1em;
  background-color: white;
  border: 0;
  border-radius: 0.375rem;
  padding: 0.3rem 1rem;
  transition: 0.1s;

  :hover {
    background-color: ${themes.colors.primary};
  }
`;
const FiRefreshCwStyle = styled(FiRefreshCw)`
  animation: spin 2.5s linear infinite;
  margin: 0 0.5em;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const PanelContainer = styled.div`
  /* margin: 5px; */
  border-radius: 0.375rem;
  /* border: 1px solid ${themes.colors.border}; */
  box-sizing: unset;
  /* max-width: 300px; */
  padding: 1.25rem;
  background: ${themes.colors.white};
`;

const Grid = styled.div`
  display: grid;
  /* grid-template-columns: auto auto auto; */
  /* grid-template-columns: repeat(auto-fit, minmax(32%, max-content)); */
  grid-template-columns: auto auto;
  gap: 1.25rem;

  @media only screen and (max-width: 987px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const GridContainer = styled.div`
  display: grid;
  /* grid-template-columns: auto auto auto; */
  /* grid-template-columns: repeat(auto-fit, minmax(32%, max-content)); */
  grid-template-columns: auto auto auto auto;
  gap: 1.25rem;

  @media only screen and (max-width: 1368px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 1124px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 987px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const GridItemStyle = styled.div`
  cursor: ${(props) => props.clickAble && "pointer"};
  /* margin: 5px; */
  border-radius: 0.375rem;
  /* border: 1px solid ${themes.colors.border}; */
  box-sizing: unset;
  max-width: ${(props) => props.maxWidth};
  /* max-width: 300px; */
  padding: 10px;
  font-size: 1.5rem;
  text-align: center;
  background: ${(props) => props.bgColor};
  transition: transform 0.2s;
  :hover {
    transform: scale(1.025);
  }
`;
export default Overall;
