import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtDataManager from "../../components/HtDataManager";
import { Label, Flex } from "../../components";
import { Input, Table, FormGroup } from "reactstrap";
import { ContentContainer } from "../orders/WorkingOrder";
import { Button } from "./MailSetting";
import themes from "../../themes";
const Currency = () => {
  const { callApi, configs, updateConfig } = Context();
  const [currency, setCurrency] = useState({
    lak: configs.currency?.lak || 0,
    lak_reward: configs.currency?.lak_reward || 0,
    krw: configs.currency?.krw || 0,
  });

  const [isEdit, setIsEdit] = useState(false);
  const updateChange = async (key, value) => {
    if (!window.confirm("ท่านต้องการแก้ไขใช่หรือไม่")) return;
    await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });
    updateConfig();
  };
  return (
    <div style={{ verticalAlign: "top", height: "100%" }}>
      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H1 color="white" weight={600}>
            ตั้งค่าค่าเงินต่างประเทศ
          </Label.H1>
          <div style={{ width: "200px", marginTop: "50px" }}>
            <Label.H4 color="white" weight={600}>
              เปิดขาย
            </Label.H4>
            <h3 className="my-0">
              <FormGroup switch>
                <Input
                  type="switch"
                  id="isOpen.store"
                  checked={configs.isOpen.store}
                  onClick={(e) => {
                    updateChange(e.target.id, e.target.checked);
                  }}
                />
              </FormGroup>
            </h3>
          </div>
          <Label.H4 margin="30px 0 0 0" color="white" weight={600}>
            ค่าเงินลาว (กีบ) ต่อ 1 บาท
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                disabled={configs.isOpen.store}
                style={{
                  width: "100px",
                  cursor: configs.isOpen.store && "not-allowed",
                }}
                type="number"
                defaultValue={currency.lak}
                onChange={(e) => {
                  setIsEdit(true);
                  setCurrency((prevState) => ({
                    ...prevState,
                    lak: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>

          <Label.H4 margin="30px 0 0 0" color="white" weight={600}>
            ค่าเงินลาว (กีบ) ต่อ 1 บาท (***สำหรับจ่ายเงินรางวัล***)
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                disabled={configs.isOpen.store}
                style={{
                  width: "100px",
                  cursor: configs.isOpen.store && "not-allowed",
                }}
                type="number"
                defaultValue={currency.lak_reward}
                onChange={(e) => {
                  setIsEdit(true);
                  setCurrency((prevState) => ({
                    ...prevState,
                    lak_reward: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>

          <Label.H4 margin="30px 0 0 0" color="white" weight={600}>
            ค่าเงินเกาหลี (วอน) ต่อ 1 บาท
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                disabled={configs.isOpen.store}
                style={{
                  width: "100px",
                  cursor: configs.isOpen.store && "not-allowed",
                }}
                type="number"
                defaultValue={currency.krw}
                onChange={(e) => {
                  setIsEdit(true);
                  setCurrency((prevState) => ({
                    ...prevState,
                    krw: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>

          {configs.isOpen.store && (
            <Label.H4 className="text-danger">*ปิดขายก่อนปรับราคา</Label.H4>
          )}
          {isEdit && (
            <Button
              style={{ margin: "0" }}
              onClick={async () =>
                await callApi("configs-edit", {
                  id: "general",
                  key: "currency",
                  value: currency,
                })
              }
            >
              บันทึก
            </Button>
          )}
        </div>
      </ContentContainer>
    </div>
  );
};
export default Currency;
