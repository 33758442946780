import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import * as R from "ramda";
export const ReportDaily = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [state, setState] = useState({
    order: {},
    lotteries: {},
    income: {},
  });
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    setupDashBoard();
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    setupDashBoard();
    // const res = await Promise.all([
    //   callApi("utils-countOrderApproved", { roundDate }),
    // ]);
    // if (!res.every((r) => r.status)) {
    //   window.alert("การนับมีปัญหา โปรดแจ้งโปรแกรมเมอร์");
    // } else {
    //   await setupDashBoard();
    // }
  };

  const setupDashBoard = async () => {
    const { data } = await callApi("utils-getDashboard", {
      roundDate: roundDate,
    });
    setState({
      lotteries: data["daily-lotteries-sales"] || {},
      order: data["daily-order-sales"] || {},
      income: data["daily-total-sales"] || {},
    });
  };
  const handleDownload = async () => {
    const date = thaiDate();

    const acc = [];

    acc.push(["ยอดขายรายวัน (บาท)"]);
    acc.push(["วันที่", "จำนวน"]);
    Object.entries(state.price).map(([key, value]) =>
      acc.push([key, `${value.toLocaleString()} บาท`])
    );
    const sumPrice = Object.values(state.price).reduce((acc, value) => {
      acc += Number(value);
      return acc;
    });

    acc.push(["รวม", `${sumPrice.toLocaleString()} บาท`]);
    acc.push(["", ""]);

    acc.push(["ยอดขายรายวัน (ใบ)"]);
    acc.push(["วันที่", "จำนวน"]);
    Object.entries(state.amount).map(([key, value]) =>
      acc.push([key, `${value} ใบ`])
    );

    const sumAmount = Object.values(state.amount).reduce((acc, value) => {
      acc += Number(value);
      return acc;
    });

    acc.push(["รวม", ` ${sumAmount.toLocaleString()} ใบ`]);
    acc.push(["", ""]);

    acc.push(["ยอดขายรายวัน (ออเดอร์)"]);
    acc.push(["วันที่", "จำนวน"]);
    Object.entries(state.order).map(([key, value]) =>
      acc.push([key, `${value} ออเดอร์`])
    );
    const sumOrder = Object.values(state.order).reduce((acc, value) => {
      acc += Number(value);
      return acc;
    });

    acc.push(["รวม", `${sumOrder.toLocaleString()} ออเดอร์`]);
    acc.push(["", ""]);

    setCsvData(acc);
    setCsvFileName(`รายงานยอดขายรายวัน_${date}.csv`);
  };

  const getSum = (x) => {
    return Object.values(x).reduce((acc, value) => {
      acc += Number(value);
      return acc;
    });
  };
  return (
    <Container>
      <Label.H2 weight={500}>รายงานยอดขายรายวัน</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>
      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      {!R.isEmpty(state.price) && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>

      <br />
      <br />

      {!R.isEmpty(state.income) && (
        <div style={{ maxWidth: "500px" }}>
          <h3>ยอดขายรายวัน (บาท)</h3>
          <Table striped bordered>
            <thead>
              <tr>
                <th>วันที่</th>
                <th>จำนวน</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(state.income)
                .sort((a, b) => {
                  const date1 = new Date(a[0]);
                  const date2 = new Date(b[0]);
                  return date2 - date1;
                })
                .map(([key, value], index) => {
                  const date = new Date(key);

                  return (
                    <tr key={index}>
                      <th>
                        {date.getDate() +
                          "/" +
                          (date.getMonth() + 1) +
                          "/" +
                          date.getFullYear()}
                      </th>
                      <th>{value.toLocaleString()} บาท</th>
                    </tr>
                  );
                })}
              <tr>
                <th>รวม</th>
                <th>{getSum(state.income).toLocaleString()} บาท</th>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      <br />
      {!R.isEmpty(state.order) && (
        <div style={{ maxWidth: "500px" }}>
          <h3>ยอดขายรายวัน (ออเดอร์)</h3>
          <Table striped bordered>
            <thead>
              <tr>
                <th>วันที่</th>
                <th>จำนวน</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(state.order)
                .sort((a, b) => {
                  const date1 = new Date(a[0]);
                  const date2 = new Date(b[0]);
                  return date2 - date1;
                })
                .map(([key, value], index) => {
                  const date = new Date(key);

                  return (
                    <tr key={index}>
                      <th>
                        {date.getDate() +
                          "/" +
                          (date.getMonth() + 1) +
                          "/" +
                          date.getFullYear()}
                      </th>
                      <th>{value.toLocaleString()} ออเดอร์</th>
                    </tr>
                  );
                })}
              <tr>
                <th>รวม</th>
                <th>{getSum(state.order).toLocaleString()} ออเดอร์</th>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      <br />
      {!R.isEmpty(state.lotteries) && (
        <div style={{ maxWidth: "500px" }}>
          <h3>ยอดขายรายวัน (ใบ)</h3>
          <Table striped bordered>
            <thead>
              <tr>
                <th>วันที่</th>
                <th>จำนวน</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(state.lotteries)
                .sort((a, b) => {
                  const date1 = new Date(a[0]);
                  const date2 = new Date(b[0]);
                  return date2 - date1;
                })
                .map(([key, value], index) => {
                  const date = new Date(key);
                  return (
                    <tr key={index}>
                      <th>
                        {date.getDate() +
                          "/" +
                          (date.getMonth() + 1) +
                          "/" +
                          date.getFullYear()}
                      </th>
                      <th>{value.toLocaleString()} ใบ</th>
                    </tr>
                  );
                })}
              <tr>
                <th>รวม</th>
                <th>{getSum(state.lotteries).toLocaleString()} ใบ</th>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

const Button = styled.button``;
