import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import * as R from "ramda";
import constant from "../../constants";

export const ReportPointRedeem = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);

  const [filteredState, setFilteredState] = useState([]);

  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    let res = await callApi("products-getAllTransaction", {
      limit: 10000,
      startAfter: "",
      queries: [
        ["roundDate", "==", roundDate],
        ["status", "==", constant.statuses.APPROVE],
        ["isPointRedeem", "==", true],
      ],
      roundDate: roundDate,
    });

    setFilteredState(res);
  };

  const handleDownload = async () => {
    const acc = [];

    acc.push(["รายงานแลกคะแนน"]);
    acc.push([
      "วันที่-เวลา",
      "รหัสHT",
      "ชื่อ-นามสกุล",
      "เบอร์โทรศัพท์",
      "ที่อยู่",
      "สินค้า",
      "ราคา",
      "คะแนนที่หัก",
      "คะแนนก่อนหัก",
      "คะแนนหลังหัก",
      "เงินที่จ่ายเพิ่ม",
      "รหัสออเดอร์",
    ]);
    filteredState.map((value, index) =>
      acc.push([
        thaiDate(new Date(parseInt(value.createdAt))),
        `HT${value.userNo}`,
        value.receiver.name,
        value.receiver.phone,
        value.receiver.address,
        value.product.name,
        value.product.point,
        value.payPoint,
        value.pointBeforeCharge,
        value.pointAfterCharge,
        value.payCash,
        value.shortId,
      ])
    );

    setCsvData(acc);
    setCsvFileName(`รายงานแลกคะแนน-${roundDate}.csv`);
  };

  let total = 0;
  return (
    <Container>
      <Label.H2 weight={500}>รายงานแลกคะแนน</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        value={roundDate}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>

      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      <br />
      <p>จำนวน {filteredState.length}</p>
      {!R.isEmpty(filteredState) && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}

      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      {!R.isEmpty(filteredState) && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>วันที่-เวลา</th>
              <th>รหัสHT</th>
              <th>ชื่อ-นามสกุล</th>
              <th>เบอร์โทรศัพท์</th>
              <th>ที่อยู่</th>
              <th>สินค้า</th>
              <th>ราคา</th>

              <th>คะแนนที่หัก</th>
              <th>คะแนนก่อนหัก</th>
              <th>คะแนนหลังหัก</th>
              <th>เงินที่จ่ายเพิ่ม</th>
              <th>รหัสออเดอร์</th>
            </tr>
          </thead>
          <tbody>
            {filteredState.map((item, index) => {
              total += item.price;
              return (
                <tr key={index}>
                  <th>{thaiDate(new Date(parseInt(item.createdAt)))}</th>
                  <th>HT{item.userNo}</th>
                  <th>{item.receiver.name}</th>
                  <th>{item.receiver.phone}</th>
                  <th>{item.receiver.address}</th>
                  <th>{item.product.name}</th>
                  <th>{item.product.point} คะแนน</th>
                  <th>{item.payPoint}</th>
                  <th>{item.pointBeforeCharge}</th>
                  <th>{item.pointAfterCharge}</th>
                  <th>{item.payCash}</th>
                  <th>{item.shortId} </th>
                </tr>
              );
            })}
            <tr>
              <th>รวม</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>{(total || 0).toLocaleString()}฿</th>
            </tr>
          </tbody>
        </Table>
      )}
      <br />
      <br />
    </Container>
  );
};

const Button = styled.button`
  margin: 2em 0;
`;
