import React from "react";
import Context from "../../Context";
import { cn } from "../../util/lib";

export const Text = ({ children, className, ...props }) => {
  const { themes } = Context();
  return (
    <p className={cn(themes.text, className)} {...props}>
      {children}
    </p>
  );
};
