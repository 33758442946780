import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import style from "../constants/style";
import styled from "styled-components";
import Context from "../Context";
import themes from "../themes";

export const StackImage = ({ urls }) => {
  const { configs } = Context();
  const [height, setHeight] = useState(0);

  console.log("url", urls);
  return (
    <Warper space={(urls.length + 4) * height}>
      {urls
        .reduce((acc, x) => {
          if (acc.includes(x)) {
            acc.push("/images/error_lotto.jpg");
          } else {
            acc.push(x);
          }

          return acc;
        }, [])
        .map((url, i) => (
          <Div index={i} space={height}>
            <Image
              ref={(el) => {
                if (el) {
                  setHeight(parseInt(el.width * 0.1));
                }
              }}
              key={i}
              src={url[0]}
            />

            {url[1] && (
              <Tag index={i}>
                <img
                  src={configs.magicNumber.image}
                  alt="magicNumber"
                  width={"30px"}
                  height={"50px"}
                  space={height}
                />
              </Tag>
            )}
            {url[2] && (
              <Tag index={i}>
                <img
                  src={configs.labubuNumber.image}
                  alt="labubuNumber"
                  width={"70px"}
                  height={"50px"}
                  index={i}
                  space={height}
                />
              </Tag>
            )}
          </Div>
        ))}
    </Warper>
  );
};
const Warper = styled.div`
  position: relative;
  padding-bottom: ${({ space }) => `${space}px`};
`;
const Image = styled.img`
  overflow: scroll;
  position: absolute;
  width: 100%;
`;

const Div = styled.div`
  top: ${({ index, space }) => `${index * space}px`};
  position: relative !important;
  :hover {
    z-index: 9999;
    ${style.css.lightShadow};
  }
`;
const Tag = styled.div`
  border-radius: 0 0 25px 25px;
  width: 15%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  background: ${themes.colors.primary};
`;

export default StackImage;
