import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { ScrollBar } from "./index";

export const ReportTopBuyer = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [downloadType, setDownloadType] = useState("admin");
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };
  const handleSearch = async () => {
    const result = await callApi("orders-getTopBuyer", {
      roundDate,
    });

    setData(result.data);
  };
  const handleDownload = async () => {
    const prepData = data;
    let listHead = [
      "ชื่อ-นามสกุล",
      "รหัส HT",
      "แจ้งเตือน",
      "เบอร์",
      "ไลน์",
      "จำนวน",
    ];

    const result = Object.values(prepData).reduce(
      (
        acc,
        { firstName, lastName, size, userNo, notifyType, lineId, phone },
        index
      ) => {
        acc.push([
          firstName + " " + lastName,
          userNo,
          notifyType,
          lineId,
          phone,
          size,
        ]);
        return acc;
      },
      []
    );
    result.unshift(listHead);
    setCsvData(result);
    setCsvFileName(`รายชื่อผู้ซื้อ Top 100 ${Date.now()}.csv`);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานผู้ซื้อ Top 100</Label.H2>
      <br />

      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates
          .sort()
          .reverse()
          .map((roundDate) => (
            <option key={roundDate}>{roundDate}</option>
          ))}
      </Input>

      <br />

      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <br />

      {data.length !== 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th></th>
              <th>ชื่อ-นามสกุล</th>
              <th>รหัส HT</th>
              <th>แจ้งเตือนทาง</th>
              <th>เบอร์</th>
              <th>ไลน์</th>
              <th>จำนวน</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(data).map(
              (
                {
                  firstName,
                  lastName,
                  phone,
                  lineId,
                  notifyType,
                  userNo,
                  size,
                },
                index
              ) => {
                return (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <th>{firstName + " " + lastName || "-"}</th>
                    <th>{userNo}</th>
                    <th>{notifyType}</th>
                    <th>{phone}</th>
                    <th>{lineId}</th>
                    <th>{size}</th>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const Button = styled.button``;
