import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { flatten } from "ramda";
export const ReportMails = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [downloadType, setDownloadType] = useState("admin");
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };
  const handleSearch = async () => {
    const data = await callApi("orders-getAllMail", {
      queries: [
        ["deliverChannel", "==", "mail"],
        ["roundDate", "==", roundDate],
        ["status", "==", "approved"],
      ],
      roundDate,
      // orderBy: ["createdAt", "desc"],
      startAfter: "",
      // limit: 1000,
    });

    const sortedData = data.sort((a, b) => b.createdAt - a.createdAt);
    setData(sortedData);
  };
  const handleDownload = async () => {
    const date = thaiDate();
    const prepData = data;

    const csvHeads = Object.keys(data[0]);
    let listHead = ["ลำดับ", "เลข", "เล่ม", "โฟลเดอร์"];

    const result = prepData.reduce((acc, x, index) => {
      const createDate = new Date(x.createdAt);
      const shippingDate = new Date(x.shippingDate);
      let lottoList = [];

      if (downloadType === "scanner") {
        Object.keys(x).forEach((ind) => {
          if (!csvHeads.includes(ind)) {
            csvHeads.push(ind);
          }
        });
        x.lotteries.forEach((lotto, index) => {
          lottoList.push([
            `${index + 1}`,
            lotto.id,
            lotto.bookNo || "ไม่พบ",
            lotto.fileNameId,
          ]);
        });
        acc.push(["ลำดับที่ ", index + 1]);
        acc.push(["รอบจัดส่ง", shippingDate]);
        acc.push(["รหัสคำสั่งซื้อ", x.shortId, "วันที่สั่งซื้อ", createDate]);
        acc.push([
          "จำนวนที่สั่งซื้อ",
          x.lotteries.length,
          "จำนวนเงินที่สั่งซื้อ",
          `${x.totalPrice} บาท`,
        ]);
        acc.push([
          "ชื่อ-นามสกุล",
          `${(x.firstName || "-") + " " + (x.lastName || "-")}`,
        ]);
        acc.push([
          "ข้อมูลที่อยู่ผู้ซื้อ",
          `${
            (x.address.addr || "-") +
            " " +
            (x.address.province || "-") +
            " " +
            (x.address.zipcode || "-")
          }`,
        ]);
        acc.push(listHead);
        lottoList.map((x) => acc.push(x));
        acc.push([
          "____________",
          "____________________________________________________________________________________________________________",
          "____________",
          "____________________________________________________________",
        ]);
      }
      if (downloadType === "admin") {
        acc.push([
          index + 1,
          shippingDate.getDate() +
            "/" +
            (shippingDate.getMonth() + 1) +
            "/" +
            shippingDate.getFullYear() +
            " " +
            shippingDate.getHours() +
            ":" +
            shippingDate.getMinutes() +
            ":" +
            shippingDate.getSeconds(),

          x.firstName + " " + x.lastName,
          createDate.getDate() +
            "/" +
            (createDate.getMonth() + 1) +
            "/" +
            createDate.getFullYear() +
            " " +
            createDate.getHours() +
            ":" +
            createDate.getMinutes() +
            ":" +
            createDate.getSeconds(),
          x.shortId,
          x.lotteries.length,
          (x.address.addr || "-") +
            " " +
            (x.address.province || "-") +
            " " +
            (x.address.zipcode || "-").toString(),
          `' ${x.phone}`,
          x.shippingStatus,
          x.shippingTrack || "-",
        ]);
      }
      return acc;
    }, []);
    result.unshift([
      "ลำดับ",
      "วันจัดส่ง",
      "ชื่อนามสกุล",
      "วันที่ซื้อ",
      "คำสั่งซื้อ",
      "จำนวนใบ",
      "ที่อยู่",
      "เบอร์โทรศัพท์",
      "สถานะการจัดส่ง",
      "เลขอ้างอิงการจัดส่ง",
    ]);
    setCsvData(result);
    setCsvFileName(`รายงานไปรษณีย์_${date}.csv`);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานไปรษณีย์</Label.H2>
      <br />

      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates
          .sort()
          .reverse()
          .map((roundDate) => (
            <option key={roundDate}>{roundDate}</option>
          ))}
      </Input>

      <br />

      <Input
        name="select"
        type="select"
        onChange={(e) => setDownloadType(e.target.value)}
        style={{ width: "100px" }}
      >
        {["admin", "scanner"].map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </Input>
      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <br />

      {data.length !== 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>รหัสคำสั่งซื้อ</th>
              <th>วันที่สั่งซื้อ</th>
              <th>วันที่จัดส่ง</th>
              <th>สถานะการจัดส่ง</th>
              <th>รหัสสมาชิก</th>
              <th>ชื่อ</th>
              <th>นามสกุล</th>
              <th>เบอร์โทร</th>
              <th>ที่อยู่</th>
              <th>จำนวนที่สั่งซื้อ</th>
              <th>ราคา</th>
              <th>เลขลอตเตอรี่</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const createDate = new Date(item.createdAt);
              const shippingDate = new Date(item.shippingDate);
              return (
                <tr key={item.shortId}>
                  <th>{index + 1}</th>
                  <th>{item.shortId || "-"}</th>
                  <th>
                    {createDate.getDate() +
                      "/" +
                      (createDate.getMonth() + 1) +
                      "/" +
                      createDate.getFullYear() +
                      " " +
                      createDate.getHours() +
                      ":" +
                      createDate.getMinutes() +
                      ":" +
                      createDate.getSeconds()}
                  </th>
                  <th>
                    {shippingDate.getDate() +
                      "/" +
                      (shippingDate.getMonth() + 1) +
                      "/" +
                      shippingDate.getFullYear()}
                  </th>
                  <th>{item.shippingStatus || "-"}</th>
                  <th>{item.userId || "-"}</th>
                  <th>{item.firstName || "-"}</th>
                  <th>{item.lastName || "-"}</th>
                  <th>{item.phone || "-"}</th>
                  <th>
                    {item.address?.addr || "-"}
                    <br />
                    {item.address?.province || "-"}
                    <br />
                    {item.address?.zipcode || "-"}
                  </th>

                  <th>{item.lotteries.length} ใบ</th>
                  <th>{item.totalPrice} บาท</th>
                  <th>
                    <ScrollBar>
                      {item.lotteries.map((lotto, index) => (
                        <h5 key={index}>
                          {lotto.id}{" "}
                          {`-${lotto.bookNo ? lotto.bookNo : "ไม่พบเลขเล่ม"} `}
                          {`-${
                            lotto.fileNameId
                              ? lotto.fileNameId
                              : "ไม่พบชื่อไฟล์"
                          }`}
                        </h5>
                      ))}{" "}
                    </ScrollBar>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export const ScrollBar = styled.div`
  max-height: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const Button = styled.button``;
