import { useState, useEffect } from "react";
import { Loading } from "../../components/modules";
import { collection, doc, onSnapshot } from "firebase/firestore";
import {
  Container,
  Input,
  Text,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "../../components/base";
import {
  ScrollArea,
  Avatar,
  AvatarImage,
  AvatarFallback,
  Separator,
  Toaster,
  useToast,
  ToastAction,
  Skeleton,
} from "../../components/ui";
import { CgProfile } from "react-icons/cg";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { FiUserPlus } from "react-icons/fi";

import Context from "../../Context";
import { MdPersonSearch } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import {
  FaCompress,
  FaExpand,
  FaTimes,
  FaCheckCircle,
  FaHourglassHalf,
  FaTimesCircle,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";
import { banks } from "../../constants";
import { FiShare2 } from "react-icons/fi";

import { useNavigate, useLocation } from "react-router-dom";

export const Users = () => {
  const { callApi, isDarkTheme, themes, configs, db } = Context();

  const location = useLocation();
  const { hash, pathname, search } = location;
  const [query, setQuery] = useState(
    search.length > 0
      ? { key: "userId", value: search.slice(1) }
      : { key: "", value: "" }
  );

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(
    localStorage.getItem("selectedUser") || false
  );
  const [selectedUserOrders, setSelectedUserOrders] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      getUserOrder();
      getUserInfo();
      const intervalId = setInterval(async () => {
        const user = await callApi("users-getUserInfoAdmin", {
          userId: selectedUser,
        });

        const orders = await callApi("orders-getAll", {
          roundDate: configs.roundDate,
          queries: [["userId", selectedUser]],
          limit: 10,
          orderBy: ["createdAt", "desc"],
        });

        user && setUser(user || false);
        orders && setSelectedUserOrders(orders || []);
      }, 5000);
      return () => clearInterval(intervalId);
    } else {
      setUser({});
      setSelectedUser(false);
      localStorage.removeItem("selectedUser");
      setSelectedUserOrders([]);
    }
  }, [selectedUser]);

  const getUserInfo = async () => {
    setUser({ isLoading: true });
    const result = await callApi("users-getUserInfoAdmin", {
      userId: selectedUser,
    });

    setUser(result || false);
  };

  const getUserOrder = async () => {
    setSelectedUserOrders({ isLoading: true });
    const result = await callApi("orders-getAll", {
      roundDate: configs.roundDate,
      queries: [["userId", selectedUser]],
      limit: 10,
      orderBy: ["createdAt", "desc"],
    });

    setSelectedUserOrders(result || []);
  };

  const updateUserInfo = async (userId, body) => {
    if (!userId) return;
    const { status } = await callApi("users-updateUserInfoAdmin", {
      userId: userId,
      data: body,
    });
    if (status) {
      getUserInfo();
    }
    return status;
  };

  const fetchUsers = async () => {
    const queries = query.key && query.value ? [[query.key, query.value]] : [];
    setUsers([]);
    const result = await callApi("users-getAll", {
      queries: queries,
      limit: 35,
      orderBy: ["createdAt", "desc"],
    });
    setUsers(result.length > 0 ? result : false);
    if (result.length === 1) {
      setSelectedUser(result[0].id);
    }
  };

  const handleSearch = () => {
    if (!query.value) {
      setQuery((prevState) => ({ ...prevState, key: false }));
    } else {
      if (new RegExp(/^\d+$|^$/).test(query.value)) {
        if (query.value.length >= 6) {
          setQuery((prevState) => ({ ...prevState, key: "phone" }));
        } else {
          setQuery((prevState) => ({ ...prevState, key: "userId" }));
        }
      } else {
        setQuery((prevState) => ({ ...prevState, key: "firstName" }));
      }
    }

    fetchUsers();
  };

  return (
    <div className="flex flex-col xl:flex-row gap-3">
      <Container
        className={` p-1 w-full h-fit shadow-md ${
          selectedUser && "xl:w-[300px]"
        }`}
      >
        {/* <Container className="h-[800px] w-full max-w-[300px] rounded-[20px] shadow-xl"> */}
        <div className="flex items-center gap-2.5 m-2">
          <div className={`relative z-10 flex-grow`}>
            <MdPersonSearch
              className={`absolute w-6 h-6 top-3 right-5 cursor-pointer duration-100 ${
                isDarkTheme ? "text-white" : " hover:text-[rgb(214,212,212)]"
              } ${query.value ? "text-[rgb(214,212,212)]" : "text-input"}`}
              onClick={() => handleSearch()}
            />

            {query?.value && (
              <FaTimes
                className={`absolute w-6 h-6 top-3 right-14 cursor-pointer duration-100 ${
                  isDarkTheme
                    ? "text-white"
                    : "text-[rgb(214,212,212)] hover:text-red-200"
                } `}
                onClick={() => setQuery({ key: "", value: "" })}
              />
            )}
            <Input
              className="rounded-2xl"
              placeholder="ค้นหา"
              value={query.value || ""}
              onChange={(e) =>
                setQuery({
                  key: new RegExp(/^\d+$|^$/).test(query.value)
                    ? query.value.length >= 6
                      ? "phone"
                      : "userId"
                    : "firstName",
                  value: e.target.value,
                })
              }
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
          </div>
          <div
            className={`flex items-center p-3 font-bold justify-center h-12 rounded-2xl duration-100 cursor-pointer ${
              isDarkTheme
                ? "text-[rgb(26,29,32)] bg-white "
                : "bg-[rgb(26,29,32)] text-gray-50"
            } hover:opacity-90`}
          >
            <FiUserPlus />
          </div>
        </div>

        {!users ? (
          <div className="flex justify-center items-center h-72">
            ไม่พบสิ่งที่ค้นหา
          </div>
        ) : users.length > 0 ? (
          <ScrollArea
            className={`relative ${
              users.length > 5
                ? `${selectedUser && "h-[300px]"} h-[80vh]`
                : "h-fit"
            } px-2 py-0 my-2`}
          >
            {users.map((user, index) => {
              return (
                <UserList
                  userData={user}
                  isDarkTheme={isDarkTheme}
                  key={user.userNo}
                  setSelectedUser={setSelectedUser}
                  isSelectedUser={selectedUser === user.id}
                  isNotLast={index !== users.length - 1}
                />
              );
            })}
          </ScrollArea>
        ) : (
          <div className="flex justify-center items-center h-72">
            <Loading />
          </div>
        )}
        {/* </Container> */}
      </Container>

      {selectedUser && (
        <div className="w-full flex flex-col gap-3 flex-grow lg:flex-row">
          <UserDetail
            selectedUser={selectedUser || false}
            isDarkTheme={isDarkTheme}
            user={user}
            setSelectedUser={setSelectedUser}
            updateUserInfo={updateUserInfo}
          />
          <Container
            className={`relative p-2 py-0 h-fit duration-150 w-full lg:w-1/3`}
          >
            <div className="w-full">
              <div className="flex ml-2 gap-2 items-center">
                <RiShoppingBag3Fill className={themes.text} />
                <Text className="text-md font-bold my-3 ">ออเดอร์ล่าสุด</Text>
              </div>

              {selectedUserOrders?.isLoading ? (
                <Container className="w-full flex justify-center items-center h-[400px]">
                  <Loading />
                </Container>
              ) : selectedUserOrders?.length === 0 ? (
                <div
                  className={`flex justify-center items-center border border-gray-600 relative h-[360px] p-2 mb-2 rounded-2xl`}
                >
                  <Text className="text-md text-gray-400 my-3 ">
                    ไม่พบออเดอร์
                  </Text>
                </div>
              ) : (
                <ScrollArea
                  className={` relative h-[360px] p-2 my-0 rounded-2xl`}
                >
                  {selectedUserOrders.map((order, index) => {
                    return (
                      <div className="mr-1" key={order.shortId}>
                        <div
                          className={`flex justify-between w-full min-h-16 items-center px-3 py-2 text-sm font-bold rounded-xl duration-100 cursor-pointer ${
                            isDarkTheme
                              ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
                              : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
                          }`}
                        >
                          <div>
                            <p>{order.shortId}</p>
                            <p className="text-xs opacity-55">
                              {order.size} ใบ |{" "}
                              {order.totalPrice.toLocaleString()}฿
                            </p>
                            <p className="text-[9px] mt-3 opacity-55 truncate">
                              {new Date(order.createdAt).toLocaleDateString(
                                "th-TH",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }
                              )}
                            </p>
                          </div>
                          {order.status === "approved" && (
                            <div className="flex flex-col w-14 gap-1 justify-center items-center">
                              <FaCheckCircle className="text-lg" />
                              <p className="text-[8px] font-light">อนุมัติ</p>
                            </div>
                          )}
                          {order.status === "pending" && (
                            <div className="flex flex-col w-14 gap-1 justify-center items-center">
                              <FaHourglassHalf className="text-lg" />
                              <p className="text-[8px] font-light">
                                รอดำเนินการ
                              </p>
                            </div>
                          )}
                          {order.status === "canceled" && (
                            <div className="flex flex-col w-14 gap-1 justify-center items-center">
                              <FaTimesCircle className="text-lg" />
                              <p className="text-[8px] font-light">ยกเลิก</p>
                            </div>
                          )}
                          {order.status === "paid" && (
                            <div className="flex flex-col w-14 gap-1 justify-center items-center">
                              <FaMoneyBillAlt className="text-lg" />
                              <p className="text-[8px] font-light">
                                จ่ายเงินแล้ว
                              </p>
                            </div>
                          )}
                        </div>
                        {index !== selectedUserOrders.length - 1 && (
                          <Separator className="pr-3 my-2" />
                        )}
                      </div>
                    );
                  })}
                </ScrollArea>
              )}
            </div>
          </Container>
        </div>
        // ) : (
        //   <div className="flex justify-center items-center h-[100px] xl:h-[80dvh] w-full text-gray-900 cursor-default font-semibold opacity-10">
        //     hongthong admin v.1.00
        //   </div>
      )}
    </div>
  );
};

const UserDetail = ({
  selectedUser,
  setSelectedUser,
  user,
  updateUserInfo,
}) => {
  const { toast } = useToast();
  const [isMinimize, setMinimize] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [body, setBody] = useState({ bank: { ...user.bank } });
  const [isLoading, setIsLoading] = useState(false);
  const { themes } = Context();

  const minimizeContent = () => {
    setEdit(false);
    setMinimize(!isMinimize);
  };

  const updateProfile = async () => {
    setIsLoading(true);
    const result = await updateUserInfo(user?.userId, body);
    if (result) {
      setEdit(false);
      setIsLoading(false);
      toast({
        variant: "destructive",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });

      return result;
    }
    setIsLoading(false);
    toast({
      variant: "destructive",
      title: (
        <div className="flex gap-2 items-center">
          <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
        </div>
      ),
    });
  };

  if (!selectedUser || !user) return;

  return (
    <Container
      onClick={() => {
        if (isMinimize) {
          setMinimize(false);
        }
      }}
      className={`${
        isMinimize && "cursor-pointer"
      } relative p-3 h-fit duration-150 w-full lg:w-2/3`}
    >
      <div
        className={`flex justify-between ${
          !isMinimize && "flex-col-reverse"
        } xl:flex-row gap-3`}
      >
        <div
          className={`flex gap-3 ${
            !isMinimize && "flex-col"
          } items-center md:flex-row`}
        >
          {user?.isLoading ? (
            <Skeleton
              className={`${
                isEdit || isMinimize
                  ? "w-12 h-12 duration-250"
                  : "w-24 h-24 duration-250"
              } rounded-full`}
            />
          ) : (
            <Avatar
              className={
                isEdit || isMinimize
                  ? "w-12 h-12 duration-150"
                  : "w-24 h-24 duration-150"
              }
            >
              <AvatarImage
                src={user?.profileImage || "https://github.com/shadcn.png"}
              />
              <AvatarFallback>{user?.firstName?.slice(0, 2)}</AvatarFallback>
            </Avatar>
          )}
          {user.isLoading ? (
            <div>
              <Skeleton className="h-4 m-1 w-[250px]" />
              <Skeleton className="h-4 m-1 w-[250px]" />
            </div>
          ) : isMinimize ? (
            <div className="flex flex-col md:flex-row items-start md:items-center gap-1">
              <Text className={`text-md sm:text-xl font-bold center`}>
                {`${user?.firstName} ${user?.lastName}`}
              </Text>
              <Text className={`text-xs sm:text-sm text-gray-400 font-bold`}>
                #{user?.userId} | {user?.phone}
              </Text>
            </div>
          ) : isEdit ? (
            <></>
          ) : (
            <div className="flex flex-col justify-center gap-1 items-center md:items-start">
              <div className="flex 2xl:items-center gap-1 2xl:gap-3 flex-col 2xl:flex-row ">
                <Text className={`text-xl sm:text-xl font-bold center`}>
                  {`${user?.firstName} ${user?.lastName}`}{" "}
                </Text>
                <Text className={`text-sm text-gray-400 font-bold`}>
                  #{user?.userId}
                </Text>
              </div>
              <Text className={`text-sm mb-3 text-gray-400 font-bold`}>
                {user?.phone}
              </Text>
              <button
                className={`bg-[rgb(1,113,216)] w-fit rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
              >
                เข้าสู่ระบบด้วยบัญชีนี้
              </button>
            </div>
          )}
        </div>
        <div
          className={`flex gap-3 justify-end ${isMinimize && "items-center"}`}
        >
          {isMinimize ? (
            <>
              <FiShare2
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://admin.hongthongofficial.com/users?${user?.id}`
                  );

                  toast({
                    title: "คัดลอกสำเร็จ",
                    description: `https://admin.hongthongofficial.com/users?${user?.id}`,
                  });
                }}
                className="cursor-pointer text-gray-400 duration-150 hover:text-gray-900"
              />
              <FaExpand
                onClick={() => minimizeContent()}
                className="cursor-pointer text-gray-400 duration-150 hover:text-gray-900"
              />
            </>
          ) : (
            <>
              {user.userId && !isEdit && (
                <FiEdit3
                  onClick={() => setEdit(!isEdit)}
                  className="cursor-pointer text-gray-400 duration-150 hover:text-gray-900"
                />
              )}
              <FiShare2
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://admin.hongthongofficial.com/users?${user?.id}`
                  );

                  toast({
                    title: "คัดลอกสำเร็จ",
                    description: `https://admin.hongthongofficial.com/users?${user?.id}`,
                  });
                }}
                className="cursor-pointer text-gray-400 duration-150 hover:text-gray-900"
              />
              <FaCompress
                onClick={() => minimizeContent()}
                className="cursor-pointer text-gray-400 duration-150 hover:text-gray-900"
              />
            </>
          )}
          <FaTimes
            onClick={() => setSelectedUser(false)}
            className="cursor-pointer text-gray-400 duration-150 hover:text-red-600"
          />
        </div>
      </div>
      {!isMinimize && (
        <div className="mt-3">
          <Separator />

          {isEdit ? (
            <div>
              <div className="flex mt-3 gap-2 items-center">
                <CgProfile className={themes.text} />
                <Text className="text-md font-bold">ข้อมูลส่วนตัว</Text>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 max-w-[800px] gap-3 mt-3 w-full">
                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>ชื่อ</Text>
                  <Input
                    className=""
                    placeholder="ชื่อ"
                    defaultValue={user.firstName || ""}
                    onKeyDown={(e) => e.key === "Enter" && updateProfile()}
                    onChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        firstName: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>นามสกุล</Text>
                  <Input
                    className=""
                    placeholder="นามสกุล"
                    defaultValue={user.lastName || ""}
                    onKeyDown={(e) => e.key === "Enter" && updateProfile()}
                    onChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        lastName: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>
                    เบอร์โทรศัพท์
                  </Text>
                  <Input
                    className=""
                    placeholder="เบอร์โทรศัพท์"
                    defaultValue={user.phone || ""}
                    onKeyDown={(e) => e.key === "Enter" && updateProfile()}
                    onChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        phone: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>สัญชาติ</Text>
                  <Select
                    defaultValue={user.nationality}
                    onValueChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        nationality: e,
                      }))
                    }
                  >
                    <SelectTrigger className="my-1 text-md font-medium">
                      <SelectValue placeholder="--ยังไม่ได้เลือก--" />
                    </SelectTrigger>
                    <SelectContent className="bg-black">
                      <SelectItem
                        className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                        value="TH"
                      >
                        ไทย
                      </SelectItem>
                      <SelectItem
                        className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                        value="LAO"
                      >
                        ลาว
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="h-fit w-full md:col-span-2">
                  <Text className={"text-md font-semibold mb-2"}>ที่อยู่</Text>
                  <Textarea
                    className=""
                    placeholder="ที่อยู่"
                    defaultValue={user.address?.addr || ""}
                    onKeyDown={(e) => e.key === "Enter" && updateProfile()}
                    onChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        address: { ...prevState.address, addr: e.target.value },
                      }))
                    }
                  />
                </div>
                <div className="h-fit w-full">
                  <Text className={"text-md font-semibold mb-2"}>จังหวัด</Text>
                  <Input
                    className=""
                    placeholder="จังหวัด"
                    defaultValue={user.address?.province || ""}
                    onKeyDown={(e) => e.key === "Enter" && updateProfile()}
                    onChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        address: {
                          ...prevState.address,
                          province: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>
                    รหัสไปรษณีย์
                  </Text>
                  <Input
                    className=""
                    placeholder="รหัสไปรษณีย์"
                    defaultValue={user.address?.zipcode || ""}
                    onKeyDown={(e) => e.key === "Enter" && updateProfile()}
                    onChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        address: {
                          ...prevState.address,
                          zipcode: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
              <Separator className="mb-3 mt-6" />
              <div className="flex mt-3 gap-2 items-center">
                <MdAccountBalance className={themes.text} />
                <Text className="text-md font-bold">บัญชีธนาคาร</Text>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 max-w-[800px] gap-3 mt-3 w-full">
                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>ธนาคาร</Text>
                  <Select
                    defaultValue={user.bank?.name}
                    onValueChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        bank: {
                          ...prevState.bank,
                          name: e,
                        },
                      }))
                    }
                  >
                    <SelectTrigger className="my-1 text-md font-medium">
                      <SelectValue placeholder="--ยังไม่ได้เลือก--" />
                    </SelectTrigger>
                    <SelectContent className="bg-black">
                      {banks.map((bank) => {
                        return (
                          <SelectItem
                            className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                            value={bank}
                          >
                            {bank}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </div>
                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>
                    ชื่อบัญชี
                  </Text>
                  <Input
                    className=""
                    placeholder="ชื่อบัญชี"
                    defaultValue={user.bank?.accName || ""}
                    onKeyDown={(e) => e.key === "Enter" && updateProfile()}
                    onChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        bank: {
                          ...prevState.bank,
                          accName: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>เลขบัญชี</Text>
                  <Input
                    className=""
                    placeholder="เลขบัญชี"
                    defaultValue={user.bank?.accNo || ""}
                    onKeyDown={(e) => e.key === "Enter" && updateProfile()}
                    onChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        bank: {
                          ...prevState.bank,
                          accNo: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="h-fit w-full ">
                  <Text className={"text-md font-semibold mb-2"}>ค่าเงิน</Text>
                  <Select
                    defaultValue={user.bank?.currency}
                    onValueChange={(e) =>
                      setBody((prevState) => ({
                        ...prevState,
                        bank: {
                          ...prevState.bank,
                          currency: e,
                        },
                      }))
                    }
                  >
                    <SelectTrigger className="my-1 text-md font-medium">
                      <SelectValue placeholder="--ยังไม่ได้เลือก--" />
                    </SelectTrigger>
                    <SelectContent className="bg-black">
                      <SelectItem
                        className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                        value="THB"
                      >
                        บาท (ไทย)
                      </SelectItem>
                      <SelectItem
                        className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                        value="LAK"
                      >
                        กีบ (ลาว)
                      </SelectItem>
                      <SelectItem
                        className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                        value="KRW"
                      >
                        วอน (เกาหลี)
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="flex items-center gap-3 mt-14">
                <button
                  onClick={() => setEdit(!isEdit)}
                  className="py-2 px-3 rounded-lg border-2 border-[rgb(1,113,216)] text-[rgb(1,113,216)] font-semibold"
                >
                  ยกเลิก
                </button>
                {isLoading ? (
                  <Loading className="mx-6 border-[rgb(1,113,216)] border-r-[rgb(0,0,0,0)]" />
                ) : (
                  <button
                    onClick={() => updateProfile()}
                    className="py-2 px-3 rounded-lg border-0 bg-[rgb(1,113,216)] text-white font-semibold"
                  >
                    บันทึก
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-col justify-start items-start">
                <div className="w-full">
                  <div className="flex ml-2 gap-2 items-center">
                    <CgProfile className={themes.text} />
                    <Text className="text-md font-bold my-3 ">
                      ข้อมูลส่วนตัว
                    </Text>
                  </div>
                  {/* <div className="flex flex-wrap flex-grow flex-col w-fit items-start sm:flex-row"> */}
                  {user.isLoading ? (
                    <div className="w-full h-64 flex items-center justify-center">
                      <Loading />
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      <div className="p-3 h-fit break-all w-full">
                        <Text className={"text-sm font-medium mb-2"}>
                          ชื่อ - นามสกุล
                        </Text>
                        <Text className={"text-sm font-semibold"}>
                          {user?.firstName} {user?.lastName}
                        </Text>
                      </div>
                      <div className="p-3 h-fit break-all w-full">
                        <Text className={"text-sm font-medium mb-2"}>
                          เบอร์โทรศัพท์
                        </Text>
                        <Text className={"text-sm font-semibold"}>
                          {user?.phone}
                        </Text>
                      </div>
                      <div className="p-3 h-fit break-all w-full">
                        <Text className={"text-sm font-medium mb-2"}>
                          สัญชาติ
                        </Text>
                        <Text className={"text-sm font-bold"}>
                          {user?.nationality === "TH"
                            ? "ไทย"
                            : user?.nationality === "LAO"
                            ? "ลาว"
                            : user?.nationality}
                        </Text>
                      </div>
                      <div className="p-3 h-fit break-all w-full">
                        <Text className={"text-sm font-medium mb-2"}>
                          อีเมล์
                        </Text>
                        <Text className={"text-sm font-semibold"}>
                          {user?.email || "-"}
                        </Text>
                      </div>
                      <div className="p-3 h-fit break-all w-full">
                        <Text className={"text-sm font-medium mb-2"}>
                          ไลน์ไอดี
                        </Text>
                        <Text className={"text-sm font-semibold"}>
                          {user?.lineId || "-"}
                        </Text>
                      </div>{" "}
                      <div className="p-3 h-fit break-all w-full">
                        <Text className={"text-sm font-medium mb-2"}>
                          ชื่อไลน์
                        </Text>
                        <Text className={"text-sm font-semibold"}>
                          {user?.lineTitle || "-"}
                        </Text>
                      </div>
                      <div className="p-3 h-fit w-full ">
                        <Text className={"text-sm font-medium mb-2"}>
                          ที่อยู่
                        </Text>
                        <Text className={"text-sm font-semibold"}>
                          {user?.address?.addr || "-"}
                        </Text>
                      </div>
                      <div className="p-3 h-fit break-all w-full">
                        <Text className={"text-sm font-medium mb-2"}>
                          จังหวัด
                        </Text>
                        <Text className={"text-sm font-semibold"}>
                          {user?.address?.province || "-"}
                        </Text>
                      </div>
                      <div className="p-3 h-fit  w-full">
                        <Text className={"text-sm font-medium mb-2"}>
                          รหัสไปรษณีย์
                        </Text>
                        <Text className={"text-sm font-semibold"}>
                          {user?.address?.zipcode || "-"}
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
                <Separator className="mt-3 mr-[10px]" />
                <div className="flex ml-2 my-3 gap-2 items-center">
                  <MdAccountBalance className={themes.text} />
                  <Text className="text-md font-bold">บัญชีธนาคาร</Text>
                </div>
                {user.isLoading ? (
                  <div className="w-full h-64 flex items-center justify-center">
                    <Loading />
                  </div>
                ) : (
                  <div className="grid grid-cols-1 w-full md:grid-cols-2">
                    <div className=" p-3 h-fit break-all">
                      <Text className={"text-sm font-medium mb-2"}>ธนาคาร</Text>
                      <Text className={"text-sm font-semibold"}>
                        {user?.bank?.name || "-"}
                      </Text>
                    </div>
                    <div className=" p-3 h-fit break-all">
                      <Text className={"text-sm font-medium mb-2"}>
                        ชื่อบัญชี
                      </Text>
                      <Text className={"text-sm font-semibold"}>
                        {user?.bank?.accName || "-"}
                      </Text>
                    </div>
                    <div className=" p-3 h-fit break-all">
                      <Text className={"text-sm font-medium mb-2"}>
                        เลขที่บัญชี
                      </Text>
                      <Text className={"text-sm font-semibold"}>
                        {user?.bank?.accNo || "-"}
                      </Text>
                    </div>
                    <div className=" p-3 h-fit break-all">
                      <Text className={"text-sm font-medium mb-2"}>
                        ค่าเงินที่ใช้
                      </Text>
                      <Text className={"text-sm font-semibold"}>
                        {user?.bank?.currency || "-"}
                      </Text>
                    </div>
                  </div>
                )}
                <Separator className="mt-3 mr-[10px]" />
                <div className="flex ml-2 my-3 gap-2 items-center">
                  <MdAccountBalance className={themes.text} />
                  <Text className="text-md font-bold">อื่นๆ</Text>
                </div>
                <div className="w-full h-48" />
              </div>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

const UserList = ({
  userData,
  isDarkTheme,
  setSelectedUser,
  isSelectedUser,
  isNotLast,
}) => {
  const handleSelectUser = (id) => {
    if (isSelectedUser) {
      setSelectedUser(false);
    } else {
      localStorage.setItem("selectedUser", userData.id);
      setSelectedUser(userData.id);
    }
  };
  return (
    <div>
      <div
        className={`flex gap-3 items-center p-3 rounded-xl duration-100 cursor-pointer ${
          isSelectedUser
            ? isDarkTheme
              ? "text-[rgb(26,29,32)] bg-white "
              : "bg-[rgb(26,29,32)] text-gray-50 "
            : isDarkTheme
            ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
            : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
        }`}
        onClick={() => {
          handleSelectUser();
        }}
      >
        <Avatar className={"w-12 h-12"}>
          <AvatarImage
            src={userData.profileImage || "https://github.com/shadcn.png"}
          />
          <AvatarFallback>{userData.firstName.slice(0, 2)} </AvatarFallback>
        </Avatar>
        <div>
          <p className={`text-sm font-semibold xl:max-w-48 truncate`}>
            {`${userData.firstName} ${userData.lastName}`}
          </p>
          <p
            className={`text-sm font-semibold text-gray-400 xl:max-w-48 truncate
      `}
          >
            {userData.phone}
          </p>
          <p
            className={`text-sm font-semibold text-gray-400 xl:max-w-48 truncate
      `}
          >
            {userData.id}
          </p>
        </div>
      </div>
      {isNotLast && <Separator className="my-2" />}
    </div>
  );
};
