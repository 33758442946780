import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import ButtonLoading from "../../components/ButtonLoading";
import { Table, Input, Badge } from "reactstrap";
import { Flex, Label } from "../../components";
import { unixToDateAndTime } from "../../util";
import { isNil, isEmpty } from "ramda";
import styled from "styled-components";
import themes from "../../themes";
import { GridItem } from "../dashboard/Overall";
const CheckBookLotteries = () => {
  const { callApi, profileState, go, configs } = Context();
  const [unimported, setUnimported] = useState({});
  const [fileNameId, setFileNameId] = useState("");
  const [filteredData, setFilteredData] = useState({});
  const [totalLotto, setTotalLotto] = useState(0);
  const [totalUnImported, setTotalUnImported] = useState(0);

  const getFilteredData = async () => {
    const data = await callApi("lotteries-getAll", {
      queries: [
        ["fileNameId", "==", fileNameId],

        ["roundDate", "==", configs.roundDate],
      ],

      limit: 200,
    });
    setTotalLotto(data.length);
    setFilteredData(data);
    console.log("data", data);
  };

  useEffect(() => {}, []);

  const getBadgeColor = (value) => {
    if (Number(value) === 100) {
      return "success";
    } else if (Number(value) >= 90) {
      return "info";
    }
  };

  return (
    <>
      <Flex margin="3em 0" align="center" width="100%">
        <Flex direction="column">
          <div style={{ width: "100%", display: "flex" }}></div>
          <Flex align="center">
            <Label.H5 margin="0 1em 0 0" weight={500}>
              ค้นหาข้อมูลโดยชื่อเล่ม
            </Label.H5>

            <Input
              name="fileNameId"
              type="text"
              placeholder="0000-100-zip"
              value={fileNameId}
              onChange={(e) => setFileNameId(e.target.value)}
              style={{ width: "200px" }}
            />

            <button
              disabled={!fileNameId}
              style={{ margin: "0 10px " }}
              onClick={() => getFilteredData()}
            >
              ค้นหา
            </button>
            <button
              disabled={!fileNameId}
              style={{ margin: "0 10px " }}
              onClick={() => {
                setFileNameId("");
                setFilteredData({});
              }}
            >
              รีเซ็ต
            </button>
          </Flex>
        </Flex>
      </Flex>
      <Label.H2>พบข้อมูลทั้งหมด {totalLotto} ใบ</Label.H2>
      <br />
      <FullScrollBar>
        <Table striped hover>
          <thead>
            <tr>
              <th className="align-middle text-center">ลำดับที่</th>
              <th className="align-middle text-center">เวลานำเข้า</th>
              <th className="align-middle text-center">เลข</th>

              <th className="align-middle text-center">นำเข้าโดย</th>
              {/* <th /> */}
            </tr>
          </thead>
          <tbody>
            {isEmpty(filteredData) ? (
              <>
                <br />
                <h1>ไม่พบข้อมูล</h1>
              </>
            ) : (
              filteredData
                .sort((a, b) => a.lastTwo - b.lastTwo)
                .map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle text-center">{index + 1}</td>
                      <td className="align-middle text-center">
                        {unixToDateAndTime(data.createdAt).replace(
                          "T",
                          " เวลา "
                        )}
                      </td>
                      <td className="align-middle text-center">{data.id}</td>

                      <td className="align-middle text-center">
                        {data.uploader}
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </Table>
      </FullScrollBar>
      <br />
    </>
  );
};
const GridContainer = styled.div`
  display: grid;
  /* grid-template-columns: auto auto auto; */
  /* grid-template-columns: repeat(auto-fit, minmax(32%, max-content)); */
  grid-template-columns: auto auto auto auto;
  gap: 1.25rem;

  @media only screen and (max-width: 1368px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 1124px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 987px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const FullScrollBar = styled(ScrollBar)`
  width: 100% !important;
  max-width: 100% !important;
  max-height: 70vh;
`;
export default CheckBookLotteries;
