/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Pages from "./pages/";
import styled from "styled-components";
import ModalLoading from "./components/ModalLoading";
import { ContextInit } from "./Context";
import axios from "axios";
import SideBar from "./components/SideBar";
import Authenticate from "./components/Authenticate";
import { Sidebar } from "./components/modules";
import config from "./constants/configs";
import { db } from "./util/firebase";
import { flatten } from "ramda";
import themes from "./themes";
import { Collapse } from "reactstrap";
import { GiNightSky } from "react-icons/gi";
import { Container, Text, Switch } from "./components/base";
import { Separator, Toaster } from "./components/ui";
import { roundDateToDate } from "./util";
import { WiDayCloudy } from "react-icons/wi";
import { Minimize } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { AllPath } from "./components/modules/sidebar";
import { RiMenu2Fill, RiMenuFill } from "react-icons/ri";
import { Login } from "./pages/Login";
import { paths } from "./constants";
import { IoMdCalendar } from "react-icons/io";

function App() {
  const [modalLoading, setModalLoading] = useState({ isShow: false });

  const hideModal = () => {
    setModalLoading({ isShow: false });
  };
  //modal function --------------------------------------------------

  const [configs, setConfigs] = useState();
  const [profileState, setProfileState] = useState();
  const [adminCart, setAdminCart] = useState([]);

  const [testAccess, setTestAccess] = useState(localStorage?.testAccess || "N");
  const [minimize, setMinimize] = useState(
    localStorage.getItem("sidebar_minimize") === "Y" || false
  );
  const [isDarkTheme, setIsDarkTheme] = useState(
    localStorage.isDarkTheme === "true"
  );
  const navigate = useNavigate();
  const location = useLocation();

  const callApi = async (path, body = {}, options = {}) => {
    const url = config.api;
    !options.notShowLoading && setModalLoading({ isShow: true });
    try {
      var data = JSON.stringify(body);
      var axiosConfigs = {
        method: body ? "post" : "get",
        url: url + path,
        headers: {
          "Content-Type": body.append
            ? "application/x-www-form-urlencoded"
            : "application/json",
          accessToken: localStorage.accessToken,
          action: path,
          ...configs,
          ...body.configs,
        },
        data,
      };
      const response = await axios(axiosConfigs);
      !options.notShowLoading && setModalLoading({ isShow: false });
      if (response.data.code === 403 || response.data.code === 401) {
        localStorage.removeItem("accessToken");
        window.alert(response?.data?.toastMessage);
        window.location.reload();
        return false;
      }

      if (response.data.refresh) {
        window.location.reload();
      }
      if (response?.data?.toastMessage) {
        window.alert(response?.data?.toastMessage);
      }
      return response.data;
    } catch (err) {
      !options.notShowLoading && setModalLoading({ isShow: false });
      console.log({
        status: err?.response?.status || 500,
        data: err?.response?.data,
      });
      return false;
    }
  };
  const promiseAllCallApi = async (callApis, options = {}) => {
    !options.notShowLoading && setModalLoading({ isShow: true });
    try {
      !options.notShowLoading && setModalLoading({ isShow: false });
      return await Promise.all(callApis);
    } catch (err) {
      !options.notShowLoading && setModalLoading({ isShow: false });
      return [];
    }
  };
  useEffect(() => {
    if (modalLoading.isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [modalLoading]);

  const updateConfig = async () => {
    const getConfigs = await callApi("configs-getConfig/", undefined, {
      notShowLoading: false,
    });
    const newConfigs = { ...getConfigs, ...config };
    setConfigs(newConfigs);
    return newConfigs;
  };

  useEffect(() => {
    (async () => {
      const loginOldUser = async (configs) => {
        if (localStorage.getItem("accessToken")) {
          const userResult = await callApi(
            "users-getUserInfo",
            { configs },
            { notShowLoading: true }
          );

          if (userResult.success) {
            return setProfileState(userResult.data);
          }
          localStorage.removeItem("accessToken");
        } else {
          setProfileState({});
        }
      };
      // await promiseAllCallApi([getConfig()])
      const newConfigs = await updateConfig();
      await loginOldUser(newConfigs);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      document.body.onkeydown = (e) => {
        if (e.key === "Escape") {
          hideModal();
        }
      };
      return () => (document.body.onkeydown = null);
    })();
  }, []);

  useEffect(() => {
    localStorage.setItem("testAccess", testAccess);
  }, [testAccess]);

  useEffect(() => {
    localStorage.setItem("sidebar_minimize", minimize ? "Y" : "N");
  }, [minimize]);

  const themes = {
    bg: {
      background: isDarkTheme
        ? "bg-[rgb(119,128,137)]"
        : "bg-gradient-to-b from-[rgb(228,228,228)] to-[rgb(214,212,212)]",

      foreground: isDarkTheme ? "bg-[rgb(26,29,32)]" : "bg-[rgb(255,255,255)]",
    },
    container: `${
      isDarkTheme ? "bg-[rgb(26,29,32)]" : "bg-[rgb(255,255,255)] shadow-xl"
    } rounded-[20px] p-6 shadow-sm`,
    text: `${isDarkTheme ? "text-gray-200" : "text-gray-950"}`,
  };

  const toogleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    localStorage.setItem("isDarkTheme", !isDarkTheme);
  };

  // if (!profileState?.id) {
  //   return (
  //     <div>
  //       <p>login</p>
  //     </div>
  //   );
  // }

  if (profileState && testAccess === "Y") {
    const { hash, pathname, search } = location;
    return (
      <ContextInit.Provider
        value={{
          profileState,
          setProfileState,
          hideModal,
          callApi,
          configs,
          updateConfig,
          setConfigs,
          callApi,
          promiseAllCallApi,
          db,
          adminCart,
          setAdminCart,
          setModalLoading,

          themes,
          toogleTheme,
          isDarkTheme,
        }}
      >
        <Toaster className={themes.bg.foreground} />

        <div className={`${themes.bg.background} flex justify-center w-full`}>
          <div className={`flex w-full max-w-[1600px] h-dvh overflow-hidden`}>
            <div className="flex flex-grow w-full min-w-fit max-w-fit overflow-scroll no-scrollbar">
              <Sidebar
                profileState={profileState}
                minimize={minimize}
                setMinimize={setMinimize}
              />
            </div>

            <div
              className={`w-full py-3 pr-4 ${
                !minimize && "min-w-[300px]"
              } pl-0 overflow-x-hidden no-scrollbar`}
            >
              <Container className="flex p-3 justify-between">
                <div className="flex items-center pl-3 gap-6">
                  {minimize ? (
                    <RiMenuFill
                      className={`cursor-pointer ${themes.text}`}
                      onClick={() => setMinimize(!minimize)}
                    />
                  ) : (
                    <RiMenu2Fill
                      className={`cursor-pointer ${themes.text}`}
                      onClick={() => setMinimize(!minimize)}
                    />
                  )}

                  <Text className={`font-bold`}>
                    {paths.find((path) => path.path === pathname)?.key ||
                      paths.find((path) => path.path === pathname)?.name}
                  </Text>
                </div>
                <div className="flex items-center gap-3">
                  <button
                    className="bg-black text-white text-xs font-bold px-4 py-2 rounded-xl truncate"
                    onClick={() => setTestAccess("N")}
                  >
                    กลับเวอร์ชั่นเก่า
                  </button>
                  <div
                    className={`bg-[rgb(235,235,235)] py-2 px-3 rounded-[20px] gap-2 hidden sm:flex`}
                  >
                    <IoMdCalendar />
                    <Text className={`font-bold text-xs text-gray-800`}>
                      {roundDateToDate(configs?.roundDate)}
                    </Text>
                  </div>
                  <Separator orientation={false} />
                  <div
                    className={`rounded-[10px] p-2 shadow-2xl w-fit cursor-pointer ${
                      isDarkTheme
                        ? "bg-[rgb(249,249,249)]"
                        : "bg-gray-950 text-white"
                    }`}
                    onClick={toogleTheme}
                  >
                    {isDarkTheme ? (
                      <WiDayCloudy className="w-7 h-7" />
                    ) : (
                      <GiNightSky className="w-7 h-7" />
                    )}
                  </div>
                </div>
              </Container>
              <div className="py-3">
                <Routes>
                  {configs &&
                    paths.map(({ component, path }) => {
                      if (
                        ["Developer", "CEO", "CTO"].some(
                          (role) => role === profileState?.role
                        ) ||
                        profileState?.permissions.some(
                          (permission) => permission === path
                        )
                      ) {
                        return (
                          <Route
                            key={path}
                            exact
                            path={path}
                            element={
                              <Authenticate path={path}>
                                {component}
                              </Authenticate>
                            }
                          />
                        );
                      }
                    })}
                </Routes>
                {/* <Container className="h-[2200px] min-w-[300px] grow rounded-[20px] shadow-xl"></Container>
                <div className="flex flex-col gap-6 grow">
                  <Container className="h-[200px] w-full bg-[rgb(8,19,37)]  grow rounded-[20px] shadow-xl"></Container>
                  <Container className="h-[400px] w-full grow rounded-[20px] shadow-xl"></Container>
                </div>
                <Container className="h-[1400px] grow rounded-[20px] shadow-xl"></Container> */}
              </div>
            </div>
          </div>
        </div>
      </ContextInit.Provider>
    );
  }
  return (
    <ContextInit.Provider
      value={{
        profileState,
        setProfileState,
        hideModal,
        callApi,
        configs,
        updateConfig,
        setConfigs,
        callApi,
        promiseAllCallApi,
        db,
        adminCart,
        setAdminCart,
        setModalLoading,
      }}
    >
      {modalLoading.isShow && <ModalLoading />}{" "}
      <BodySc>
        <SideBar setTestAccess={setTestAccess} profileState={profileState} />

        <MainDisplay>
          <br />
          <MainDisplayBody>
            <Routes>
              {profileState &&
                configs &&
                flatten(
                  Pages.map(({ Component, path, children }) => {
                    const aPage = [
                      children
                        .filter(
                          (x) =>
                            profileState?.role === "Developer" ||
                            (profileState?.permissions || []).includes(x.path)
                        )
                        // .filter((x) =>
                        //   x.permissions.includes(profileState?.role)
                        // )
                        .map(({ Component, path }) => {
                          return (
                            <Route
                              key={path}
                              exact
                              path={path}
                              element={
                                <Authenticate path={path}>
                                  {Component}
                                </Authenticate>
                              }
                            />
                          );
                        }),
                      Component && (
                        <Route
                          key={path}
                          exact
                          path={path}
                          element={
                            <Authenticate path={path}>{Component}</Authenticate>
                          }
                        />
                      ),
                    ];
                    return aPage;
                  })
                )}
            </Routes>
          </MainDisplayBody>
        </MainDisplay>
      </BodySc>
    </ContextInit.Provider>
  );
}
export default App;

const BodySc = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(241, 245, 249);
  /* background: linear-gradient(
    180deg,
    rgba(40, 60, 145, 1) 0%,
    rgba(25, 30, 75, 1) 100%
  ); */

  display: flex;
`;
const MainDisplay = styled.div`
  overflow: scroll;
  position: relative;
  flex: 1;
  height: calc(100vh);
  margin: 20px;
`;
const MainDisplayBody = styled.div`
  position: relative;
  width: calc(100%);
  height: calc(100%);
  overflow: scroll;
  padding-bottom: 200px;
  /* background: #eeeecc; */
`;

const PanelContainer = styled.div`
  /* margin: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${themes.colors.border};
  box-sizing: unset;
  width: 100%;
  height: 80%;
  /* max-width: 300px; */
  padding: 10px;
  background: ${themes.colors.white};
`;
