import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { flatten } from "ramda";
export const ReportBookDeliver = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);

  const [book, setBook] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };
  const handleSearch = async () => {
    const data = await callApi("orders-getAllMail", {
      queries: [
        ["deliverChannel", "==", "mail"],
        ["roundDate", "==", roundDate],
        ["status", "==", "approved"],
      ],
      startAfter: "",
    });

    const bookData = flatten(
      data.map((child) => {
        let originBook = [];
        child.lotteries.reduce((acc, x, index) => {
          originBook.push({
            ["name"]: x.fileNameId,
            ["id"]: x.id,
          });
        }, []);

        return originBook;
      })
    ).reduce((acc, value) => {
      // acc[key] ??= { key: key, value: [] };
      if (acc[value.name]) {
        acc[value.name].id = acc[value.name].push(value.id);
      } else {
        acc[value.name] = [value.id];
      }
      return acc;
    }, {});

    setBook(bookData);
  };
  const handleDownload = async () => {
    const date = thaiDate();
    let result = Object.entries(book).map(([key, values], index) => [
      index + 1,
      key,
      values.map((value) => value),
    ]);
    result.unshift(
      ["รายงานเล่มและเลขที่ต้องจัดส่ง", date],
      ["ลำดับ", "เล่มที่", "เลข"]
    );
    setCsvData(result);
    setCsvFileName(`รายงานไปรษณีย์_${date}.csv`);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานเล่มและเลขที่ต้องจัดส่ง</Label.H2>
      <br />

      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate}>{roundDate}</option>
        ))}
      </Input>

      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {book.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <br />
      <Flex style={{ gap: "2em" }}>
        <Flex direction="column">
          {book.length !== 0 && (
            <Table>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>เล่ม</th>
                  <th>เลข</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(book).map(([key, values], index) => {
                  return (
                    <tr>
                      <th>{index + 1}</th>
                      <th>{key}</th>
                      <th>
                        <ScrollBar>
                          {values.map((id) => (
                            <>
                              {id} <br />
                            </>
                          ))}
                        </ScrollBar>
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};
const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 250px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const Button = styled.button``;
