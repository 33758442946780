import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { useParams } from "react-router-dom";

export const MissionDetail = () => {
  const {
    callApi,
    profileState,
    configs,
    adminCart,
    setAdminCart,
    updateConfig,
  } = Context();
  const { missionId } = useParams();

  return (
    <div className="bg-white max-w-[1200px] mb-24  p-8 rounded-2xl drop-shadow-xl">
      <p className="text-3xl font-semibold">คำขอแลกรางวัล {missionId}</p>
      {missionId}
    </div>
  );
};
