import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";
import constant from "../../constants";
import { Container } from "../../components";
import { Input, FormGroup } from "reactstrap";

const UserPrizes = () => {
  const navigate = useNavigate();
  const { callApi } = Context();
  const [isShowOnlyAdmin, setIsShowOnlyAdmin] = useState(true);

  return (
    <Container>
      แสดงเฉพาะพนักงาน
      <FormGroup switch>
        <Input
          type="switch"
          checked={isShowOnlyAdmin}
          onClick={() => {
            setIsShowOnlyAdmin(!isShowOnlyAdmin);
          }}
        />
      </FormGroup>
      <HtTablePaging
        title={"พนักงาน"}
        getData={(body) => callApi("users-getAll", body)}
        updateApi={async (body) => {
          return await callApi("users-edit", {
            ...body,
            collectionName: "Users",
          });
        }}
        hideColumns={[
          "address",
          "accName",
          "bankName",
          "accNo",
          "buyPoint",
          "email",
          "referPoint",
          "admin",
          "activated",
          "cartTime",
          "is80Privilage",
          "nationality",
          "isAcceptConsent",
          "friend",
          "influencer",
          "userNo",
        ]}
        translations={{
          Manage: "จัดการบัญชี",
          firstName: "ชื่อ",
          lastName: "นามสกุล",
          phone: "โทรศัพท์",
          id: "เลขสมาชิก",
        }}
        baseQrs={isShowOnlyAdmin ? [["admin", "==", true]] : []}
        rerender={isShowOnlyAdmin}
        dropdownFilter={{
          activated: ["Yes", "No"],
        }}
        dropdownAble={{
          admin: ["Yes", "No"],
          activated: ["Yes", "No"],
        }}
        columns={["Manage"]}
        overrides={{
          inject: (props) => (
            <button
              onClick={async () => {
                let reasult = await callApi("users-addAdminToken", {
                  userId: props.row.id,
                });
                window.open(
                  constant.webConfigs.customerUrl +
                    "orders?adminToken=" +
                    reasult.adminToken
                );
              }}
            >
              เปิดเว็บ
            </button>
          ),
          Manage: (props) => (
            <button
              onClick={async () => {
                window.open("/admins/" + props.row["id"], "_blank");
              }}
            >
              สิทธิ์การเข้าถึง
            </button>
          ),
        }}
      />
    </Container>
  );
};
export default UserPrizes;
