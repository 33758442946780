import React, { useEffect, useContext, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";

const LogList = () => {
  const { callApi } = Context();
  return (
    <HtTablePaging
      title={"บันทึกรายการทำงาน"}
      getData={(body) => callApi("logs-getAll", body)}
      updateApi={async (body) => {
        return await callApi("logs-edit", body);
      }}
      translations={{
        createdAt: "วันเวลา",
        name: "ชื่อผู้แก้ไข",
        actor: "รหัสผู้แก้ไข",
        action: "ประเภท",
        type: "หมวด",
        target: "รหัส",
        key: "หัวข้อ",
        value: "ค่า",
      }}
      columns={[
        "createdAt",
        "name",
        "actor",
        "action",
        "type",
        "target",
        "key",
        "value",
      ]}
      hideFilters={["createdAt"]}
      defaultEditable={true}
      hideColumns={["id"]}
      dropdownFilter={{
        type: [
          "configs",
          "points",
          "orders",
          "users",
          "complains",
          "carts",
          "userPrizes",
          "blocks",
          "lotteries",
          "audit",
          "bads",
          "payment",
          "prizes",
          "tests",
          "working",
        ],
      }}
      dropdownAble={{ status: ["open", "closed"] }}
    />
  );
};
export default LogList;
