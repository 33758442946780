import React from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";
import constant from "../../constants";
import { Container } from "../../components";
import { Input } from "reactstrap";
const UserPrizes = () => {
  const navigate = useNavigate();
  const { callApi } = Context();
  return (
    <Container>
      <HtTablePaging
        title={"สมาชิก"}
        getData={(body) => callApi("users-getAll", body)}
        updateApi={async (body) => {
          return await callApi("users-edit", {
            ...body,
            collectionName: "Users",
          });
        }}
        hideColumns={[
          "userNo",
          "address",
          "accName",
          "bankName",
          "accNo",
          "permissions",
          "admin",
          "referPoint",
          "nationality",
          "isAcceptConsent",
          "friend",
          "influencer",
          "role",
          "is80Privilage",
          "cartTime",
        ]}
        translations={{
          // id: "รหัสสมาชิก", //add
          // is80Privilage: "ลูกค้า80",
          shop: "ปลอมเป็นลูกค้า",
          activated: "ยืนยันตัวตนแล้ว",
          status: "สถานะ",
          admin: "พนักงาน",
          buyPoint: "คะแนนซื้อ",
          email: "อีเมล",
          firstName: "ชื่อ",
          lastName: "นามสกุล",
          phone: "โทรศัพท์",
          referPoint: "คะแนนแนะนำ",
          id: "รหัสสมาชิก",
          cartTime: "เวลาตะกร้า",
        }}
        dropdownFilter={{
          is80Privilage: ["Yes", "No"],
          admin: ["Yes", "No"],
          activated: ["Yes", "No"],
          status: ["active", "inactive"],
        }}
        dropdownAble={{
          admin: ["Yes", "No"],
          activated: ["Yes", "No"],
        }}
        columns={["รายละเอียด", "shop", "status"]}
        overrides={{
          shop: (props) => (
            <button
              style={{ background: "red" }}
              onClick={async () => {
                let reasult = await callApi("users-addAdminToken", {
                  userId: props.row.id,
                });
                window.open(
                  // constant.webConfigs.customerUrl +
                  "https://shop.hongthongofficial.com/" +
                    "orders?adminToken=" +
                    reasult.adminToken
                );
              }}
            >
              เปิดเว็บ
            </button>
          ),
          Point: (props) => (
            <button
              style={{ background: "red" }}
              onClick={async () => {
                let reasult = await callApi("users-addAdminToken", {
                  userId: props.row.id,
                });
                window.open(
                  constant.webConfigs.customerUrl +
                    "orders?adminToken=" +
                    reasult.adminToken
                );
              }}
            >
              แก้ไขคะแนน
            </button>
          ),
          status: (props) => (
            <Input
              onChange={async (e) =>
                await callApi("users-edit", {
                  id: props.row.id,
                  key: "status",
                  value: e.target.value,
                })
              }
              style={{ width: "120px", margin: "auto" }}
              type="select"
              defaultValue={props.row.status}
            >
              <option value="active">ปกติ</option>
              <option value="inactive">หยุดใช้งานชั่วคราว</option>
            </Input>
          ),
          รายละเอียด: (props) => (
            <button
              onClick={async () => {
                window.open("/users/" + props.row["id"]);
              }}
            >
              ดูเพิ่มเติม
            </button>
          ),
        }}
      />
    </Container>
  );
};
export default UserPrizes;
