import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { useParams } from "react-router-dom";
import { thaiDate, role } from "../../util";
import themes from "../../themes";
import constant from "../../constants";
import styled from "styled-components";
import {
  ContentContainer,
  Content,
  getStatusBadge,
  Button,
} from "../orders/WorkingOrder";
import { Label, Flex } from "../../components";
import { Table, Input } from "reactstrap";
import { NoteContainer } from "../coupons/CouponOrderDetail";
export const GiftshopOrderDetail = () => {
  const { callApi, profileState } = Context();
  const [orderData, setOrderData] = useState({});
  const [userData, setUserData] = useState({});
  const [pointsHistory, setPointsHistory] = useState([]);
  const [note, setNote] = useState("");
  const [showChangePrice, setShowChangePrice] = useState(false);
  const [newPrice, setNewPrice] = useState(0);
  const { orderId } = useParams();

  useEffect(() => {
    fetchData();
  }, [orderId]);

  const fetchData = async () => {
    await callApi("products-getTransaction", {
      orderId,
    }).then((result) => {
      if (result) {
        setOrderData(result.data);
        callApi("users-getUserInfoAdmin", { userId: result.data.userId }).then(
          (userData) => {
            setUserData(userData);
          }
        );
        callApi("points-getPointHistoryByUser", {
          userId: result.data.userId,
        }).then((history) => setPointsHistory(history.data));
      } else {
        alert("error");
      }
    });
  };
  const letValue = [
    {
      title: "วัน-เวลาที่สั่งซื้อ",
      value: new Date(parseInt(orderData.createdAt)).toLocaleString(),
    },
    { title: "ชื่อ-นามสกุล (ผู้รับ)", value: orderData.receiver?.name },
    {
      title: "ที่อยู่ (ผู้รับ)",
      value: orderData.isPickup
        ? "รับเองที่สำนักงานใหญ่หงษ์ทอง"
        : orderData.receiver?.address,
    },
    { title: "เบอร์โทร (ผู้รับ)", value: orderData.receiver?.phone },
  ];

  const rightValue = [
    {
      title: "",
      value: (
        <img
          src={orderData.product?.image}
          alt=" product"
          style={{ width: "100%" }}
        />
      ),
    },
    { title: "สินค้า", value: orderData.product?.name },
    { title: "ราคา", value: orderData.product?.price },
    {
      title: `สลิปหลักฐานการโอน จำนวนที่ต้องชำระเพิ่มเติม ${orderData.payCash} บาท`,
      value: orderData.slip ? (
        <img src={orderData.slip} alt=" slip" style={{ width: "100%" }} />
      ) : (
        "-"
      ),
    },
    { title: "", value: "" },
    { title: "", value: "" },
  ];

  const updateStatus = async (status) => {
    await callApi("products-editStatus", { id: orderData.id, status });
    fetchData();
  };

  const addNote = async () => {
    await callApi("products-adminAddNote", {
      id: orderData.id,
      note: note,
    }).then((response) => {
      if (response.status) {
        setNote("");
        fetchData();
      } else {
        console.log(response);
      }
    });
  };
  const submitChangePrice = async () => {
    if (window.confirm("ต้องการแก้ไขข้อมูลใช่หรือไม่")) {
      const result = await callApi("products-changePriceByAdmin", {
        newPrice: newPrice || 0,
        orderId: orderId,
      });
      if (result.success) {
        setShowChangePrice(false);
        window.location.reload();
      }
    } else {
      return { status: false };
    }
  };
  return (
    <>
      <ContentContainer className="flex column blue-bg">
        <Label.H2 color="white" weight={600}>
          คำสั่งซื้อที่ {orderId}
        </Label.H2>
        <br />
        <Flex wrap="wrap" width="100%">
          <Wrapper>
            {letValue.map((order) => (
              <Content title={order.title} value={order.value} />
            ))}
            <Flex
              width="100%"
              direction="column"
              align="flex-start"
              padding="0.5em 0"
            >
              <Label.H5 weight={600} color={themes.colors.primary}>
                ราคารวม
              </Label.H5>

              <Flex
                style={{
                  width: "100%",
                  fontSize: "1.1em",
                  fontWeight: 500,
                }}
                align="center"
                color={themes.colors.white}
              >
                {showChangePrice ? (
                  <>
                    <Input
                      type="number"
                      value={newPrice}
                      onChange={(e) => setNewPrice(e.target.value)}
                      style={{ width: "90px" }}
                    ></Input>
                    <Button
                      className="button-style-primary"
                      onClick={() => submitChangePrice()}
                    >
                      ปรับราคา
                    </Button>
                  </>
                ) : (
                  <>
                    <div style={{ marginRight: "20px" }}>
                      {orderData.price} บาท
                    </div>
                    <Button
                      className="button-style-primary"
                      onClick={() => setShowChangePrice(true)}
                    >
                      แก้ราคา
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>

            <Content
              title="หมายเหตุเพิ่มเติมจากลูกค้า"
              value={orderData.customerNote || "-"}
            />

            <Content title="สถานะ" value={getStatusBadge(orderData.status)} />
            <Content
              title="เปลี่ยนสถานะคำสั่งซื้อ"
              value={
                <>
                  {orderData.status !== "approved" &&
                    (orderData.status !== constant.statuses.CANCEL ? (
                      <Button
                        color={themes.colors.success}
                        onClick={() => updateStatus(constant.statuses.APPROVE)}
                      >
                        อนุมัติ
                      </Button>
                    ) : (
                      "ไม่สามารถเปลี่ยนแปลงคำสั่งซื้อที่ถูกยกเลิกแล้วได้"
                    ))}

                  {orderData.status !== constant.statuses.CANCEL ? (
                    orderData.status === "approved" ? (
                      ["superAdmin", "dev"].includes(profileState.role) ? (
                        <Button
                          color={themes.colors.dangerRed}
                          onClick={() => updateStatus(constant.statuses.CANCEL)}
                        >
                          ยกเลิก
                        </Button>
                      ) : (
                        "ติดต่อ Super Admin เพื่อเปลี่ยนคำสั่งซื้อที่ถูกอนุมัติแล้ว"
                      )
                    ) : (
                      <Button
                        color={themes.colors.dangerRed}
                        onClick={() => updateStatus(constant.statuses.CANCEL)}
                      >
                        ยกเลิก
                      </Button>
                    )
                  ) : (
                    ""
                  )}
                </>
              }
            />

            {orderData.approvedBy && (
              <Content
                title="ผู้อนุมัติ"
                value={
                  orderData.approvedBy +
                  " " +
                  new Date(parseInt(orderData.approvedAt)).toLocaleString()
                }
              ></Content>
            )}

            {orderData.canceledBy && (
              <Content
                title="ผู้ยกเลิก"
                value={
                  orderData.canceledBy +
                  " " +
                  new Date(parseInt(orderData.canceledAt)).toLocaleString()
                }
              ></Content>
            )}
          </Wrapper>
          <Wrapper>
            {rightValue.map((order) => (
              <Content title={order.title} value={order.value} />
            ))}
            <Content
              title="บันทึกเพิ่มเติม"
              value={
                <Flex direction="column">
                  <Flex margin="0 0 1em 0">
                    <Input
                      type="text"
                      id="note"
                      value={note}
                      name="note"
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <Button
                      className="note"
                      color={themes.colors.oldGrean}
                      onClick={() => addNote()}
                    >
                      บันทึก
                    </Button>
                  </Flex>
                  {orderData.note
                    ?.sort((a, b) => b.time - a.time)
                    .map((data) => {
                      return (
                        <NoteContainer>
                          {new Date(parseInt(data.time)).toLocaleString()} |{" "}
                          {data.by} <br />
                          <br /> {data.note}
                        </NoteContainer>
                      );
                    })}
                </Flex>
              }
            ></Content>
          </Wrapper>
        </Flex>
      </ContentContainer>
      <br />

      <ContentContainer className=" blue-bg">
        <Flex align="flex-start">
          <div>
            <Label.H2
              weight={600}
              margin="0 1em 0 0"
              color={themes.colors.white}
            >
              สมาชิกหมายเลข {userData.userId}
            </Label.H2>
            <Label.H4
              weight={400}
              margin="0 1em 0 0"
              color={themes.colors.white}
            >
              สมาชิกลำดับที่ {userData.userNo}
            </Label.H4>
            {userData.role && (
              <Label.H5
                weight={400}
                margin="0 1em 0 0"
                color={themes.colors.white}
              >
                {role[userData.role] || ""}
              </Label.H5>
            )}
          </div>
          <Button
            onClick={() => window.open("/users/" + orderData.userId, "_blank")}
            color={themes.colors.oldGrean}
          >
            ดูข้อมูล
          </Button>
        </Flex>
        <br />
        <Flex align="flex-start" wrap="wrap">
          <Wrapper>
            <Content
              title="ชื่อ-นามสกุล"
              value={userData.firstName + " " + userData.lastName}
            />
            <Content title="เบอร์โทรศัพท์" value={userData.phone || "-"} />
            <Content title="คะแนน" value={userData.buyPoint || "-"} />
            <Content title="ไอดีไลน์" value={userData.lineId || "-"} />
            <Content title="อีเมล์ไลน์" value={userData.lineEmail || "-"} />
          </Wrapper>
          <Wrapper style={{ maxWidth: "300px" }}>
            <Content title="ธนาคาร" value={userData.bank?.name || "-"} />
            <Content title="ชื่อบัญชี" value={userData.bank?.accName || "-"} />
            <Content title="เลขบัญชี" value={userData.bank?.accNo || "-"} />
            <Content title="ชื่อไลน์" value={userData.lineTitle || "-"} />
          </Wrapper>
          <Wrapper style={{ maxWidth: "900px" }}>
            <FullScrollBar
              direction="column"
              style={{
                maxHeight: "300px",
                overflow: "scroll",
              }}
            >
              <Label.H2 textAlign="center" color="white" weight={600}>
                ประวัติคะแนน
              </Label.H2>
              <br />
              {pointsHistory.length !== 0 ? (
                <Table bordered striped style={{ maxWidth: "900px" }}>
                  <thead>
                    <tr>
                      <th className="text-light text-center">วันที่</th>
                      <th className="text-light text-center">ก่อนปรับ</th>
                      <th className="text-light text-center">ปรับ</th>
                      <th className="text-light text-center">หลังปรับ</th>
                      <th className="text-light text-center">ผู้แก้ไข</th>
                      <th className="text-light text-center">
                        บันทึกเพิ่มเติม
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pointsHistory
                      .sort((a, b) => b.createdAt - a.createdAt)
                      .map((data) => {
                        return (
                          <tr>
                            <th className="text-light text-center">
                              {thaiDate(new Date(parseInt(data.createdAt)))}
                            </th>
                            <th className="text-light text-center">
                              {data.oldPoint}
                            </th>
                            <th className="text-light text-center">
                              {data.point}
                            </th>
                            <th className="text-light text-center">
                              {data.newPoint}
                            </th>
                            <th className="text-light text-center">
                              {data.updatedBy}
                            </th>
                            <th className="text-light text-center">
                              {data.note}
                            </th>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              ) : (
                <div style={{ width: "100%" }}>
                  <Label.H4 textAlign="center" color="white" weight={600}>
                    ไม่พบประวัติ
                  </Label.H4>
                </div>
              )}
            </FullScrollBar>
          </Wrapper>
        </Flex>
      </ContentContainer>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
`;
const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const FullScrollBar = styled(ScrollBar)`
  width: 100% !important;
  max-width: 1000px !important;
  max-height: 50vh;
`;
