import React from "react";
import themes from "../themes";
import styled from "styled-components";

const PanelContainer = styled.div`
  /* margin: 5px; */

  border-radius: 8px;
  border: 1px solid ${themes.colors.border};
  box-sizing: unset;

  /* max-width: 300px; */
  padding: 10px;
  background: ${themes.colors.white};
  overflow: auto;
`;

const BlueContainer = styled.div`
  border-radius: 0.375em;

  width: 100%;
  padding: 2em;
  box-shadow: 4px 4px 8px 2px rgb(0 0 0 / 10%);

  background: ${themes.colors.secondaryGradient2};
  color: white;
  font-weight: 500;

  display: flex;
  flex-direction: column;
  &.justify {
    justify-content: space-between;
  }
`;

export const ContentContainer = ({ children }) => {
  return <BlueContainer>{children}</BlueContainer>;
};

export const Container = ({ children }) => {
  return <PanelContainer>{children}</PanelContainer>;
};
