import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { ScrollBar } from "./index";

export const RoundDateBuyerList = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);

  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)
  const [filter, setFilter] = useState("all");
  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };
  const handleSearch = async () => {
    const result = await callApi("orders-getBuyerLindId", {
      roundDate,
    });

    const data = Object.values(result.data).map((data) => data);

    setData(data);
    setFilteredData(data);
  };

  const changeFilter = (e) => {
    setCsvFileName("");
    setDownloaded([]);
    setFilter(e.target.value);
    if (e.target.value === "sms") {
      setFilteredData(data.filter((user) => user.notifyType === "SMS"));
    }
    if (e.target.value === "line") {
      setFilteredData(data.filter((user) => user.notifyType === "LINE"));
    }
    if (e.target.value === "all") {
      setFilteredData(data);
    }
  };

  const handleDownload = async () => {
    const prepData = filteredData;
    let listHead = ["userNo", "lineId"];
    if (filter === "all") {
      listHead = ["userNo", "lineId", "phone"];
    }

    if (filter === "sms") {
      listHead = ["userNo", "phone"];
    }
    console.log(prepData);
    const result = prepData.reduce(
      (acc, { userNo, notifyType, lineId, phone }, index) => {
        if (filter === "sms") {
          acc.push([userNo, phone]);
        }

        if (filter === "line") {
          if (lineId) {
            acc.push([userNo, lineId]);
          }
        }

        if (filter === "all") {
          acc.push([userNo, lineId, phone]);
        }
        return acc;
      },
      []
    );
    result.unshift(listHead);
    setCsvData(result);
    setCsvFileName(`รายชื่อผู้ซื้องวดนี้.csv`);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานผู้ซื้อรายงวด</Label.H2>
      <br />
      <Flex align="center" gap="15px">
        <Label.H5 weight={500}>งวด</Label.H5>
        <Input
          name="select"
          type="select"
          onChange={(e) => changeRoundDate(e)}
          style={{ width: "100px" }}
        >
          {configs.roundDates
            .sort()
            .reverse()
            .map((roundDate) => (
              <option key={roundDate}>{roundDate}</option>
            ))}
        </Input>
      </Flex>
      <br />
      <Flex align="center" gap="15px">
        <Label.H5 weight={500}>notify type</Label.H5>
        <Input
          name="select"
          type="select"
          onChange={(e) => changeFilter(e)}
          style={{ width: "100px" }}
        >
          <option value="all">all</option>
          <option value="line">line</option>
          <option value="sms">sms</option>
        </Input>
      </Flex>
      <br />

      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <br />

      {filteredData.length !== 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th></th>
              <th>userNo</th>
              <th>notifyType</th>
              <th>lineId</th>
              <th>phone</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map(
              ({ userNo, notifyType, lineId, phone }, index) => {
                return (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <th>{userNo || "-"}</th>
                    <th>{notifyType || "-"}</th>
                    <th>{lineId || "-"}</th>
                    <th>{phone || "-"}</th>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const Button = styled.button``;
