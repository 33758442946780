const style = {
  // TODO: temporary unused
  // "colors": {
  //   "mainDark1": "#79a0ab",
  //   "mainDark2": "#5b7197",
  //   "mainDark3": "#2A4C84",
  //   "mainLight1": "#e3a494",
  //   "mainLight2": "#eecfc2",
  //   "mainLight3": "#e8e3dd",
  //   "lightGray": "#eeeeee",
  //   "gray": "#bcbcbc",
  //   "darktGray": "#999999",
  //   "textDark": "white",
  //   "textWhite": "black",
  //   "success": "#019035",
  //   "danger": "darkred",
  //   "darkBlue": "rgb(30,45,100)",
  //   "goldText": "rgb(219, 193, 103)",
  //   "brightGoldText": "rgb(222,170,90)",
  //   "golden": `rgba(245,194,64,1)`,
  //   "golden8": `rgba(245,194,64,.8)`
  // },
  css: {
    lightShadow: "box-shadow: 0 0px 25px -5px black;",
    shadow: "box-shadow: 0 0px 15px 5px black;",
    gradian:
      "background: linear-gradient(90deg, rgba(20,30,75,1) 0%, rgba(30,40,90,1) 60%, rgba(40,60,150,1) 100%);",
    gradianReverse:
      "background: linear-gradient(2700deg, rgba(20,30,75,1) 0%, rgba(30,40,90,1) 60%, rgba(40,60,150,1) 100%);",
    goldenGradian:
      "background: linear-gradient(90deg, rgba(245,194,64,1) 0%, rgba(245,194,64,1) 10%, rgba(255,249,133,1) 50%, rgba(245,194,64,1) 90%,rgba(245,194,64,1) 100%);",
  },
};
export default style;
