import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { flatten } from "ramda";

export const ReportSpecial = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [pointData, setPointData] = useState([]);
  const [from, setForm] = useState(0);
  const [to, setTo] = useState(0);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };
  const handleSearch = async () => {
    const data = await callApi("orders-getAll", {
      queries: [
        ["status", "==", "approved"],
        ["roundDate", "==", roundDate],
        ["createdAt", ">", Number(from)],
        ["createdAt", "<", Number(to)],
        // ["size", ">=", 10],
      ],
      // orderBy: ["createdAt", "desc"],
      startAfter: "",
      limit: 5000,
    });
    // const data = await callApi("orders-getSpecialEventOrder", {
    //   roundDate: roundDate,
    //   from: Number(from),
    //   to: Number(to),
    // });

    console.log("data length", data.length);
    const filteredData = data.reduce((arr, order) => {
      if (
        order.lotteries.length >= 10
        // order.status === "approved" &&
      ) {
        arr.push(order);
      }
      return arr;
    }, []);
    // const filteredData = data.reduce((arr, order) => {
    //   if (order.lotteryIds.length >= 5 && order.lotteryIds <= 9) {
    //     arr.push(order);
    //   }
    //   return arr;
    // }, []);

    const sortedData = filteredData.sort((a, b) => a.createdAt - b.createdAt);
    console.log("sortedData length", sortedData.length);
    setData(sortedData);
  };

  // const giveAPoint = async () => {
  //   const data = await callApi("points-addPoint", {
  //     timestampStart: Number(from),
  //     timestampEnd: Number(to),
  //   });

  //   console.log(data);
  //   setPointData(data.result);
  // };

  const handleDownload = async () => {
    const date = thaiDate();
    const prepData = data;

    const csvHeads = Object.keys(data[0]);
    let listHead = [
      "ลำดับ",
      "เวลา",
      "รหัสออเดอร์",
      "รหัสผู้ใช้",
      "ชื่อ",
      "นามสกุล",
      "เบอร์โทรศัพท์",
      "จำนวน",
    ];
    const ar = [];
    ar.push(listHead);
    const result = prepData.reduce((acc, x, index) => {
      const createDate = new Date(x.createdAt);
      ar.push([
        index + 1,
        createDate.getDate() +
          "/" +
          (createDate.getMonth() + 1) +
          "/" +
          createDate.getFullYear() +
          " " +
          createDate.getHours() +
          ":" +
          createDate.getMinutes() +
          ":" +
          createDate.getSeconds(),
        x.shortId,
        x.userNo,
        x.firstName,
        x.lastName,
        x.phone,
        x.lotteries.length,
      ]);
      return acc;
    }, []);

    setCsvData(ar);
    setCsvFileName(`รายงานกิจกรรมพิเศษ_${date}.csv`);
  };
  // 28 - 31 มีนาคม

  // วันละ 3 รอบ
  // 12.12 - 16.16 ทั้งหมด 1 รอบ ถ้าครบ 50 สิทธิ์ก่อนก็ตัดก่อน

  // 16.16-19.19  ทั้งหมด 1 รอบ ถ้าครบ 50 สิทธิ์ก่อนก็ตัดก่อน

  // 19.19 - 22.22  ทั้งหมด 1 รอบ ถ้าครบ 50 สิทธิ์ก่อนก็ตัดก่อน

  //28
  //12.12 1679980320000
  //16.16 1679994960000
  return (
    <Container>
      <Label.H2 weight={500}>รายงานพิเศษ</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates
          .sort()
          .reverse()
          .map((roundDate) => (
            <option key={roundDate}>{roundDate}</option>
          ))}
      </Input>
      <br />
      จาก
      <Input
        name="since"
        type="number"
        placeholder="จาก"
        value={from}
        onChange={(e) => setForm(e.target.value)}
        style={{ width: "200px" }}
      />
      จาก
      <Input
        name="to"
        type="number"
        placeholder="ถึง"
        value={to}
        onChange={(e) => setTo(e.target.value)}
        style={{ width: "200px" }}
      />
      {/* <Button onClick={() => giveAPoint()}>ให้คะแนนพิเศษ</Button> */}
      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <p>พบทั้งหมด {data.length}</p>
      <br />
      {data.length !== 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>วันที่-เวลา</th>
              <th>รหัสคำสั่งซื้อ</th>
              <th>รหัสผู้ใช้</th>
              <th>ชื่อ</th>
              <th>นามสกุล</th>
              <th>เบอร์โทร</th>

              <th>จำนวนที่สั่งซื้อ</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const createDate = new Date(item.createdAt);
              const shippingDate = new Date(item.shippingDate);
              return (
                <tr key={item.shortId}>
                  <th>{index + 1}</th>

                  <th>
                    {createDate.getDate() +
                      "/" +
                      (createDate.getMonth() + 1) +
                      "/" +
                      createDate.getFullYear() +
                      " " +
                      createDate.getHours() +
                      ":" +
                      createDate.getMinutes() +
                      ":" +
                      createDate.getSeconds()}
                  </th>
                  <th>{item.shortId || "-"}</th>
                  <th>{item.userNo || "-"}</th>
                  <th>{item.firstName || "-"}</th>
                  <th>{item.lastName || "-"}</th>
                  <th>{item.phone || "-"}</th>
                  <th>{item.lotteries.length} ใบ</th>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const Button = styled.button``;
