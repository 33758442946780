import React, { useEffect, useState, useRef } from "react";
import { getFirestore, onSnapshot, collection, doc } from "firebase/firestore";
import Context from "../../Context";
import styled from "styled-components";
import style from "../../constants/style";
import HtTablePaging from "../../components/HtTablePaging";
import ButtonLoading from "../../components/ButtonLoading";

const PrizesPay = () => {
  const { callApi, db } = Context();
  const [data, setData] = useState([]);
  const [getAllWinnerOrdersPercent, setGetAllWinnerOrdersPercent] =
    useState(-1);

  useEffect(() => {}, []);
  return <>PrizesPay</>;
};
export default PrizesPay;
