const configs = {
  local: {
    api: "http://localhost:5001/hongthong-lotto/asia-east1/",
    customerUrl: "http://localhost:3000/",
    supportUrl: "http://localhost:3001/",
    firebaseConfig: {
      apiKey: "AIzaSyBVwN2LDBf1MxCqFSpEcAITY4qI9SOIDUQ",
      authDomain: "hongthong-lotto.firebaseapp.com",
      projectId: "hongthong-lotto",
      storageBucket: "hongthong-lotto.appspot.com",
      messagingSenderId: "661497762889",
      appId: "1:661497762889:web:671fafa439ffc97c835b98",
      measurementId: "G-MKW7MEE6NQ",
    },
  },
  dev: {
    api: "https://asia-east1-hongthong-lotto.cloudfunctions.net/",
    customerUrl: "https://shop-hongthong-lotto.web.app/",
    supportUrl: "https://support-hongthong-lotto.web.app/",
    firebaseConfig: {
      apiKey: "AIzaSyBVwN2LDBf1MxCqFSpEcAITY4qI9SOIDUQ",
      authDomain: "hongthong-lotto.firebaseapp.com",
      projectId: "hongthong-lotto",
      storageBucket: "hongthong-lotto.appspot.com",
      messagingSenderId: "661497762889",
      appId: "1:661497762889:web:671fafa439ffc97c835b98",
      measurementId: "G-MKW7MEE6NQ",
    },
  },
  prod: {
    api: "https://asia-southeast1-hongthong-prod.cloudfunctions.net/",
    customerUrl: "https://shop.hongthongofficial.com/",
    supportUrl: "https://support-hongthong-prod.web.app/",
    firebaseConfig: {
      apiKey: "AIzaSyC8GKZMJy1GnBNeqQf5THNsRVjcHvwURv4",
      authDomain: "hongthong-prod.firebaseapp.com",
      projectId: "hongthong-prod",
      storageBucket: "hongthong-prod.appspot.com",
      messagingSenderId: "177085503211",
      appId: "1:177085503211:web:861eec51db8e1360fdd0ae",
      measurementId: "G-27RS08YBWE",
    },
  },
};
export default configs[process.env.REACT_APP_EVIRONMENT];
