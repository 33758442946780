import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";
import { Container, Label, Flex } from "../../components";
import { ScrollBar } from "../../components";
import { Input, Table, Badge } from "reactstrap";
import { thaiDate } from "../../util";
import { isEmpty } from "ramda";

import {
  IoCheckmarkCircleSharp,
  IoCloseCircleSharp,
  IoWallet,
  IoAlarmSharp,
  IoSearch,
} from "react-icons/io5";
import themes from "../../themes";

// import { qr } from "../../../public/images/qr.png";
const Cart = () => {
  const { callApi, showModalItemDetail, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [orderData, setOrderData] = useState([]);
  const [queriesKey, setKey] = useState("");
  const [queriesValue, setValue] = useState("");
  const [startAfter, setStartAfter] = useState({ 1: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [orderCount, setOrderCount] = useState(0);
  const [lotteryCount, setLotteryCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    handleSearch();
  }, [currentPage]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };
  const handleSearch = async (body) => {
    const result = await callApi("carts-getAllCarts");

    const lottoCount = result.reduce((acc, data) => {
      acc += data.size;

      return acc;
    }, 0);

    setLotteryCount(lottoCount);
    setOrderData(result);
    setOrderCount(result.length);
  };

  return (
    <Container>
      <Flex align="center">
        <Label.H1 weight={600}>คำสั่งซื้อค้างตะกร้า</Label.H1>

        <button
          style={{
            backgroundColor: "rgba(245,194,64,1)",
            border: "0",
            borderRadius: "5px",
            padding: "5px 10px",
            margin: "0 2em",
          }}
          onClick={() => {
            handleSearch();
          }}
        >
          รีเฟรช
        </button>
      </Flex>

      <br />
      <br />
      <Flex align="center" justify="space-between">
        <Label.H4 weight={500}>
          ทั้งหมด
          <Badge style={{ margin: "0 5px" }}>{orderCount || 0}</Badge>
          รายการ
          <Badge style={{ margin: "0 5px" }}>{lotteryCount || 0}</Badge>
          ใบ
        </Label.H4>
        <Flex></Flex>
      </Flex>
      <br />
      <Table striped hover>
        <thead>
          <tr>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              รหัสผู้ใช้งาน
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              เวลาหมดอายุ
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              เลขในตะกร้า
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              จำนวนเลขในตะกร้า
            </th>
          </tr>
        </thead>
        <tbody>
          {orderData.map((order) => {
            return (
              <tr key={order.shortId}>
                <th className="align-middle text-center">
                  <button
                    style={{
                      backgroundColor: themes.colors.dangerRed,
                      color: "white",
                      border: "0",
                      borderRadius: "5px",
                      padding: "5px 10px",
                    }}
                    onClick={() => {
                      window.open(`/users/${order.id}`, "_blank");
                    }}
                  >
                    {order.id}
                  </button>
                </th>
                <th className="align-middle text-center">
                  {new Date(parseInt(order.expireAt)).toLocaleString()}
                </th>
                <th className="align-middle text-center">
                  <ScrollBar>
                    {Object.entries(order.lotteryBlocks).map(
                      ([key, value], index) => (
                        <p key={index} style={{ margin: "0" }}>
                          {key} ({value.length})
                        </p>
                      )
                    )}
                  </ScrollBar>
                </th>
                <th className="align-middle text-center">{order.size} </th>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default Cart;
