import React, { useEffect, useState, useRef } from "react";
import { onSnapshot, collection, doc } from "firebase/firestore";
import Context from "../../Context";
import styled from "styled-components";
import ButtonLoading from "../../components/ButtonLoading";
import themes from "../../themes";
import { Container } from "../../components";
const MakeReward = () => {
  //   var input = "250149108675196954309743805914";

  // var result = [];
  // while (input.length) {
  //     result.push(input.substr(0, 6));
  //     input = input.substr(6);
  // }

  // (result.forEach(data => console.log(data))
  const { callApi, db, configs } = Context();
  const [data, setData] = useState([]);
  const [getAllWinnerOrdersPercent, setGetAllWinnerOrdersPercent] =
    useState(-1);
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const prizeList = {
    first: {
      numbers: [],
      count: 1,
      length: 6,
      digitType: "number",
      text: "รางวัลที่ 1",
      prizeAmount: 6000000,
    },
    second: {
      numbers: [],
      count: 5,
      length: 6,
      digitType: "number",
      text: "รางวัลที่ 2",
      prizeAmount: 200000,
    },
    nearBy: {
      numbers: [],
      count: 2,
      length: 6,
      digitType: "number",
      text: "รางวัลข้างเคียงรางวัลที่ 1",
      prizeAmount: 100000,
    },
    third: {
      numbers: [],
      count: 10,
      length: 6,
      digitType: "number",
      text: "รางวัลที่ 3",
      prizeAmount: 80000,
    },
    forth: {
      numbers: [],
      count: 50,
      length: 6,
      digitType: "number",
      text: "รางวัลที่ 4",
      prizeAmount: 40000,
    },
    fith: {
      numbers: [],
      count: 100,
      length: 6,
      digitType: "number",
      text: "รางวัลที่ 5",
      prizeAmount: 20000,
    },
    lastTwo: {
      numbers: [],
      count: 1,
      length: 2,
      digitType: "lastTwo",
      text: "เลขท้าย 2 ตัว",
      prizeAmount: 2000,
    },
    firstThree: {
      numbers: [],
      count: 2,
      length: 3,
      digitType: "firstThree",
      text: "เลขหน้า 3 ตัว",
      prizeAmount: 4000,
    },
    lastThree: {
      numbers: [],
      count: 2,
      length: 3,
      digitType: "lastThree",
      text: "เลขท้าย 3 ตัว",
      prizeAmount: 4000,
    },
  };
  const [isEditing, setIsEditing] = useState(false);
  const [prizes, setPrizes] = useState(prizeList);

  useEffect(() => {
    getRoundPrize();
    const sub = () => {
      const Status = collection(db, "Status");
      const makingPrizeOrders = doc(Status, "makingPrizeOrders");
      return onSnapshot(makingPrizeOrders, (snapshot) => {
        const { find, marked } = snapshot.get("markingOrders");
        setGetAllWinnerOrdersPercent((x) => {
          let per = find === 0 ? 0 : Math.floor((marked / find) * 100);
          if (x === -1) {
            per = 0;
          }
          return per;
        });
      });
    };
    sub();
    return sub;
  }, []);
  const getAllWinnerOrders = async () => {
    const yes = window.confirm(
      "ท่านต้องการ\nตรวจรางวัลในทุกคำสั่งซื้อ\nใช่ หรือ ไม่"
    );
    if (yes) {
      await callApi("prizes-getAllWinnerOrders");
    }
  };
  const getBookPrizes = () => {
    callApi("prizes-getAllWinnerLotteries");
  };
  const getRoundPrize = () => {
    return callApi("prizes-getRoundPrizes", { roundDate }).then((result) =>
      setPrizes((old) => {
        Object.keys(old).forEach((key) => {
          old[key] = { ...old[key], ...result[key] };
        });
        return old;
      })
    );
  };
  // const clearUserPrize = () => {
  //   const yes = window.confirm("ท่านต้องการ\nลบรางวัลทั้งหมด\nใช่ หรือ ไม่");
  //   if (yes) {
  //     setData([]);
  //     callApi("prizes-clearUserPrize");
  //   }
  // };
  const submit = async () => {
    if (validate()) {
      const res = Object.keys(prizes).reduce((acc, key) => {
        acc[key] = {
          numbers: prizes[key].numbers,
          prizeAmount: prizeList[key].prizeAmount,
          digitType: prizeList[key].digitType,
        };
        return acc;
      }, {});
      await callApi("prizes-editAll", { id: roundDate, value: res });
      setIsEditing(false);
      return { status: true };
    }
  };
  const setNumber = (type, value) => {
    setPrizes((old) => {
      old[type].numbers = value.split("\n").filter((x) => x);
      return old;
    });
  };
  const validate = () => {
    return Object.keys(prizeList).every((key) => {
      let theBadOne = "";
      const prizeArray = prizes[key].numbers;
      if (prizeArray) {
        const isCorrectLength = prizeArray.every((number) => {
          if (number.length === prizeList[key].length) {
            return true;
          } else {
            theBadOne = number;
            return false;
          }
        });
        const isAllNumber = prizeArray.every((number) => {
          if (isNaN(number)) {
            theBadOne = number;
            return false;
          } else {
            return true;
          }
        });
        const isCorrectSize = prizeArray.length === prizeList[key].count;
        if (isCorrectLength && isCorrectSize && isAllNumber) {
          return true;
        } else {
          if (!isCorrectLength) {
            alert(
              prizeList[key].text +
                " มีเลขบางตัวไม่ครบ 6 หลัก " +
                `(${theBadOne})`
            );
          } else if (!isCorrectSize) {
            alert(
              prizeList[key].text +
                " มีเลขรางวัลไม่ครบ" +
                `(${prizeArray.length}/${prizeList[key].count})`
            );
          } else if (!isAllNumber) {
            alert(
              prizeList[key].text +
                " มีเลขรางวัลบางตัวไม่ใช่ตัวเลข " +
                `(${theBadOne})`
            );
          }
          return false;
        }
      } else {
        alert(prizeList[key].text + " ข้อมูลไม่ถูกต้อง" + `(${theBadOne})`);
        return false;
      }
    });
  };

  return (
    <Container>
      <div>
        <h3>งวดที่ {configs.roundDate}</h3>
      </div>
      <ButtonLoading
        title={"ตรวจรางวัลในทุกคำสั่งซื้อ"}
        handleClick={getAllWinnerOrders}
        text={"ตรวจรางวัล"}
        percent={getAllWinnerOrdersPercent}
      />
      <ButtonLoading
        title={"หารางวัลในเล่ม"}
        handleClick={getBookPrizes}
        text={"หารางวัลในเล่ม"}
        percent={-1}
      />
      {/* <ButtonLoading
        title={"clearUserPrize"}
        handleClick={clearUserPrize}
        text={"clearUserPrize"}
        percent={-1}
      /> */}
      <br />
      <h4> กรอกเลขรางวัล</h4>
      <div style={{ display: "flex" }}>
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"first"}
        />
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"second"}
        />
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"nearBy"}
        />
      </div>
      <div style={{ display: "flex" }}>
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"lastTwo"}
        />
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"firstThree"}
        />
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"lastThree"}
        />
      </div>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"third"}
        />
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"forth"}
        />
        <InputBox
          isEditing={isEditing}
          setNumber={setNumber}
          prizes={prizes}
          type={"fith"}
        />
      </div>
      {isEditing ? (
        <>
          <button
            onClick={() => {
              getRoundPrize();
              setIsEditing(false);
            }}
            style={{ color: "white", background: themes.colors.danger }}
          >
            ยกเลิก
          </button>
          <button
            style={{
              float: "right",
              color: "white",
              background: themes.colors.success,
            }}
            onClick={submit}
          >
            บันทึก
          </button>
        </>
      ) : (
        <button
          style={{ color: "white", background: themes.colors.success }}
          onClick={() => setIsEditing(true)}
        >
          แก้ไข
        </button>
      )}
    </Container>
  );
};
const InputBox = ({ isEditing, setNumber, prizes, type }) => {
  return (
    <div style={{ flex: 1, border: "1px solid gray", padding: "10px" }}>
      <div>
        <b>{prizes?.[type]?.text}</b> ({prizes?.[type]?.count} รางวัล)
      </div>
      <div style={{ color: "red" }}>
        {prizes?.[type]?.prizeAmount.toLocaleString()} บาท
      </div>
      {isEditing ? (
        <Input
          defaultValue={prizes?.[type]?.numbers.join("\n")}
          onChange={(e) => setNumber(type, e.target.value)}
        />
      ) : (
        <pre style={{ marginTop: 0 }}>{prizes?.[type]?.numbers.join("\n")}</pre>
      )}
    </div>
  );
};
const Input = styled.textarea`
  width: 100%;
  min-height: 100px;
`;
export default MakeReward;
