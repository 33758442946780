import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";
import { Input, InputGroup, Button } from "reactstrap";
const UserPrizes = () => {
  const { callApi } = Context();
  return (
    <HtTablePaging
      title={"ร้องเรียน"}
      getData={(body) => callApi("complains-getAll", body)}
      updateApi={async (body) => {
        console.log(body);
        return await callApi("complains-updateStatus", {
          ...body,
          collectionName: "Users",
        });
      }}
      dropdownFilter={{
        status: ["open", "closed"],
      }}
      translations={{
        createdAt: "สร้างเมื่อ",
        detail: "รายละเอียด",
        status: "สถานะ",
        firstName: "ชื่อ",
        lastName: "นามสกุล",
        userNo: "รหัสผู้ใช้",
        title: "เรื่อง",
        body: "รายระเอียด",
        phone: "โทรศัพท์",
        type: "ประเภท",
        adminName: "ชื่อพนักงาน",
        notes: "บันทึก",
      }}
      defaultEditable={true}
      hideColumns={["id"]}
      dropdownAble={{ status: ["open", "pending", "closed"] }}
      overrides={{
        notes: (props) => {
          return (
            <InputGroup>
              <Input
                id={props.row.id}
                name="notes"
                placeholder=""
                defaultValue={props.row.notes}
                type="input"
              />
              <Button
                onClick={async () => {
                  const value = document.getElementById(props.row.id).value;
                  return await callApi("complains-updateNote", {
                    collectionName: "Users",
                    id: props.row.id,
                    key: "notes",
                    value: value,
                  });
                }}
              >
                บันทึก
              </Button>
            </InputGroup>
          );
        },
      }}
    />
  );
};
export default UserPrizes;
