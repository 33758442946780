import * as React from "react";

import { cn } from "../util/lib";

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        "flex h-12 w-full rounded-[0.5rem] border border-input bg-transparent px-3 py-1 text-md font-medium shadow-sm focus:outline-1 focus:outline-black invalid:cursor-not-allowed transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

Input.displayName = "Input";

export { Input };
