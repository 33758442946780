import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { useParams } from "react-router-dom";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  Switch,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Input,
} from "../../components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import { isEmpty } from "ramda";

export const MissionList = () => {
  const {
    callApi,
    profileState,
    configs,
    adminCart,
    setAdminCart,
    updateConfig,
  } = Context();
  const { missionId } = useParams();
  const [queriesValue, setValue] = useState("");
  const [queriesKey, setKey] = useState("");
  const [startAfter, setStartAfter] = useState({ 1: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [orderData, setOrderData] = useState([]);

  useEffect(() => {
    handleSearch();
  }, [currentPage, roundDate]);

  const handleSearch = async (body) => {
    console.log("missionId", missionId);
    const queries =
      isEmpty(queriesKey) || isEmpty(queriesValue)
        ? []
        : [queriesKey, queriesValue];

    const result = await callApi("missions-getAllMissionOrders", {
      ...body,
      roundDate: roundDate,
      queries: missionId ? [["id", missionId], ...queries] : [queries],
      limit: 50,
      startAfter: startAfter[currentPage],
      orderBy: ["createdAt", "desc"],
    });

    const sortResult = result.sort((a, b) => b.createdAt - a.createdAt);

    setOrderData(sortResult);
  };

  const nextPage = () => {
    const startAfterId = orderData.slice(-1)[0].id;
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    setStartAfter((prevState) => {
      return { ...prevState, [nextPage]: startAfterId };
    });
  };

  const prevPage = () => {
    let prevPage = currentPage - 1;
    setCurrentPage(prevPage);
  };

  return (
    <>
      <div className="bg-white max-w-[1200px] p-8 rounded-2xl drop-shadow-xl">
        <p className="text-3xl font-semibold">คำขอแลกรางวัลทั้งหมด </p>
        <div className="flex justify-between mt-4 ">
          <div className="flex gap-4 items-center">
            <p className="text-lg font-medium">งวด</p>
            <Select value={roundDate} onValueChange={(e) => setRoundDate(e)}>
              <SelectTrigger className="my-1 text-md font-medium">
                <SelectValue placeholder="-- โปรดเลือก --" />
              </SelectTrigger>
              <SelectContent className="bg-black">
                {configs.roundDates.map((roundDate) => {
                  return (
                    <SelectItem
                      className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                      value={roundDate}
                    >
                      {roundDate}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
          <div className="flex gap-4 items-center">
            <p className="text-lg max-w-[70px] w-full font-medium">ฟิลเตอร์</p>
            <Select
              className=""
              value={queriesKey}
              onValueChange={(e) => setKey(e)}
            >
              <SelectTrigger className="my-1 text-md font-medium">
                <SelectValue placeholder="--Filter--" />
              </SelectTrigger>
              <SelectContent className="bg-black">
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value="shortId"
                >
                  ออเดอร์ไอดี
                </SelectItem>

                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value="phone"
                >
                  เบอร์โทรศัพท์
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value="rewardId"
                >
                  คูปองรางวัล
                </SelectItem>
              </SelectContent>
            </Select>
            <Input
              className="my-1"
              placeholder="ชื่อภารกิจ"
              value={queriesValue}
              onChange={(e) => setValue(e.target.value)}
            />
            <button
              onClick={() => handleSearch()}
              className="h-12 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </div>

      <div
        className={`bg-white max-w-[1200px] my-4  p-8 rounded-2xl drop-shadow-xl ${
          orderData.length === 0 && "hidden"
        }`}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px] text-center">
                วันที่ - เวลา
              </TableHead>
              <TableHead className="text-center">ออเดอร์ไอดี</TableHead>
              <TableHead className="text-center">ชื่อภารกิจ</TableHead>
              <TableHead className="text-center">ชื่อ - นามสกุล</TableHead>
              <TableHead className="text-center">เบอร์โทรศัพท์</TableHead>
              <TableHead className="text-center">รหัสผู้ใช้</TableHead>
              <TableHead className="text-center">คูปองรางวัล</TableHead>
              <TableHead className="text-center">ออเดอร์แลกรางวัล</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orderData.length !== 0 &&
              orderData.map((order) => {
                return (
                  <TableRow>
                    <TableCell className="font-medium align-baseline">
                      {new Date(parseInt(order?.createdAt)).toLocaleString()}
                    </TableCell>
                    <TableCell className="text-center font-bold align-baseline">
                      {order?.shortId}
                    </TableCell>
                    <TableCell className="text-center align-baseline">
                      {order?.mission.name}
                    </TableCell>
                    <TableCell className="text-center align-baseline">
                      {order?.userData
                        ? order?.userData?.firstName +
                          " " +
                          order?.userData?.lastName
                        : "-"}
                    </TableCell>
                    <TableCell className="text-center align-baseline">
                      {order?.userData?.phone || "-"}
                    </TableCell>
                    <TableCell className="align-baseline">
                      <div
                        className={`
                       flex items-center mx-auto bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                        onClick={() =>
                          window.open(
                            `https://admin.hongthongofficial.com/users/${order?.userId}`,
                            "__blank"
                          )
                        }
                      >
                        {order?.userId || "-"}
                      </div>
                    </TableCell>

                    <TableCell className="align-baseline">
                      <div
                        className={`
                       flex items-start mx-auto bg-gray-950 justify-start w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                        onClick={() =>
                          window.open(
                            `https://admin.hongthongofficial.com/coupon/${order?.rewardId}`,
                            "__blank"
                          )
                        }
                      >
                        {order?.rewardId?.slice(-5).toUpperCase() || "-"}
                      </div>
                    </TableCell>
                    <TableCell className="flex flex-col gap-2 ">
                      {order?.orderReference.map((orderRef) => {
                        return (
                          <div
                            className={`
                     flex items-center mx-auto bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                            onClick={() =>
                              window.open(
                                `https://admin.hongthongofficial.com/orders/working/${orderRef}`,
                                "__blank"
                              )
                            }
                          >
                            {orderRef?.slice(-5).toUpperCase() || "-"}
                          </div>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <div className="text-right mt-14">
          {orderData.length >= 50 && (
            <button
              className="h-10 my-4 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => {
                nextPage();
              }}
            >
              หน้าต่อไป
            </button>
          )}

          {currentPage !== 1 && (
            <button
              className="h-10 my-4 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => {
                prevPage();
              }}
            >
              ย้อนกลับ
            </button>
          )}
        </div>
      </div>
    </>
  );
};
