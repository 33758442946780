import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { useNavigate, useParams } from "react-router-dom";
import HtDataManager from "../../components/HtDataManager";
import { thaiDate, role } from "../../util";
import constant from "../../constants";
import styled from "styled-components";
import { ContentContainer, Label, Flex } from "../../components";
import { FormGroup, Input, Table } from "reactstrap";
import { Children } from "react";

const Orders = () => {
  const { callApi, profileState, setModalLoading } = Context();
  const { userId } = useParams();
  const dataLeftRef = useRef(null);
  const [point, setPoint] = useState();
  const [cash, setCash] = useState();
  const [notePoint, setNotePoint] = useState("");
  const [noteCash, setNoteCash] = useState("");
  const [dataLeft, setDataLeft] = useState({});
  const [userData, setUserData] = useState([]);
  const [pointsHistory, setPointsHistory] = useState([]);
  const [cashesHistory, setCashesHistory] = useState([]);
  const getUserData = async () => {
    setDataLeft({});
    await Promise.all([
      callApi("cashes-getCashesHistoryByUser", { userId }).then((history) =>
        setCashesHistory(history.data)
      ),

      callApi("points-getPointHistoryByUser", { userId }).then((history) =>
        setPointsHistory(history.data)
      ),

      callApi("users-getUserInfoAdmin", { userId }).then((user) => {
        setUserData(user);
        const {
          accName,
          accNo,
          activated,
          admin,
          bankName,
          buyPoint,
          referralPoint,
          email,
          firstName,
          lastName,
          password,
          phone,
          userNo,
          address,
          is80Privilage,
          cartTime,
          otp,
          lineId,
          lineTitle,
          nationality,
        } = user;

        setDataLeft({
          boxes: [
            {
              sections: [
                {
                  id: userId,
                  updateChange: async (body) => {
                    return await callApi("users-edit", body);
                  },
                  header: "คะแนนแนะนำเพื่อน",
                  size: 1,
                  card: {
                    size: 3,
                    cards: [
                      {
                        title: "ยอดเงิน",
                        value: {
                          component: "Input",
                          value: referralPoint,
                          field: "referralPoint",
                        },
                      },
                    ],
                  },
                },
                {
                  Component: UpdatePoint,
                  props: {
                    userId,
                    type: "referralPoints",
                    choices: ["ถอนคะแนน"],
                  },
                },
              ],
            },

            {
              sections: [
                {
                  id: userId,
                  updateChange: async (body) => {
                    if (body.key === "cartTime") {
                      body.value = parseInt(body.value);
                    }
                    return await callApi("users-edit", body);
                  },
                  header: "สิทธิพิเศษ",
                  size: 1,
                  card: {
                    size: 3,
                    cards: [
                      {
                        title: "เวลาตะกร้า",
                        value: {
                          component: "Choice",
                          choices: [10, 20, 30, 60],
                          value: cartTime || 10,
                          field: "cartTime",
                        },
                      },
                      {
                        title: "ลูกค้า 80 บาททุกใบ",
                        value: {
                          component: "Choice",
                          choices: [true, false],
                          value: is80Privilage,
                          field: "is80Privilage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
            {
              sections: [
                {
                  id: userId,
                  updateChange: async (body) => {
                    return await callApi("users-edit", body);
                  },
                  header: "แก้ไขรหัสเข้าระบบ",
                  size: 1,
                  card: {
                    size: 3,
                    cards: [
                      {
                        title: "OTP",
                        value: {
                          component: "Text",
                          value: otp?.code,
                          field: "otp.code",
                        },
                      },
                      {
                        title: "OTP Ref",
                        value: {
                          component: "Text",
                          value: otp?.ref,
                          field: "otp.ref",
                        },
                      },
                      {
                        title: "เวลา OTP หมดอายุ",
                        value: {
                          component: "Text",
                          field: "admin",
                          value: otp?.expireAt,
                          field: "admin",
                        },
                      },
                    ],
                  },
                },
                {
                  Component: Password,
                  props: { userId },
                },
              ],
            },
          ],
        });
      }),
    ]);
  };

  const updateChange = async (key, value) => {
    if (!window.confirm("ท่านต้องการแก้ไขใช่หรือไม่")) return;

    if (key === "bank") {
      const getBank = constant.bankList.find((bank) => bank.bankName === value);

      console.log(getBank);
      await callApi("users-edit", {
        id: userId,
        key: key,
        value: {
          name: getBank.bankName,
          currency: getBank.currency,
          accName: userData.bank.accName || "",
          accNo: userData.bank.accNo || "",
        },
      });

      return getUserData();
    }

    await callApi("users-edit", {
      id: userId,
      key: key,
      value: value,
    });
    getUserData();
  };

  const updatePoint = async () => {
    if (!point) {
      return alert("โปรดใส่ข้อมูลให้ครบถ้วน");
    }
    if (window.confirm("ท่านต้องการแก้ไข คะแนน ใช่หรือไม่")) {
      await callApi(`points-add`, {
        event: notePoint || `ปรับคะแนนโดย ${profileState.userId}`,
        amount: parseInt(point),
        owner: userId,
        ownerNo: userData.userNo,
        user: profileState.userNo || "idk",
      });
    }
    getUserData();
  };

  const updateCash = async () => {
    if (!cash) {
      return alert("โปรดใส่ข้อมูลให้ครบถ้วน");
    }
    if (window.confirm("ท่านต้องการแก้ไข คะแนน ใช่หรือไม่")) {
      await callApi(`cashes-editAffiliateCashByAdmin`, {
        event: noteCash || `ปรับคะแนนโดย ${profileState.userId}`,
        amount: parseInt(cash),
        owner: userData.userNo,
        userNo: profileState.userNo || "idk",
      });
    }
    getUserData();
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div style={{ verticalAlign: "top", height: "100%" }} ref={dataLeftRef}>
      <ContentContainer>
        <Flex align="center" wrap="wrap">
          <img
            src={userData.profileImage || "/images/HT_logo_squire_new2.png"}
            width={100}
            height={100}
            alt="profile"
            style={{ marginRight: "2em" }}
          />
          <div>
            <Label.H1 color="white" weight={600}>
              สมาชิกหมายเลข {userData.userId || userId}
            </Label.H1>
            <Label.H4 color="white">
              สมาชิกลำดับที่{" "}
              <span style={{ fontWeight: 600 }}>{userData.userNo}</span>
            </Label.H4>
            {userData.role && (
              <Label.H5 color="white">
                <span style={{ fontWeight: 600 }}>{role[userData.role]}</span>
              </Label.H5>
            )}
          </div>
        </Flex>
        <Flex wrap="wrap">
          <InputForm header="สถานะ">
            <Input
              type="select"
              id="status"
              value={userData?.status}
              onChange={(e) => {
                updateChange(e.target.id, e.target.value);
              }}
              style={
                userData?.status === "active"
                  ? {
                      background: "green",
                      color: "white",
                    }
                  : userData?.status === "inactive"
                  ? {
                      background: "yellow",
                      color: "black",
                    }
                  : {
                      background: "red",
                      color: "white",
                    }
              }
            >
              <option value="active">ปกติ</option>
              <option value="inactive">ระงับชั่วคราว</option>
              <option value="removed">ระงับถาวร</option>
            </Input>
          </InputForm>
          <InputForm header="ยืนยันเบอร์มือถือ">
            <h3>
              <Input
                type="switch"
                id="activated"
                checked={userData?.activated}
                onChange={(e) => {
                  updateChange(e.target.id, e.target.checked);
                }}
                style={{ margin: "0" }}
              />
            </h3>
          </InputForm>
        </Flex>

        <Flex wrap="wrap">
          <InputForm header="ชื่อ">
            <Input
              type="text"
              id="firstName"
              defaultValue={userData?.firstName}
              onBlur={(e) => updateChange(e.target.id, e.target.value)}
            />
          </InputForm>
          <InputForm header="นามสกุล">
            <Input
              type="text"
              id="lastName"
              defaultValue={userData?.lastName}
              onBlur={(e) => updateChange(e.target.id, e.target.value)}
            />
          </InputForm>
        </Flex>
        <Flex wrap="wrap">
          <InputForm header="สัญชาติ">
            <Input
              type="select"
              id="nationality"
              value={userData?.nationality}
              onChange={(e) => {
                updateChange(e.target.id, e.target.value);
              }}
            >
              <option value="">ไม่ระบุ</option>
              <option value="THA">ไทย</option>
              <option value="LAO">ลาว</option>
            </Input>
          </InputForm>
          <InputForm header="เบอร์โทรศัพท์">
            <Input
              type="text"
              id="phone"
              defaultValue={userData.phone}
              onBlur={(e) => updateChange(e.target.id, e.target.value)}
            />
          </InputForm>
        </Flex>

        <Flex wrap="wrap">
          <InputForm header="อีเมล">
            <Input
              type="text"
              id="email"
              defaultValue={userData?.email}
              onBlur={(e) => updateChange(e.target.id, e.target.value)}
            />
          </InputForm>
          <InputForm header="ไลน์ไอดี">
            <Input
              type="text"
              id="lineId"
              defaultValue={userData.lineId}
              onBlur={(e) => updateChange(e.target.id, e.target.value)}
            />
          </InputForm>
        </Flex>
        <InputForm header="ที่อยู่" width="100%">
          <Input
            type="textarea"
            id="address.addr"
            defaultValue={userData.address?.addr}
            onBlur={(e) => updateChange(e.target.id, e.target.value)}
          />
        </InputForm>
        <Flex wrap="wrap">
          <InputForm header="จังหวัด">
            <Input
              type="select"
              id="address.province"
              defaultValue={userData?.address?.province}
              onBlur={(e) => updateChange(e.target.id, e.target.value)}
            >
              {constant.provinces.map((roundDate) => (
                <option key={roundDate}>{roundDate}</option>
              ))}
            </Input>
          </InputForm>
          <InputForm header="เลขไปรษณีย์">
            <Input
              type="text"
              id="address.zipcode"
              defaultValue={userData.address?.zipcode}
              onBlur={(e) => updateChange(e.target.id, e.target.value)}
            />
          </InputForm>
        </Flex>

        <InputForm header="ชื่อบัญชี">
          <Input
            type="text"
            id="bank.accName"
            defaultValue={userData.bank?.accName}
            onBlur={(e) => updateChange(e.target.id, e.target.value)}
          />
        </InputForm>
        <Flex>
          <InputForm header="เลขบัญชี">
            <Input
              type="text"
              id="bank.accNo"
              defaultValue={userData.bank?.accNo}
              onBlur={(e) => updateChange(e.target.id, e.target.value)}
            />
          </InputForm>
          <InputForm header="ธนาคาร">
            <Input
              type="select"
              id="bank"
              value={userData.bank?.name}
              onChange={(e) => {
                updateChange("bank", e.target.value);
              }}
            >
              {/* <option key={""}>{""}</option>
              {constant.bankNames.map((bankNames) => (
                <option key={bankNames}>{bankNames}</option>
              ))} */}
              {!userData.bank?.name && <option></option>}
              <optgroup label="Thai Bank">
                {constant.bankList
                  .filter((bank) => bank.currency === "THB")
                  .map((x) => (
                    <option value={x.bankName}>{x.bankName}</option>
                  ))}
              </optgroup>
              <optgroup label="Lao Bank">
                {constant.bankList
                  .filter((bank) => bank.currency === "LAK")
                  .map((x) => (
                    <option value={x.bankName}>{x.bankName}</option>
                  ))}
              </optgroup>
              <optgroup label="Korean Bank">
                {constant.bankList
                  .filter((bank) => bank.currency === "KRW")
                  .map((x) => (
                    <option key={x.bankName} value={x.bankName}>
                      {x.bankName}
                    </option>
                  ))}
              </optgroup>
            </Input>
          </InputForm>
        </Flex>
      </ContentContainer>
      <br />
      <ContentContainer>
        <Flex wrap="wrap">
          <div style={{ paddingRight: "50px" }}>
            <Label.H1 color="white" weight={600}>
              ปรับคะแนน
            </Label.H1>
            <InputForm header="คะแนนปัจจุบัน">
              <Label.H2 color="white" weight={600}>
                {userData?.buyPoint}
              </Label.H2>
            </InputForm>
            <Flex wrap="wrap" align="flex-end">
              <InputForm header="ปรับคะแนน">
                <Input
                  type="number"
                  id="point"
                  defaultValue={0}
                  onChange={(e) => setPoint(e.target.value)}
                ></Input>
              </InputForm>
              <InputForm header="บันทึกเพิ่มเติม">
                <Input
                  type="text"
                  id="point"
                  defaultValue={""}
                  placeholder="บันทึกเพิ่มเติม"
                  onChange={(e) => setNotePoint(e.target.value)}
                ></Input>
              </InputForm>
              <button
                style={{
                  background: "rgba(245,194,64,1)",
                  borderRadius: "5px",
                  margin: "0 0 7px 0",
                }}
                onClick={() => updatePoint()}
              >
                บันทึก
              </button>
            </Flex>
          </div>
          <FullScrollBar
            direction="column"
            style={{ maxHeight: "300px", marginTop: "2em", overflow: "scroll" }}
          >
            <Label.H2 textAlign="center" color="white" weight={600}>
              ประวัติคะแนน
            </Label.H2>
            <br />
            {pointsHistory.length !== 0 ? (
              <Table bordered striped style={{ maxWidth: "900px" }}>
                <thead>
                  <tr>
                    <th className="text-light text-center">วันที่</th>
                    <th className="text-light text-center">ก่อนปรับ</th>
                    <th className="text-light text-center">ปรับ</th>
                    <th className="text-light text-center">หลังปรับ</th>
                    <th className="text-light text-center">ผู้แก้ไข</th>
                    <th className="text-light text-center">บันทึกเพิ่มเติม</th>
                  </tr>
                </thead>
                <tbody>
                  {pointsHistory
                    .sort((a, b) => b.createdAt - a.createdAt)
                    .map((data) => {
                      return (
                        <tr>
                          <th className="text-light text-center">
                            {thaiDate(new Date(parseInt(data.createdAt)))}
                          </th>
                          <th className="text-light text-center">
                            {data.oldPoint}
                          </th>
                          <th className="text-light text-center">
                            {data.point}
                          </th>
                          <th className="text-light text-center">
                            {data.newPoint}
                          </th>
                          <th className="text-light text-center">
                            {data.updatedBy}
                          </th>
                          <th className="text-light text-center">
                            {data.note}
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              <div style={{ width: "100%" }}>
                <Label.H4 textAlign="center" color="white" weight={600}>
                  ไม่พบประวัติ
                </Label.H4>
              </div>
            )}
          </FullScrollBar>
        </Flex>
      </ContentContainer>
      <br />
      <ContentContainer>
        <Flex wrap="wrap">
          <div style={{ paddingRight: "50px" }}>
            <Label.H1 color="white" weight={600}>
              รายได้
            </Label.H1>
            <InputForm header="รายได้ปัจจุบัน">
              <Label.H2 color="white" weight={600}>
                {userData?.buyCash || 0}
              </Label.H2>
            </InputForm>
            <Flex wrap="wrap" align="flex-end">
              <InputForm header="ปรับรายได้">
                <Input
                  type="number"
                  id="cash"
                  defaultValue={0}
                  onChange={(e) => setCash(e.target.value)}
                ></Input>
              </InputForm>
              <InputForm header="บันทึกเพิ่มเติม">
                <Input
                  type="text"
                  id="cash"
                  defaultValue={""}
                  placeholder="บันทึกเพิ่มเติม"
                  onChange={(e) => setNoteCash(e.target.value)}
                ></Input>
              </InputForm>
              <button
                style={{
                  background: "rgba(245,194,64,1)",
                  borderRadius: "5px",
                  margin: "0 0 7px 0",
                }}
                onClick={() => updateCash()}
              >
                บันทึก
              </button>
            </Flex>
          </div>
          <FullScrollBar
            direction="column"
            style={{ maxHeight: "300px", marginTop: "2em", overflow: "scroll" }}
          >
            <Label.H2 textAlign="center" color="white" weight={600}>
              ประวัติรายได้
            </Label.H2>
            <br />
            {cashesHistory.length !== 0 ? (
              <Table bordered striped style={{ maxWidth: "900px" }}>
                <thead>
                  <tr>
                    <th className="text-light text-center">วันที่</th>
                    <th className="text-light text-center">ก่อนปรับ</th>
                    <th className="text-light text-center">ปรับ</th>
                    <th className="text-light text-center">หลังปรับ</th>
                    <th className="text-light text-center">รหัสคำสั่งซื้อ</th>
                    <th className="text-light text-center">บันทึกเพิ่มเติม</th>
                  </tr>
                </thead>
                <tbody>
                  {cashesHistory
                    .sort((a, b) => b.createdAt - a.createdAt)
                    .map((data) => {
                      return (
                        <tr>
                          <th className="text-light text-center">
                            {thaiDate(new Date(parseInt(data.createdAt)))}
                          </th>
                          <th className="text-light text-center">
                            {data.oldCash}
                          </th>
                          <th className="text-light text-center">
                            {data.cash}
                          </th>
                          <th className="text-light text-center">
                            {data.newCash}
                          </th>
                          <th className="text-light text-center">
                            {data.shortId}
                          </th>
                          <th className="text-light text-center">
                            {data.note}
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              <div style={{ width: "100%" }}>
                <Label.H4 textAlign="center" color="white" weight={600}>
                  ไม่พบประวัติ
                </Label.H4>
              </div>
            )}
          </FullScrollBar>
        </Flex>
      </ContentContainer>
      <br />
      <div
        style={{ width: "100%", display: "inline-block", verticalAlign: "top" }}
      >
        <HtDataManager data={dataLeft} />
      </div>
    </div>
  );
};

const InputForm = ({ header, children, width }) => {
  return (
    <div
      style={{
        width: width || "200px",
        maxWidth: "450px",
        margin: "50px 50px 0 0",
      }}
    >
      <Label.H4 color="white" weight={600}>
        {header}
      </Label.H4>
      <FormGroup switch className="p-0">
        {children}
      </FormGroup>
    </div>
  );
};
export default Orders;
const UpdatePoint = ({ userId, type, choices }) => {
  const [pointChange, setPointChange] = useState(0);
  const [reason, setReason] = useState();
  const { callApi, configs, profileState, updateChange } = Context();

  const submit = () => {
    if (!pointChange || !reason) {
      return alert("โปรดใส่ข้อมูลให้ครบถ้วน");
    }
    if (window.confirm("ท่านต้องการแก้ไข คะแนน ใช่หรือไม่")) {
      callApi(`${type}-add`, {
        event: reason,
        amount: parseInt(pointChange),
        owner: userId,
        user: profileState.userId || "idk",
      });
    }
  };
  const changeValue = (e) => {
    if (configs?.premiums?.some((item) => item.name === reason)) {
      return; //choosing premium can't change price
    }
    setPointChange(e.target.value);
  };
  const changeItem = (e) => {
    const fixedPrice = configs?.premiums?.filter(
      (item) => item.name === e.target.value
    );
    if (fixedPrice?.length) {
      //choosing premium can't change price
      setPointChange(fixedPrice[0].price);
    }
    setReason(e.target.value);
  };
  return (
    <div style={{ margin: "10px" }}>
      <div style={{ marginRight: "20px" }}>
        <b>ปรับคะแนน</b>
      </div>
      <select
        onChange={changeItem}
        value={reason}
        style={{ marginRight: "20px" }}
      >
        <option></option>
        <option>เพิ่มคะแนน: ระบบผิดพลาด</option>
        <option>ลดคะแนน: ระบบผิดพลาด</option>
        {choices ? (
          choices.map((choice) => <option>{choice}</option>)
        ) : (
          <>
            {configs?.premiums?.map((item) => (
              <option key={item.name} value={item.name}>
                แลกของ: {item.name}
              </option>
            ))}
          </>
        )}
      </select>
      <input
        onChange={changeValue}
        value={pointChange}
        type={"number"}
        style={{ marginRight: "20px" }}
      />
      <button onClick={submit}>บันทึก</button>
    </div>
  );
};
const Password = ({ userId }) => {
  const { callApi, adminCart, setAdminCart } = Context();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const submit = async () => {
    if (window.confirm("ต้องการเปลี่ยนรหัสเข้าระบบจริงๆหรือไม่")) {
      if (!password || !confirmPassword) {
        return alert("โปรดกรอกรหัสให้ครับถ้วน");
      }
      if (password !== confirmPassword) {
        return alert("รหัสที่กรอกไม่ตรงกัน");
      }
      const result = await callApi("users-adminResetPassword", {
        userId,
        password,
      });
      if (result.status) {
        alert("เปลี่ยนรหัสสำเร็จ");
      } else {
        alert("เปลี่ยนรหัสสำเร็จไม่สำเร็จ โปรดลองใหม่อีกครั้ง");
        window.location.reload();
      }
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      <input
        style={{ marginRight: "10px" }}
        onChange={(e) => setPassword(e.target.value)}
        placeholder={"กรอกรหัส"}
      />
      <input
        style={{ marginRight: "10px" }}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder={"ยืนยันรหัส"}
      />
      <button onClick={submit}>บันทึก</button>
      <br />
      <br />
      {adminCart.length !== 0 && (
        <button
          onClick={async () => {
            const result = await callApi("orders-adminCreateOrder", {
              lotteryIds: adminCart,
              userId,
            });
            if (result.status === true) {
              setAdminCart([]);
            }
          }}
        >
          สร้างคำสั่งซื้อ {adminCart.length}
        </button>
      )}
      <br />
    </div>
  );
};

const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const FullScrollBar = styled(ScrollBar)`
  width: 100% !important;
  max-width: 1000px !important;
  max-height: 50vh;
`;
