import React, { useEffect, useState, useCallback } from "react";
import Context from "../Context";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

const Login = () => {
  const { callApi, profileState } = Context();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha("yourAction");
    setToken(recaptchaToken);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <div style={{ verticalAlign: "top", height: "100%" }}>
      <div
        style={{ width: "100%", display: "inline-block", verticalAlign: "top" }}
      >
        {localStorage.accessToken ? (
          <button
            onClick={async () => {
              localStorage.removeItem("accessToken");
              window.location.reload();
            }}
          >
            ออกจากระบบ
          </button>
        ) : (
          <>
            บัญชี:
            <input onChange={(e) => setUsername(e.target.value)} />
            <br />
            <br />
            รหัส:
            <input
              type={"password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <br />
            <button
              onClick={async () => {
                const result = await callApi("users-login", {
                  username,
                  password,
                  reCaptchaToken: token,
                  admin: true,
                });

                if (result.success) {
                  localStorage.setItem("accessToken", result.data.accessToken);
                } else {
                  localStorage.removeItem("accessToken");
                }
                window.location.reload();
              }}
            >
              เข้าระบบ
            </button>
          </>
        )}
      </div>
    </div>
  );
};
export default Login;
