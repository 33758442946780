import React, { useState, useEffect } from "react";
import Context from "../../Context";
import styled from "styled-components";
import themes from "../../themes";
import { Container, Label, Flex } from "../../components";
import { RiCoupon2Fill } from "react-icons/ri";
import { FiImage } from "react-icons/fi";
import {
  Modal,
  ModalBody,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Badge,
  FormGroup,
} from "reactstrap";
import { ContentContainer } from "../orders/WorkingOrder";
import { resizeImage } from "../../util";
export const ManageDonate = () => {
  const { callApi, showModalItemDetail, updateConfig, configs, profileState } =
    Context();
  const [modal, setModal] = useState(false);
  const [allCoupons, setAllCoupons] = useState([]);
  const [edit, setEdit] = useState();
  const toggleAdd = () => {
    setEdit(false);
    setModal(!modal);
  };

  useEffect(() => {
    fetchCoupons();
  }, [modal]);

  const fetchCoupons = async () => {
    const result = await callApi("donates-getAllDonate");
    setAllCoupons(result);
  };

  const handleEditClick = (id) => {
    setEdit(allCoupons.find((c) => c.id === id));
    setModal(!modal);
  };

  const updateChange = async (key, value) => {
    if (!window.confirm("ท่านต้องการแก้ไขใช่หรือไม่")) return;
    await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });
    updateConfig();
  };
  return (
    <ContentContainer className=" blue-bg">
      <AddDonateModal
        modal={modal}
        toggle={toggleAdd}
        callApi={callApi}
        editData={edit}
        profileState={profileState}
      />
      <Label.H1 color="white" weight={600}>
        การจัดการบริจาค
      </Label.H1>
      <br />
      <div style={{ width: "200px", marginTop: "50px" }}>
        <Label.H4 color="white" weight={600}>
          เปิดบริจาค
        </Label.H4>
        <h3 className="my-0">
          <FormGroup switch>
            <Input
              type="switch"
              id="isOpen.donate"
              checked={configs.isOpen.donate}
              onClick={(e) => {
                updateChange(e.target.id, e.target.checked);
              }}
            />
          </FormGroup>
        </h3>
      </div>
      <br />
      <Flex align="stretch" margin="2em 0 1em 0" gap="2em" wrap="wrap">
        <AddCoupon toggle={toggleAdd} />
        {allCoupons &&
          allCoupons.map((coupon) => {
            return (
              <Coupon
                data={coupon}
                handleEditClick={handleEditClick}
                key={coupon.id}
              />
            );
          })}
      </Flex>
    </ContentContainer>
  );
};

const Coupon = ({ data, handleEditClick }) => {
  return (
    <Card style={{ width: "250px", minHeight: "300px" }}>
      <img
        src={data.image}
        alt="coupon"
        style={{ width: "200px", height: "200px" }}
      />
      <CardBody
        style={{
          color: "black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <>
          <CardTitle tag="h5" color="primary">
            {data.name}
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            <Badge color={data.status !== "active" ? "danger" : "success"}>
              {data.status === "active" ? "ปกติ" : "ปิดใช้งาน"}
              {data.isHotDeal && " 🔥"}
            </Badge>
          </CardSubtitle>
          <div className="my-3">
            <p className="my-0">ยอดบริจาค</p>
            <p className="my-0">
              คะแนน {new Intl.NumberFormat().format(data.donatePoint)} คะแนน
            </p>
            <p className="my-0">
              เงินโอน {new Intl.NumberFormat().format(data.donateCash)} บาท{" "}
            </p>
          </div>
        </>

        <Button color="danger" onClick={() => handleEditClick(data.id)}>
          แก้ไข
        </Button>
      </CardBody>
    </Card>
  );
};

const AddCoupon = ({ toggle }) => {
  return (
    <DotWrapper onClick={toggle}>
      <h1>
        + <RiCoupon2Fill />
      </h1>
      <br />
      <Label.H3 weight={600} color={themes.colors.blue}>
        เพิ่มบริจาค
      </Label.H3>
    </DotWrapper>
  );
};

const AddDonateModal = ({ modal, toggle, callApi, editData, profileState }) => {
  const [body, setBody] = useState({
    image: undefined,
    thankyouImage: undefined,
    name: "",
    detail: "",
  });
  const [previewImage, setPreviewImage] = useState(editData?.image || "");
  const [thankyouImage, setThankyouImage] = useState(
    editData?.imageThankyou || ""
  );

  useEffect(() => {
    if (editData) {
      setPreviewImage(editData.image);
      setThankyouImage(editData.imageThankyou);
      setBody({
        image: editData?.image,
        name: editData?.name,
        status: editData?.status,
        id: editData?.id,
        detail: editData?.detail,
        imageThankyou: editData?.imageThankyou,
        donateCash: editData?.donateCash,
        donatePoint: editData?.donatePoint,
        previousCash: editData?.donateCash,
        previousPoint: editData?.donatePoint,
        isHotDeal: editData.isHotDeal,
      });
    } else {
      setPreviewImage("");
      setBody({
        image: undefined,
        imageThankyou: undefined,
        name: "",
        status: "active",
        detail: "",
        isHotDeal: false,
      });
    }
  }, [modal]);

  const handleCreateCoupon = async () => {
    console.log(body);
    if (editData) {
      console.log(body);
      const result = await callApi("donates-editDonate", body);
      if (result) return toggle();
    } else {
      const result = await callApi("donates-createDonate", body);
      console.log(result);
      if (result) return toggle();
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalBody
        style={{
          background: themes.colors.secondaryGradient2,
        }}
      >
        <Label.H1 color={themes.colors.white} weight={600}>
          {editData ? "แก้ไขบริจาค" : "สร้างบริจาคใหม่"}
        </Label.H1>
        <br />
        {previewImage ? (
          <Flex direction="column" align="center">
            <br />
            <img src={previewImage} width="200" alt="preview" />
            <br />
            <button
              style={{
                border: "1px solid red",
                backgroundColor: "red",
                borderRadius: "5px",
                padding: "5px 10px",
                width: "70px",
              }}
              onClick={() => {
                setPreviewImage("");
                setBody((prevState) => ({ ...prevState, image: undefined }));
              }}
            >
              แก้ไข
            </button>
          </Flex>
        ) : (
          <Flex direction="column" align="center">
            <label htmlFor="files-slips">
              <UploadBtn>
                <h1>
                  + <FiImage />
                </h1>
                <br />
                <Label.H4 weight={600}>อัปโหลดรูปภาพ</Label.H4>
              </UploadBtn>
            </label>
          </Flex>
        )}

        <input
          style={{
            marginBottom: "15px",
            visibility: "hidden",
            position: "absolute",
            display: "none",
          }}
          id="files-slips"
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];
            const base64 = await resizeImage(file);
            if (base64) {
              const previewUrl = URL.createObjectURL(file);
              setPreviewImage(previewUrl);
              setBody((prevState) => ({ ...prevState, image: base64 }));
            } else {
              alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
            }
          }}
        />
        <br />
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            สถานะ
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="select"
                name="status"
                id="status"
                value={body.status}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }));
                }}
              >
                <option value="active">ปกติ</option>
                <option value="inactive">ปิดใช้งานชั่วคราว</option>
                <option value="removed">ปิดใช้งานถาวร</option>
              </Input>
            </div>
          </Flex>
        </div>
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            ชื่อสถานที่บริจาค
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="สถานที่บริจาค"
                defaultValue={body.name}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
          </Flex>
        </div>
        <div style={{ margin: "35px 0 0 0" }}>
          <Label.H4 color="white" weight={600}>
            Hot Deal
          </Label.H4>
          <Flex gap="10px" align="center">
            <Label.H4 color="white" weight={600}>
              ปิด
            </Label.H4>
            <FormGroup switch>
              <Input
                type="switch"
                checked={body.isHotDeal}
                onClick={() => {
                  setBody((prevState) => ({
                    ...prevState,
                    isHotDeal: !prevState.isHotDeal,
                  }));
                }}
              />
            </FormGroup>
            <Label.H4 color="white" weight={600}>
              เปิด
            </Label.H4>
          </Flex>
        </div>
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            รายละเอียด
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="textarea"
                name="quantity"
                id="quantity"
                placeholder="รายละเอียด"
                defaultValue={body.detail}
                value={body.detail || null}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    detail: e.target.value,
                  }));
                }}
              />
            </div>
          </Flex>
        </div>

        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            จำนวนเงินบริจาครวม
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="number"
                name="donateCash"
                step=".01"
                id="donateCash"
                placeholder="จำนวนเงินบริจาครวม"
                value={body.donateCash}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    donateCash: e.target.value,
                  }));
                }}
              />
            </div>
          </Flex>
        </div>

        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            จำนวนคะแนนบริจาครวม
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="number"
                name="donatePoint"
                disabled={profileState.role !== "superAdmin"}
                step=".01"
                id="donatePoint"
                placeholder="จำนวนคะแนนบริจาครวม"
                value={body.donatePoint}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    donatePoint: e.target.value,
                  }));
                }}
              />
            </div>
          </Flex>
        </div>
        <br />
        <Label.H4 color="white" weight={600}>
          รูปภาพขอบคุณ
        </Label.H4>
        {thankyouImage ? (
          <Flex direction="column" align="center">
            <br />
            <img src={thankyouImage} width="200" alt="preview" />
            <br />
            <button
              style={{
                border: "1px solid red",
                backgroundColor: "red",
                borderRadius: "5px",
                padding: "5px 10px",
                width: "70px",
              }}
              onClick={() => {
                setPreviewImage("");
                setBody((prevState) => ({ ...prevState, image: undefined }));
              }}
            >
              แก้ไข
            </button>
          </Flex>
        ) : (
          <Flex direction="column" align="center">
            <label htmlFor="files-thankyou-slips">
              <UploadBtn>
                <h1>
                  + <FiImage />
                </h1>
                <br />
                <Label.H4 weight={600}>อัปโหลดรูปภาพ</Label.H4>
              </UploadBtn>
            </label>
          </Flex>
        )}
        <input
          style={{
            marginBottom: "15px",
            visibility: "hidden",
            position: "absolute",
            display: "none",
          }}
          id="files-thankyou-slips"
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];
            const base64 = await resizeImage(file);
            if (base64) {
              const previewUrl = URL.createObjectURL(file);
              setThankyouImage(previewUrl);
              setBody((prevState) => ({ ...prevState, imageThankyou: base64 }));
            } else {
              alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
            }
          }}
        />
        <br />

        <Flex margin="50px 0 0 0">
          <button
            style={{
              border: "1px solid rgba(245,194,64,1)",
              backgroundColor: "rgba(245,194,64,1)",
              borderRadius: "5px",
              padding: "5px 10px",
              margin: "0 auto",
            }}
            onClick={() => {
              handleCreateCoupon();
            }}
          >
            {editData ? "ยืนยันการแก้ไข" : "สร้างบริจาค"}
          </button>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

const UploadBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 200px;
  width: 200px;
  background: white;
  border-radius: 15%;
  transition-duration: 100ms;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
`;

const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: ${themes.colors.blue};
  width: 200px;
  min-height: 300px;
  background-color: white;
  transition-duration: 100ms;

  :hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
`;
