import React, { useState, useEffect } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { Container } from "../../components";
const Lotteries = () => {
  const { callApi, db, go, adminCart, setAdminCart, profileState } = Context();
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (profileState.userId !== "201989") {
      go("/home");
    }
  }, [profileState]);
  const addAdminCart = (id) => {
    console.log("id", id);
    setAdminCart([...new Set([...adminCart, id])]);
  };
  const removeAdminCart = (id) => {
    setAdminCart(adminCart.filter((cart) => cart !== id));
  };
  const checkInCart = (id) => {
    return adminCart.includes(id);
  };

  const addAllToCart = async () => {
    const data = await callApi("lotteries-getAll", {
      queries: [
        ["isOnBlock", "==", false],
        ["setNo", "==", "xx"],
      ],
      startAfter: "",
      limit: 500,
    });
    const allId = data.filter((x) => x.setNo === "xx").map((item) => item.id);
    setAdminCart(allId);
  };
  return (
    <Container>
      หวยที่จอง {adminCart.length}
      <button onClick={() => addAllToCart()}>ใส่ตะกร้าทั้งหมด</button>
      <input
        type="text"
        style={{ width: "200px" }}
        placeholder="userId"
        onChange={(e) => setUserId(e.target.value)}
      />
      {adminCart.length !== 0 && (
        <button
          onClick={async () => {
            const result = await callApi("orders-adminCreateOrder", {
              lotteryIds: adminCart,
              userId,
            });
            if (result.status === true) {
              setAdminCart([]);
            }
          }}
        >
          สร้างคำสั่งซื้อ {adminCart.length}
        </button>
      )}
      <HtTablePaging
        getData={(body) => callApi("lotteries-getAll", body)}
        columns={["addCart"]}
        baseQrs={[["isOnBlock", "==", false]]}
        hideColumns={["id", "userId", "isLotteryOut"]}
        overrides={{
          shortId: ({ row, key }) =>
            (row["orderId"] || "").slice(-5).toUpperCase(),
          addCart: ({ row, key }) =>
            row.isOnBlock ? (
              <></>
            ) : checkInCart(row["id"]) ? (
              <button onClick={() => removeAdminCart(row["id"])}>เอาออก</button>
            ) : (
              <button onClick={() => addAdminCart(row["id"])}>ใส่ตะกร้า</button>
            ),
        }}
        hideFilters={["isOnBlock", "addCart"]}
        dropdownFilter={{
          isSold: ["Yes", "No"],
        }}
        translations={{
          year: "ปี",
          createdAt: "เข้าระบบเมื่อ",
          isLotteryOut: "ลูกค้ารับไปแล้ว",
          roundNo: "เลขงวด",
          setNo: "เลขชุด",
          number: "หมายเลข",
          isOnBlock: "นำออกขาย",
          isSold: "ขายแล้ว",
          orderShortId: "รหัสย่อคำสั่งซื้อ",
          userId: "รหัสสมาชิก",
          image: "รูป",
          bookNo: "เลขเล่ม",
        }}
      />
    </Container>
  );
};

export default Lotteries;
