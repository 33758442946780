import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";

import {
  Switch,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import * as R from "ramda";
import constant from "../../constants";

export const ReportShop = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [selectedRoundDate, setSelectedRoundDate] = useState(configs.roundDate);

  const [filteredState, setFilteredState] = useState([]);

  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [sumData, setSumData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const handleSearch = async () => {
    let res = await callApi("products-getAllTransaction", {
      limit: 10000,
      startAfter: "",
      queries: [
        ["roundDate", "==", selectedRoundDate],
        ["status", "==", constant.statuses.APPROVE],
        ["isPointRedeem", "==", false],
      ],
      roundDate: selectedRoundDate,
    });

    const sum = res.reduce((acc, value) => {
      if (acc[value.product.name]) {
        acc[value.product.name].totalIncome += value.product.price;
        acc[value.product.name].totalOrder += 1;
      } else {
        acc[value.product.name] = {
          name: value.product.name,
          totalIncome: value.product.price,
          totalOrder: 1,
        };
      }
      return acc;
    }, []);

    console.log("Object.values(sum)", Object.values(sum));
    setSumData(Object.values(sum));

    setFilteredState(res);
  };

  const handleDownload = async () => {
    const acc = [];

    acc.push(["รายงานร้านค้า"]);
    acc.push([
      "วันที่-เวลา",
      "รหัสHT",
      "ชื่อ-นามสกุล",
      "เบอร์โทรศัพท์",
      "ที่อยู่",
      "สินค้า",
      "ราคา",
      "รหัสออเดอร์",
    ]);
    filteredState.map((value, index) =>
      acc.push([
        thaiDate(new Date(parseInt(value.createdAt))),
        `HT${value.userNo}`,
        value.receiver.name,
        value.receiver.phone,
        value.receiver.address,
        value.product.name,
        value.product.price,
        value.shortId,
      ])
    );

    setCsvData(acc);
    setCsvFileName(`รายงานร้านค้า-${selectedRoundDate}.csv`);
  };

  let total = 0;
  return (
    <div className="bg-white max-w-[1200px] mb-24  p-8 rounded-2xl drop-shadow-xl">
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>
      <p className="text-3xl font-semibold">รายงานร้านค้า</p>
      <div className="flex items-center gap-4 mt-4 max-w-80">
        <p className="text-lg font-bold">งวด</p>
        <Select
          value={selectedRoundDate}
          onValueChange={(e) => setSelectedRoundDate(e)}
        >
          <SelectTrigger className="my-1 text-md font-medium">
            <SelectValue placeholder="-- โปรดเลือก --" />
          </SelectTrigger>
          <SelectContent className="bg-black">
            {configs.roundDates.map((roundDate) => {
              return (
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value={roundDate}
                >
                  {roundDate}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <button
          className="h-12 my-4 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
          onClick={handleSearch}
        >
          ค้นหา
        </button>
      </div>
      <div
        className={`max-w-[789px] mt-16 mb-24 ${
          sumData.length === 0 && "hidden"
        }`}
      >
        <p className="text-xl font-semibold mb-8">สรุปออเดอร์ร้านค้า</p>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">อันดับ</TableHead>
              <TableHead className="">สินค้า</TableHead>
              <TableHead className="text-center">ออเดอร์ทั้งหมด</TableHead>
              <TableHead className="text-right">จำนวนเงินทั้งหมด</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sumData.map((data, index) => {
              return (
                <TableRow key={data.name}>
                  <TableCell className="text-center">{index + 1}</TableCell>
                  <TableCell>{data.name}</TableCell>
                  <TableCell className="text-center">
                    {data.totalOrder}
                  </TableCell>
                  <TableCell className="text-right">
                    {data.totalIncome?.toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={` mt-16  ${filteredState.length === 0 && "hidden"}`}>
        <div className="flex gap-4 mb-12 items-center">
          <p className="text-xl font-semibold">
            รายงานออเดอร์ร้านค้า ({filteredState.length} ออเดอร์)
          </p>
          <button
            className="h-8 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white text-sm font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
            onClick={() => handleDownload(filteredState)}
          >
            ดาวน์โหลด
          </button>
        </div>

        <Table className="">
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">อันดับ</TableHead>
              <TableHead className="text-center">วัน - เวลาที่สร้าง</TableHead>
              <TableHead className="text-center">รหัสสมาชิก</TableHead>
              <TableHead className="text-center">ชื่อ - นามสกุล</TableHead>
              <TableHead className="text-center">ที่อยู่</TableHead>
              <TableHead className="text-center">สินค้า</TableHead>
              <TableHead className="text-center">ราคา</TableHead>
              <TableHead className="text-center">รหัสออเดอร์</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredState.map((order, index) => {
              return (
                <TableRow key={order.shortId} className="hover:bg-gray-300">
                  <TableCell className="font-medium text-center">
                    {index + 1}
                  </TableCell>
                  <TableCell className="font-medium">
                    {new Date(parseInt(order.createdAt)).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <div
                      className={`
                       flex items-center mx-auto bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                      onClick={() =>
                        window.open(
                          `https://admin.hongthongofficial.com/giftshop/${order.id}`,
                          "__blank"
                        )
                      }
                    >
                      {order.userId}
                    </div>
                  </TableCell>
                  <TableCell className="text-center">
                    {order.receiver.name}
                  </TableCell>
                  <TableCell>{order.receiver.address}</TableCell>
                  <TableCell className="">
                    <div
                      className={`
                       flex items-center bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold `}
                    >
                      {order.product.name}
                    </div>
                  </TableCell>

                  <TableCell className="text-center font-medium">
                    {order.product.price}
                  </TableCell>
                  <TableCell>
                    <div
                      className={`
                       flex items-center mx-auto bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                      onClick={() =>
                        window.open(
                          `https://admin.hongthongofficial.com/giftshop/${order.id}`,
                          "__blank"
                        )
                      }
                    >
                      {order.shortId}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const Button = styled.button`
  margin: 2em 0;
`;
