import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { useNavigate, useParams } from "react-router-dom";
import HtDataManager from "../../components/HtDataManager";
import { thaiDate, roundDateToDate } from "../../util";
import constant from "../../constants";
import { Flex, Label } from "../../components";
import { Table } from "reactstrap";

import { StackImage } from "../../components/StackImages";
import styled from "styled-components";
import themes from "../../themes";
import { isEmpty } from "ramda";
import { Badge, Input } from "reactstrap";
import { FiEdit, FiSave, FiCopy } from "react-icons/fi";
import { NoteContainer } from "../coupons/CouponOrderDetail";

const Orders = () => {
  const { callApi, profileState, configs } = Context();
  const { orderId } = useParams();
  const dataLeftRef = useRef(null);
  const [orderData, setOrderData] = useState([]);
  const [orderContact, setOrderContact] = useState([]);
  const [buyForContact, setBuyForContact] = useState([]);

  const [shippingTrack, setShippingTrack] = useState("");
  const [shippingStatus, setShippingStatus] = useState("");
  const [currentEdit, setCurrentEdit] = useState("");
  const [newNote, setNewNote] = useState("");
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showChangePrice, setShowChangePrice] = useState(false);
  const [newPrice, setNewPrice] = useState(0);
  const bankList = {
    0: "โปรดเลือก",
    1: "ธนาคารกรุงเทพฯ",
    2: "ธนาคารไทยพาณิชย์",
    3: "ธนาคารกสิกรไทย",
    4: "ธนาคารกรุงศรีอยุธยา",
    5: "ธนาคารกรุงไทย",
    6: "ธนาคารธนชาต",
    7: "ธนาคารทิสโก้",
    8: "ธนาคารออมสิน",
    9: "ธนาคารอาคารสงเคราะห์",
    10: "ธนาคารอิสลามแห่งประเทศไทย",
    11: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    12: "ธนาคารทหารไทยธนชาต",
    13: "ธนาคารซีไอเอ็มบีไทย",
    14: "ธนาคารยูโอบี",
    15: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    16: "ธนาคารเกียรตินาคินภัทร",
  };

  const getWorkingOrder = async (skip) => {
    callApi("orders-getWorkingOrder", { skip, orderId }).then((order) => {
      if (order.order.userId) {
        callApi("users-getUserInfoAdmin", { userId: order.order.userId }).then(
          (user) => {
            const WorkingOrder = {
              ...order.order,
              orderId: order.orderId,
              owner: order.owner,
            };

            setOrderData(WorkingOrder);
            setUserData(user);
            setIsLoading(false);
            setNewPrice(WorkingOrder.totalPrice);
            setShippingTrack(WorkingOrder.shippingTrack || "");
            setShippingStatus(WorkingOrder.shippingStatus || "pending");
            setBuyForContact([
              {
                label: "ชื่อ",
                value: WorkingOrder.buyFor?.firstName || "-",
                key: "firstName",
              },
              {
                label: "นามสกุล",
                value: WorkingOrder.buyFor?.lastName || "-",
                key: "lastName",
              },
              {
                label: "เบอร์โทรศัพท์",
                value: WorkingOrder.buyFor?.phone || "-",
                key: "phone",
              },

              {
                label: "เลขบัญชีธนาคาร",
                value: WorkingOrder.buyFor?.accNo || "-",
                key: "accNo",
              },
              {
                label: "ชิ่ือธนาคาร",
                value: WorkingOrder.buyFor?.bank || "-",
                key: "bank",
              },
              {
                label: "ชื่อบัญชี",
                value: WorkingOrder.buyFor?.accName || "-",
                key: "accName",
              },
            ]);
            setOrderContact([
              {
                label: "ชื่อ",
                value: WorkingOrder.firstName,
                key: "firstName",
              },
              {
                label: "นามสกุล",
                value: WorkingOrder.lastName,
                key: "lastName",
              },
              {
                label: "เบอร์โทรศัพท์",
                value: WorkingOrder.phone,
                key: "phone",
              },
              {
                label: "อีเมล",
                value: WorkingOrder.email,
                key: "email",
              },
              {
                label: "ที่อยู่",
                value: WorkingOrder.address?.addr,
                key: "address",
              },
              {
                label: "จังหวัด",
                value: WorkingOrder.address?.province,
                key: "address",
              },
              {
                label: "รหัสไปรษณีย์",
                value: WorkingOrder.address?.zipcode,
                key: "address",
              },
            ]);
          }
        );
      }
    });
  };

  useEffect(() => {
    getWorkingOrder();
    return () => callApi("orders-leaveWorking", { orderId });
  }, []);

  if (isLoading) {
    return <></>;
  }

  const resizeImage = (file) =>
    new Promise((resolve, reject) => {
      if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type))
        reject(false);
      const maxW = 600; //กำหนดความกว้าง
      const maxH = 1200; //กำหนดความสูง
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = document.createElement("img");
      img.onload = function () {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min(maxW / iw, maxH / ih);
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        context.drawImage(img, 0, 0, iwScaled, ihScaled);
        resolve(canvas.toDataURL("image/jpeg", 1)); //0.5 คือ คุณภาพของรูป ที่ Resize
      };
      img.src = URL.createObjectURL(file);
    });

  const updateValue = async (key, value) => {
    if (window.confirm("ต้องการแก้ไขข้อมูลใช่หรือไม่")) {
      await callApi("orders-edit", {
        id: orderData.orderId,
        key: key,
        value: value,
      });
      window.location.reload();
    } else {
      return { status: false };
    }
  };

  const fixLottoImage = async () => {
    orderData?.lotteries?.forEach(async (data) => {
      const url = decodeURIComponent(data.image);
      const splitUrl = url.split("/");
      const id = splitUrl[splitUrl.length - 1];
      const roundDate = splitUrl[splitUrl.length - 2];
      const location = splitUrl[splitUrl.length - 3];
      await callApi("utils-makeFilePublic", {
        bucket: location,
        url: roundDate + "/" + id,
      });
    });
  };

  const deliverChannelTranslations = {
    online: "ทางเลือกที่ 1 ฝากไว้กับหงษ์ทอง",
    mail: "ทางเลือกที่ 2 ส่งด่วน EMS",
    offline: "ทางเลือกที่ 3 มารับเองที่ สำนักงานใหญ่หงษ์ทอง",
  };

  const submitChangePrice = async () => {
    if (window.confirm("ต้องการแก้ไขข้อมูลใช่หรือไม่")) {
      const result = await callApi("orders-changePriceByAdmin", {
        newPrice: newPrice || 0,
        orderId: orderId,
      });
      if (result.success) {
        setShowChangePrice(false);
        window.location.reload();
      }
    } else {
      return { status: false };
    }
  };

  return (
    <Flex direction="column">
      <ContentContainer className="flex blue-bg">
        <Flex direction="column" maxWidth="600px" width="50%">
          <Content
            title="เวลาที่ซื้อ"
            value={new Date(parseInt(orderData.createdAt)).toLocaleString()}
          />
          <Content
            title="งวดวันที่"
            value={roundDateToDate(orderData.roundDate)}
          />
          <Content
            title="ช่องทางการสั่งซื้อ"
            value={`${deliverChannelTranslations[orderData.deliverChannel]}`}
          />
          <Content title="รหัสคำสั่งซื้อแบบเต็ม" value={orderData.orderId} />
          <Content title="รหัสคำสั่งซื้อแบบย่อ" value={orderData.shortId} />
          {orderData.deliverChannel === "mail" && (
            <>
              <Content
                title="สถานะการจัดส่ง"
                value={
                  currentEdit === "shippingStatus" ? (
                    <Flex>
                      <Input
                        name="select"
                        type="select"
                        onChange={(e) => {
                          setShippingStatus(e.target.value);
                        }}
                        style={{ width: "130px" }}
                      >
                        {[
                          {
                            key: constant.shippingStatuses.PENDING,
                            value: constant.shippingStatusesTranslate.PENDING,
                          },
                          {
                            key: constant.shippingStatuses.PACKING,
                            value: constant.shippingStatusesTranslate.PACKING,
                          },
                          {
                            key: constant.shippingStatuses.SHIPPED,
                            value: constant.shippingStatusesTranslate.SHIPPED,
                          },
                          {
                            key: constant.shippingStatuses.CANCEL,
                            value: constant.shippingStatusesTranslate.CANCEL,
                          },
                        ].map((choice, index) => (
                          <option key={index} value={choice.key}>
                            {choice.value}
                          </option>
                        ))}
                      </Input>
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            x &&
                              orderData.shippingStatus !== shippingStatus &&
                              updateValue(
                                "shippingStatus",
                                shippingStatus || "pending"
                              );
                            return "";
                          })
                        }
                        color={themes.colors.success}
                      >
                        <Flex align="center">
                          <FiSave style={{ marginRight: "3px" }} />
                        </Flex>
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {constant.shippingStatusesTranslate[
                        orderData.shippingStatus?.toUpperCase()
                      ] || "-"}
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            return "shippingStatus";
                          })
                        }
                        color={themes.colors.goldText}
                      >
                        <Flex align="center">
                          <FiEdit style={{}} />
                        </Flex>
                      </Button>
                    </>
                  )
                }
              />

              <Content
                title="เลขติดตามการจัดส่ง"
                value={
                  <>
                    {currentEdit === "shippingTrack" ? (
                      <Flex>
                        <Input
                          style={{ maxWidth: "90%" }}
                          type="text"
                          defaultValue={shippingTrack}
                          onChange={(e) => {
                            setShippingTrack(e.target.value);
                          }}
                        />
                        <Button
                          className="edit"
                          onClick={() =>
                            setCurrentEdit((x) => {
                              x &&
                                orderData.shippingTrack !== shippingTrack &&
                                updateValue("shippingTrack", shippingTrack);
                              return "";
                            })
                          }
                          color={themes.colors.success}
                        >
                          <Flex align="center">
                            <FiSave style={{ marginRight: "3px" }} />
                          </Flex>
                        </Button>
                      </Flex>
                    ) : (
                      <>
                        {orderData.shippingTrack || "-"}
                        <Button
                          className="edit"
                          onClick={() =>
                            setCurrentEdit((x) => {
                              return "shippingTrack";
                            })
                          }
                          color={themes.colors.goldText}
                        >
                          <Flex align="center">
                            <FiEdit style={{}} />
                          </Flex>
                        </Button>
                      </>
                    )}
                  </>
                }
              />
            </>
          )}
          <Content title="จำนวนที่สั่งซื้อ" value={`${orderData.size} ใบ`} />
          {orderData?.paidCurrency === "LAK" && (
            <>
              <Content title="ค่าเงินที่ชำระ" value="ลาวกีบ" />

              <Content
                title="ค่าเงินกีบ"
                value={`${orderData.currency?.lak} กีบ ต่อ 1 บาท`}
              />

              <Content
                title="ราคารวมกีบ"
                value={`${orderData.totalPrice_lak} กีบ`}
              />
            </>
          )}
          {orderData.coupon && (
            <>
              <Content title="คูปอง" value={orderData.coupon.coupon.name} />

              <Content
                title="รหัสคำสั่งซื้อคูปอง"
                value={`${orderData.coupon.id}`}
              />

              <Content
                title="ราคาก่อนหักคูปอง"
                value={`${orderData.totalPriceBeforeDiscount}`}
              />
            </>
          )}
          {orderData.status !== constant.statuses.PENDING &&
          orderData.status !== constant.statuses.PAID ? (
            <Content title="ราคารวม" value={orderData.totalPrice} />
          ) : (
            <Flex
              width="100%"
              direction="column"
              align="flex-start"
              padding="0.5em 0"
            >
              <Label.H5 weight={600} color={themes.colors.primary}>
                ราคารวม
              </Label.H5>

              <Flex
                style={{ width: "100%", fontSize: "1.1em", fontWeight: 500 }}
                align="center"
                color={themes.colors.white}
              >
                {showChangePrice ? (
                  <>
                    <Input
                      type="number"
                      value={newPrice}
                      onChange={(e) => setNewPrice(e.target.value)}
                      style={{ width: "90px" }}
                    ></Input>
                    <Button
                      className="button-style-primary"
                      onClick={() => submitChangePrice()}
                    >
                      ปรับราคา
                    </Button>
                  </>
                ) : (
                  <>
                    <div style={{ marginRight: "20px" }}>
                      {orderData.totalPrice} บาท
                    </div>
                    <Button
                      className="button-style-primary"
                      onClick={() => setShowChangePrice(true)}
                    >
                      แก้ราคา
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          )}

          <Content
            title="ชำระเงินผ่านทาง"
            value={
              <img
                src={
                  orderData.paymentChannel === "qr"
                    ? "/images/qr.png"
                    : "/images/banktransfer.png"
                }
                width="150px"
                alt=""
              />
            }
          />
          <Content title="สถานะ" value={getStatusBadge(orderData.status)} />
          {orderData.cancelerName && (
            <Content
              title="ผู้ยกเลิกคำสั่งซื้อ"
              value={<BadgeStyle>{orderData.cancelerName}</BadgeStyle>}
            />
          )}
          {orderData.approverName && (
            <Content
              title="ผู้อนุมัติคำสั่งซื้อ"
              value={<BadgeStyle>{orderData.approverName}</BadgeStyle>}
            />
          )}
          <Content title="ผู้แนะนำ" value={`${orderData.affiliateId}`} />
        </Flex>
        <Flex
          direction="column"
          align="flex-start"
          maxWidth="600px"
          width="50%"
        >
          <Button
            color={themes.colors.success}
            onClick={() => {
              fixLottoImage();
            }}
          >
            ซ่อมรูปลอตเตอรี่
          </Button>
          <Label.H4 weight={500} color={themes.colors.primary}>
            ลอตเตอรี่ทั้งหมด
          </Label.H4>
          {configs.roundDate === orderData.roundDate ? (
            <StackImageContainer
              direction="column"
              style={{
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <StackImage
                urls={orderData?.lotteries?.map((x) => [
                  x.image,
                  x.isMagicNumber,
                  x.isLabubuNumber,
                ])}
              />
            </StackImageContainer>
          ) : (
            <Table bordered>
              <thead>
                <tr>
                  <td style={{ color: themes.colors.white }}>ลำดับที่</td>
                  <td style={{ color: themes.colors.white }}>หมายเลข</td>
                </tr>
              </thead>
              <tbody>
                {orderData?.lotteries.map((x, index) => {
                  return (
                    <tr>
                      <td style={{ color: themes.colors.white }}>
                        {index + 1}
                      </td>
                      <th style={{ color: themes.colors.white }}>{x.number}</th>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          <Content
            title="การชำระเงิน"
            value={
              orderData.paymentChannel === constant.paymentChannels.QR ? (
                <img
                  src={"/images/qr.png"}
                  alt="slip"
                  style={{ maxWidth: "300px" }}
                />
              ) : !isEmpty(orderData.slips) ? (
                <StackImageContainer
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  {orderData.slips?.reverse().map((slip) => (
                    <Img src={slip} alt="slip" />
                  ))}
                </StackImageContainer>
              ) : (
                "ไม่พบสลิป"
              )
            }
          />
        </Flex>
      </ContentContainer>
      <br />

      <ContentContainer className="flex  blue-bg">
        <Flex width="50%" direction="column" maxWidth="600px">
          <Content
            title="เปลี่ยนสถานะคำสั่งซื้อ"
            value={
              <>
                {orderData.status !== "approved" &&
                  (orderData.status !== constant.statuses.CANCEL ? (
                    <Button
                      color={themes.colors.success}
                      onClick={() =>
                        updateValue("status", constant.statuses.APPROVE)
                      }
                    >
                      อนุมัติ
                    </Button>
                  ) : (
                    "ไม่สามารถเปลี่ยนแปลงคำสั่งซื้อที่ถูกยกเลิกแล้วได้"
                  ))}
                {orderData.status === "pending" && (
                  <Button
                    color={themes.colors.brightBlue}
                    onClick={() =>
                      updateValue("status", constant.statuses.PAID)
                    }
                  >
                    จ่ายเงินแล้ว
                  </Button>
                )}
                {orderData.status !== constant.statuses.CANCEL ? (
                  orderData.status === "approved" ? (
                    ["superAdmin", "dev"].includes(profileState.role) ? (
                      <Button
                        color={themes.colors.dangerRed}
                        onClick={() =>
                          updateValue("status", constant.statuses.CANCEL)
                        }
                      >
                        ยกเลิก
                      </Button>
                    ) : (
                      "ติดต่อ Super Admin เพื่อเปลี่ยนคำสั่งซื้อที่ถูกอนุมัติแล้ว"
                    )
                  ) : (
                    <Button
                      color={themes.colors.dangerRed}
                      onClick={() =>
                        updateValue("status", constant.statuses.CANCEL)
                      }
                    >
                      ยกเลิก
                    </Button>
                  )
                ) : (
                  ""
                )}
              </>
            }
          />
          <Content
            title="เปลี่ยนช่องทางการสั่งซื้อ"
            value={
              <>
                <Input
                  name="select"
                  type="select"
                  onChange={(e) =>
                    updateValue("deliverChannel", e.target.value)
                  }
                  style={{ width: "130px" }}
                >
                  {[
                    { key: constant.deliverChannels.ONLINE, value: "ออนไลน์" },
                    {
                      key: constant.deliverChannels.MAIL,
                      value: "ส่งไปรษณีย์",
                    },
                    {
                      key: constant.deliverChannels.OFFLINE,
                      value: "รับด้วยตนเอง",
                    },
                  ].map((choice, index) => (
                    <option
                      key={index}
                      selected={orderData.deliverChannel === choice.key}
                      value={choice.key}
                    >
                      {choice.value}
                    </option>
                  ))}
                </Input>
              </>
            }
          />

          <Content
            title="เปลี่ยนช่องทางการจ่ายเงิน"
            value={
              <>
                <Input
                  name="select"
                  type="select"
                  onChange={(e) =>
                    updateValue("paymentChannel", e.target.value)
                  }
                  style={{ width: "130px" }}
                >
                  {[
                    { key: "", value: "ยังไม่ได้โอน" },
                    { key: constant.paymentChannels.QR, value: "QR" },
                    { key: constant.paymentChannels.BANK, value: "โอนเงิน" },
                    { key: constant.paymentChannels.OFFLINE, value: "เงินสด" },
                  ].map((choice, index) => (
                    <option
                      key={index}
                      selected={orderData.paymentChannel === choice.key}
                      value={choice.key}
                    >
                      {choice.value}
                    </option>
                  ))}
                </Input>
              </>
            }
          />
          <Content
            title="ส่งยืนยันคำสั่งซื้ออีกครั้ง"
            value={
              <>
                <Button
                  color={themes.colors.golden}
                  onClick={() =>
                    callApi("utils-confirmMessageAgain", {
                      orderId: orderData.orderId,
                      type: "Sms",
                    })
                  }
                >
                  SMS
                </Button>
                <Button
                  color={themes.colors.success}
                  onClick={() =>
                    callApi("utils-confirmMessageAgain", {
                      orderId: orderData.orderId,
                      type: "Line",
                    })
                  }
                >
                  Line
                </Button>
              </>
            }
          />
        </Flex>
        <Flex width="50%" maxWidth="600px" direction="column">
          {orderData.paymentChannel !== "qr" && (
            <>
              {orderData.deliverChannel !== "offline" && (
                <Content
                  title="อัพโหลดสลิป"
                  value={
                    <div>
                      <label
                        style={{
                          width: "100% !important",
                          marginBottom: "15px",

                          margin: "4px 0",
                          padding: "8px",
                          cursor: "pointer",
                          backgroundColor: themes.colors.goldText,
                          borderRadius: "4px",
                          verticalAlign: "middel",
                          fontWeight: 600,
                        }}
                        htmlFor="files-slips"
                      >
                        อัพโหลดสลิป
                      </label>
                      <input
                        style={{
                          marginBottom: "15px",
                          visibility: "hidden",
                          position: "absolute",
                        }}
                        id="files-slips"
                        type="file"
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          const base64 = await resizeImage(file);
                          if (base64) {
                            console.log(file.name, base64);
                            await updateValue("slips", {
                              fileName: file.name,
                              base64,
                            });
                          } else {
                            alert(
                              "อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png"
                            );
                          }
                        }}
                      />
                    </div>
                  }
                />
              )}
              <Content
                title="หมายเลขสลิป"
                value={
                  <p style={{ wordWrap: "break-word" }}>
                    {orderData.slipId || "ไม่พบเลขสลิป"}
                  </p>
                }
              />
            </>
          )}
          <Content
            title={`บันทึกเพิ่มเติม รหัสสมาชิก ${userData.userNo}`}
            value={
              <>
                <Flex>
                  <Input
                    style={{ maxWidth: "90%" }}
                    type="text"
                    defaultValue={""}
                    onChange={(e) => {
                      const value = e.target.value;
                      return setNewNote(value);
                    }}
                  />
                  <Button
                    className="edit"
                    onClick={() => {
                      !isEmpty(newNote) &&
                        updateValue(
                          "note",
                          orderData.note
                            ? orderData.note.concat({
                                by: `${profileState.firstName} (${profileState.userNo})`,
                                time: Date.now(),
                                note: newNote,
                              })
                            : [
                                {
                                  by: `${profileState.firstName} (${profileState.userNo})`,
                                  time: Date.now(),
                                  note: newNote,
                                },
                              ]
                        );

                      return setNewNote("");
                    }}
                    color={themes.colors.success}
                  >
                    <Flex align="center">เพิ่ม</Flex>
                  </Button>
                </Flex>
                <Flex direction="column">
                  {orderData.note?.map((data, index) => {
                    return data.note ? (
                      <NoteContainer key={index}>
                        {data.time &&
                          new Date(parseInt(data.time)).toLocaleString()}{" "}
                        | {data.by} <br />
                        <br /> {data.note}
                      </NoteContainer>
                    ) : (
                      data
                    );
                  })}
                </Flex>
              </>
            }
          />
          <button
            style={{
              backgroundColor: "rgba(245,194,64,1)",
              border: "0",
              borderRadius: "5px",
              padding: "5px 10px",
            }}
            onClick={async () => {
              let reasult = await callApi("users-addAdminToken", {
                userId: orderData.userId,
              });

              window.open(
                // constant.webConfigs.customerUrl +
                "https://shop.hongthongofficial.com/" +
                  "orders?adminToken=" +
                  reasult.adminToken
              );
            }}
          >
            เข้ารหัสลูกค้า
          </button>
        </Flex>
      </ContentContainer>
      <br />
      <ContentContainer className="blue-bg">
        <Flex align="center">
          <Label.H4 weight={600} color={themes.colors.primary}>
            ข้อมูลผู้สั่งซื้อ
          </Label.H4>
        </Flex>
        <Flex>
          <Flex width="50%" maxWidth="600px" direction="column">
            <Content
              title="ชื่อ"
              value={
                <>
                  {currentEdit === "firstName" ? (
                    <Flex>
                      <Input
                        style={{ maxWidth: "90%" }}
                        type="text"
                        defaultValue={orderContact[0].value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setOrderContact((address) => {
                            address[0].value = value;
                            return address;
                          });
                        }}
                      />
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            x &&
                              orderContact[0].value !== orderData.firstName &&
                              updateValue("firstName", orderContact[0].value);
                            return "";
                          })
                        }
                        color={themes.colors.success}
                      >
                        <Flex align="center">
                          <FiSave style={{ marginRight: "3px" }} />
                        </Flex>
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {orderContact[0].value}{" "}
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            return "firstName";
                          })
                        }
                        color={themes.colors.goldText}
                      >
                        <Flex align="center">
                          <FiEdit style={{}} />
                        </Flex>
                      </Button>
                    </>
                  )}
                </>
              }
            />
            <Content
              title="เบอร์โทรศัพท์"
              value={
                <>
                  {currentEdit === "phone" ? (
                    <Flex>
                      <Input
                        style={{ maxWidth: "90%" }}
                        type="text"
                        defaultValue={orderContact[2].value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setOrderContact((address) => {
                            address[2].value = value;
                            return address;
                          });
                        }}
                      />
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            x &&
                              orderContact[2].value !== orderData.phone &&
                              updateValue("phone", orderContact[2].value);
                            return "";
                          })
                        }
                        color={themes.colors.success}
                      >
                        <Flex align="center">
                          <FiSave style={{ marginRight: "3px" }} />
                        </Flex>
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {orderContact[2].value}{" "}
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            return "phone";
                          })
                        }
                        color={themes.colors.goldText}
                      >
                        <Flex align="center">
                          <FiEdit style={{}} />
                        </Flex>
                      </Button>
                    </>
                  )}
                </>
              }
            />
            <Content
              title="ที่อยู่"
              value={
                <>
                  {currentEdit === "address" ? (
                    <Flex>
                      <Input
                        style={{ maxWidth: "90%" }}
                        type="text"
                        defaultValue={orderContact[4].value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setOrderContact((address) => {
                            address[4].value = value;
                            return address;
                          });
                        }}
                      />
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            x &&
                              orderContact[4].value !==
                                orderData.address.addr &&
                              updateValue("address", {
                                addr: orderContact[4].value,
                                province: orderContact[5].value,
                                zipcode: orderContact[6].value,
                              });
                            return "";
                          })
                        }
                        color={themes.colors.success}
                      >
                        <Flex align="center">
                          <FiSave style={{ marginRight: "3px" }} />
                        </Flex>
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {orderContact[4].value || "-"}{" "}
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            return "address";
                          })
                        }
                        color={themes.colors.goldText}
                      >
                        <Flex align="center">
                          <FiEdit style={{}} />
                        </Flex>
                      </Button>
                    </>
                  )}
                </>
              }
            />
            <Content
              title="เลขไปรษณีย์"
              value={
                <>
                  {currentEdit === "zipcode" ? (
                    <Flex>
                      <Input
                        style={{ maxWidth: "90%" }}
                        type="text"
                        defaultValue={orderContact[6].value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setOrderContact((address) => {
                            address[6].value = value;
                            return address;
                          });
                        }}
                      />
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            x &&
                              orderContact[6].value !==
                                orderData.address.zipcode &&
                              updateValue("address", {
                                addr: orderContact[4].value,
                                province: orderContact[5].value,
                                zipcode: orderContact[6].value,
                              });
                            return "";
                          })
                        }
                        color={themes.colors.success}
                      >
                        <Flex align="center">
                          <FiSave style={{ marginRight: "3px" }} />
                        </Flex>
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {orderContact[6].value || "-"}{" "}
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            return "zipcode";
                          })
                        }
                        color={themes.colors.goldText}
                      >
                        <Flex align="center">
                          <FiEdit style={{}} />
                        </Flex>
                      </Button>
                    </>
                  )}
                </>
              }
            />

            <Content
              title="ซื้อให้"
              value={
                <Flex>
                  <Input
                    name="select1"
                    type="select"
                    onChange={(e) =>
                      updateValue(
                        "isBuyForSomeoneElse",
                        e.currentTarget.value === "true"
                      )
                    }
                    style={{ width: "130px" }}
                  >
                    {console.log(
                      "orderData.isBuyForSomeoneElse",
                      orderData,
                      orderData.isBuyForSomeoneElse,
                      !orderData.isBuyForSomeoneElse
                    )}

                    <option
                      selected={!orderData.isBuyForSomeoneElse}
                      value={false}
                    >
                      ตนเอง
                    </option>
                    <option
                      selected={orderData.isBuyForSomeoneElse}
                      value={true}
                    >
                      ผู้อื่น
                    </option>
                  </Input>
                </Flex>
              }
            />
          </Flex>
          <Flex width="50%" maxWidth="600px" direction="column">
            <Content
              title="นามสกุล"
              value={
                <>
                  {currentEdit === "lastName" ? (
                    <Flex>
                      <Input
                        style={{ maxWidth: "90%" }}
                        type="text"
                        defaultValue={orderContact[1].value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setOrderContact((address) => {
                            address[1].value = value;
                            return address;
                          });
                        }}
                      />
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            x &&
                              orderContact[1].value !== orderData.lastName &&
                              updateValue("lastName", orderContact[1].value);
                            return "";
                          })
                        }
                        color={themes.colors.success}
                      >
                        <Flex align="center">
                          <FiSave style={{ marginRight: "3px" }} />
                        </Flex>
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {orderContact[1].value}{" "}
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            return "lastName";
                          })
                        }
                        color={themes.colors.goldText}
                      >
                        <Flex align="center">
                          <FiEdit style={{}} />
                        </Flex>
                      </Button>
                    </>
                  )}
                </>
              }
            />
            <Content
              title="อีเมล"
              value={
                <>
                  {currentEdit === "email" ? (
                    <Flex>
                      <Input
                        style={{ maxWidth: "90%" }}
                        type="text"
                        defaultValue={orderContact[3].value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setOrderContact((address) => {
                            address[3].value = value;
                            return address;
                          });
                        }}
                      />
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            x &&
                              orderContact[3].value !== orderData.email &&
                              updateValue("email", orderContact[3].value);
                            return "";
                          })
                        }
                        color={themes.colors.success}
                      >
                        <Flex align="center">
                          <FiSave style={{ marginRight: "3px" }} />
                        </Flex>
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {orderContact[3].value || "-"}
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            return "email";
                          })
                        }
                        color={themes.colors.goldText}
                      >
                        <Flex align="center">
                          <FiEdit style={{}} />
                        </Flex>
                      </Button>
                    </>
                  )}
                </>
              }
            />
            <Content
              title="จังหวัด"
              value={
                <>
                  {currentEdit === "province" ? (
                    <Flex>
                      <Input
                        style={{ maxWidth: "90%" }}
                        type="text"
                        defaultValue={orderContact[5].value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setOrderContact((address) => {
                            address[5].value = value;
                            return address;
                          });
                        }}
                      />
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            x &&
                              orderContact[5].value !==
                                orderData.address.province &&
                              updateValue("address", {
                                addr: orderContact[4].value,
                                province: orderContact[5].value,
                                zipcode: orderContact[6].value,
                              });
                            return "";
                          })
                        }
                        color={themes.colors.success}
                      >
                        <Flex align="center">
                          <FiSave style={{ marginRight: "3px" }} />
                        </Flex>
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {orderContact[5].value || "-"}{" "}
                      <Button
                        className="edit"
                        onClick={() =>
                          setCurrentEdit((x) => {
                            return "province";
                          })
                        }
                        color={themes.colors.goldText}
                      >
                        <Flex align="center">
                          <FiEdit style={{}} />
                        </Flex>
                      </Button>
                    </>
                  )}
                </>
              }
            />
          </Flex>
        </Flex>
        {orderData.isBuyForSomeoneElse && (
          <>
            <Flex align="center" margin="2em 0 0 0">
              <Label.H4 weight={600} color={themes.colors.primary}>
                ข้อมูลผู้รับ
              </Label.H4>

              {["superAdmin", "dev"].includes(profileState.role) ? (
                currentEdit === "isBuyForSomeoneElse" ? (
                  <Button
                    className="edit"
                    onClick={() =>
                      setCurrentEdit((x) => {
                        console.log(buyForContact);
                        x &&
                          updateValue("buyFor", {
                            firstName: buyForContact[0].value,
                            lastName: buyForContact[1].value,
                            phone: buyForContact[2].value,
                            accNo: buyForContact[3].value,
                            bank: buyForContact[4].value,
                            accName: buyForContact[5].value,
                          });
                        return "";
                      })
                    }
                    color={themes.colors.success}
                  >
                    <Flex align="center">
                      <FiSave style={{ marginRight: "3px" }} />
                    </Flex>
                  </Button>
                ) : (
                  <Button
                    className="edit"
                    onClick={() =>
                      setCurrentEdit((x) => {
                        return "isBuyForSomeoneElse";
                      })
                    }
                    color={themes.colors.goldText}
                  >
                    <Flex align="center">
                      <FiEdit style={{}} />
                    </Flex>
                  </Button>
                )
              ) : (
                <></>
              )}
            </Flex>
            <Flex>
              <Flex width="50%" maxWidth="600px" direction="column">
                <Content
                  title="ชื่อ"
                  value={
                    <>
                      {currentEdit === "isBuyForSomeoneElse" ? (
                        <Input
                          style={{ maxWidth: "90%" }}
                          type="text"
                          defaultValue={buyForContact[0].value}
                          onChange={(e) => {
                            const value = e.target.value;
                            setBuyForContact((buyForContact) => {
                              buyForContact[0].value = value;
                              return buyForContact;
                            });
                          }}
                        />
                      ) : (
                        <>{buyForContact[0].value} </>
                      )}
                    </>
                  }
                />
                <Content
                  title="นามสกุล"
                  value={
                    <>
                      {currentEdit === "isBuyForSomeoneElse" ? (
                        <Input
                          style={{ maxWidth: "90%" }}
                          type="text"
                          defaultValue={buyForContact[1].value}
                          onChange={(e) => {
                            const value = e.target.value;
                            setBuyForContact((state) => {
                              state[1].value = value;
                              return state;
                            });
                          }}
                        />
                      ) : (
                        <>{buyForContact[1].value}</>
                      )}
                    </>
                  }
                />
                <Content
                  title="เบอร์โทรศัพท์"
                  value={
                    <>
                      {currentEdit === "isBuyForSomeoneElse" ? (
                        <Input
                          style={{ maxWidth: "90%" }}
                          type="text"
                          defaultValue={buyForContact[2].value}
                          onChange={(e) => {
                            const value = e.target.value;
                            setBuyForContact((state) => {
                              state[2].value = value;
                              return state;
                            });
                          }}
                        />
                      ) : (
                        <>{buyForContact[2].value} </>
                      )}
                    </>
                  }
                />
              </Flex>
              <Flex width="50%" maxWidth="600px" direction="column">
                <Content
                  title="เลขบัญชีธนาคาร"
                  value={
                    <>
                      {currentEdit === "isBuyForSomeoneElse" ? (
                        <Input
                          style={{ maxWidth: "90%" }}
                          type="text"
                          defaultValue={buyForContact[3].value}
                          onChange={(e) => {
                            const value = e.target.value;
                            setBuyForContact((state) => {
                              state[3].value = value;
                              return state;
                            });
                          }}
                        />
                      ) : (
                        <>{buyForContact[3].value}</>
                      )}
                    </>
                  }
                />
                <Content
                  title="ชื่อธนาคาร"
                  value={
                    <>
                      {currentEdit === "isBuyForSomeoneElse" ? (
                        <>
                          <Input
                            style={{ maxWidth: "90%" }}
                            type="select"
                            defaultValue={buyForContact[4].value}
                            onChange={(e) => {
                              const value = e.target.value;
                              setBuyForContact((state) => {
                                state[4].value = value;
                                return state;
                              });
                            }}
                          >
                            {Object.entries(bankList).map(([key, value]) => (
                              <option key={key} value={value}>
                                {value}
                              </option>
                            ))}
                          </Input>
                        </>
                      ) : (
                        <>{buyForContact[4].value}</>
                      )}
                    </>
                  }
                />
                <Content
                  title="ชื่อบัญชีธนาคาร"
                  value={
                    <>
                      {currentEdit === "isBuyForSomeoneElse" ? (
                        <Input
                          style={{ maxWidth: "90%" }}
                          type="text"
                          defaultValue={buyForContact[5].value}
                          onChange={(e) => {
                            const value = e.target.value;
                            setBuyForContact((state) => {
                              state[5].value = value;
                              return state;
                            });
                          }}
                        />
                      ) : (
                        <>{buyForContact[5].value}</>
                      )}
                    </>
                  }
                />
              </Flex>
            </Flex>
          </>
        )}
      </ContentContainer>
      <br />
      <ContentContainer className=" blue-bg">
        <Flex align="center">
          <Label.H4
            weight={600}
            margin="0 1em 0 0"
            color={themes.colors.primary}
          >
            ข้อมูลสมาชิก รหัส {userData.userNo}
          </Label.H4>
          <Button
            onClick={() => window.open("/users/" + orderData.userId, "_blank")}
            color={themes.colors.oldGrean}
          >
            ดูข้อมูล
          </Button>
        </Flex>
        <Flex>
          <Flex direction="column" maxWidth="600px" width="50%">
            <Content
              title="ชื่อ-นามสกุล"
              value={userData.firstName + " " + userData.lastName}
            />
            <Content title="เบอร์โทรศัพท์" value={userData.phone || "-"} />
            <Content title="คะแนน" value={userData.buyPoint || "-"} />
            <Content title="ไอดีไลน์" value={userData.lineId || "-"} />
            <Content title="อีเมล์ไลน์" value={userData.lineEmail || "-"} />
          </Flex>
          <Flex width="50%" direction="column" maxWidth="600px">
            <Content
              title="ธนาคาร"
              value={userData.bank?.name || userData.bank?.bankName || "-"}
            />
            <Content title="ชื่อบัญชี" value={userData.bank?.accName || "-"} />
            <Content title="เลขบัญชี" value={userData.bank?.accNo || "-"} />
            <Content title="ชื่อไลน์" value={userData.lineTitle || "-"} />
          </Flex>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export const Content = ({ title, value, children }) => {
  return (
    <Flex width="100%" direction="column" align="flex-start" padding="0.5em 0">
      <Label.H5 weight={600} color={themes.colors.primary}>
        {title}
      </Label.H5>
      {value && (
        <div
          style={{ width: "100%", fontSize: "1.1em", fontWeight: 500 }}
          color={themes.colors.white}
        >
          {value}
        </div>
      )}
      {children}
    </Flex>
  );
};

export const getStatusBadge = (status) => {
  switch (status) {
    case constant.statuses.APPROVE:
      return <BadgeStyle bgcolor={themes.colors.success}>อนุมัติ</BadgeStyle>;
    case constant.statuses.PAID:
      return (
        <BadgeStyle bgcolor={themes.colors.primary}>จ่ายเงินแล้ว</BadgeStyle>
      );
    case constant.statuses.PENDING:
      return (
        <BadgeStyle bgcolor={themes.colors.brightBlue}>รอดำเนินการ</BadgeStyle>
      );
    case constant.statuses.CANCEL:
      return <BadgeStyle bgcolor={themes.colors.dangerRed}>ยกเลิก</BadgeStyle>;
    case constant.statuses.EXPIRED:
      return <BadgeStyle bgcolor={themes.colors.dangerRed}>หมดอายุ</BadgeStyle>;
    case constant.statuses.ACTIVE:
      return (
        <BadgeStyle bgcolor={themes.colors.success}>พร้อมใช้งาน</BadgeStyle>
      );

    default:
      break;
  }
};

export default Orders;

const Img = styled.img`
  max-width: 400px;
  width: 100%;
  margin: 1em;
  :hover {
    transform: scale(1.5) translateX(-90px);
    position: absolute;
  }
`;
const StackImageContainer = styled(Flex)`
  max-height: 300px;
  height: 100%;
  padding: 0em 2em 2em 0;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;

export const ContentContainer = styled.div`
  border-radius: 0.375em;
  background-color: white;
  width: 100%;
  padding: 2em;
  box-shadow: 4px 4px 8px 2px rgb(0 0 0 / 10%);
  &.blue-bg {
    background: ${themes.colors.secondaryGradient2};
    color: white;
    font-weight: 500;
  }

  &.flex {
    display: flex;
  }
  &.justify {
    justify-content: space-between;
  }

  &.column {
    flex-direction: column;
  }
`;

const Select = styled.select`
  z-index: 9000;
  position: absolute;
  top: 55px;
  right: 120px;
`;
export const Button = styled.button`
  border-radius: 0.375em;
  border: 3px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
  color: white;
  font-size: 1em;

  padding: 0.2em 0.5em;
  font-weight: 600;
  margin: ${(props) => props.margin || `0.5em`};
  margin-left: 0;
  &.button-style-primary {
    background-color: ${themes.colors.primary};
    border: 3px solid ${themes.colors.primary};
    color: ${themes.colors.black};
    font-weight: 300;
  }
  &.edit {
    font-size: 0.5 em;
    padding: 0.2em 0.5em;
    margin: 0 1em;
  }
  &.note {
    margin: 0;
  }
  :hover {
    background-color: ${(props) => "white"};
    color: ${(props) => props.color};
  }

  ${(props) =>
    props.disabled &&
    `background: ${themes.colors.gray2}; cursor: no-drop !important; color:${themes.colors.white}; border: 1px solid ${themes.colors.gray2};`}
`;
export const BadgeStyle = styled(Badge)`
  background-color: ${(props) => props.bgcolor} !important;
  font-size: 0.8em;
  cursor: ${(props) => props.cursor};
  padding: 0.5em 1em;
`;

const Note = styled.div`
  background-color: white;
  color: black;
  font-size: 0.7em;
  border-radius: 0.375em;
  padding: 0.5em;
`;
