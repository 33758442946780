"use client";

import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import Context from "../../Context";
import { cn } from "../../util/lib";

const Separator = (
  { className, orientation = "horizontal", decorative = true, ...props },
  ref
) => {
  const { isDarkTheme } = Context();
  return (
    <SeparatorPrimitive.Root
      decorative={decorative}
      orientation={orientation}
      className={cn(
        "shrink-0",
        orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
        className,
        isDarkTheme ? "bg-gray-600" : "bg-gray-300"
      )}
      {...props}
    />
  );
};
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
