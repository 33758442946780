import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtDataManager from "../../components/HtDataManager";
import { Label, Flex } from "../../components";
import { Input, Table, FormGroup } from "reactstrap";
import { ContentContainer } from "../orders/WorkingOrder";
import { Button } from "./MailSetting";
import themes from "../../themes";
const Prices = () => {
  const { callApi, configs } = Context();

  const [currentSpecial, setCurrentSpecial] = useState("xxxxxx");
  const [currentPrice, setCurrentPrize] = useState(0);
  const [specials, setSpecials] = useState(configs.specialPrices || []);
  const [currentSpecialNumber, setCurrentSpecialNumber] = useState("xxxxxx");
  const [specialsNumber, setSpecialsNumber] = useState(
    configs.specialNumbers || []
  );
  const [standardPrice, setStandardPrice] = useState(configs.price || 0);
  const [currency, setCurrency] = useState({
    lak: configs.currency?.lak || 0,
    krw: configs.currency?.krw || 0,
  });
  const [servicePrice, setServicePrice] = useState(configs.fee || 0);
  const [affiliatePrice, setAffiliatePrice] = useState(
    configs.affiliatePrice || 0
  );

  const [ts, setTs] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const addSpecial = (type) => {
    if (type === "number") {
      setSpecialsNumber((prevState) => [...prevState, currentSpecialNumber]);
      setCurrentSpecialNumber("xxxxxx");
    } else {
      setSpecials((x) => {
        return { ...x, [currentSpecial]: parseInt(currentPrice) };
      });
      setCurrentSpecial("xxxxxx");
      setCurrentPrize(0);
    }
  };

  const addSuperSpecial = (type) => {
    const arrSpectial = currentSpecial.split(",").reduce((acc, data) => {
      acc[`xxxx${data}`] = parseInt(currentPrice);

      return acc;
    }, {});
    console.log(arrSpectial);

    setSpecials((x) => {
      return { ...x, ...arrSpectial };
    });
  };

  const removeSpecial = (number, type) => {
    if (type === "number") {
      setSpecialsNumber(
        specialsNumber.filter((item) => item !== number) || specialsNumber
      );
    } else {
      setSpecials((x) => {
        delete x[number];
        return x;
      });
    }

    setTs(Date.now());
  };

  const pullNumber = () => {
    const num = Object.keys(specials).map((special) => special);
    setSpecialsNumber(num);
  };
  const onSaveClick = async () => {
    if (affiliatePrice !== configs.affiliatePrice) {
      await callApi("configs-edit", {
        id: "general",
        key: "affiliatePrice",
        value: affiliatePrice,
      });
    }
    if (standardPrice !== configs.price) {
      await callApi("configs-edit", {
        id: "general",
        key: "price",
        value: standardPrice,
      });
    }

    if (servicePrice !== configs.fee) {
      await callApi("configs-edit", {
        id: "general",
        key: "fee",
        value: servicePrice,
      });
    }

    setIsEdit(false);
  };
  const updateChange = async (key, value) => {
    if (!window.confirm("ท่านต้องการแก้ไขใช่หรือไม่")) return;
    await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });
    window.location.reload();
  };

  return (
    <div style={{ verticalAlign: "top", height: "100%" }}>
      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H1 color="white" weight={600}>
            ตั้งค่าราคา
          </Label.H1>
          <Flex wrap="wrap" margin="5px 0 0 0">
            <div
              style={{
                width: "200px",
                margin: "30px 0 0 0",
                paddingRight: "50px",
              }}
            >
              <Label.H4 color="white" weight={600}>
                ราคาพื้นฐาน
              </Label.H4>
              <Flex className="my-3" direction="column">
                <div>
                  <Input
                    disabled={configs.isOpen.store}
                    style={{ cursor: configs.isOpen.store && "not-allowed" }}
                    type="number"
                    name="price"
                    id="price"
                    defaultValue={configs.price}
                    onChange={(e) => {
                      setIsEdit(true);
                      setStandardPrice(Number(e.target.value));
                    }}
                  />
                </div>
              </Flex>
            </div>
            <div
              style={{
                width: "200px",
                margin: "30px 0 0 0",
                paddingRight: "50px",
              }}
            >
              <Label.H4 color="white" weight={600}>
                ราคาค่าบริการ
              </Label.H4>
              <Flex className="my-3" direction="column">
                <div>
                  <Input
                    disabled={configs.isOpen.store}
                    style={{ cursor: configs.isOpen.store && "not-allowed" }}
                    type="number"
                    name="fee"
                    id="fee"
                    defaultValue={configs.fee}
                    onChange={(e) => {
                      setIsEdit(true);
                      setServicePrice(Number(e.target.value));
                    }}
                  />
                </div>
              </Flex>
            </div>
          </Flex>
          {configs.isOpen.store && (
            <Label.H4 className="text-danger">*ปิดขายก่อนปรับราคา</Label.H4>
          )}
          {isEdit && (
            <Button style={{ margin: "0" }} onClick={() => onSaveClick()}>
              บันทึก
            </Button>
          )}
        </div>
      </ContentContainer>
      <br />
      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H1 color="white" weight={600}>
            ตั้งค่าค่าเงินต่างประเทศ
          </Label.H1>

          <Label.H4 margin="30px 0 0 0" color="white" weight={600}>
            ค่าเงินลาว (กีบ) ต่อ 1 บาท
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                disabled={configs.isOpen.store}
                style={{
                  width: "100px",
                  cursor: configs.isOpen.store && "not-allowed",
                }}
                type="number"
                defaultValue={currency.lak}
                onChange={(e) => {
                  setIsEdit(true);
                  setCurrency((prevState) => ({
                    ...prevState,
                    lak: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>

          <Label.H4 margin="30px 0 0 0" color="white" weight={600}>
            ค่าเงินเกาหลี (วอน) ต่อ 1 บาท
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                disabled={configs.isOpen.store}
                style={{
                  width: "100px",
                  cursor: configs.isOpen.store && "not-allowed",
                }}
                type="number"
                defaultValue={currency.krw}
                onChange={(e) => {
                  setIsEdit(true);
                  setCurrency((prevState) => ({
                    ...prevState,
                    krw: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>

          {configs.isOpen.store && (
            <Label.H4 className="text-danger">*ปิดขายก่อนปรับราคา</Label.H4>
          )}
          {isEdit && (
            <Button
              style={{ margin: "0" }}
              onClick={async () =>
                await callApi("configs-edit", {
                  id: "general",
                  key: "currency",
                  value: currency,
                })
              }
            >
              บันทึก
            </Button>
          )}
        </div>
      </ContentContainer>
      <br />
      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H1 color="white" weight={600}>
            ตั้งค่าลิ้งชวนเพื่อน และลิ้งนายหน้า
          </Label.H1>
          <Flex margin="2em 0 0 0" align="center">
            <Label.H4 color="white" weight={600}>
              ลิ้งชวนเพื่อน
            </Label.H4>
            <h3 style={{ margin: "0 5px 0 20px" }}>
              <FormGroup switch>
                <Input
                  type="switch"
                  id="isAffiliateOpen"
                  checked={configs.isAffiliateOpen}
                  onClick={(e) => {
                    updateChange(e.target.id, e.target.checked);
                  }}
                />
              </FormGroup>
            </h3>
            <Label.H4 color="white" weight={600}>
              ลิ้งนายหน้า
            </Label.H4>
          </Flex>
          <div
            style={{
              width: "250px",
              margin: "30px 0 0 0",
              paddingRight: "50px",
            }}
          >
            <Label.H4 color="white" weight={600}>
              ราคาลิ้งนายหน้า (ต่อใบ)
            </Label.H4>
            <Flex className="my-3" direction="column">
              <div>
                <Input
                  disabled={configs.isOpen.store}
                  style={{ cursor: configs.isOpen.store && "not-allowed" }}
                  type="number"
                  name="affiliatePrice"
                  id="affiliatePrice"
                  defaultValue={configs.affiliatePrice}
                  onChange={(e) => {
                    setIsEdit(true);
                    setAffiliatePrice(Number(e.target.value));
                  }}
                />
              </div>
            </Flex>
          </div>
          {isEdit && (
            <Button style={{ margin: "0" }} onClick={() => onSaveClick()}>
              บันทึก
            </Button>
          )}
        </div>
      </ContentContainer>
      <br />
      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H1 color="white" weight={600}>
            ราคาเฉพาะเลข
          </Label.H1>
          <Flex>
            <div
              style={{
                width: "200px",
                margin: "30px 0 0 0",
                paddingRight: "50px",
              }}
            >
              <Label.H4 color="white" weight={600}>
                เลข 6 หลัก
              </Label.H4>
              <Flex className="my-3" direction="column">
                <div>
                  <Input
                    disabled={configs.isOpen.store}
                    style={{ cursor: configs.isOpen.store && "not-allowed" }}
                    type="text"
                    defaultValue="xxxxxx"
                    value={currentSpecial}
                    onChange={(e) => {
                      setCurrentSpecial(e.target.value);
                    }}
                  />
                </div>
              </Flex>
            </div>
            <div
              style={{
                width: "200px",
                margin: "30px 0 0 0",
                paddingRight: "50px",
              }}
            >
              <Label.H4 color="white" weight={600}>
                ราคา
              </Label.H4>
              <Flex className="my-3" direction="column">
                <Flex>
                  <Input
                    disabled={configs.isOpen.store}
                    style={{ cursor: configs.isOpen.store && "not-allowed" }}
                    type="number"
                    value={currentPrice}
                    onChange={(e) => {
                      setCurrentPrize(e.target.value);
                    }}
                  />
                  <Button
                    className={configs.isOpen.store && "disabled"}
                    onClick={() => addSpecial()}
                    style={{ margin: "0" }}
                  >
                    เพิ่ม
                  </Button>
                  <Button
                    className={configs.isOpen.store && "disabled"}
                    onClick={() => addSuperSpecial()}
                    style={{ margin: "0" }}
                  >
                    เพิ่มแบบพิเศษ
                  </Button>
                </Flex>
              </Flex>
            </div>
          </Flex>
          <Table
            striped
            bordered
            hover
            style={{
              maxWidth: "500px",
              margin: "2em 0",
              backgroundColor: "white",
            }}
          >
            <thead>
              <tr>
                <th className="align-middle text-center">ลำดับ</th>
                <th className="align-middle text-center">เลข</th>
                <th className="align-middle text-center">ราคา</th>
                <th className="align-middle text-center"></th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(specials)
                .sort(
                  (numA, numB) =>
                    numA.replaceAll("x", "") - numB.replaceAll("x", "")
                )
                .map((number, i) => (
                  <tr key={i}>
                    <th className="align-middle text-center">{i + 1}</th>
                    <th className="align-middle text-center">{number}</th>
                    <th className="align-middle text-center">
                      <span style={{ color: themes.colors.dangerRed }}>
                        {specials[number]}
                      </span>
                    </th>
                    <th className="align-middle text-center">
                      <Button
                        className="remove"
                        style={{
                          margin: "0",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => removeSpecial(number)}
                      >
                        ลบ
                      </Button>
                    </th>
                  </tr>
                ))}
            </tbody>
          </Table>
          {configs.isOpen.store && (
            <Label.H4 className="text-danger">*ปิดขายก่อนปรับราคา</Label.H4>
          )}
          <Button
            className={configs.isOpen.store && "disabled"}
            style={{ margin: "0" }}
            disabled={configs.isOpen.store}
            onClick={() =>
              callApi("configs-edit", {
                key: "specialPrices",
                value: specials,
                id: "general",
              })
            }
          >
            บันทึก
          </Button>
        </div>
      </ContentContainer>
      <br />
      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H1 color="white" weight={600}>
            ราคาเซียนดัง
          </Label.H1>
          <Flex>
            <div
              style={{
                width: "200px",
                margin: "30px 0 0 0",
                paddingRight: "50px",
              }}
            >
              <Label.H4 color="white" weight={600}>
                เลข 6 หลัก
              </Label.H4>
              <Flex className="my-3">
                <div>
                  <Input
                    disabled={configs.isOpen.store}
                    style={{ cursor: configs.isOpen.store && "not-allowed" }}
                    type="text"
                    defaultValue="xxxxxx"
                    value={currentSpecialNumber}
                    onChange={(e) => {
                      setCurrentSpecialNumber(e.target.value);
                    }}
                  />
                </div>

                <Button
                  className={configs.isOpen.store && "disabled"}
                  onClick={() => addSpecial("number")}
                  style={{ margin: "0" }}
                >
                  เพิ่ม
                </Button>
                <Button
                  className={configs.isOpen.store && "disabled"}
                  onClick={() => pullNumber()}
                  style={{ margin: "0" }}
                >
                  ดึงข้อมูล
                </Button>
              </Flex>
            </div>
          </Flex>
          <Table
            striped
            bordered
            hover
            style={{
              maxWidth: "500px",
              margin: "2em 0",
              backgroundColor: "white",
            }}
          >
            <thead>
              <tr>
                <th className="align-middle text-center">ลำดับ</th>
                <th className="align-middle text-center">เลข</th>

                <th className="align-middle text-center"></th>
              </tr>
            </thead>
            <tbody>
              {Object.values(specialsNumber).map((number, i) => (
                <tr>
                  <th className="align-middle text-center">{i + 1}</th>
                  <th className="align-middle text-center">
                    <span style={{ color: "red" }}> {number}</span>
                  </th>

                  <th className="align-middle text-center">
                    <Button
                      className="remove"
                      style={{
                        margin: "0",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => removeSpecial(number, "number")}
                    >
                      ลบ
                    </Button>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
          {configs.isOpen.store && (
            <Label.H4 className="text-danger">*ปิดขายก่อนปรับราคา</Label.H4>
          )}

          <Button
            className={configs.isOpen.store && "disabled"}
            style={{ margin: "0" }}
            disabled={configs.isOpen.store}
            onClick={() =>
              callApi("configs-edit", {
                key: "specialNumbers",
                value: specialsNumber,
                id: "general",
              })
            }
          >
            บันทึก
          </Button>
        </div>
      </ContentContainer>
    </div>
  );
};
export default Prices;
