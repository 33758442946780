import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { flatten } from "ramda";
export const ReportBookWinnerSummary = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);

  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    let data = await callApi("prizes-getAllLotteriesWithPrize", {
      roundDate,
    });

    data = data.reduce((acc, order) => {
      order.prizes.forEach((prize) => {
        if (acc[prize.prizeType]) {
          acc[prize.prizeType].count += 1;
          acc[prize.prizeType].amount += prize.prizeAmount;
        } else {
          acc[prize.prizeType] = { count: 1, amount: prize.prizeAmount };
        }
      });

      return acc;
    }, {});

    // const sortedData = data.sort((a, b) =>
    //   a.fileNameId.localeCompare(b.fileNameId, undefined, {
    //     numeric: true,
    //   })
    // );

    setData(data);
  };

  const prizeType = {
    first: { label: "รางวัลที่ 1", reward: "6,000,000", index: 1 },
    firstThree: { label: "รางวัลเลขหน้า 3 ตัว", reward: "4,000", index: 7 },
    fith: { label: "รางวัลที่ 5", reward: "20,000", index: 6 },
    forth: { label: "รางวัลที่ 4", reward: "40,000", index: 5 },
    lastThree: { label: "รางวัลเลขท้าย 3 ตัว", reward: "4,000", index: 8 },
    lastTwo: { label: "รางวัลเลขท้าย 2 ตัว", reward: "2,000", index: 9 },
    nearBy: { label: "รางวัลไกล้เคียง", reward: "100,000", index: 2 },
    second: { label: "รางวัลที่ 2", reward: "200,000", index: 3 },
    third: { label: "รางวัลที่ 3", reward: "80,000", index: 4 },
  };

  const handleDownload = async () => {
    const prepData = data;

    let listHead = [
      "ชื่อรางวัล",
      "รางวัลละ (บาท)",
      "จำนวนใบ (ใบ)",
      "มูลค่า (บาท)",
    ];
    let ar = [];
    ar.push([`รายการรางวัล งวด ${roundDate}`]);
    ar.push(listHead);
    let total = 0;
    Object.entries(prepData)
      .sort(
        ([key1, value1], [key2, value2]) =>
          prizeType[key1].index - prizeType[key2].index
      )
      .forEach(([key, value]) => {
        total += value.amount;
        ar.push([
          prizeType[key].label,
          prizeType[key].reward,
          value.count.toLocaleString(),
          value.amount.toLocaleString(),
        ]);
      });
    ar.push(["รวม", "", "", total.toLocaleString()]);
    setCsvData(ar);
    setCsvFileName(`รายงานสรุปจำนวนรางวัล_${roundDate}.csv`);
  };
  let total = 0;
  return (
    <Container>
      <Label.H2 weight={500}>รายงานสรุปจำนวนรางวัล</Label.H2>
      <br />

      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates
          .sort()
          .reverse()
          .map((roundDate) => (
            <option key={roundDate}>{roundDate}</option>
          ))}
      </Input>

      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <br />

      {data.length !== 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ชื่อรางวัล</th>
              <th>รางวัลละ (บาท)</th>
              <th>จำนวน (ใบ)</th>
              <th>มูลค่า (บาท)</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data)
              .sort(
                ([key1, value1], [key2, value2]) =>
                  prizeType[key1].index - prizeType[key2].index
              )
              .map(([key, value], index) => {
                total = total + value.amount;
                return (
                  <tr key={index}>
                    <th>{prizeType[key].label || "-"}</th>
                    <th>{prizeType[key].reward || "-"}</th>
                    <th>{value.count.toLocaleString() || "-"}</th>
                    <th>{value.amount.toLocaleString() || "-"}</th>
                  </tr>
                );
              })}
            <tr>
              <th>รวม</th>
              <th></th>
              <th></th>
              <th>{total.toLocaleString()}</th>
            </tr>
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const Button = styled.button``;
