import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { range, splitWhen, equals, union, flatten, without } from "ramda";
import { boolean2YN, yN2Boolean, thaiDate } from "../util";
import { CSVLink, CSVDownload } from "react-csv";

const HtTablePaging = ({
  title = "ตาราง",
  getData,
  downloadable = false,
  roundDate,
  onRowClick = () => {},
  onCellClick = () => {},
  columns = [],
  overrides = {},
  totalPage = 1,
  limit = 50,
  sort = [],
  updateAble = [],
  updateApi = async () => {},
  dropdownAble = {},
  dropdownFilter = {},
  dateFilter = [],
  hideFilters = [],
  hideColumns = [],
  deleteColumns = () => {},
  customFunction = () => {},
  focusToPreview = [],
  baseQrs = [],
  translations = {},
  defaultEditable = false,
  rerender,
  page,
}) => {
  const downloadRef = useRef(null);
  const INIT_START = { 1: "" };
  const [currentPage, setCurrentPage] = useState("1");
  const [queries, setQueries] = useState({});
  const [startAfter, setStartAfter] = useState(INIT_START);
  const [lastPage, setLastPage] = useState(totalPage);
  const [preview, setPreview] = useState("");
  const [heads, setHeads] = useState(columns);
  const [rows, setRows] = useState([]);
  const [editAbleTrack, setEditAbleTrack] = useState("");
  const [hideRow, setHideRow] = useState([]);
  const [togleEdit, setTogleEdit] = useState(defaultEditable);
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)
  const [downloadLimitPage, setDownloadLimitPage] = useState(1);
  const [downloadLimit, setDownloadLimit] = useState(1000);
  const formatCell = (key, row) => {
    let value = row[key];
    const raw = row[key];
    const type = getType(value);
    if (type === "number") {
      value = parseInt(value).toLocaleString();
    } else if (type === "boolean") {
      value = value ? "Yes" : "No";
    } else if (key === "delete") {
      value = (
        <button
          onClick={() => {
            window.confirm("โปรดยืนยันการลบ") && deleteColumns(row.id);
          }}
        >
          ลบ
        </button>
      );
    } else if (key === "custom") {
      value = (
        <button
          onClick={() => {
            window.confirm("โปรดยืนยันการสั่งงาน") &&
              customFunction(row.id).then((result) => {
                result.status === true && window.location.reload();
              });
          }}
        >
          สั่งการ
        </button>
      );
    } else if (type === "image") {
      value = (
        <Image
          onMouseEnter={() => setPreview(raw)}
          onMouseLeave={() => setPreview("")}
          alt={value}
          src={value}
        />
      );
    } else if (type === "array-image") {
      value = raw.map((x, i) => (
        <div key={i}>
          <Image
            onMouseEnter={() => setPreview(x)}
            onMouseLeave={() => setPreview("")}
            alt={x}
            src={x}
          />
        </div>
      ));
    } else if (type === "array-object") {
      value = raw.map((x, i) => JSON.stringify(x));
    } else if (type === "object") {
      value = JSON.stringify(value);
    } else if (type?.split("-")?.[0] === "array") {
      value = value.map((x, i) => <div key={i}>{x}</div>);
    }
    // type === "date"
    if (
      parseInt(row[key]) > 1500000000000 &&
      parseInt(row[key]) < 1900000000000
    ) {
      value = new Date(parseInt(row[key])).toLocaleString();
    }
    if (updateAble.includes(key)) {
      value = (
        <div
          contentEditable={togleEdit}
          style={{ backgroundColor: "white", border: "1px solid black" }}
          onFocus={(e) => {
            setEditAbleTrack(e.target.innerText);
            focusToPreview[key] && setPreview(row[focusToPreview[key]]);
          }}
          onBlur={(e) => {
            updateChange(
              e,
              { id: row.id, key, value: e.target.innerText },
              "innerText"
            );
            setPreview("");
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              e.target.blur();
              e.preventDefault();
            }
          }}
        >
          {row[key]}
        </div>
      );
    }
    if (dropdownAble[key]) {
      value = (
        <select
          disabled={!togleEdit}
          style={{ textAlign: "center" }}
          onFocus={(e) => setEditAbleTrack(e.target.value)}
          onChange={(e) =>
            updateChange(e, { id: row.id, key, value: e.target.value }, "value")
          }
        >
          {union([value], dropdownAble[key]).map((val, i) => (
            <option key={i} value={val}>
              {val}
            </option>
          ))}
        </select>
      );
    }
    return { value, type, style: { textAlign: "center" } };
  };
  const getType = (value) => {
    let type = getNanoType(value);
    if (type === "object") {
      if (Array.isArray(value)) {
        type = "array-" + getNanoType(value[0]);
      }
    }
    return type;
  };
  const getNanoType = (value) => {
    let type = typeof value;
    if (type === "string") {
      const isImage =
        (["jpeg", "png", "jpg"].includes(value?.split(".")?.pop()) ||
          /storage\.googleapis\.com\/hongthong\-/.test(value) ||
          /http\:\/\/127.0.0.1:9199/.test(value)) &&
        /^http/.test(value);
      if (isImage) {
        type = "image";
      }
    }
    return type;
  };
  const updateChange = async (e, params, type) => {
    if (editAbleTrack === params.value) return;
    if (!window.confirm("ต้องการแก้ไขข้อมูลใช่หรือไม่")) {
      e.target[type] = editAbleTrack;
      return;
    }
    const result = await updateApi(params);
    if (!result.status === true) {
      alert("Something went wrong please try again later.");
      e.target[type] = editAbleTrack;
    } else if (result.removes) {
      setHideRow((old) => [...old, ...result.removes]);
    }
  };
  const formatFilter = (key) => {
    if (dropdownFilter[key]) {
      return (
        <Filter>
          <select
            style={{ flex: 1, textAlign: "center" }}
            onChange={(e) => formQuery(key, e.target.value)}
          >
            {union([""], dropdownFilter[key]).map((val, i) => (
              <option key={i} value={val}>
                {val}
              </option>
            ))}
          </select>
        </Filter>
      );
    } else if (dateFilter.includes(key)) {
      return (
        <Filter>
          <input
            type="date"
            style={{ flex: 1, textAlign: "center" }}
            onChange={(e) => formQuery(key, +new Date(e.target.value))}
          />
        </Filter>
      );
    } else if (hideFilters.includes(key)) {
      return <></>;
    } else {
      return (
        <Filter>
          <span
            key={key}
            onBlur={(e) => {
              formQuery(key, e.target.innerText.replace(/^\s+|\s+$/g, ""));
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                e.target.blur();
              }
            }}
            style={{
              flex: 1,
              backgroundColor: "white",
              border: "1px solid black",
            }}
            contentEditable={true}
          ></span>
        </Filter>
      );
    }
  };
  const getDataAndSetRows = async ({ qrs = [], page = "1" }) => {
    const data = await getData({
      queries: [...baseQrs, ...qrs],
      roundDate: roundDate,
      startAfter: startAfter[page],
      limit,
    });

    if (data.length) {
      setHeads((old) =>
        without(
          hideColumns,
          union(old, flatten(data.map((x) => Object.keys(x))))
        )
      );
      setCurrentPage(page);
      if (limit === data.length) {
        setLastPage(Math.max(lastPage, parseInt(page) + 1));
        setStartAfter((old) => {
          return {
            ...old,
            [String(parseInt(page) + 1)]: data[data.length - 1].id,
          };
        });
      }
    }
    if (sort.includes("createdAt")) {
      setRows(data.sort((a, b) => b.createdAt - a.createdAt));
      return;
    }
    setRows(data);
  };

  useEffect(() => {
    getDataAndSetRows({});
  }, [rerender]);
  const formQuery = (key, value, not) => {
    setQueries((old) => {
      if (!old[key] && !value) return old;
      if (old[key]?.value === value) return old;
      if (!value) {
        delete old[key];
      } else {
        old[key] = value;
      }
      if (key === "shortId") {
        old[key] = value.slice(-5).toUpperCase();
      }
      setStartAfter(INIT_START);
      getDataAndSetRows({ qrs: queriesObjToArray(old) });
      return old;
    });
  };
  const queriesObjToArray = (old) => {
    return Object.keys(old).map((o) => {
      return [o, yN2Boolean(old[o])];
    });
  };
  const setPage = (page) => {
    page = String(page);
    getDataAndSetRows({ qrs: queriesObjToArray(queries), page });
    setCurrentPage(page);
  };
  const download = async () => {
    const limit = downloadLimit;
    let keepGoing = true;
    let startAft = "";

    if (
      !window.confirm(
        "ท่านแน่ใจหรือไม่ว่าต้องการดาวน์โหลดข้อมูลนี้จำนวน " +
          (downloadLimitPage * limit).toLocaleString() +
          " รายการ"
      )
    ) {
      return;
    }
    let page = 1;
    const date = thaiDate();
    const query =
      JSON.stringify(queries) === "{}" ? "" : `_(${JSON.stringify(queries)})`;

    while (keepGoing) {
      const data = await getData({
        queries: [...baseQrs, ...queriesObjToArray(queries)],
        startAfter: startAft,
        limit,
      });
      if (!data.length) {
        return;
      }
      startAft = data[data.length - 1].id;
      keepGoing = data.length === limit && downloadLimitPage !== page;

      const csvHeads = Object.keys(data[0]);
      const result = data.reduce((acc, x) => {
        let temp = Array(csvHeads.length).fill("");
        Object.keys(x).forEach((ind) => {
          if (!csvHeads.includes(ind)) {
            csvHeads.push(ind);
          }
          temp[csvHeads.indexOf(ind)] =
            typeof x[ind] === "string"
              ? x[ind]
              : JSON.stringify(x[ind]).replace(/,/g, "-");
        });
        acc.push(temp);
        return acc;
      }, []);
      result.unshift(csvHeads);

      setCsvData(result);
      setCsvFileName(`${title}${query}_${page++}_${date}.csv`);
    }
  };

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  return (
    <div>
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <div style={{ position: "relative" }}>
        <h4 style={{ textAlign: "center" }}>{title}</h4>
        {/* <EditingSc onClick={() => setTogleEdit(x => !x)}>{togleEdit ? "Lock" : "Unlock"}</EditingSc> */}
        {downloadable && (
          <div style={{ position: "absolute", top: 0, left: 0 }}>
            <button onClick={download} style={{ display: "inline" }}>
              Download Excel
            </button>
            {/* <input
              type="number"
              min="0"
              defaultValue={downloadLimit}
              style={{ width: "80px", display: "inline" }}
              onChange={(e) => {
                if (e.target.value > 10000) {
                  setDownloadLimit(10000);
                  e.target.value = 10000;
                  alert("Download limit is 10,000");
                }
                return setDownloadLimit(parseInt(e.target.value));
              }}
            /> */}
          </div>
        )}
        <PagingSc>
          {currentPage !== "1" && (
            <>
              <Page onClick={() => setPage(1)}>{" << "}</Page>
              <Page
                onClick={() => {
                  const page = String(parseInt(currentPage) - 1);
                  setPage(page);
                }}
              >
                {" < "}
              </Page>
            </>
          )}
          {splitWhen(equals(currentPage), Object.keys(startAfter).sort())[0]
            .slice(-3)
            .map((x) => {
              return (
                <Page key={x} isSelected={false} onClick={() => setPage(x)}>
                  {x}
                </Page>
              );
            })}
          <Page isSelected={true}>{currentPage}</Page>
          {splitWhen(equals(currentPage), Object.keys(startAfter).sort())[1]
            .slice(1, 4)
            .map((x) => {
              return (
                <Page key={x} isSelected={false} onClick={() => setPage(x)}>
                  {x}
                </Page>
              );
            })}

          {/* {lastPage.toString() !== currentPage && ( */}
          {rows.length >= limit && (
            <>
              <Page
                onClick={() => {
                  const page = String(parseInt(currentPage) + 1);
                  setPage(page);
                }}
              >
                {" > "}
              </Page>
            </>
          )}
          <Input type={"number"}></Input>
          <button>Go</button>
        </PagingSc>
      </div>
      <div style={{ position: "relative" }}>
        <Preview
          src={preview}
          onMouseEnter={() => setPreview(preview)}
          onMouseLeave={() => setPreview("")}
        />
        <Table>
          <thead>
            <Tr>
              <Th></Th>
              {heads.map((key, i) => (
                <Th key={i} style={{ fontWeight: "normal" }}>
                  {formatFilter(key)}
                </Th>
              ))}
            </Tr>
            <Tr>
              <Th>ลำดับ</Th>
              {heads.map((key, i) => (
                <Th key={i}>{translations[key] || key}</Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {rows.map(
              (row, index) =>
                !hideRow.includes(row.id) && (
                  <Tr
                    key={row.id}
                    onClick={onRowClick ? () => onRowClick(row.id) : () => {}}
                  >
                    <Td style={{ textAlign: "center", fontWeight: "700" }}>
                      {index + 1}
                    </Td>
                    {heads.map((key, i) => {
                      const formated = formatCell(key, row);
                      return (
                        <Td
                          style={formated.style}
                          key={i}
                          onClick={
                            onCellClick
                              ? () =>
                                  onCellClick({
                                    id: row.id,
                                    value: row[key],
                                    key,
                                  })
                              : () => {}
                          }
                        >
                          {overrides[key]
                            ? overrides[key]({ row, key }) || formated.value
                            : formated.value}
                        </Td>
                      );
                    })}
                  </Tr>
                )
            )}
          </tbody>
          <tfoot />
        </Table>
        <br />
      </div>
    </div>
  );
};

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
const Th = styled.th`
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
`;
const Td = styled.td`
  text-align: right;
  border: 1px solid #dddddd;
  padding: 8px;
`;
const Tr = styled.tr`
  :nth-child(even) {
    background-color: rgba(0, 0, 0, 0.1);
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
const Input = styled.input`
  text-align: center;
  width: 50px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::input[type="number"] {
    -moz-appearance: textfield;
  }
`;
const Page = styled.div`
  text-align: center;
  display: inline-block;
  width: 30px;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    `
    font-weight: bold;
    color: firebrick;
  `}
  :hover {
    font-weight: bold;
  }
`;
const Image = styled.img`
  max-width: 200px;
  max-height: 80px;
`;
const Filter = styled.div`
  text-align: center;
  display: flex;
  background-color: white;
  border: 1px solid black;
`;
const PagingSc = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
`;
const EditingSc = styled.button`
  position: absolute;
  left: 0px;
  bottom: 0px;
`;
const Preview = styled.img`
  z-index: 9999;
  position: fixed;
  max-width: 50%;
  min-height: 50%;
  max-height: 50%;
  top: 50%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
`;
export default HtTablePaging;
