import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { flatten } from "ramda";

export const ReportTemp = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [pointData, setPointData] = useState([]);
  const [from, setForm] = useState(0);
  const [to, setTo] = useState(0);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };
  const handleSearch = async () => {
    const data = await callApi("orders-countBigOrder", {
      roundDate: roundDate,
    });
    // const data = await callApi("orders-getSpecialEventOrder", {
    //   roundDate: roundDate,
    //   from: Number(from),
    //   to: Number(to),
    // });

    console.log("data length", data.length);

    // const filteredData = data.reduce((arr, order) => {
    //   if (order.lotteryIds.length >= 5 && order.lotteryIds <= 9) {
    //     arr.push(order);
    //   }
    //   return arr;
    // }, []);

    const sortedData = Object.values(data.acc)
      .filter((order) => order.size >= 120)
      .sort((a, b) => a.size - b.size);

    setData(sortedData);
  };

  // const giveAPoint = async () => {
  //   const data = await callApi("points-addPoint", {
  //     timestampStart: Number(from),
  //     timestampEnd: Number(to),
  //   });

  //   console.log(data);
  //   setPointData(data.result);
  // };

  const handleDownload = async () => {
    const date = thaiDate();
    const prepData = data;

    const csvHeads = Object.keys(data[0]);
    let listHead = ["ลำดับ", "ชื่อ", "นามสกุล", "เบอร์โทรศัพท์", "จำนวน"];
    const ar = [];
    ar.push(listHead);
    const result = Object.values(prepData).forEach((x, index) => {
      const createDate = new Date(x.createdAt);
      ar.push([index + 1, x.firstName, x.lastName, x.phone, x.size]);
    });

    setCsvData(ar);
    setCsvFileName(`รายงานกิจกรรมพิเศษ_${date}.csv`);
  };
  // 28 - 31 มีนาคม

  // วันละ 3 รอบ
  // 12.12 - 16.16 ทั้งหมด 1 รอบ ถ้าครบ 50 สิทธิ์ก่อนก็ตัดก่อน

  // 16.16-19.19  ทั้งหมด 1 รอบ ถ้าครบ 50 สิทธิ์ก่อนก็ตัดก่อน

  // 19.19 - 22.22  ทั้งหมด 1 รอบ ถ้าครบ 50 สิทธิ์ก่อนก็ตัดก่อน

  //28
  //12.12 1679980320000
  //16.16 1679994960000
  return (
    <Container>
      <Label.H2 weight={500}>รายงานพิเศษ</Label.H2>
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>
      {/* <Button onClick={() => giveAPoint()}>ให้คะแนนพิเศษ</Button> */}
      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <p>พบทั้งหมด {data.length}</p>
      <br />
      {Object.values(data).length !== 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>ชื่อ</th>
              <th>นามสกุล</th>
              <th>เบอร์โทร</th>
              <th>จำนวนที่สั่งซื้อ</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <th>{item.firstName || "-"}</th>
                  <th>{item.lastName || "-"}</th>
                  <th>{item.phone || "-"}</th>
                  <th>{item.size} ใบ</th>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const Button = styled.button``;
