import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import themes from "../../themes";
import { FiRefreshCw } from "react-icons/fi";
import { Container } from "../../components";
import { ScrollBar } from "../../components";
const Books = () => {
  const { callApi } = Context();

  return (
    <Container>
      {/* <Button
        onClick={async () => {
          callApi("books-countAllLotteriesToBooks");
        }}
      >
        <FiRefreshCwStyle /> นับใหม่
      </Button> */}
      <HtTablePaging
        getData={(body) => callApi("books-getAllBooks", body)}
        columns={[]}
        hideColumns={[]}
        overrides={{
          lotteryIds: (props) => {
            return (
              <ScrollBar>
                {props.row.lotteryIds.map((lotto, index) => (
                  <h6 style={{ fontWeight: 600 }}>
                    {index + 1}: {lotto}
                  </h6>
                ))}{" "}
              </ScrollBar>
            );
          },
        }}
        downloadable={true}
        limit={20}
        defaultEditable={true}
        translations={{
          delete: "ลบ",
          custom: "ลบชุด",
          matrixText: "เลขหวย",
          createdRawAt: "เข้าระบบเมื่อ",
          roundDate: "งวดที่",
          path: "ไฟล์",
          fileNameId: "ชื่อไฟล์ zip",
          publicUrl: "รูป",
        }}
      />
    </Container>
  );
};

const Button = styled.button`
  margin: 0 1em;
  background-color: white;
  border: 0;
  border-radius: 0.375rem;
  padding: 0.3rem 1rem;
  transition: 0.1s;

  :hover {
    background-color: ${themes.colors.primary};
  }
`;

const FiRefreshCwStyle = styled(FiRefreshCw)`
  ${Button}:hover & {
    animation: spin 2.5s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default Books;
