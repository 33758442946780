import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { flatten } from "ramda";
export const ReportBookWinner = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const handleSearch = async () => {
    const data = await callApi("prizes-getAllLotteriesWithPrize");

    const sortedData = data.sort((a, b) => {
      if (
        a.fileNameId.localeCompare(b.fileNameId, undefined, {
          numeric: true,
        }) <
        b.fileNameId.localeCompare(b.fileNameId, undefined, {
          numeric: true,
        })
      ) {
        return -1;
      } else if (
        a.fileNameId.localeCompare(b.fileNameId, undefined, {
          numeric: true,
        }) >
        b.fileNameId.localeCompare(b.fileNameId, undefined, {
          numeric: true,
        })
      ) {
        return 1;
      } else {
        // keyA == keyB
        if (a.prizes[0].prizeAmount > b.prizes[0].prizeAmount) {
          return -1;
        } else if (a.prizes[0].prizeAmount < b.prizes[0].prizeAmount) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    // const dataFilter = sortedData.filter((reward) => reward.orderShortId);

    setData(sortedData);
    setFilterData(sortedData);
  };

  const prizeTypes = {
    first: "รางวัลที่ 1",
    firstThree: "รางวัลเลขหน้า 3 ตัว",
    fith: "รางวัลที่ 5",
    forth: "รางวัลที่ 4",
    lastThree: "รางวัลเลขท้าย 3 ตัว",
    lastTwo: "รางวัลเลขท้าย 2 ตัว",
    nearBy: "รางวัลไกล้เคียง",
    second: "รางวัลที่ 2",
    third: " รางวัลที่ 3",
  };

  const handleDownload = async () => {
    const date = thaiDate();
    const prepData = filterData;

    const csvHeads = Object.keys(data[0]);
    let listHead = ["ชื่อไฟล์", "เลข", "เลขออเดอร์", "รางวัล"];
    let ar = [];
    ar.push(listHead);
    let total = 0;
    const result = prepData.reduce((acc, x, index) => {
      let lottoList = [];
      Object.keys(x).forEach((ind) => {
        if (!csvHeads.includes(ind)) {
          csvHeads.push(ind);
        }
      });
      let totalPrize = x.prizes.reduce((acc, prize) => {
        acc += prize.prizeAmount;
        return acc;
      }, 0);
      total += totalPrize;

      ar.push([
        x.fileNameId,
        x.number,
        x.orderShortId,
        x.prizes.map((data) => prizeTypes[data.prizeType]),
        totalPrize,
      ]);

      return acc;
    }, []);
    ar.push(["รวม", "", "", "", total]);
    setCsvData(ar);
    setCsvFileName(`รายงานเล่มที่ถูกรางวัล_${date}.csv`);
  };

  // const changeFilter = (e) => {
  //   if (e.target.value === "ยังไม่ขาย") {
  //     setFilterData(data.filter((reward) => !reward.orderShortId));
  //   } else {
  //     setFilterData(data.filter((reward) => reward.orderShortId));
  //   }
  // };

  let total = 0;

  return (
    <Container>
      <Label.H2 weight={500}>รายงานเล่มที่ถูกรางวัล</Label.H2>
      <br />
      {/* 
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate}>{roundDate}</option>
        ))}
      </Input> */}
      {/* <Input
        name="select"
        type="select"
        onChange={(e) => changeFilter(e)}
        style={{ width: "100px" }}
      >
        <option key={true}>ขายแล้ว</option>
        <option key={false}>ยังไม่ขาย</option>
      </Input> */}
      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <br />

      {filterData.length !== 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ลำดับที่</th>
              <th>ชื่อไฟล์</th>
              <th>เลข</th>
              <th>เลขออเดอร์</th>
              <th>รางวัล</th>
            </tr>
          </thead>
          <tbody>
            {filterData.map((item, index) => {
              let totalPrize = item.prizes.reduce((acc, prize) => {
                acc += prize.prizeAmount;
                return acc;
              }, 0);
              total += totalPrize;
              return (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <th>{item.fileNameId || "-"}</th>
                  <th>{item.number || "-"}</th>
                  <th>{item.orderShortId || "-"}</th>
                  <th>
                    {item.prizes.map((data) => {
                      return <p>{prizeTypes[data.prizeType]}</p>;
                    }) || "-"}
                  </th>
                  <th>{totalPrize}</th>
                </tr>
              );
            })}
            <tr>
              <th>รวม</th>
              <th></th>
              <th></th>
              <th></th>
              <th>{total.toLocaleString()}</th>
            </tr>
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const Button = styled.button``;
