import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { Container } from "../../components";
const Lotteries = () => {
  const { callApi } = Context();

  return (
    <Container>
      <HtTablePaging
        getData={(body) => callApi("bads-getAll", body)}
        updateApi={(body) => {
          return callApi("bads-edit", body);
        }}
        deleteColumns={(id) => callApi("bads-deletes", { id })}
        customFunction={(id) => callApi("bads-deletesZip", { id })}
        columns={["matrixText", "delete", "custom"]}
        updateAble={["matrixText"]}
        hideColumns={["id", "bucket", "retry"]}
        limit={20}
        focusToPreview={{ matrixText: "publicUrl" }}
        defaultEditable={true}
        translations={{
          delete: "ลบ",
          custom: "ลบชุด",
          matrixText: "เลขหวย",
          createdRawAt: "เข้าระบบเมื่อ",
          roundDate: "งวดที่",
          path: "ไฟล์",
          fileNameId: "ชื่อไฟล์ zip",
          publicUrl: "รูป",
        }}
      />
    </Container>
  );
};

export default Lotteries;
