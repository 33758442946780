import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Flex, Label } from "../../components";
import HtDataManager from "../../components/HtDataManager";
import { dateToUnix, roundDateToDate, unixToDateAndTime } from "../../util";
import { FormGroup, Input } from "reactstrap";
import { ContentContainer } from "../orders/WorkingOrder";

const General = () => {
  const { callApi, configs, updateConfig, setConfigs } = Context();
  const dataLeftRef = useRef(null);
  const [dataLeft, setDataLeft] = useState({});
  const [newRoundDate, setNewRoundDate] = useState("");
  const [updateData, setUpdateData] = useState([]);

  const [currentItem, setCurrentItem] = useState("");
  const [currentPrice, setCurrentPrice] = useState(0);
  const [premiums, setPremiums] = useState(configs.premiums);

  const addNewRoundDate = async (newRoundDate) => {
    let [yyyy, mm, dd] = newRoundDate.split("-");
    yyyy = parseInt(yyyy);
    if (!window.confirm("ท่านต้องการแก้ไข เพิ่มงวด ใช่หรือไม่")) return;
    await callApi("configs-addNewRoundDate", {
      roundDate: [yyyy, mm, dd].join("-"),
    });
    updateConfig();
  };
  const changeRoundDate = async (e) => {
    if (!window.confirm("ท่านต้องการแก้ไข เปลี่ยนงวด ใช่หรือไม่")) return;
    await callApi("configs-edit", {
      id: "general",
      key: "roundDate",
      value: e.target.value,
    });
    updateConfig();
  };

  const changeNextRoundDate = async (e) => {
    if (!window.confirm("ท่านต้องการแก้ไข เปลี่ยนงวด ใช่หรือไม่")) return;
    await callApi("configs-edit", {
      id: "general",
      key: "nextRoundDate",
      value: e.target.value,
    });
    updateConfig();
  };

  const updateChange = async (key, value) => {
    if (!window.confirm("ท่านต้องการแก้ไขใช่หรือไม่")) return;
    await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });
    updateConfig();
  };

  const calRoundedPoint = async (e) => {
    await callApi("points-addRoundedPoint");
  };

  // const updatePaymentChannel = (checked, value) => {
  //   let arr = configs.paymentChannel.list || [];
  //   if (checked) {
  //     updateChange(
  //       "paymentChannel.list",
  //       arr.filter((list) => list !== value)
  //     );
  //   } else {
  //     updateChange("paymentChannel.list", [...arr, value]);
  //   }
  // };

  return (
    <div style={{ verticalAlign: "top", height: "100%" }} ref={dataLeftRef}>
      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H1 color="white" weight={600}>
            งวดประจำวันที่ {roundDateToDate(configs.roundDate)}
          </Label.H1>
          <br />
          <Label.H3 color="white" weight={600}>
            ตั้งค่าทั้วไป
          </Label.H3>

          <div style={{ width: "200px", marginTop: "50px" }}>
            <Label.H4 color="white" weight={600}>
              เปิดร้าน
            </Label.H4>
            <h3 className="my-0">
              <FormGroup switch>
                <Input
                  type="switch"
                  id="isOpen.store"
                  checked={configs.isOpen.store}
                  onClick={(e) => {
                    updateChange(e.target.id, e.target.checked);
                  }}
                />
              </FormGroup>
            </h3>
          </div>

          <Flex wrap="wrap">
            <div
              style={{
                width: "200px",
                marginTop: "50px",
                paddingRight: "20px",
              }}
            >
              <Label.H4 color="white" weight={600}>
                งวดปัจจุบัน
              </Label.H4>
              <FormGroup>
                <Input
                  type="select"
                  disabled={configs.isOpen.store}
                  onChange={changeRoundDate}
                  value={configs.roundDate}
                >
                  {configs.roundDates.map((roundDate) => (
                    <option key={roundDate}>{roundDate}</option>
                  ))}
                </Input>
              </FormGroup>
            </div>

            <div
              style={{
                width: "200px",
                marginTop: "50px",
                paddingRight: "20px",
              }}
            >
              <FormGroup>
                <Label.H4 color="white" weight={600}>
                  วัน-เวลาเปิดขาย
                </Label.H4>

                <Input
                  id="nextRoundDateTime"
                  name="nextRoundDateTime"
                  type="datetime-local"
                  defaultValue={unixToDateAndTime(
                    configs.nextRoundDateTime + 7 * 3600000
                  )}
                  onBlur={(e) =>
                    updateChange(e.target.id, dateToUnix(e.target.value))
                  }
                />
              </FormGroup>
            </div>
          </Flex>
          <Flex>
            <div
              style={{
                width: "200px",
                marginTop: "50px",
                paddingRight: "20px",
              }}
            >
              <FormGroup>
                <Label.H4 color="white" weight={600}>
                  งวดถัดไป
                </Label.H4>
                <Input
                  type="select"
                  disabled={configs.isOpen.store}
                  onChange={changeNextRoundDate}
                  value={configs.newRoundDate}
                >
                  {configs.roundDates
                    .sort()
                    .reverse()
                    .map((roundDate) => (
                      <option key={roundDate}>{roundDate}</option>
                    ))}
                </Input>
              </FormGroup>
            </div>
            <div
              style={{
                width: "200px",
                marginTop: "50px",
                paddingRight: "20px",
              }}
            >
              <FormGroup>
                <Label.H4 color="white" weight={600}>
                  เพิ่มงวดใหม่
                </Label.H4>
                <Input
                  type="date"
                  onChange={(e) => addNewRoundDate(e.target.value)}
                />
              </FormGroup>
            </div>
          </Flex>
          <Flex wrap="wrap" align="flex-end">
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ประกาศรางวัลงวดนี้
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    id="isAwardReady"
                    checked={configs.isAwardReady}
                    onClick={(e) => {
                      updateChange(e.target.id, e.target.checked);
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
            <div style={{ width: "200px", marginTop: "50px" }}>
              <button
                style={{
                  background: "rgba(245,194,64,1)",
                  borderRadius: "5px",
                }}
                onClick={() => calRoundedPoint()}
              >
                คำนวนคะแนนงวดนี้
              </button>
            </div>
          </Flex>
        </div>
      </ContentContainer>

      <br />

      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H3 color="white" weight={600}>
            ตั้งค่าแสดงปุ่ม
          </Label.H3>
          <Flex wrap="wrap">
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขชุด 2 ใบ
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.two}
                    onClick={() => {
                      updateChange("showButton.two", !configs.showButton.two);
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขชุด 3 ใบ
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.three}
                    onClick={() => {
                      updateChange(
                        "showButton.three",
                        !configs.showButton.three
                      );
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขชุด 4 ใบ
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.four}
                    onClick={() => {
                      updateChange("showButton.four", !configs.showButton.four);
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขชุด 5 ใบ
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.five}
                    onClick={() => {
                      updateChange("showButton.five", !configs.showButton.five);
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขชุดใหญ่
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.bigNumber}
                    onClick={() => {
                      updateChange(
                        "showButton.bigNumber",
                        !configs.showButton.bigNumber
                      );
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
          </Flex>

          <Flex wrap="wrap">
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขสุ่ม
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.random}
                    onClick={() => {
                      updateChange(
                        "showButton.random",
                        !configs.showButton.random
                      );
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขหน้าสวย
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.prettyFronts}
                    onClick={() => {
                      updateChange(
                        "showButton.prettyFronts",
                        !configs.showButton.prettyFronts
                      );
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขเซียนดัง
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.specialNumber}
                    onClick={() => {
                      updateChange(
                        "showButton.specialNumber",
                        !configs.showButton.specialNumber
                      );
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
            <div style={{ width: "200px", marginTop: "50px" }}>
              <Label.H4 color="white" weight={600}>
                ปุ่มเลขบวงสรวง
              </Label.H4>
              <h3 className="my-0">
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={configs.showButton.magicNumber}
                    onClick={() => {
                      updateChange(
                        "showButton.magicNumber",
                        !configs.showButton.magicNumber
                      );
                    }}
                  />
                </FormGroup>
              </h3>
            </div>
          </Flex>
        </div>
      </ContentContainer>
      <br />
      <ContentContainer className="flex blue-bg">
        <div>
          <Label.H3 color="white" weight={600}>
            ช่องทางรับเงิน
          </Label.H3>
          <Flex direction="column" wrap="wrap">
            <Flex direction="column" margin="50px 0 0 0">
              <Label.H4 color="white" weight={600}>
                ช่องทางการรับเงินเริ่มต้น
              </Label.H4>
              <Flex align="center" gap="20px">
                <Label.H4 color="white" weight={600}>
                  QR Code
                </Label.H4>
                <h3 className="my-0">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      id="bank"
                      checked={configs?.payment?.defaultPayment === "bank"}
                      onClick={(e) => {
                        updateChange(
                          "payment.defaultPayment",
                          configs?.payment?.defaultPayment === "bank"
                            ? "qr"
                            : "bank"
                        );
                      }}
                    />
                  </FormGroup>
                </h3>
                <Label.H4 color="white" weight={600}>
                  โอนเงิน
                </Label.H4>
              </Flex>
            </Flex>
            <Flex direction="column" margin="50px 0 0 0">
              <Label.H4 color="white" weight={600}>
                บัญชีธนาคาร QR code
              </Label.H4>
              <Flex align="center" gap="20px">
                <Label.H4 color="white" weight={600}>
                  CIMB
                </Label.H4>
                <h3 className="my-0">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      id="bank"
                      checked={configs?.payment?.qrChannel === "bbl"}
                      onClick={(e) => {
                        updateChange(
                          "payment.qrChannel",
                          configs?.payment?.qrChannel === "bbl" ? "cimb" : "bbl"
                        );
                      }}
                    />
                  </FormGroup>
                </h3>
                <Label.H4 color="white" weight={600}>
                  BBL
                </Label.H4>
              </Flex>
            </Flex>
          </Flex>
        </div>
      </ContentContainer>
      <br />
      {/* <div
        style={{ width: "100%", display: "inline-block", verticalAlign: "top" }}
      >
        <HtDataManager data={dataLeft} />

        <div
          style={{
            margin: "20px 0",
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <div style={{ padding: "10px" }}>
            <div style={{ fontSize: "1.2rem" }}>
              <b>คะแนนแลกสินค้า</b>
            </div>
            <br />
            สินค้า{" "}
            <input
              value={currentItem}
              onChange={(e) => setCurrentItem(e.target.value)}
            />
            คะแนน{" "}
            <input
              style={{ width: "75px" }}
              type={"number"}
              value={currentPrice}
              onChange={(e) => setCurrentPrice(parseInt(e.target.value))}
            />
            <input
              type={"submit"}
              onClick={() =>
                setPremiums((old) => [
                  ...old,
                  { name: currentItem, price: currentPrice },
                ])
              }
              value={"เพิ่ม"}
            />
            {premiums?.map(({ name, price }) => (
              <div key={name} style={{ margin: "10px" }}>
                <button
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={() =>
                    setPremiums((old) =>
                      old.filter((premium) => premium.name !== name)
                    )
                  }
                >
                  ลบ
                </button>
                <span style={{ color: "red" }}> {price} </span>
                {name}
              </div>
            ))}
            <button
              onClick={() => {
                if (!window.confirm("ต้องการบันทึกรายการ ใช่หรือไม่")) return;
                callApi("configs-edit", {
                  key: "premiums",
                  value: premiums,
                  id: "general",
                }).then((result) => result.status && updateConfig());
              }}
            >
              บันทึก
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default General;
