import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import { flatten } from "ramda";

export const ReportUploader = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [downloadType, setDownloadType] = useState("admin");
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    const data = await callApi("lotteries-countUpload", {
      roundDate,
    });

    const sortedData = Object.entries(data.countImport).sort(
      ([key1, value1], [key2, value2]) => value2 - value1
    );

    setData(sortedData);
  };
  // const handleDownload = async () => {
  //   const date = thaiDate();
  //   const prepData = data;

  //   const csvHeads = Object.keys(data[0]);
  //   let listHead = ["ลำดับ", "เลข", "เล่ม", "โฟลเดอร์"];

  //   const result = prepData.reduce((acc, x, index) => {
  //     const createDate = new Date(x.createdAt);
  //     const shippingDate = new Date(x.shippingDate);
  //     let lottoList = [];

  //     return acc;
  //   }, []);

  //   setCsvData(result);
  //   setCsvFileName(`รายงานไปรษณีย์_${date}.csv`);
  // };

  return (
    <Container>
      <Label.H2 weight={500}>รายงาน Scanner</Label.H2>
      <br />

      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates
          .sort()
          .reverse()
          .map((roundDate) => (
            <option key={roundDate}>{roundDate}</option>
          ))}
      </Input>

      <br />

      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {/* {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div> */}
      <br />
      <br />

      {data.length !== 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>ชื่อ</th>
              <th>จำนวนเล่ม</th>
            </tr>
          </thead>
          <tbody>
            {data.map(([key, value], index) => {
              return (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <th>{key}</th>
                  <th>{value}</th>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const Button = styled.button``;
