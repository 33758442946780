import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import * as R from "ramda";
import { bankTranslater } from "../../util";

export const ReportRewardwinnerNew = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [state, setState] = useState([]);

  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    let res = await callApi("userPrizes-getAll", {
      limit: 10000,
      startAfter: "",
      queries: [["roundDate", "==", roundDate]],
      roundDate: roundDate,
    });

    setState(res);
  };

  const handleDownload = async () => {
    const date = thaiDate();

    const acc = [];

    state.forEach((prize) => {
      const contact = prize.notifyType === "LINE" ? prize.lineId : prize.phone;
      acc.push([
        bankList[prize.bankName],
        prize.accNo.split("-").join(""),
        prize.accName,
        prize.totalPrizes,
        prize.userId,
        prize.notifyType,
        contact,
      ]);
    });
    setCsvData(acc);
    setCsvFileName(`รายงานผู้ถูกรางวัล.csv`);
  };

  const bankList = {
    "": 0,
    ธนาคารกรุงเทพฯ: "002",
    ธนาคารไทยพาณิชย์: "014",
    ธนาคารกสิกรไทย: "004",
    ธนาคารกรุงศรีอยุธยา: "025",
    ธนาคารกรุงไทย: "006",
    ธนาคารธนชาต: "065",
    ธนาคารทิสโก้: "067",
    ธนาคารออมสิน: "030",
    ธนาคารอาคารสงเคราะห์: "033",
    ธนาคารอิสลามแห่งประเทศไทย: "066",
    ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร: "034",
    ธนาคารทหารไทยธนชาต: "011",
    ธนาคารซีไอเอ็มบีไทย: "022",
    ธนาคารยูโอบี: "024",
    "ธนาคารแลนด์ แอนด์ เฮ้าส์": "073",
    ธนาคารเกียรตินาคินภัทร: "069",
  };

  let total = 0;
  return (
    <Container>
      <Label.H2 weight={500}>รายงานผู้ถูกรางวัลทั้งหมด (ระบบทดสอบ)</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>

      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      <br />
      <p>จำนวน {state.length}</p>
      {!R.isEmpty(state) && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}

      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      {!R.isEmpty(state) && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ธนาคาร</th>
              <th>เลขบัญชี</th>
              <th>ชื่อบัญชี</th>
              <th>จำนวนเงิน</th>
              <th>เลขที่อ้างอิง</th>
              <th>แจ้งเตือนผ่าน</th>
              <th>เบอร์โทรศัพท์หรือ Line id</th>
            </tr>
          </thead>
          <tbody>
            {state.map((item, index) => {
              total += item.totalPrizes;
              return (
                <tr key={index}>
                  <th>{item.accNo && bankList[item.bankName]}</th>
                  <th>{item.accNo && item.accNo.split("-").join("")}</th>
                  <th>{item.accNo && item.accName}</th>
                  <th>{item.totalPrizes}</th>
                  <th>{item.userId}</th>
                  <th>{item.notifyType}</th>
                  <th>
                    {item.notifyType === "LINE" ? item.lineId : item.phone}
                  </th>
                </tr>
              );
            })}
            <tr>
              <th>รวม</th>
              <th></th>
              <th></th>
              <th></th>
              <th>{total.toLocaleString()}</th>
            </tr>
          </tbody>
        </Table>
      )}
      <br />
      <br />
    </Container>
  );
};

const Button = styled.button`
  margin: 2em 0;
`;
