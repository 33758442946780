import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";
import { Container } from "../../components";
import styled from "styled-components";

const OrdersOnline = () => {
  const { callApi, showModalItemDetail } = Context();
  const navigate = useNavigate();
  return (
    <Container>
      <HtTablePaging
        title="คำสั่งซื้อ ส่งไปรษณีย์"
        getData={(body) => callApi("orders-getAll", body)}
        baseQrs={[["deliverChannel", "==", "mail"]]}
        updateApi={async (body) => {
          return await callApi("orders-edit", {
            ...body,
            collectionName: "Orders",
          });
        }}
        columns={[
          "รายละเอียด",
          "shippingDate",
          "shippingStatus",
          "shortId",
          "status",
          "userNo",
          "firstName",
          "lastName",
          "phone",
          "address",
          "length",
          "lotteryIds",
        ]}
        hideColumns={[
          "slipId",
          "id",
          "userId",
          "working",
          "lotteries",
          "deliverChannel",
          "expireAt",
          "stockStatus",
        ]}
        sort={["createdAt"]}
        onCellClick={({ id, key }) => {
          key === "shortId" && showModalItemDetail({ orderId: id });
        }}
        dropdownFilter={{
          status: ["pending", "paid", "approved", "canceled"],
          working: ["Yes", "No"],
          deliverChannel: ["online", "mail", "offline"],
          paymentChannel: ["qr", "bank", "offline"],
          shippingStatus: Object.values(constants.shippingStatuses),
        }}
        dateFilter={["shippingDate"]}
        dropdownAble={{
          changeExpire: [0.2, 0.5, 1, 2, 4, 6, 12, 24],
        }}
        downloadable={true}
        translations={{
          // changeExpire: "เปลี่ยนเวลาหมดอายุ",
          shippingStatus: "สถานะจัดส่ง",
          shippingDate: "วันจัดส่ง",
          shortId: "รหัสย่อ",
          status: "สถานะ",
          // userId: "รหัสสมาชิก",
          userNo: "เลขสมาชิก",
          slips: "สลิป",
          deliverChannel: "การจัดส่ง",
          paymentChannel: "การจ่ายเงิน",
          totalPrice: "ราคารวม",
          expireAt: "หมดอายุเมื่อ",
          phone: "เบอร์โทร",
          firstName: "ชื่อ",
          lastName: "นามสกุล",
          address: "ที่อยู่",
          length: "จำนวนใบ",
          lotteryIds: "เลขที่ซื้อ",
          createdAt: "เวลาที่ซื้อ",
        }}
        overrides={{
          lotteryIds: (props) => {
            return (
              <ScrollBar>
                {props.row.lotteries?.map((lotto, index) => (
                  <h6 style={{ fontWeight: 600 }}>
                    {lotto.id +
                      "-" +
                      (lotto.bookNo
                        ? lotto.bookNo
                        : lotto.fileNameId
                        ? lotto.fileNameId
                        : "ไม่พบเล่ม")}
                  </h6>
                ))}{" "}
              </ScrollBar>
            );
          },

          length: (props) => {
            return (
              <h6 style={{ fontWeight: 600 }}>
                {props.row.lotteryIds?.length || "-"}
              </h6>
            );
          },
          address: (props) => {
            return (
              <div>
                <h6>{props.row.address?.addr && props.row.address.addr}</h6>
                <h6>
                  {props.row.address?.province && props.row.address.province}
                </h6>
                <h6>
                  {props.row.address?.zipcode && props.row.address.zipcode}
                </h6>
              </div>
            );
          },
          status: (props) => {
            if (props.row.status === "pending") {
              return (
                <h4
                  style={{
                    fontWeight: 500,
                    background: "#e8b028",
                    color: "black",
                  }}
                >
                  PENDING
                </h4>
              );
            } else if (props.row.status === "paid") {
              return (
                <h4
                  style={{
                    fontWeight: 500,
                    background: "#45b6fe",
                    color: "black",
                  }}
                >
                  PAID
                </h4>
              );
            } else if (props.row.status === "approved") {
              return (
                <h4
                  style={{
                    fontWeight: 500,
                    background: "#30ac13",
                    color: "white",
                  }}
                >
                  APPROVED
                </h4>
              );
            } else if (props.row.status === "canceled") {
              return (
                <h4
                  style={{
                    fontWeight: 500,
                    background: "#bd1816",
                    color: "white",
                  }}
                >
                  CANCELED
                </h4>
              );
            }
          },

          paymentChannel: (props) =>
            props.row.paymentChannel && (
              <img
                src={
                  props.row.paymentChannel === "bank"
                    ? "/images/banktransfer.png"
                    : props.row.paymentChannel === "qr"
                    ? "/images/qr.png"
                    : ""
                }
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "160px",
                  maxHeight: "80px",
                }}
                alt={props.row.paymentChannel}
              />
            ),
          รายละเอียด: (props) => (
            <button
              onClick={async () => {
                window.open("/orders/working/" + props.row["id"], "_blank");
              }}
            >
              เปิด
            </button>
          ),
          lotteries: ({ row, key }) => {
            return row["lotteries"].map((lottery) => (
              <div key={lottery["id"]}>{`${lottery["id"]}-(${String(
                lottery["price"]
              ).padStart(3, "_")})`}</div>
            ));
          },
        }}
      />
    </Container>
  );
};

export const ScrollBar = styled.div`
  max-height: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
export default OrdersOnline;
