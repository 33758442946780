import React from "react";
import Context from "../../Context";
import { cn } from "../../util/lib";

export const Container = ({ children, className, ...props }) => {
  const { themes } = Context();

  return (
    <div className={cn(themes.container, className)} {...props}>
      {children}
    </div>
  );
};
