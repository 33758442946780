import { useState, useEffect } from "react";
import { Loading } from "../../components/modules";
import { collection, doc, onSnapshot } from "firebase/firestore";
import {
  Container,
  Input,
  Text,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "../../components/base";
import {
  ScrollArea,
  Avatar,
  AvatarImage,
  AvatarFallback,
  Separator,
  Toaster,
  useToast,
  ToastAction,
  Skeleton,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui";
import { CgProfile } from "react-icons/cg";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { FiUserPlus } from "react-icons/fi";
import { PiBankBold } from "react-icons/pi";
import { BsQrCode } from "react-icons/bs";
import Context from "../../Context";
import { MdPersonSearch } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { IoMdCalendar } from "react-icons/io";
import {
  FaCompress,
  FaExpand,
  FaTimes,
  FaCheckCircle,
  FaHourglassHalf,
  FaTimesCircle,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";
import { banks } from "../../constants";
import { FiShare2 } from "react-icons/fi";

import { useNavigate, useLocation } from "react-router-dom";

export const Orders = () => {
  const { callApi, isDarkTheme, themes, configs, db } = Context();
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [orders, setOrders] = useState(false);
  const [selectedRoundDate, setSelectedRoundDate] = useState(configs.roundDate);
  const [orderSummary, setOrderSummary] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(
    localStorage.getItem("selectedOrder") || false
  );
  const [query, setQuery] = useState(
    search.length > 0
      ? { key: "shortId", value: search.slice(1) }
      : { key: "", value: "" }
  );

  useEffect(() => {
    fetchUsers();
    getOrderSummary(false);
    getOrderSummary("approved");
    getOrderSummary("paid");
    getOrderSummary("pending");
    getOrderSummary("canceled");
  }, [selectedRoundDate]);

  const fetchUsers = async () => {
    const queries = query.key && query.value ? [[query.key, query.value]] : [];
    setOrders([]);
    const result = await callApi("orders-getAll", {
      queries: queries,
      roundDate: selectedRoundDate,
      limit: 50,
      orderBy: ["createdAt", "desc"],
    });
    setOrders(result.length > 0 ? result : false);
    if (result.length === 1) {
      setSelectedOrder(result[0].id);
    }
  };

  const getOrderSummary = async (status) => {
    setOrderSummary((prevState) => ({
      ...prevState,
      [status || "all"]: false,
    }));
    const { result } = await callApi("orders-getOrderSummary", {
      roundDate: selectedRoundDate,
      status: status,
    });

    setOrderSummary((prevState) => ({
      ...prevState,
      [status || "all"]: result,
    }));
  };

  const handleSearch = () => {
    if (!query.value) {
      setQuery((prevState) => ({ ...prevState, key: false }));
    } else {
      if (new RegExp(/^\d+$|^$/).test(query.value)) {
        if (query.value.length >= 6) {
          setQuery((prevState) => ({ ...prevState, key: "phone" }));
        } else {
          setQuery((prevState) => ({ ...prevState, key: "userId" }));
        }
      } else {
        setQuery((prevState) => ({ ...prevState, key: "firstName" }));
      }
    }

    fetchUsers();
  };

  return (
    <>
      <div className="grid grid-cols-2 xl:grid-cols-5 gap-3 mb-3">
        <div className="py-2 px-3 w-full col-span-2 xl:col-span-1 bg-gray-950 text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-150">
          <p className="text-sm font-medium">ทั้งหมด</p>
          <p className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary || !orderSummary?.all ? (
              <Loading />
            ) : (
              orderSummary?.all?.toLocaleString()
            )}
          </p>
        </div>
        <div className="py-2 px-3 w-full bg-[rgb(59,176,101)] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%]  duration-150">
          <p className="text-sm font-medium">อนุมัติ</p>
          <p className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary || !orderSummary?.approved ? (
              <Loading />
            ) : (
              orderSummary?.approved?.toLocaleString()
            )}
          </p>
        </div>
        <div className="py-2 px-3 w-full bg-[#37beb0] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-150">
          <p className="text-sm font-medium">จ่ายแล้ว</p>
          <p className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary || !orderSummary?.paid ? (
              <Loading />
            ) : (
              orderSummary?.paid?.toLocaleString()
            )}
          </p>
        </div>
        <div className="py-2 px-3 w-full bg-[#ffb067] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-150">
          <p className="text-sm font-medium">รอจ่าย</p>
          <p className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary || !orderSummary?.pending ? (
              <Loading />
            ) : (
              orderSummary?.pending?.toLocaleString()
            )}
          </p>
        </div>
        <div className="py-2 px-3 w-full bg-[#c15b78] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-150">
          <p className="text-sm font-medium">ยกเลิก</p>
          <p className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary || !orderSummary?.canceled ? (
              <Loading />
            ) : (
              orderSummary?.canceled?.toLocaleString()
            )}
          </p>
        </div>
      </div>

      <Container
        className={` p-1 w-full h-fit shadow-md ${
          selectedOrder && "xl:w-[300px]"
        }`}
      >
        {/* <Container className="h-[800px] w-full max-w-[300px] rounded-[20px] shadow-xl"> */}
        <div className="flex items-center gap-2.5 m-2">
          <div className={`relative z-10 flex-grow`}>
            <MdPersonSearch
              className={`absolute w-6 h-6 top-3 right-5 cursor-pointer duration-100 ${
                isDarkTheme ? "text-white" : " hover:text-[rgb(214,212,212)]"
              } ${query.value ? "text-[rgb(214,212,212)]" : "text-input"}`}
              onClick={() => handleSearch()}
            />

            {query?.value && (
              <FaTimes
                className={`absolute w-6 h-6 top-3 right-14 cursor-pointer duration-100 ${
                  isDarkTheme
                    ? "text-white"
                    : "text-[rgb(214,212,212)] hover:text-red-200"
                } `}
                onClick={() => setQuery({ key: "", value: "" })}
              />
            )}
            <Input
              className="rounded-2xl"
              placeholder="ค้นหา"
              value={query.value || ""}
              onChange={(e) =>
                setQuery({
                  key: new RegExp(/^\d+$|^$/).test(query.value)
                    ? query.value.length >= 6
                      ? "phone"
                      : "userId"
                    : "firstName",
                  value: e.target.value,
                })
              }
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
          </div>

          <Select
            defaultValue={selectedRoundDate}
            onValueChange={(e) => {
              return setSelectedRoundDate(e);
            }}
          >
            <SelectTrigger className="my-1 w-44 text-md font-medium rounded-2xl">
              {/* <button
              className={`flex items-center p-3 font-bold justify-center h-12 rounded-2xl duration-100 cursor-pointer ${
                isDarkTheme
                  ? "text-[rgb(26,29,32)] bg-white "
                  : "bg-[rgb(26,29,32)] text-gray-50"
              } hover:opacity-90`}
            >
              <IoMdCalendar />
            </button> */}
              <SelectValue className="" placeholder={configs.roundDate} />
            </SelectTrigger>
            <SelectContent className="bg-black rounded-2xl">
              {configs.roundDates.map((roundDate) => {
                return (
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value={roundDate}
                    key={roundDate}
                  >
                    {roundDate}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>

          {/* <div
          className={`flex items-center p-3 font-bold justify-center h-12 rounded-2xl duration-100 cursor-pointer ${
            isDarkTheme
              ? "text-[rgb(26,29,32)] bg-white "
              : "bg-[rgb(26,29,32)] text-gray-50"
          } hover:opacity-90`}
        >
          <FiUserPlus />
        </div> */}
        </div>

        {!orders ? (
          <div className="flex justify-center items-center h-72">
            ไม่พบสิ่งที่ค้นหา
          </div>
        ) : orders.length > 0 ? (
          <ScrollArea
            className={`relative ${
              orders.length > 6
                ? `${selectedOrder && "h-[300px]"} h-[50vh]`
                : "h-fit"
            } px-2 py-0 my-2`}
          >
            {selectedOrder ? (
              <div className="grid grid-cols-3 w-full gap-3 items-center p-3 rounded-xl">
                <p className="font-medium text-sm">คำสั่งซ์้อ</p>

                <p className="font-medium text-center text-sm">การชำระ</p>

                <p className="font-medium text-center text-sm">สถานะ</p>
              </div>
            ) : (
              <div className="grid grid-cols-3 md:grid-cols-6 xl:grid-cols-12 w-full gap-3 items-center p-3 rounded-xl">
                <p className="font-medium text-sm">คำสั่งซื้อ</p>
                <div className="col-span-2 text-sm text-center hidden md:inline-block">
                  วันที่ซื้อ
                </div>
                <p className="font-medium text-center text-sm hidden xl:inline-block">
                  ไอดี
                </p>
                <p className="font-medium text-center text-sm hidden xl:inline-block">
                  ชื่อ
                </p>
                <p className="font-medium text-center text-sm hidden xl:inline-block">
                  นามสกุล
                </p>
                <p className="font-medium text-center text-sm hidden xl:inline-block">
                  โทรศัพท์
                </p>
                <p className="font-medium text-center text-sm hidden xl:inline-block">
                  จำนวน
                </p>
                <p className="font-medium text-center text-sm hidden xl:inline-block">
                  ราคา
                </p>
                <p className="font-medium text-center text-sm">การชำระ</p>
                <p className="font-medium text-center text-sm hidden md:inline-block">
                  สลิป
                </p>
                <p className="font-medium text-center text-sm">สถานะ</p>
              </div>
            )}
            {orders.map((order, index) => {
              return (
                <OrderList
                  orderData={order}
                  isDarkTheme={isDarkTheme}
                  key={order.shortId}
                  setSelectedOrder={setSelectedOrder}
                  isSelectedOrder={selectedOrder === order.id}
                  selectedOrder={selectedOrder}
                  isNotLast={index !== orders.length - 1}
                />
              );
            })}
          </ScrollArea>
        ) : (
          <div className="flex justify-center items-center h-72">
            <Loading />
          </div>
        )}
        {/* </Container> */}
      </Container>
    </>
  );
};

const OrderList = ({
  orderData,
  isDarkTheme,
  setSelectedOrder,
  isSelectedOrder,
  selectedOrder,
  isNotLast,
}) => {
  const handleSelectUser = (id) => {
    if (isSelectedOrder) {
      setSelectedOrder(false);
    } else {
      localStorage.setItem("selectedOrder", orderData.id);
      setSelectedOrder(orderData.id);
    }
  };

  return (
    <div>
      {selectedOrder ? (
        <div
          className={`grid grid-cols-3 w-full gap-1 items-center p-3 rounded-xl duration-100 cursor-pointer ${
            isSelectedOrder
              ? isDarkTheme
                ? "text-[rgb(26,29,32)] bg-white "
                : "bg-[rgb(26,29,32)] text-gray-50 "
              : isDarkTheme
              ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
              : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
          }`}
          onClick={() => {
            handleSelectUser();
          }}
        >
          <div className="">
            <p className={`text-sm font-semibold truncate`}>
              {orderData?.shortId}
            </p>
          </div>

          <div className=" flex justify-center">
            <div className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.paymentChannel === "bank" ? (
                <div className="flex flex-col items-center">
                  <PiBankBold className="text-[25px]" />
                  <p className="text-[10px]">โอนเงิน</p>
                </div>
              ) : orderData?.paymentChannel === "qr" ? (
                <div className="flex flex-col items-center">
                  <BsQrCode className="text-[25px]" />
                  <p className="text-[10px]">QR PAYMENT</p>
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <div className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData.status === "approved" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaCheckCircle className="text-lg" />
                  <p className="text-[8px] font-light">อนุมัติ</p>
                </div>
              )}
              {orderData.status === "pending" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaHourglassHalf className="text-lg" />
                  <p className="text-[8px] font-light">รอดำเนินการ</p>
                </div>
              )}
              {orderData.status === "canceled" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaTimesCircle className="text-lg" />
                  <p className="text-[8px] font-light">ยกเลิก</p>
                </div>
              )}
              {orderData.status === "paid" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaMoneyBillAlt className="text-lg" />
                  <p className="text-[8px] font-light">จ่ายเงินแล้ว</p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`grid grid-cols-3 md:grid-cols-6 xl:grid-cols-12 w-full gap-3 items-center p-3 rounded-xl duration-100 cursor-pointer ${
            isSelectedOrder
              ? isDarkTheme
                ? "text-[rgb(26,29,32)] bg-white "
                : "bg-[rgb(26,29,32)] text-gray-50 "
              : isDarkTheme
              ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
              : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
          }`}
          onClick={() => {
            handleSelectUser();
          }}
        >
          <div className="">
            <p className={`text-sm font-semibold xl:max-w-48 truncate`}>
              {orderData?.shortId}
            </p>
          </div>
          <div className="justify-center col-span-2 hidden md:flex">
            <p className={`text-xs font-semibold truncate`}>
              {new Date(orderData.createdAt).toLocaleDateString("th-TH", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-semibold xl:max-w-48 truncate`}>
              {orderData?.userId}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-semibold xl:max-w-48 truncate`}>
              {orderData?.firstName}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.lastName}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block ">
            <p className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.phone}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.size}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.totalPrice?.toLocaleString()}
            </p>
          </div>
          <div className=" flex justify-center">
            <div className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.paymentChannel === "bank" ? (
                <div className="flex flex-col items-center">
                  <PiBankBold className="text-[25px]" />
                  <p className="text-[10px]">โอนเงิน</p>
                </div>
              ) : orderData?.paymentChannel === "qr" ? (
                <div className="flex flex-col items-center">
                  <BsQrCode className="text-[25px]" />
                  <p className="text-[10px]">QR PAYMENT</p>
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="justify-center hidden md:flex">
            {orderData?.slips ? (
              <img
                className="h-10 hover:-translate-y-1/2 hover:absolute hover:h-96 hover:z-10"
                src={orderData?.slips}
                alt="slips"
              />
            ) : (
              "-"
            )}
          </div>
          <div className="flex justify-center">
            <p className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData.status === "approved" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaCheckCircle className="text-lg" />
                  <p className="text-[8px] font-light">อนุมัติ</p>
                </div>
              )}
              {orderData.status === "pending" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaHourglassHalf className="text-lg" />
                  <p className="text-[8px] font-light">รอดำเนินการ</p>
                </div>
              )}
              {orderData.status === "canceled" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaTimesCircle className="text-lg" />
                  <p className="text-[8px] font-light">ยกเลิก</p>
                </div>
              )}
              {orderData.status === "paid" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaMoneyBillAlt className="text-lg" />
                  <p className="text-[8px] font-light">จ่ายเงินแล้ว</p>
                </div>
              )}
            </p>
          </div>
        </div>
      )}
      {isNotLast && <Separator className="my-2" />}
    </div>
  );
};
