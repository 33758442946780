import React, { useState, useEffect } from "react";
import Context from "../../Context";
import styled from "styled-components";
import themes from "../../themes";
import { Container, Label, Flex } from "../../components";
import {
  dateToUnix,
  getThaiDateAndTimeFormat,
  unixToDateAndTime,
} from "../../util";
import { RiCoupon2Fill } from "react-icons/ri";
import { FiImage } from "react-icons/fi";
import {
  Modal,
  ModalBody,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Badge,
  FormGroup,
} from "reactstrap";
import { ContentContainer } from "../orders/WorkingOrder";
import { resizeImage } from "../../util";
import constant from "../../constants";

export const ManageCoupon = () => {
  const { callApi, updateConfig, configs } = Context();
  const [modal, setModal] = useState({
    addCoupon: false,
    createTransaction: false,
  });
  const [allProducts, setAllProducts] = useState([]);
  const [edit, setEdit] = useState();
  const [coupon, setCoupon] = useState();
  const [phone, setPhone] = useState("");
  const [successCreateCoupon, setSuccessCreateCoupon] = useState("");

  const toggleAdd = () => {
    setEdit(false);
    setModal((prevState) => ({ ...prevState, addCoupon: !modal.addCoupon }));
  };

  const toggleCreate = () => {
    setEdit({});
    setModal((prevState) => ({
      ...prevState,
      createTransaction: !modal.createTransaction,
    }));
    setPhone("");
    setCoupon("");
    setSuccessCreateCoupon("");
  };

  useEffect(() => {
    fetchCoupon();
  }, []);

  const fetchCoupon = async () => {
    const result = await callApi("coupons-getAllCoupons");
    setAllProducts(result);
  };

  const handleCreateTransaction = async (couponId) => {
    const result = await callApi("coupons-createTransactionToUserByAdmin", {
      phone,
      id: couponId,
    });
    setSuccessCreateCoupon(result);
  };

  const handleEditClick = (id) => {
    setEdit(allProducts.find((c) => c.id === id));
    setModal((prevState) => ({ ...prevState, addCoupon: !modal.addCoupon }));
  };

  const handleCreateTransactionModal = (id) => {
    setCoupon(allProducts.find((c) => c.id === id));
    setModal((prevState) => ({
      ...prevState,
      createTransaction: !modal.createTransaction,
    }));
  };

  const updateChange = async (key, value) => {
    if (!window.confirm("ท่านต้องการแก้ไขใช่หรือไม่")) return;
    await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });
    updateConfig();
  };
  return (
    <ContentContainer className=" blue-bg">
      <AddCouponModal
        modal={modal.addCoupon}
        toggle={toggleAdd}
        callApi={callApi}
        editData={edit}
      />
      <CreateTransactionModal
        modal={modal.createTransaction}
        toggle={toggleCreate}
        phone={phone}
        setPhone={setPhone}
        coupon={coupon}
        handleCreateTransaction={handleCreateTransaction}
        successCreateCoupon={successCreateCoupon}
      />
      <Label.H1 color="white" weight={600}>
        การจัดการคูปอง
      </Label.H1>
      <br />
      <div style={{ width: "200px", marginTop: "50px" }}>
        <Label.H4 color="white" weight={600}>
          เปิดขายคูปอง
        </Label.H4>
        <h3 className="my-0">
          <FormGroup switch>
            <Input
              type="switch"
              id="isOpen.coupon"
              checked={configs.isOpen.coupon}
              onClick={(e) => {
                updateChange(e.target.id, e.target.checked);
              }}
            />
          </FormGroup>
        </h3>
      </div>

      <div
        style={{
          width: "200px",
          marginTop: "50px",
          paddingRight: "20px",
        }}
      >
        <FormGroup>
          <Label.H4 color="white" weight={600}>
            งวด
          </Label.H4>
          <Input
            id="couponRoundDate"
            name="couponRoundDate"
            type="date"
            defaultValue={configs.couponRoundDate + 7 * 3600000}
            onBlur={(e) => updateChange(e.target.id, e.target.value)}
          />
        </FormGroup>
      </div>
      <div
        style={{
          width: "200px",
          marginTop: "50px",
          paddingRight: "20px",
        }}
      >
        <FormGroup>
          <Label.H4 color="white" weight={600}>
            วัน-เวลาเปิดขาย
          </Label.H4>

          <Input
            id="couponRoundDateTime"
            name="couponRoundDateTime"
            type="datetime-local"
            defaultValue={unixToDateAndTime(
              configs.couponRoundDateTime || 0 + 7 * 3600000
            )}
            onBlur={(e) =>
              updateChange(e.target.id, dateToUnix(e.target.value))
            }
          />
        </FormGroup>
      </div>
      <br />
      <Flex align="stretch" margin="2em 0 1em 0" gap="2em" wrap="wrap">
        <AddCoupon toggle={toggleAdd} />
        {allProducts &&
          allProducts
            .filter(
              (coup) =>
                coup.status === constant.statuses.ACTIVE ||
                constant.statuses.OFFLINE
            )
            .map((coupon) => {
              return (
                <Coupon
                  data={coupon}
                  handleEditClick={handleEditClick}
                  key={coupon.id}
                  handleCreateTransactionModal={handleCreateTransactionModal}
                />
              );
            })}
      </Flex>
    </ContentContainer>
  );
};

const Coupon = ({ data, handleEditClick, handleCreateTransactionModal }) => {
  return (
    <Card style={{ minWidth: "200px", minHeight: "200px" }}>
      <CardBody
        style={{
          color: "black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <CardTitle tag="h5" color="primary">
            {data.name}
          </CardTitle>
          <CardSubtitle className="text-muted" tag="h6">
            <Badge color={data.status !== "active" ? "warning" : "success"}>
              {constant.statusesTranslate[data.status]}
            </Badge>
          </CardSubtitle>
          <br />
          <CardText>
            ประเภทคูปอง: {constant.couponTypeTranslated[data.type]}
          </CardText>
          <CardText>
            วิธีชำระ: {data.isPointRedeem ? "แลกคะแนน" : "เงินโอน"}
          </CardText>
          <CardText>
            ราคาขายใบละ: {data.price} {data.isPointRedeem ? "คะแนน" : "บาท"}
          </CardText>
          <CardText>
            หมดอายุ: {getThaiDateAndTimeFormat(new Date(data.expireAt))}
          </CardText>
        </div>
        <br />
        <Button color="danger" onClick={() => handleEditClick(data.id)}>
          แก้ไข
        </Button>
        <br />
        <Button
          color="success"
          onClick={() => handleCreateTransactionModal(data.id)}
        >
          สร้างคำสั่งซื้อ
        </Button>
      </CardBody>
    </Card>
  );
};

const AddCoupon = ({ toggle }) => {
  return (
    <DotWrapper onClick={toggle}>
      <h1>
        + <RiCoupon2Fill />
      </h1>
      <br />
      <Label.H3 weight={600} color={themes.colors.blue}>
        เพิ่มสินค้า
      </Label.H3>
    </DotWrapper>
  );
};
const CreateTransactionModal = ({
  modal,
  toggle,
  phone,
  setPhone,
  coupon,
  handleCreateTransaction,
  successCreateCoupon,
}) => {
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      {successCreateCoupon ? (
        <ModalBody
          style={{
            background: themes.colors.secondaryGradient2,
          }}
        >
          <Label.H1 color={themes.colors.white} weight={600}>
            สร้างคำสั่งซื้อคูปองสำเร็จ
          </Label.H1>
          <br />
          <Label.H4 color={themes.colors.white} weight={600}>
            ข้อมูลคูปอง: {coupon.name}
          </Label.H4>
          <Label.H4 color={themes.colors.white} weight={600}>
            คำสั่งซื้อหมายเลข: {successCreateCoupon.orderId}
          </Label.H4>
          <br />
          <Label.H4 color={themes.colors.white} weight={600}>
            ชื่อ-นามสกุล: {successCreateCoupon.userData.firstName}{" "}
            {successCreateCoupon.userData.lastName}
          </Label.H4>
          <Label.H4 color={themes.colors.white} weight={600}>
            เบอร์โทรศัพท์: {successCreateCoupon.userData.phone}
          </Label.H4>

          <br />
          <Flex>
            <button
              style={{
                border: "1px solid rgba(245,194,64,1)",
                backgroundColor: "rgba(245,194,64,1)",
                borderRadius: "5px",
                padding: "5px 10px",
                margin: "0 auto",
              }}
              onClick={() => {
                toggle();
              }}
            >
              ปิด
            </button>
          </Flex>
        </ModalBody>
      ) : (
        <ModalBody
          style={{
            background: themes.colors.secondaryGradient2,
          }}
        >
          <Label.H1 color={themes.colors.white} weight={600}>
            สร้างคำสั่งซื้อ คูปอง {coupon?.name}
          </Label.H1>
          <br />

          <div
            style={{
              margin: "30px 0 0 0",
            }}
          >
            <Label.H4 color="white" weight={600}>
              เบอร์โทรศัพท์
            </Label.H4>
            <Flex className="my-3" direction="column">
              <div>
                <Input
                  type="number"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </Flex>
          </div>

          <br />
          <Flex>
            <button
              style={{
                border: "1px solid rgba(245,194,64,1)",
                backgroundColor: "rgba(245,194,64,1)",
                borderRadius: "5px",
                padding: "5px 10px",
                margin: "0 auto",
              }}
              onClick={() => {
                handleCreateTransaction(coupon.id);
              }}
            >
              สร้างคำสั่งซื้อคูปอง
            </button>
          </Flex>
        </ModalBody>
      )}
    </Modal>
  );
};

const AddCouponModal = ({ modal, toggle, callApi, editData }) => {
  const [body, setBody] = useState({
    image: undefined,
    status: "active",
    price: 0,
    isPointRedeem: false,
    type: "",
    discount: 0,
    quantity: 1,
    minimum: 0,
    expireAtDate: Date.now(),
  });
  const [previewImage, setPreviewImage] = useState(editData?.image || "");

  useEffect(() => {
    if (editData) {
      setPreviewImage(editData.image);
      setBody({
        image: editData?.image,
        id: editData?.id,
        status: editData?.status,
        price: editData?.price || 0,
        reserveType: editData?.reserveType,
        limitPerUsed: editData?.limitPerUsed,
        isPointRedeem: editData?.isPointRedeem || false,
        type: editData?.type || "",
        discount: editData?.discount || 0,
        quantity: editData?.quantity || 1,
        minimum: editData?.minimum || 0,
        expireAtDate: editData?.expireAtDate,
        expireAt: editData?.expireAt,
      });
    }
  }, [modal]);

  const handleCreateCoupon = async () => {
    if (editData) {
      const result = await callApi("coupons-editCoupon", body);
      if (result) return window.location.reload();
    } else {
      const result = await callApi("coupons-createCoupon", body);
      if (result) return window.location.reload();
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalBody
        style={{
          background: themes.colors.secondaryGradient2,
        }}
      >
        <Label.H1 color={themes.colors.white} weight={600}>
          {editData ? "แก้ไขคูปอง" : "สร้างคูปองใหม่"}
        </Label.H1>
        <br />
        {previewImage ? (
          <Flex direction="column" align="center">
            <br />
            <img src={previewImage} width="200" alt="preview" />
            <br />
            <button
              style={{
                border: "1px solid red",
                backgroundColor: "red",
                borderRadius: "5px",
                padding: "5px 10px",
                width: "70px",
              }}
              onClick={() => {
                setPreviewImage("");
                setBody((prevState) => ({ ...prevState, image: undefined }));
              }}
            >
              แก้ไข
            </button>
          </Flex>
        ) : (
          <Flex direction="column" align="center">
            <label htmlFor="files-slips">
              <UploadBtn>
                <h1>
                  + <FiImage />
                </h1>
                <br />
                <Label.H4 weight={600}>อัปโหลดรูปภาพ</Label.H4>
              </UploadBtn>
            </label>
          </Flex>
        )}

        <input
          style={{
            marginBottom: "15px",
            visibility: "hidden",
            position: "absolute",
            display: "none",
          }}
          id="files-slips"
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];
            const base64 = await resizeImage(file);
            if (base64) {
              const previewUrl = URL.createObjectURL(file);
              setPreviewImage(previewUrl);
              setBody((prevState) => ({ ...prevState, image: base64 }));
            } else {
              alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
            }
          }}
        />
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            สถานะ
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="select"
                name="status"
                id="status"
                value={body.status}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }));
                }}
              >
                <option value="active">ปกติ</option>
                <option value="hold">จะเปิดใช้งานเร็วๆนี้</option>
                <option value="offline">เปิดใช้งานหลังบ้านเท่านั้น</option>
                <option value="inactive">ปิดใช้งานชั่วคราว</option>
                <option value="removed">ลบคูปองถาวร</option>
              </Input>
            </div>
          </Flex>
        </div>
        {/* <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            ชื่อคูปอง
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="ชื่อคูปอง"
                defaultValue={body.name}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
          </Flex>
        </div> */}
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            ประเภทคูปอง
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="select"
                name="type"
                id="type"
                value={body.type}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    type: e.target.value,
                  }));
                }}
              >
                <option value="">โปรดเลือก</option>
                <option value="discount">ส่วนลด</option>
                <option value="voucher">วอยเชอร์</option>
                <option value="reserve">จองเลขท้าย</option>
                <option value="reserve100">จอง 100 ใบ (1 เล่ม)</option>
              </Input>
            </div>
          </Flex>
        </div>
        {body.type === "reserve" && (
          <div
            style={{
              margin: "30px 0 0 0",
            }}
          >
            <Label.H4 color="white" weight={600}>
              ประเภทการจองสิทธิ์
            </Label.H4>
            <Flex className="my-3" direction="column">
              <div>
                <Input
                  type="select"
                  name="reserveType"
                  id="reserveType"
                  value={body.reserveType}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      reserveType: e.target.value,
                    }));
                  }}
                >
                  <option value="">โปรดเลือก</option>
                  <option value="lastTwo">เลขท้าย 2 ตัว</option>
                  <option value="lastThree">เลขท้าย 3 ตัว</option>
                </Input>
              </div>
            </Flex>
          </div>
        )}
        <div style={{ margin: "35px 0 0 0" }}>
          <Label.H4 color="white" weight={600}>
            รูปแบบการชำระ
          </Label.H4>
          <Flex gap="10px" align="center">
            <Label.H4 color="white" weight={600}>
              เงินโอน
            </Label.H4>
            <FormGroup switch>
              <Input
                type="switch"
                checked={body.isPointRedeem}
                onClick={() => {
                  setBody((prevState) => ({
                    ...prevState,
                    isPointRedeem: !prevState.isPointRedeem,
                  }));
                }}
              />
            </FormGroup>
            <Label.H4 color="white" weight={600}>
              แลกคะแนน
            </Label.H4>
          </Flex>
        </div>
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            ราคาขายคูปอง {body?.isPointRedeem ? "(คะแนน)" : "(เงินโอน)"}
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="number"
                name="price"
                id="price"
                defaultValue={body.price}
                value={body.price || null}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    price: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>
        </div>
        {/* <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            จำนวนคูปองต่อ 1 ชุด
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="number"
                name="quantity"
                id="quantity"
                defaultValue={body.quantity}
                value={body.quantity || null}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    quantity: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>
        </div> */}

        {body.type === "discount" && (
          <div
            style={{
              margin: "30px 0 0 0",
            }}
          >
            <Label.H4 color="white" weight={600}>
              ส่วนลด (บาท)
            </Label.H4>
            <Flex className="my-3" direction="column">
              <div>
                <Input
                  type="number"
                  name="discount"
                  id="discount"
                  defaultValue={body.discount}
                  value={body.discount || null}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      discount: Number(e.target.value),
                    }));
                  }}
                />
              </div>
            </Flex>
          </div>
        )}

        {body.type === "voucher" && (
          <div
            style={{
              margin: "30px 0 0 0",
            }}
          >
            <Label.H4 color="white" weight={600}>
              จำนวนใบที่ฟรี
            </Label.H4>
            <Flex className="my-3" direction="column">
              <div>
                <Input
                  type="number"
                  name="quantity"
                  id="quantity"
                  defaultValue={body.quantity}
                  value={body.quantity || null}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      quantity: Number(e.target.value),
                    }));
                  }}
                />
              </div>
            </Flex>
          </div>
        )}

        {body.type === "reserve" ? (
          <>
            <div
              style={{
                margin: "30px 0 0 0",
              }}
            >
              <Label.H4 color="white" weight={600}>
                จำนวนสิทธิ์
              </Label.H4>
              <Flex className="my-3" direction="column">
                <div>
                  <Input
                    type="number"
                    name="quantity"
                    id="quantity"
                    defaultValue={body.quantity}
                    value={body.quantity || null}
                    onChange={(e) => {
                      setBody((prevState) => ({
                        ...prevState,
                        quantity: Number(e.target.value),
                      }));
                    }}
                  />
                </div>
              </Flex>
            </div>
            <div
              style={{
                margin: "30px 0 0 0",
              }}
            >
              <Label.H4 color="white" weight={600}>
                จำนวนใบสูงสุดต่อ 1 สิทธิ์
              </Label.H4>
              <Flex className="my-3" direction="column">
                <div>
                  <Input
                    type="number"
                    name="limitPerUsed"
                    id="limitPerUsed"
                    defaultValue={body.limitPerUsed}
                    value={body.limitPerUsed}
                    onChange={(e) => {
                      setBody((prevState) => ({
                        ...prevState,
                        limitPerUsed: Number(e.target.value),
                      }));
                    }}
                  />
                </div>
              </Flex>
            </div>
          </>
        ) : (
          <div
            style={{
              margin: "30px 0 0 0",
            }}
          >
            <Label.H4 color="white" weight={600}>
              จำนวนซื้อขั้นต่ำเพื่อใช้คูปอง (ใบ)
            </Label.H4>
            <Flex className="my-3" direction="column">
              <div>
                <Input
                  type="number"
                  name="minimum"
                  id="minimum"
                  defaultValue={body.minimum}
                  value={body.minimum || null}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      minimum: Number(e.target.value),
                    }));
                  }}
                />
              </div>
            </Flex>
          </div>
        )}

        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <FormGroup>
            <Label.H4 color="white" weight={600}>
              วันหมดอายุ
            </Label.H4>

            <Input
              id="expireAtDate"
              name="expireAtDate"
              type="datetime-local"
              value={body?.expireAtDate}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  expireAtDate: e.target.value,
                  expireAt: dateToUnix(e.target.value),
                }))
              }
            />
          </FormGroup>
        </div>
        <br />
        <Flex>
          <button
            style={{
              border: "1px solid rgba(245,194,64,1)",
              backgroundColor: "rgba(245,194,64,1)",
              borderRadius: "5px",
              padding: "5px 10px",
              margin: "0 auto",
            }}
            onClick={() => {
              handleCreateCoupon();
            }}
          >
            {editData ? "แก้ไข" : "สร้างสินค้า"}
          </button>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

const UploadBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 200px;
  width: 200px;
  background: white;
  border-radius: 15%;
  transition-duration: 100ms;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
`;

const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: ${themes.colors.blue};
  width: 200px;
  min-height: 150px;
  background-color: white;
  transition-duration: 100ms;

  :hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
`;
