import React from "react";
import style from "../constants/style";
import styled from "styled-components";

const FlexStyle = styled.div`
  ${style.css.mdRad}
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  min-height: ${(props) => props.minHeight};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: ${(props) => props.maxWidth};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  flex-wrap: ${(props) => props.wrap};
  background-color: ${(props) => props.backgroundColor};
  gap: ${(props) => props.gap};
`;
export const Flex = (props) => {
  return <FlexStyle {...props}>{props.children}</FlexStyle>;
};
