import React, { useState, useRef } from "react";
import Context from "../../Context";
import themes from "../../themes";
import { unixToDate, dateToUnix } from "../../util";

import { FormGroup, Input } from "reactstrap";
import { ContentContainer } from "../orders/WorkingOrder";
import { Label, Flex } from "../../components";
import styled from "styled-components";
const MailSetting = () => {
  const { callApi, configs } = Context();
  const dataLeftRef = useRef(null);
  const [updateData, setUpdateData] = useState([]);
  const [shippingPrice, setShippingPrice] = useState(
    configs?.shipping?.price.join(",") || []
  );
  const [isEdit, setIsEdit] = useState(false);

  const updateChange = (id, value) => {
    setIsEdit(true);
    let body = { id: "general", key: id, value: value };
    setUpdateData((prevState) => [...prevState, body]);
  };

  const onSaveClick = async () => {
    if (shippingPrice !== configs?.shipping?.price.join(",")) {
      await callApi("configs-edit", {
        id: "general",
        key: "shipping.price",
        value: shippingPrice.split(","),
      });
    }
    updateData.forEach(async (data) => await callApi("configs-edit", data));
    setIsEdit(false);
    return setUpdateData([]);
  };

  return (
    <div style={{ verticalAlign: "top", height: "100%" }} ref={dataLeftRef}>
      <ContentContainer className="flex blue-bg">
        <div>
          <Flex align="center">
            <Label.H1 color="white" weight={600}>
              ตั้งค่าไปรษณีย์
            </Label.H1>
            {isEdit && <Button onClick={() => onSaveClick()}>บันทึก</Button>}
          </Flex>

          <Flex wrap="wrap" margin="25px 0 0 0">
            <FormGroup className="mx-3">
              <Label.H4 color="white" weight={600}>
                วันเริ่มจัดส่ง
              </Label.H4>
              <Input
                id="shipping.start"
                name="start"
                type="date"
                onChange={(e) =>
                  updateChange(e.target.id, dateToUnix(e.target.value))
                }
                defaultValue={unixToDate(configs.shipping.start)}
              />
            </FormGroup>
            <FormGroup className="mx-3">
              <Label.H4 color="white" weight={600}>
                วันสิ้นสุดจัดส่ง
              </Label.H4>
              <Input
                id="shipping.end"
                name="end"
                type="date"
                onChange={(e) =>
                  updateChange(e.target.id, dateToUnix(e.target.value))
                }
                defaultValue={unixToDate(configs.shipping.end)}
              />
            </FormGroup>
            <FormGroup className="mx-3">
              <Label.H4 color="white" weight={600}>
                วันที่จัดส่ง
              </Label.H4>
              <Input
                id="shipping.send"
                name="send"
                type="date"
                onChange={(e) =>
                  updateChange(e.target.id, dateToUnix(e.target.value))
                }
                defaultValue={unixToDate(configs.shipping.send)}
              />
            </FormGroup>
          </Flex>

          <div className="m-3">
            <Label.H4 color="white" weight={600}>
              ราคาค่าส่ง{" "}
              {configs.isOpen.store && (
                <span className="text-danger">*ปิดขายก่อนปรับราคา</span>
              )}
            </Label.H4>

            <Flex className="my-3" direction="column">
              <div>
                <Input
                  disabled={configs.isOpen.store}
                  style={{ cursor: configs.isOpen.store && "not-allowed" }}
                  type="text"
                  name="price"
                  id="shipping.price"
                  defaultValue={shippingPrice}
                  onChange={(e) => {
                    setIsEdit(true);
                    setShippingPrice(e.target.value);
                  }}
                />
              </div>
            </Flex>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export const Button = styled.button`
  padding: 0.2em 1em;
  margin: 0 2em;
  border-radius: 5px;
  background-color: ${themes.colors.oldGrean};
  font-weight: 600;
  color: white;
  &.add {
    border-radius: 100px;
    margin: 1em 0 0 0;
    background-color: ${themes.colors.brightBlue};
  }

  &.remove {
    margin: 0 1em;
    background-color: ${themes.colors.dangerRed};
  }

  &.disabled {
    background-color: ${themes.colors.disable};
    cursor: not-allowed;
  }
`;
export default MailSetting;
