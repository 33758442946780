import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import * as R from "ramda";
import constant from "../../constants";

export const ReportAdminApproved = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);

  const [filteredState, setFilteredState] = useState([]);

  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    let res = await callApi("utils-countAdminConfirmOrder", {
      roundDate: roundDate,
    });

    setFilteredState(res.data);
  };

  const handleDownload = async () => {
    const acc = [];

    acc.push(["วันที่", "รหัสHT", "จำนวน"]);
    Object.keys(filteredState)
      .sort((key1, key2) => key1.split("/")[0] - key2.split("/")[0])
      .map((value, index) =>
        Object.entries(filteredState[value]).map(([id, amount]) =>
          acc.push([value, id, amount])
        )
      );
    console.log(acc);
    setCsvData(acc);
    setCsvFileName(`รายงานร้านค้า-${roundDate}.csv`);
  };

  let total = 0;
  return (
    <Container>
      <Label.H2 weight={500}>รายงานแอดมินอนุมัติออเดอร์</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        value={roundDate}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>

      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      <br />

      {!R.isEmpty(filteredState) && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}

      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      {!R.isEmpty(filteredState) && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>วันที่-เวลา</th>
              <th>รหัสHT</th>

              <th>จำนวน</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(filteredState)
              .sort((key1, key2) => key1.split("/")[0] - key2.split("/")[0])
              .map((value, index) => {
                return (
                  <>
                    <tr key={index}>
                      <th>{[value]}</th>
                      {}
                      {/* <th>{Object.keys(value)}</th>
                  <th>{Object.values(value)}</th> */}
                    </tr>
                    {Object.entries(filteredState[value]).map(
                      ([id, amount]) => {
                        return (
                          <tr>
                            <th></th>
                            <th>{id}</th>
                            <th>{amount}</th>{" "}
                          </tr>
                        );
                      }
                    )}
                  </>
                );
              })}
          </tbody>
        </Table>
      )}
      <br />
      <br />
    </Container>
  );
};

const Button = styled.button`
  margin: 2em 0;
`;
