import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import * as R from "ramda";
export const ReportRewardwinnerForAdmin = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [state, setState] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    const res = await callApi("userPrizes-getAll", {
      limit: 0,
      queries: [],
      startAfter: "",
      roundDate: roundDate,
    });

    console.log(res);
    setState(res);
  };

  const handleDownload = async () => {
    const date = thaiDate();

    const acc = [];

    acc.push(["รายงานผู้ถูกรางวัลทั้งหมด "]);
    acc.push([
      "ธนาคาร",
      "เลขบัญชี",
      "ชื่อบัญชี",
      "จำนวนเงิน",
      "เลขที่อ้างอิง",
      "ชื่อ",
      "นามสกุล",
      "ค่าธรรมเนียม",
      "รหัสสาขา",
      "เบอร์โทรศัพท์",
      "อีเมล์",
    ]);
    state.map((value, index) =>
      acc.push([
        value.bankName,
        value.accNo,
        value.accName,
        value.totalPrizes.toLocaleString(),
        value.userId,
        value.firstName,
        value.lastName,
        "Payer (OUT)",
        "0001",
        value.phone,
        value.email || "paranee.t@vproperty.co.th",
      ])
    );
    // <th>{item.bankName}</th>
    //             <th>{item.accNo}</th>
    //             <th>{item.accName}</th>
    //             <th>{item.totalPrizes.toLocaleString()} บาท</th>
    //             <th>{item.userId}</th>
    //             <th>Payer (OUT)</th>
    //             <th>0001</th>
    //             <th>{item.phone}</th>
    //             <th>{item.email || "paranee.t@vproperty.co.th"}</th>

    setCsvData(acc);
    setCsvFileName(`รายงานยอดขายรายวัน_${date}.csv`);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานผู้ถูกรางวัลทั้งหมด</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>
      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      {!R.isEmpty(state) && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      {!R.isEmpty(state) && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ธนาคาร</th>
              <th>เลขบัญชี</th>
              <th>ชื่อบัญชี</th>
              <th>จำนวนเงิน</th>
              <th>เลขที่อ้างอิง</th>
              <th>ชื่อ</th>
              <th>นามสกุล</th>
              <th>ค่าธรรมเนียม</th>
              <th>รหัสสาขา</th>
              <th>เบอร์โทรศัพท์</th>
              <th>อีเมล์</th>
            </tr>
          </thead>
          <tbody>
            {state.map((item, index) => (
              <tr key={index}>
                <th>{item.bankName}</th>
                <th>{item.accNo}</th>
                <th>{item.accName}</th>
                <th>{item.totalPrizes.toLocaleString()} บาท</th>
                <th>{item.userId}</th>
                <th>{item.firstName}</th>
                <th>{item.lastName}</th>
                <th>Payer (OUT)</th>
                <th>0001</th>
                <th>{item.phone}</th>
                <th>{item.email || "paranee.t@vproperty.co.th"}</th>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <br />
      <br />
    </Container>
  );
};

const Button = styled.button``;
