import React from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { Container } from "../../components";
const LotteriesPrizes = () => {
  const { callApi } = Context();

  const handleApi = async () => {
    const result = await callApi("prizes-getAllLotteriesWithPrize");
    return result;
  };
  return (
    <Container>
      <HtTablePaging
        title="เล่มที่ถูกรางวัล"
        getData={() => handleApi()}
        columns={["id", "number", "fileNameId", "prizes"]}
        hideColumns={[]}
        downloadable={true}
        translations={{
          fileNameId: "ชื่อไฟล์",
          id: "หวย",
          prizes: "รางวัล",
          number: "เลข",
        }}
        overrides={{
          prizes: (props) =>
            props.row.prizes.map(({ prizeType, prizeAmount }) => (
              <div key={prizeType + prizeAmount}>
                {prizeType}-{prizeAmount}
              </div>
            )),
        }}
      />
    </Container>
  );
};
export default LotteriesPrizes;
