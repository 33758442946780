import React from "react";
import { useNavigate } from "react-router-dom";

const Authenticate = ({ path, children }) => {
  const navigate = useNavigate();
  if (localStorage.accessToken) {
    return children;
  } else {
    if (path !== "/login") {
      navigate("/login");
      return <></>;
    }
    return children;
  }
};
export default Authenticate;
