import * as React from "react";
import Context from "../../Context";
import { cn } from "../../util/lib";

const Textarea = ({ className, ...props }, ref) => {
  const { callApi, isDarkTheme, themes } = Context();
  return (
    <textarea
      className={cn(
        "flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
        isDarkTheme
          ? "text-white placeholder:text-[rgb(255,255,255,0.6)]"
          : "placeholder:text-gray-300",
        className
      )}
      {...props}
    />
  );
};
Textarea.displayName = "Textarea";

export { Textarea };
