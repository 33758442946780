import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";
import { Container, Label, Flex } from "../../components";
import { Input, Table, Badge } from "reactstrap";
import { thaiDate } from "../../util";
import { isEmpty } from "ramda";
import {
  IoCheckmarkCircleSharp,
  IoCloseCircleSharp,
  IoWallet,
  IoAlarmSharp,
  IoSearch,
} from "react-icons/io5";
import themes from "../../themes";

// import { qr } from "../../../public/images/qr.png";
export const DonateList = () => {
  const { callApi, showModalItemDetail, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [orderData, setOrderData] = useState([]);
  const [queriesKey, setKey] = useState("shortId");
  const [queriesValue, setValue] = useState("");
  const [startAfter, setStartAfter] = useState({ 1: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [orderCount, setOrderCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    handleSearch();
  }, [currentPage]);

  const handleSearch = async (body) => {
    const queries =
      isEmpty(queriesKey) || isEmpty(queriesValue)
        ? []
        : [[queriesKey, queriesValue]];

    const result = await callApi("donates-getAllTransaction", {
      ...body,
      queries: queries,
      limit: 250,
      startAfter: startAfter[currentPage],
    });

    const sortResult = result.sort((a, b) => b.createdAt - a.createdAt);

    setOrderData(sortResult);
  };

  const filterKey = {
    เลขที่ใบสั่ง: "shortId",
    เบอร์โทร: "user.phone",
  };

  const nextPage = () => {
    const startAfterId = orderData.slice(-1)[0].id;
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    setStartAfter((prevState) => {
      return { ...prevState, [nextPage]: startAfterId };
    });
  };

  const prevPage = () => {
    let prevPage = currentPage - 1;
    setCurrentPage(prevPage);
  };

  const getStatus = (status) => {
    if (status === "pending") {
      return (
        <IoAlarmSharp
          style={{ fontSize: "2em", color: themes.colors.primary }}
        />
      );
    } else if (status === "paid") {
      return (
        <IoWallet
          style={{ fontSize: "2em", color: themes.colors.brightBlue }}
        />
      );
    } else if (status === "approved") {
      return (
        <IoCheckmarkCircleSharp
          style={{ fontSize: "2em", color: themes.colors.success }}
        />
      );
    } else if (status === "canceled") {
      return (
        <IoCloseCircleSharp
          style={{
            fontSize: "2em",
            fontWeight: 500,
            color: themes.colors.dangerRed,
          }}
        />
      );
    }
  };

  return (
    <Container>
      <Label.H1 weight={600}>การบริจาคทั้งหมด</Label.H1>
      <br />
      <Flex align="center" justify="space-between">
        <Label.H4 weight={500}>
          การบริจาคทั้งหมด
          <Badge style={{ margin: "0 5px" }}>
            {orderData.length || "กำลังนับ"}
          </Badge>
          รายการ
        </Label.H4>
        <Flex>
          <Input
            name="select"
            type="select"
            onChange={(e) => {
              setKey(e.target.value);
            }}
            value={queriesKey}
            style={{ width: "120px", margin: "0 0 0 10px" }}
          >
            {Object.entries(filterKey).map(([key, value]) => (
              <option key={filterKey} value={value}>
                {key}
              </option>
            ))}
          </Input>

          <Input
            type="text"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={queriesValue}
            style={{ width: "150px", margin: "0 10px" }}
          />

          <button
            style={{
              backgroundColor:
                isEmpty(queriesKey) || isEmpty(queriesValue)
                  ? "rgb(209,209,209)"
                  : "rgba(245,194,64,1)",
              border: "0",
              borderRadius: "5px",
              padding: "5px 10px",
            }}
            onClick={() => {
              if (isEmpty(queriesKey) || isEmpty(queriesValue)) return;
              return handleSearch();
            }}
          >
            ค้นหา
          </button>
          <button
            style={{
              backgroundColor:
                isEmpty(queriesKey) || isEmpty(queriesValue)
                  ? "rgb(209,209,209)"
                  : "#F78888",
              border: "0",
              borderRadius: "5px",
              padding: "5px 10px",
              margin: "0 10px",
            }}
            onClick={async () => {
              const result = await callApi("coupons-getAllTransaction", {
                queries: [],
                limit: 250,
                startAfter: startAfter[currentPage],
              });
              setOrderData(result.sort((a, b) => b.createdAt - a.createdAt));
              setKey("");
              setValue("");
            }}
          >
            รีเซ็ต
          </button>
        </Flex>
      </Flex>
      <br />
      <Table striped hover>
        <thead>
          <tr>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              วันเวลาที่สั่ง
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              เลขที่ใบสั่ง
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              รหัสสมาชิก
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              ชื่อ-นามสกุล
            </th>

            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              เบอร์โทร
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              บริจาค
            </th>

            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              จำนวนที่บริจาค
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              ช่องทางการบริจาค
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              สถานะ
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              รายละเอียด
            </th>
          </tr>
        </thead>
        <tbody>
          {orderData.map((order) => {
            return (
              <tr key={order.id}>
                <td className="align-middle text-center" scope="row">
                  {new Date(parseInt(order.createdAt)).toLocaleString()}
                </td>
                <th className="align-middle text-center">{order.shortId}</th>
                <td className="align-middle text-center">
                  {order.user?.userNo}
                </td>
                <td className="align-middle text-center">{order.user?.name}</td>

                <th className="align-middle text-center">
                  {order.user?.phone}
                </th>

                <th className="align-middle text-center">
                  {order.donate.name}
                </th>

                <td className="align-middle text-center">
                  {order.donateAmount || order.payPoint}
                </td>
                <th className="align-middle text-center">
                  {order.isDonateWithCash ? "เงินโอน" : "หักคะแนน"}
                </th>
                <td className="align-middle text-center">
                  {getStatus(order.status)}
                </td>
                <td className="align-middle text-center">
                  <button
                    style={{
                      backgroundColor: "rgba(245,194,64,1)",
                      border: "0",
                      borderRadius: "5px",
                      padding: "5px 10px",
                    }}
                    onClick={() => {
                      // navigate("/orders/working/" + props.row["id"]);
                      window.open("/donates/" + order.id, "_blank");
                    }}
                  >
                    <IoSearch />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <button
        style={{
          border: "1px solid rgba(245,194,64,1)",
          backgroundColor: "white",
          color: "rgba(245,194,64,1)",
          borderRadius: "5px",
          padding: "5px 10px",
          float: "right",
        }}
        onClick={() => {
          nextPage();
        }}
      >
        หน้าต่อไป
      </button>
      {currentPage !== 1 && (
        <button
          style={{
            border: "1px solid rgb(219,9,52)",
            backgroundColor: "white",
            color: "rgb(219,9,52)",
            borderRadius: "5px",
            padding: "5px 10px",
            float: "right",
            margin: "0 10px",
          }}
          onClick={() => {
            prevPage();
          }}
        >
          ย้อนกลับ
        </button>
      )}
    </Container>
  );
};
