import React from "react";
import style from "../constants/style";
import styled from "styled-components";
import themes from "../themes";
const Main = styled.div`
  ${style.css.mdRad}
  margin: 15px;
  padding: 5px 10px;
  display: inline-block;
  background-color: ${themes.colors.mainDark3};
  color: white;
  cursor: pointer;
  :hover {
    background-color: ${themes.colors.mainDark2};
  }
  :active {
    background-color: ${themes.colors.mainDark1};
  }
`;
const Button = ({ children, handleClick }) => {
  return <Main onClick={handleClick}>{children}</Main>;
};
export default Button;
