import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { useParams } from "react-router-dom";
import { thaiDate, role, getThaiDateAndTimeFormat } from "../../util";
import themes from "../../themes";
import constant from "../../constants";
import styled from "styled-components";
import HtTablePaging from "../../components/HtTablePaging";
import {
  ContentContainer,
  Content,
  getStatusBadge,
  Button,
} from "../orders/WorkingOrder";
import { BadgeStyle } from "../orders/WorkingOrder";
import { Label, Flex } from "../../components";
import { Table, Input, Row, Col, Modal } from "reactstrap";

export const CouponOrderDetail = () => {
  const {
    callApi,
    profileState,
    configs,
    adminCart,
    setAdminCart,
    updateConfig,
    go,
  } = Context();
  const [orderData, setOrderData] = useState({});
  const [userData, setUserData] = useState({});
  const [pointsHistory, setPointsHistory] = useState([]);
  const [note, setNote] = useState("");
  const { orderId } = useParams();

  useEffect(() => {
    fetchData();
  }, [orderId]);

  const fetchData = async () => {
    await callApi("coupons-checkExpiredCoupon", { orderId });
    await callApi("coupons-getTransaction", {
      orderId,
    }).then((result) => {
      if (result) {
        setOrderData(result.data);
        callApi("users-getUserInfoAdmin", {
          userId: result.data.userData.userId,
        }).then((userData) => {
          setUserData(userData);
        });
      } else {
        alert("error");
      }
    });
  };

  const letValue = [
    {
      title: "วัน-เวลาที่สั่งซื้อ",
      value: new Date(parseInt(orderData?.createdAt)).toLocaleString(),
    },
    {
      title: "ชื่อสินค้า",
      value: orderData.coupon?.name,
    },
    {
      title: "ราคาคูปอง",
      value: `${orderData.price}${
        orderData.coupon?.isPointRedeem ? "คะแนน" : "บาท"
      }`,
    },

    // {
    //   title: "ราคา",
    //   value: `${orderData?.price?.toLocaleString()} บาท`,
    // },
    {
      title: "ใช้ได้ถึง",
      value: getThaiDateAndTimeFormat(new Date(orderData?.coupon?.expireAt)),
    },

    { title: "สถานะ", value: getStatusBadge(orderData?.status) },
  ];

  const rightValue = [
    {
      title: `สลิปหลักฐานการโอน ${orderData?.price?.toLocaleString()} บาท`,
      value: orderData.slip ? (
        <img src={orderData.slip} alt=" slip" style={{ width: "100%" }} />
      ) : (
        "-"
      ),
    },
  ];

  const updateStatus = async (status) => {
    await callApi("coupons-editStatus", { id: orderData.id, status });
    fetchData();
  };

  const addNote = async () => {
    await callApi("coupons-adminAddNote", {
      id: orderData.id,
      note: note,
    }).then((response) => {
      if (response.status) {
        setNote("");
        fetchData();
      } else {
        console.log(response);
      }
    });
  };

  return (
    <>
      <ContentContainer className="flex column blue-bg">
        <Label.H2 color="white" weight={600}>
          คำสั่งซื้อที่ {orderId}
        </Label.H2>
        <br />
        <Flex wrap="wrap" width="100%">
          <Wrapper>
            {letValue.map((order) => (
              <Content title={order.title} value={order.value} />
            ))}

            <Content
              title="เปลี่ยนสถานะคำสั่งซื้อ"
              value={
                <>
                  {orderData.status !== "approved" &&
                    (orderData.status !== constant.statuses.CANCEL ? (
                      <Button
                        color={themes.colors.success}
                        onClick={() => updateStatus(constant.statuses.APPROVE)}
                      >
                        อนุมัติ
                      </Button>
                    ) : (
                      "ไม่สามารถเปลี่ยนแปลงคำสั่งซื้อที่ถูกยกเลิกแล้วได้"
                    ))}

                  {orderData.status !== constant.statuses.CANCEL ? (
                    orderData.status === "approved" ? (
                      ["superAdmin", "dev"].includes(profileState.role) ? (
                        <Button
                          color={themes.colors.dangerRed}
                          onClick={() => updateStatus(constant.statuses.CANCEL)}
                        >
                          ยกเลิก
                        </Button>
                      ) : (
                        "ติดต่อ Super Admin เพื่อเปลี่ยนคำสั่งซื้อที่ถูกอนุมัติแล้ว"
                      )
                    ) : (
                      <Button
                        color={themes.colors.dangerRed}
                        onClick={() => updateStatus(constant.statuses.CANCEL)}
                      >
                        ยกเลิก
                      </Button>
                    )
                  ) : (
                    ""
                  )}
                </>
              }
            />

            {orderData.approvedBy && (
              <Content
                title="ผู้อนุมัติ"
                value={
                  orderData.approvedBy +
                  " " +
                  new Date(parseInt(orderData.approvedAt)).toLocaleString()
                }
              ></Content>
            )}

            {orderData.canceledBy && (
              <Content
                title="ผู้ยกเลิก"
                value={
                  orderData.canceledBy +
                  " " +
                  new Date(parseInt(orderData.canceledAt)).toLocaleString()
                }
              ></Content>
            )}
            {orderData.coupon?.usedBy?.length > 5 && (
              <Content title="ออเดอร์อ้างอิง" value={orderData.coupon.usedBy}>
                <button
                  onClick={() => {
                    // navigate("/orders/working/" + props.row["id"]);
                    window.open(
                      "/orders/working/" + orderData.coupon.usedBy,
                      "_blank"
                    );
                  }}
                >
                  ดูข้อมูลคำสั่งซื้อ
                </button>
              </Content>
            )}
          </Wrapper>
          <Wrapper>
            {rightValue.map((order) => (
              <Content title={order.title} value={order.value} />
            ))}
            {orderData.customerNote && (
              <Content
                title="บันทึกเพิ่มเติมจากลูกค้า"
                value={orderData.customerNote.map((value, index) => {
                  return (
                    <div
                      className="p-2 w-fit bg-white cursor-pointer text-gray-950 rounded-[0.5rem]"
                      key={index}
                      onClick={() =>
                        window.open(`/mission/list/${value.split(" ")[1]}`)
                      }
                    >
                      {value}
                    </div>
                  );
                })}
              ></Content>
            )}

            <Content
              title="บันทึกเพิ่มเติม"
              value={
                <Flex direction="column">
                  <Flex margin="0 0 1em 0">
                    <Input
                      type="text"
                      id="note"
                      value={note}
                      name="note"
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <Button
                      className="note"
                      color={themes.colors.oldGrean}
                      onClick={() => addNote()}
                    >
                      บันทึก
                    </Button>
                  </Flex>
                  {orderData.note
                    ?.sort((a, b) => b.time - a.time)
                    .map((data) => {
                      return (
                        <NoteContainer>
                          {new Date(parseInt(data.time)).toLocaleString()} |{" "}
                          {data.by} <br />
                          <br /> {data.note}
                        </NoteContainer>
                      );
                    })}
                </Flex>
              }
            ></Content>
          </Wrapper>
        </Flex>
      </ContentContainer>
      <br />
      {(orderData?.coupon?.type === "reserve" ||
        orderData?.coupon?.type === "reserve100") && (
        <ReservedOrder
          orderData={orderData}
          profileState={profileState}
          callApi={callApi}
          configs={configs}
          adminCart={adminCart}
          setAdminCart={setAdminCart}
          fetchData={fetchData}
        />
      )}
      <br />
      <ContentContainer className=" blue-bg">
        <Flex align="flex-start">
          <div>
            <Label.H2
              weight={600}
              margin="0 1em 0 0"
              color={themes.colors.white}
            >
              สมาชิกหมายเลข {userData.userId}
            </Label.H2>
            <Label.H4
              weight={400}
              margin="0 1em 0 0"
              color={themes.colors.white}
            >
              สมาชิกลำดับที่ {userData.userNo}
            </Label.H4>
            {userData.role && (
              <Label.H5
                weight={400}
                margin="0 1em 0 0"
                color={themes.colors.white}
              >
                {role[userData.role] || ""}
              </Label.H5>
            )}
          </div>
          <Button
            onClick={() => window.open("/users/" + orderData.userId, "_blank")}
            color={themes.colors.oldGrean}
          >
            ดูข้อมูล
          </Button>
        </Flex>
        <br />
        <Flex align="flex-start" wrap="wrap">
          <Wrapper>
            <Content
              title="ชื่อ-นามสกุล"
              value={userData.firstName + " " + userData.lastName}
            />
            <Content title="เบอร์โทรศัพท์" value={userData.phone || "-"} />
            <Content title="คะแนน" value={userData.buyPoint || "-"} />
            <Content title="ไอดีไลน์" value={userData.lineId || "-"} />
            <Content title="อีเมล์ไลน์" value={userData.lineEmail || "-"} />
          </Wrapper>
          <Wrapper style={{ maxWidth: "300px" }}>
            <Content title="ธนาคาร" value={userData.bank?.name || "-"} />
            <Content title="ชื่อบัญชี" value={userData.bank?.accName || "-"} />
            <Content title="เลขบัญชี" value={userData.bank?.accNo || "-"} />
            <Content title="ชื่อไลน์" value={userData.lineTitle || "-"} />
          </Wrapper>
          <Wrapper style={{ maxWidth: "900px" }}></Wrapper>
        </Flex>
      </ContentContainer>
    </>
  );
};

const ReservedOrder = ({
  orderData,
  callApi,
  configs,
  adminCart,
  setAdminCart,
  fetchData,
  profileState,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectOrderIndex, setSelectOrderIndex] = useState(undefined);
  const [bookName, setBookName] = useState("");

  const addAdminCart = (id) => {
    setAdminCart([...new Set([...adminCart, id])]);
  };
  const removeAdminCart = (id) => {
    setAdminCart(adminCart.filter((cart) => cart !== id));
  };
  const checkInCart = (id) => {
    return adminCart.includes(id);
  };

  const searchBook = async () => {
    const result = await callApi("lotteries-getAll", {
      queries: [
        ["fileNameId", bookName],
        ["isSold", false],
        ["isOnBlock", false],
      ],
      limit: 200,
    });
    setAdminCart(result.map((book) => book.id));
  };

  return (
    <>
      <Modal isOpen={showModal} centered toggle={() => setShowModal(false)}>
        <ContentContainer
          style={{
            width: orderData.coupon.type === "reserve" ? "fit-content" : "100%",
          }}
        >
          {orderData.coupon.type === "reserve100" ? (
            <>
              <Label.H2 weight={600}>สร้างคำสั่งซื้อ 100 ใบ</Label.H2>
              <br />

              <Label.H5 weight={600}>ชื่อเล่ม</Label.H5>
              <div className="flex gap-4">
                <input
                  type="text"
                  value={bookName}
                  onChange={(e) => setBookName(e.target.value)}
                  style={{ border: "1px solid black" }}
                />
                <button onClick={searchBook}>ค้นหา</button>

                <button
                  disabled={adminCart.length === 0}
                  onClick={async () => {
                    const result = await callApi("orders-createReserveOrder", {
                      lotteryIds: adminCart,
                      userId: orderData.userId,
                      couponOrderId: orderData.id,
                      selectOrderIndex: selectOrderIndex,
                    });
                    if (result.status === true) {
                      setAdminCart([]);
                      setShowModal(false);
                      fetchData();
                    }
                  }}
                >
                  สร้างคำสั่งซื้อ {adminCart.length} ใบ
                </button>
              </div>
            </>
          ) : (
            <>
              <Label.H2 weight={600}>สร้างคำสั่งซื้อ</Label.H2>
              <br />
              <Label.H4 weight={600}>เลขในตระกร้า</Label.H4>

              {adminCart.map((number, index) => {
                return (
                  <div key={index}>
                    <Label.H5 weight={600}>{number}</Label.H5>
                  </div>
                );
              })}
              <br />

              <button
                disabled={adminCart.length === 0}
                onClick={async () => {
                  const result = await callApi("orders-createReserveOrder", {
                    lotteryIds: adminCart,
                    userId: orderData.userId,
                    couponOrderId: orderData.id,
                    selectOrderIndex: selectOrderIndex,
                  });
                  if (result.status === true) {
                    setAdminCart([]);
                    setShowModal(false);
                    fetchData();
                  }
                }}
              >
                สร้างคำสั่งซื้อ {adminCart.length}
              </button>

              <br />
              <HtTablePaging
                getData={(body) => callApi("lotteries-getAll", body)}
                columns={["addCart"]}
                baseQrs={[
                  ["isOnBlock", "==", false],
                  ["isSold", "==", false],
                  ["roundDate", "==", configs.roundDate],
                ]}
                hideColumns={[
                  "id",
                  "userId",
                  "isLotteryOut",
                  "createdAt",
                  "uploader",
                  "isLotteryOut",
                  "isOnBlock",
                  "isSold",
                  "orderShortId",
                  "image",
                  "bookNo",
                  "firstThree",
                ]}
                overrides={{
                  shortId: ({ row, key }) =>
                    (row["orderId"] || "").slice(-5).toUpperCase(),
                  addCart: ({ row, key }) =>
                    checkInCart(row["id"]) ? (
                      <Button
                        color="red"
                        onClick={() => removeAdminCart(row["id"])}
                      >
                        เอาออก
                      </Button>
                    ) : (
                      <Button
                        color="green"
                        onClick={() => addAdminCart(row["id"])}
                      >
                        ใส่ตะกร้า
                      </Button>
                    ),
                }}
                hideFilters={["isOnBlock", "addCart"]}
                translations={{
                  year: "ปี",
                  fileNameId: "ชื่อไฟล์",
                  roundNo: "เลขงวด",
                  setNo: "เลขชุด",
                  number: "หมายเลข",
                  isOnBlock: "นำออกขาย",
                  isSold: "ขายแล้ว",
                  orderShortId: "รหัสย่อคำสั่งซื้อ",
                  lastTwo: "เลขท้าย 2 ตัว",
                  lastThree: "เลขท้าย 3 ตัว",
                  addCart: ".",
                }}
              />
            </>
          )}
        </ContentContainer>
      </Modal>
      <ContentContainer className=" blue-bg">
        <Label.H2 color="white" weight={600}>
          {orderData.coupon.type === "reserve100"
            ? "จอง 100 ใบ (1 เล่ม)"
            : "จองเลขท้าย"}
        </Label.H2>
        <Row lg="2" md="1" xs="1">
          <Col style={{ maxWidth: "700px" }}>
            <Content
              title="สถานะคูปอง"
              value={
                <BadgeStyle
                  bgcolor={constant.statusColor[orderData.coupon.status]}
                >
                  {constant.statusesTranslate[orderData.coupon.status]}
                </BadgeStyle>
              }
            />
            <Content
              title="จำนวนสิทธิ์การจอง (ใช้แล้ว/ทั้งหมด)"
              value={
                orderData?.coupon?.reserveOrder.length +
                "/" +
                orderData?.coupon?.quantity
              }
            />
          </Col>
          <Col>
            <Table bordered dark striped>
              <thead>
                <tr>
                  <th className="text-light text-center">ลำดับที่</th>
                  <th className="text-light text-center">เลขท้าย</th>
                  <th className="text-light text-center">จำนวนใบ</th>
                  <th className="text-light text-center">สถานะ</th>
                </tr>
              </thead>
              <tbody>
                {orderData.coupon.reserveOrder.map((order, index) => {
                  return (
                    <tr key={index}>
                      <th className="text-light text-center">{index + 1}</th>
                      <th className="text-light text-center">{order.number}</th>
                      <th className="text-light text-center">
                        {order.quantity}
                      </th>
                      <th className="text-light text-center">
                        {orderData.coupon.status ===
                        constant.statuses.EXPIRED ? (
                          <BadgeStyle
                            bgcolor={
                              constant.statusColor[orderData.coupon.status]
                            }
                          >
                            {
                              constant.statusesTranslate[
                                orderData.coupon.status
                              ]
                            }
                          </BadgeStyle>
                        ) : (
                          <BadgeStyle
                            onClick={() => {
                              if (
                                profileState.role === "scanner" ||
                                profileState.role === "Developer"
                              ) {
                                if (
                                  order.status === constant.statuses.PENDING
                                ) {
                                  setShowModal(true);
                                  setSelectOrderIndex(index);
                                } else {
                                  window.open(
                                    "/orders/working/" + order.orderId,
                                    "_blank"
                                  );
                                }
                              }
                            }}
                            cursor="pointer"
                            bgcolor={
                              order.status === constant.statuses.USED
                                ? "green"
                                : "orange"
                            }
                          >
                            {order.status === constant.statuses.USED
                              ? `orderId: ${order.orderId}`
                              : "สร้างออเดอร์"}
                          </BadgeStyle>
                        )}
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
};
const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
`;
const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const FullScrollBar = styled(ScrollBar)`
  width: 100% !important;
  max-width: 1000px !important;
  max-height: 50vh;
`;

export const NoteContainer = styled.div`
  background-color: white;
  color: black;
  border-radius: 15px;
  line-break: anywhere;
  margin: 15px 0 0 0;
  padding: 15px;
`;
