import React, { useState, useEffect } from "react";
import Context from "../../Context";
import styled from "styled-components";
import themes from "../../themes";
import { Container, Label, Flex } from "../../components";
import { RiCoupon2Fill } from "react-icons/ri";
import { FiImage } from "react-icons/fi";
import {
  Modal,
  ModalBody,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Badge,
} from "reactstrap";
import { ContentContainer } from "../orders/WorkingOrder";
import { resizeImage } from "../../util";
export const ManageGiftshop = () => {
  const { callApi, showModalItemDetail, configs } = Context();
  const [modal, setModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [edit, setEdit] = useState();
  const toggleAdd = () => {
    setEdit(false);
    setModal(!modal);
  };

  useEffect(() => {
    fetchGiftshop();
  }, [modal]);

  const fetchGiftshop = async () => {
    const result = await callApi("giftshops-getAllProduct");
    setAllProducts(result);
  };

  const handleEditClick = (id) => {
    setEdit(allProducts.find((c) => c.id === id));
    setModal(!modal);
  };
  return (
    <ContentContainer className=" blue-bg">
      <AddProductModal
        modal={modal}
        toggle={toggleAdd}
        callApi={callApi}
        editData={edit}
      />
      <Label.H1 color="white" weight={600}>
        การจัดการร้านค้า
      </Label.H1>
      <br />
      <Flex align="stretch" margin="2em 0 1em 0" gap="2em" wrap="wrap">
        <AddCoupon toggle={toggleAdd} />
        {allProducts &&
          allProducts.map((coupon) => {
            return (
              <Coupon
                data={coupon}
                handleEditClick={handleEditClick}
                key={coupon.id}
              />
            );
          })}
      </Flex>
    </ContentContainer>
  );
};

const Coupon = ({ data, handleEditClick }) => {
  return (
    <Card style={{ width: "200px", minHeight: "300px" }}>
      <img
        src={data.image}
        alt="coupon"
        style={{ width: "200px", height: "200px" }}
      />
      <CardBody
        style={{
          color: "black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <>
          <CardTitle tag="h5" color="primary">
            {data.name}
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            <Badge color={data.status !== "active" ? "danger" : "success"}>
              {data.status === "active" ? "ปกติ" : "ปิดใช้งาน"}{" "}
              {data.isHotDeal && " 🔥"}
            </Badge>
          </CardSubtitle>
          <CardText>ราคา {data.price} คะแนน</CardText>
          <CardText>จำนวนคงเหลือ {data.quantity} ชิ้น</CardText>
        </>

        <Button color="danger" onClick={() => handleEditClick(data.id)}>
          แก้ไข
        </Button>
      </CardBody>
    </Card>
  );
};

const AddCoupon = ({ toggle }) => {
  return (
    <DotWrapper onClick={toggle}>
      <h1>
        + <RiCoupon2Fill />
      </h1>
      <br />
      <Label.H3 weight={600} color={themes.colors.blue}>
        เพิ่มสินค้า
      </Label.H3>
    </DotWrapper>
  );
};

const AddProductModal = ({ modal, toggle, callApi, editData }) => {
  const [body, setBody] = useState({
    image: undefined,
    name: "",
    price: 0,
    quantity: 0,
  });
  const [previewImage, setPreviewImage] = useState(editData?.image || "");

  useEffect(() => {
    if (editData) {
      setPreviewImage(editData.image);
      setBody({
        image: editData?.image,
        name: editData?.name,
        price: editData?.price,
        status: editData?.status,
        id: editData?.id,
        quantity: editData?.quantity,
      });
    } else {
      setPreviewImage("");
      setBody({
        image: undefined,
        name: "",
        price: 0,
        status: "active",
        quantity: 0,
      });
    }
  }, [modal]);

  const handleCreateCoupon = async () => {
    if (editData) {
      console.log(body);
      const result = await callApi("giftshops-editProduct", body);
      if (result) return toggle();
    } else {
      const result = await callApi("giftshops-createProduct", body);
      if (result) return toggle();
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalBody
        style={{
          background: themes.colors.secondaryGradient2,
        }}
      >
        <Label.H1 color={themes.colors.white} weight={600}>
          {editData ? "แก้ไขสินค้า" : "สร้างสินค้าใหม่"}
        </Label.H1>
        <br />
        {previewImage ? (
          <Flex direction="column" align="center">
            <br />
            <img src={previewImage} width="200" alt="preview" />
            <br />
            <button
              style={{
                border: "1px solid red",
                backgroundColor: "red",
                borderRadius: "5px",
                padding: "5px 10px",
                width: "70px",
              }}
              onClick={() => {
                setPreviewImage("");
                setBody((prevState) => ({ ...prevState, image: undefined }));
              }}
            >
              แก้ไข
            </button>
          </Flex>
        ) : (
          <Flex direction="column" align="center">
            <label htmlFor="files-slips">
              <UploadBtn>
                <h1>
                  + <FiImage />
                </h1>
                <br />
                <Label.H4 weight={600}>อัปโหลดรูปภาพ</Label.H4>
              </UploadBtn>
            </label>
          </Flex>
        )}

        <input
          style={{
            marginBottom: "15px",
            visibility: "hidden",
            position: "absolute",
            display: "none",
          }}
          id="files-slips"
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];
            const base64 = await resizeImage(file);
            if (base64) {
              const previewUrl = URL.createObjectURL(file);
              setPreviewImage(previewUrl);
              setBody((prevState) => ({ ...prevState, image: base64 }));
            } else {
              alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
            }
          }}
        />
        <br />
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            สถานะ
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="select"
                name="status"
                id="status"
                value={body.status}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }));
                }}
              >
                <option value="active">ปกติ</option>
                <option value="inactive">ปิดใช้งานชั่วคราว</option>
                <option value="removed">ปิดใช้งานถาวร</option>
              </Input>
            </div>
          </Flex>
        </div>
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            ชื่อสินค้า
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="ชื่อสินค้า"
                defaultValue={body.name}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
          </Flex>
        </div>
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            ราคาสินค้า
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="number"
                name="price"
                id="price"
                defaultValue={body.price}
                value={body.price || null}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    price: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>
        </div>
        <div
          style={{
            margin: "30px 0 0 0",
          }}
        >
          <Label.H4 color="white" weight={600}>
            จำนวน
          </Label.H4>
          <Flex className="my-3" direction="column">
            <div>
              <Input
                type="number"
                name="quantity"
                id="quantity"
                defaultValue={body.quantity}
                value={body.quantity || null}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    quantity: Number(e.target.value),
                  }));
                }}
              />
            </div>
          </Flex>
        </div>
        <br />
        {body.name && body.price && body.image && (
          <Flex>
            <button
              style={{
                border: "1px solid rgba(245,194,64,1)",
                backgroundColor: "rgba(245,194,64,1)",
                borderRadius: "5px",
                padding: "5px 10px",
                margin: "0 auto",
              }}
              onClick={() => {
                handleCreateCoupon();
              }}
            >
              {editData ? "แก้ไข" : "สร้างสินค้า"}
            </button>
          </Flex>
        )}
      </ModalBody>
    </Modal>
  );
};

const UploadBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 200px;
  width: 200px;
  background: white;
  border-radius: 15%;
  transition-duration: 100ms;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
`;

const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: ${themes.colors.blue};
  width: 200px;
  min-height: 300px;
  background-color: white;
  transition-duration: 100ms;

  :hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
`;
