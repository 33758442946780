import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import * as R from "ramda";
import { bankTranslater } from "../../util";

export const ReportUserPrizes = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [state, setState] = useState([]);

  const [filteredState, setFilteredState] = useState([]);

  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };

  const handleTypeChange = (e) => {
    if (e.target.value === "all") {
      setFilteredState(state);
    } else {
      e.target.value === "line"
        ? setFilteredState((currentState) =>
            currentState.filter(
              (data) => data.notifyType.toLowerCase() === "line"
            )
          )
        : setFilteredState((currentState) =>
            currentState.filter(
              (data) => data.notifyType.toLowerCase() !== "line"
            )
          );
    }
  };
  const handleSearch = async () => {
    let res = await callApi("userPrizes-getAll", {
      limit: 100000,
      startAfter: "",
      roundDate: roundDate,
    });

    setState(res);
    setFilteredState(res);
  };

  const handleDownload = async () => {
    const arrData = [["refId", "phone", "message"]];

    filteredState.forEach((data, index) => {
      arrData.push([
        `${data.userId}${index + 1}`,
        data.phone,
        `  ยินดีด้วยคุณถูกรางวัลมูลค่า ${data.totalPrizes} บาท ตรวจสอบที่ https://shop.hongthongofficial.com/histories`,
      ]);
    });
    setCsvData(arrData);
    setCsvFileName(
      `รายงานการแจ้งเตือนผู้ถูกรางวัลทางโทรศัพท์งวด ${roundDate}.csv`
    );
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานการแจ้งเตือนผู้ถูกรางวัล</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        value={roundDate}
        style={{ width: "100px" }}
      >
        {configs.roundDates.reverse().map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>

      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      <Input
        name="select"
        type="select"
        onChange={(e) => handleTypeChange(e)}
        style={{ width: "100px" }}
      >
        <option key="all" value="all">
          all
        </option>
        <option key="sms" value="sms">
          sms
        </option>
        <option key="line" value="line">
          line
        </option>
      </Input>
      <br />
      <p>จำนวน {filteredState.length}</p>
      {!R.isEmpty(filteredState) && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}

      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      {!R.isEmpty(filteredState) && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>index</th>
              <th>notifyType</th>
              <th>refid</th>
              <th>phone | lineId</th>
              <th>message</th>
            </tr>
          </thead>
          <tbody>
            {filteredState
              .sort((a, b) => a.userId - b.userId)
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <th>
                      {item.notifyType.toLowerCase() !== "line"
                        ? "sms"
                        : "line"}
                    </th>
                    <th>
                      {item.userId}
                      {index + 1}
                    </th>
                    <th>
                      {item.notifyType.toLowerCase() !== "line"
                        ? item.phone
                        : item.lineId}
                    </th>
                    <th>
                      ยินดีด้วยคุณถูกรางวัลมูลค่า {item.totalPrizes} บาท
                      ตรวจสอบที่ https://shop.hongthongofficial.com/histories
                    </th>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
      <br />
      <br />
    </Container>
  );
};

const Button = styled.button`
  margin: 2em 0;
`;
