import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import themes from "../../themes";
const PrizesList = () => {
  const navigate = useNavigate();
  const { callApi, profileState } = Context();
  return (
    <PanelContainer>
      <HtTablePaging
        getData={(body) => callApi("userPrizes-getAll", body)}
        updateApi={async (body) => {
          return await callApi("prizes-edit", {
            ...body,
            collectionName: "Prizes",
          });
        }}
        hideColumns={["id"]}
        downloadable={true}
        title="รางวัลรายบุคคล"
        translations={{
          accName: "ชื่อบัญชี",
          accNo: "เลขบัญชี",
          bankName: "ชื่อธนาคาร",
          email: "อีเมล",
          firstName: "ชื่อ",
          lastName: "นามสกุล",
          paid: "จ่ายรางวัลแล้ว",
          phone: "โทรศัพท์",
          totalPrizes: "รางวัลรวม",
          userId: "รหัสสมาชิก",
          userNo: "เลขสมาชิก",
        }}
        columns={["รายละเอียด"]}
        overrides={{
          รายละเอียด: (props) => (
            <button
              onClick={async () => {
                profileState.permissions.includes("/prizes-list/:userId")
                  ? navigate("/prizes-list/" + props.row["id"])
                  : alert("user นี้ไม่มีสิทธในการเข้าถึง");
              }}
            >
              ดูเพิ่มเติม
            </button>
          ),
        }}
      />
    </PanelContainer>
  );
};

const PanelContainer = styled.div`
  /* margin: 5px; */
  border-radius: 10px;
  border: 1px solid ${themes.colors.border};
  overflow-x: auto;
  width: 100%;
  /* max-width: 300px; */
  padding: 10px 20px;
  background: ${themes.colors.white};
`;
export default PrizesList;
