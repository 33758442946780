import React, { useContext } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { Container } from "../../components";
const Lotteries = () => {
  const { callApi, db, configs } = Context();

  return (
    <Container>
      <HtTablePaging
        getData={(body) => callApi("lotteries-getAll", body)}
        hideColumns={["id"]}
        overrides={{
          shortId: ({ row, key }) =>
            (row["orderId"] || "").slice(-5).toUpperCase(),
          limit: 500,
        }}
        baseQrs={[["roundDate", "==", configs.roundDate]]}
        dropdownFilter={{
          isSold: ["Yes", "No"],
          isOnBlock: ["Yes", "No"],
        }}
        limit={500}
        translations={{
          createdAt: "เข้าระบบเมื่อ",
          isLotteryOut: "ลูกค้ารับไปแล้ว",
          roundNo: "เลขงวด",
          setNo: "เลขชุด",
          number: "หมายเลข",
          lastTwo: "เลขท้าย 2 ตัว",
          firstThree: "เลขหน้า 3 ตัว",
          lastThree: "เลขท้าย 3 ตัว",
          isOnBlock: "นำออกขาย",
          isSold: "ขายแล้ว",
          orderShortId: "รหัสย่อคำสั่งซื้อ",
          userId: "รหัสสมาชิก",
          image: "รูป",
          bookNo: "เลขเล่ม",
        }}
      />
    </Container>
  );
};

export default Lotteries;
