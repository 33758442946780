import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import ButtonLoading from "../../components/ButtonLoading";
import { Table, Input } from "reactstrap";
import { Flex, Label } from "../../components";
import { thaiDate } from "../../util";
import { isNil, isEmpty } from "ramda";
import styled from "styled-components";
import themes from "../../themes";

const LotteriesNotImported = () => {
  const { callApi, configs, profileState } = Context();
  const [unimported, setUnimported] = useState({});
  const [fileNameId, setFileNameId] = useState("");
  const [filteredData, setFilteredData] = useState({});
  const [preparedImport, setPreparedImport] = useState([]);
  const getData = async () => {
    const data = await callApi("lotteries-countImports", {
      isOnBlock: false,
    }).then((result) => {
      return result.data;
    });

    setUnimported(data || {});
  };

  const getFilteredData = async () => {
    const data = await callApi("lotteries-getAll", {
      queries: [
        ["fileNameId", "==", fileNameId],
        ["isOnBlock", "==", false],
        ["roundDate", "==", configs.roundDate],
      ],

      limit: 200,
    });

    const getData = data.reduce(
      (acc, lotto) => {
        acc["length"] += 1;
        if (!acc["uploader"].includes(lotto.uploader)) {
          acc["uploader"].push(lotto.uploader);
        }
        return acc;
      },
      {
        fileNameId: fileNameId,
        length: 0,
        uploader: [],
      }
    );
    if (getData.length <= 0) {
      setFilteredData("error");
      return;
    }
    setFilteredData(getData);
  };
  useEffect(() => {
    getData();
  }, []);

  const pushToBlock = async (isMagicNumber, isLabubuNumber = false) => {
    await callApi("blocks-pushToBlock", {
      isActive: true,
      createdAts: preparedImport,
      isMagicNumber: isMagicNumber,
      isLabubuNumber: isLabubuNumber,
    }).then(() => {
      setPreparedImport([]);
      getData();
    });
  };

  const selectedAll = () => {
    const allCreatedRawAt = Object.values(unimported).map(
      (book) => book.createdRawAt
    );
    if (isEmpty(preparedImport)) {
      setPreparedImport(allCreatedRawAt);
    } else {
      setPreparedImport([]);
    }
  };
  return (
    <>
      <Button onClick={getData}>Refresh</Button>
      <Label.H5 margin="0 1em 0 0" color="red">
        *** การดึงข้อมูลทั้งหมดใช้เวลานานมาก หากไม่จำเป็นให้ค้นหาชื่อไฟล์แทน
      </Label.H5>
      <Flex margin="3em 0" align="center" width="100%">
        <Flex direction="column">
          <div style={{ width: "100%", display: "flex" }}>
            <Label.H2 margin="0 1em 0 0" weight={500}>
              จำนวนเลขในระบบหลังบ้านทั้งหมด{" "}
              {Object.values(unimported)
                .filter((x) => x.newLotteries !== 0)
                .length.toLocaleString()}{" "}
              เล่ม (
              {Object.values(unimported)
                .reduce((acc, x) => {
                  return acc + x.size;
                }, 0)
                .toLocaleString()}
              ) ใบ
            </Label.H2>
          </div>
          <Label.H5 margin="0 1em 0 0" color="red">
            *** หากไม่เจอไฟล์ในตาราง ให้ค้นหาโดยชื่อไฟล์
          </Label.H5>
          <br />
          <Flex align="center">
            <Label.H5 margin="0 1em 0 0" weight={500}>
              ค้นหาข้อมูลโดยชื่อเล่ม
            </Label.H5>
            <Input
              name="fileNameId"
              type="text"
              placeholder="0000-100-zip"
              value={fileNameId}
              onChange={(e) => setFileNameId(e.target.value)}
              style={{ width: "200px" }}
            />
            <Button
              disabled={!fileNameId}
              style={{ margin: "0 10px " }}
              onClick={() => getFilteredData()}
            >
              ค้นหา
            </Button>
            <Button
              disabled={!fileNameId}
              style={{ margin: "0 10px " }}
              onClick={() => {
                setFileNameId("");
                setFilteredData({});
              }}
            >
              รีเซ็ต
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <br />
      <Flex gap="10px">
        <Button
          disabled={isEmpty(preparedImport)}
          onClick={() => pushToBlock(false)}
        >
          วางขายปกติ {preparedImport?.length} เล่ม
        </Button>{" "}
        <Button
          className="danger"
          disabled={isEmpty(preparedImport)}
          onClick={() => pushToBlock(true)}
        >
          วางขายใน เลขบรวงสรวง {preparedImport?.length} เล่ม
        </Button>
        <Button
          className="danger"
          disabled={isEmpty(preparedImport)}
          onClick={() => pushToBlock(false, true)}
        >
          วางขายใน เลข Labubu {preparedImport?.length} เล่ม
        </Button>
      </Flex>
      <br />
      <FullScrollBar>
        <Table striped hover>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>
                <Button onClick={() => selectedAll()}>
                  {isEmpty(preparedImport) ? "เลือกทั้งหมด" : "ยกเลิก"}
                </Button>
              </th>
              <th style={{ textAlign: "center" }}>ดูข้อมูล</th>
              <th style={{ textAlign: "center" }}>ลำดับที่</th>
              <th style={{ textAlign: "center" }}>ชื่อไฟล์</th>
              <th style={{ textAlign: "center" }}>จำนวนเลขในระบบหลังบ้าน</th>
              <th style={{ textAlign: "center" }}>นำเข้าโดย</th>
              {/* <th /> */}
            </tr>
          </thead>
          <tbody>
            {!isEmpty(filteredData) ? (
              filteredData === "error" ? (
                <>
                  <br />
                  <h1>ไม่พบข้อมูล</h1>
                </>
              ) : (
                <tr>
                  <td align="center">1</td>
                  <td align="center">
                    <button
                      onClick={() =>
                        window.open(
                          `/lotteries/detail?fileNameId=${filteredData.fileNameId}`,
                          "_blank"
                        )
                      }
                    >
                      ดูข้อมูล
                    </button>
                  </td>
                  <td align="center">1</td>
                  <td align="center">{filteredData.fileNameId}</td>
                  <td align="center">{filteredData.length}</td>
                  <td align="center">
                    {filteredData.uploader.map((x) => x + " ")}
                  </td>
                </tr>
              )
            ) : (
              Object.entries(unimported)
                .sort(([key1, value1], [key2, value2]) =>
                  key1.localeCompare(key2, undefined, {
                    numeric: true,
                  })
                )
                .map(([key, value], index) => {
                  return (
                    <tr key={index}>
                      <td align="center">
                        <input
                          type={"checkbox"}
                          id={value.createdRawAt}
                          style={{
                            margin: "4px",
                            marginRight: "1em",
                            transform: "scale(2)",
                          }}
                          checked={preparedImport?.includes(value.createdRawAt)}
                          onChange={(e) =>
                            setPreparedImport((olds) => {
                              const selectedCheckboxes = [...preparedImport];
                              const isChecked = selectedCheckboxes.includes(
                                value.createdRawAt
                              );
                              if (!isChecked) {
                                selectedCheckboxes.push(value.createdRawAt);
                              } else {
                                selectedCheckboxes.splice(
                                  selectedCheckboxes.indexOf(
                                    value.createdRawAt
                                  ),
                                  1
                                );
                              }
                              return setPreparedImport(selectedCheckboxes);
                            })
                          }
                        />
                      </td>
                      <td align="center">
                        <Button
                          onClick={() =>
                            window.open(
                              `/lotteries/detail?fileNameId=${key}`,
                              "_blank"
                            )
                          }
                        >
                          ดูข้อมูล
                        </Button>
                      </td>
                      <td align="center">{(index + 1).toLocaleString()}</td>
                      <td align="center">{key}</td>
                      <td align="center">{value.size}</td>
                      <td align="center">{value.uploader}</td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </Table>
      </FullScrollBar>
      <br />
    </>
  );
};

const Button = styled.button`
  background-color: ${themes.colors.primary};
  border-radius: 0.375em;
  border: 1px solid ${themes.colors.primary};
  font-size: 1.5em;
  font-weight: 800;
  padding: 0.5em;

  &.danger {
    background-color: ${themes.colors.danger};
    border-radius: 0.375em;
    border: 1px solid ${themes.colors.danger};
    color: white;
  }
  :hover {
    color: ${themes.colors.primary};
    background-color: white;

    &.danger {
      color: ${themes.colors.danger};
    }
  }
`;
const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const FullScrollBar = styled(ScrollBar)`
  width: 100% !important;
  max-width: 100% !important;
  max-height: 70vh;
`;
export default LotteriesNotImported;
