import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import { unixToDateAndTime } from "../../util";

export const ReportBookSummary = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [stat, setStat] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    const data = await callApi("books-getAllBooks", {
      queries: [["roundDate", "==", roundDate]],
      limit: 50000,
    });

    // const sortedData = data.sort((a, b) =>
    //   a.fileNameId.localeCompare(b.fileNameId, undefined, {
    //     numeric: true,
    //   })
    // );

    const stat = {
      countLotto: 0,
      countBook: data.length,
    };
    data.forEach((data) => {
      stat.countLotto += data.lotteryIds.length;
    });
    setStat(stat);
    setData(data);
  };

  const handleDownload = async () => {
    const prepData = data;

    let listHead = [
      "ลำดับที่",
      `ชื่อเล่ม (${stat.countBook} เล่ม)`,
      `จำนวน (${stat.countLotto} ใบ)`,
      `สร้างเมื่อ`,
    ];
    let ar = [];
    ar.push(["", `รายงานสรุปการนำเล่มเข้าระบบ งวด ${roundDate}`]);
    ar.push(listHead);

    prepData.forEach((value, index) => {
      ar.push([
        index + 1,
        value.id,
        value.lotteryIds.length,
        unixToDateAndTime(value.createdAt).replace("T", " เวลา "),
        value.uploader || "-",
      ]);
    });

    setCsvData(ar);
    setCsvFileName(`รายงานสรุปการนำเล่มเข้าระบบ_${roundDate}.csv`);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานสรุปการนำเล่มเข้าระบบ</Label.H2>
      <br />
      <Flex align="center">
        <Input
          name="select"
          type="select"
          onChange={(e) => changeRoundDate(e)}
          style={{ width: "100px" }}
        >
          {configs.roundDates
            .sort()
            .reverse()
            .map((roundDate) => (
              <option key={roundDate}>{roundDate}</option>
            ))}
        </Input>
        <Button
          onClick={async () =>
            await callApi("books-countAllLotteriesToBooks", {
              roundDate: configs.roundDate,
            })
          }
          style={{ display: "inline", margin: "0 1em" }}
        >
          นับใหม่
        </Button>
        <Label.H5 weight={500} color="red">
          **การนับใหม่ ใช่เวลาและทรัพยากรจำนวนมาก อย่านับบ่อย
        </Label.H5>
      </Flex>

      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>
      {data.length !== 0 && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      <br />
      <ScrollBar>
        {data.length !== 0 && (
          <Table striped bordered>
            <thead>
              <tr>
                <th>ลำดับที่</th>
                <th>ชื่อเล่ม ({stat.countBook} เล่ม)</th>
                <th>จำนวน ({stat.countLotto} ใบ)</th>
                <th>สร้างเมื่อ</th>

                <th>ผู้รับผิดชอบ</th>
              </tr>
            </thead>
            <tbody>
              {data.map((book, index) => {
                return (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <th>{book.id || "-"}</th>
                    <th>{book.lotteryIds.length || "-"}</th>
                    <th>
                      {unixToDateAndTime(book.createdAt).replace("T", " เวลา ")}
                    </th>
                    <th>{book.uploader || "-"}</th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </ScrollBar>
    </Container>
  );
};

const ScrollBar = styled.div`
  max-height: 80vh;
  max-width: 100%;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const Button = styled.button``;
