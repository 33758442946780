import { useState, useEffect } from "react";
import Context from "../../Context";
import { Sheet, SheetContent, SheetTrigger, Switch } from "../../components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import { Input } from "../../components/Input";
import { AiFillPlusCircle } from "react-icons/ai";
import constant from "../../constants";

export const ManageMission = () => {
  const { callApi, updateConfig, configs } = Context();

  const [missions, setMissions] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [body, setBody] = useState({
    name: "",
    type: "",
    requiredLotto: null,
    requiredOrder: null,
    limitClaim: null,
    limitPerUser: null,
    status: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchMission();
  }, []);

  const fetchMission = async () => {
    const missions = await callApi("missions-getMissions");
    const coupons = await callApi("coupons-getAllCoupons");
    setMissions(missions);
    setCoupons(coupons);
  };

  const createMission = async (body) => {
    const result = await callApi("missions-createMission", { ...body });

    if (result.status) {
      await fetchMission();
      onToggleSheet();
    }
  };

  const updateMission = async (body) => {
    const result = await callApi("missions-editMission", { ...body });

    if (result.status) {
      await fetchMission();
      onToggleSheet();
    }
  };

  const updateChange = async (key, value) => {
    if (!window.confirm("ท่านต้องการแก้ไขใช่หรือไม่")) return;
    await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });
    updateConfig();
  };

  const onBodyChange = (key, value) => {
    setBody((prevState) => ({ ...prevState, [key]: value }));
  };

  const onSelectMission = (mission) => {
    setBody(mission);
    setIsOpen(true);
  };

  const onToggleSheet = () => {
    setIsOpen(!isOpen);
    setBody({
      name: "",
      type: "",
      requiredLotto: null,
      requiredOrder: null,
      limitClaim: null,
      limitPerUser: null,
      status: "",
    });
  };

  return (
    <div className="bg-white max-w-[1200px] mb-24  p-8 rounded-2xl drop-shadow-xl">
      <p className="text-3xl font-semibold">จัดการภารกิจ</p>
      <div className="flex gap-2 mt-6 items-center ">
        <p className={configs?.isOpen?.mission ? "opacity-50" : "font-bold"}>
          ปิด
        </p>
        <Switch
          checked={configs?.isOpen?.mission}
          onCheckedChange={(e) => updateChange("isOpen.mission", e)}
        />
        <p className={!configs?.isOpen?.mission ? "opacity-50" : "font-bold"}>
          เปิด
        </p>
      </div>
      <CreateMission
        isOpen={isOpen}
        onToggleSheet={onToggleSheet}
        coupons={coupons}
        createMission={createMission}
        body={body}
        onBodyChange={onBodyChange}
        updateMission={updateMission}
      />
      {missions.length > 0 ? (
        missions.map((mission) => {
          return (
            <div
              className="min-h-48 w-full mt-8 p-4 rounded-[0.5rem] border-2 duration-150 cursor-pointer hover:scale-[102%] bg-white hover:border-black"
              key={mission.id}
              onClick={() => {
                onSelectMission(mission);
              }}
            >
              <p className="text-xl font-semibold">{mission.name}</p>
              <div
                className={`${
                  mission.status === "active" ? "bg-green-600" : "bg-black"
                } flex items-center justify-center w-fit py-1 px-2 mt-2 rounded-[0.5rem] text-xs text-white font-semibold`}
              >
                {constant.statusesTranslate[mission.status]}
              </div>
              <div class="grid grid-cols-none grid-flow-row md:grid-cols-3 gap-4 mt-3">
                <div className="flex gap-2 items-center">
                  <span className="font-bold">ประเภท: </span>
                  {mission.type === "amountInOneOrder" ? (
                    "ไม่ระบุเลขท้าย"
                  ) : (
                    <>
                      ระบุเลขท้าย
                      <div className="flex items-center justify-center w-fit py-1 px-2 bg-black rounded-[0.5rem] text-xs text-white font-semibold">
                        {mission.lastTwo}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-2 col-span-2 items-center">
                  ขั้นต่ำ
                  <div className="flex items-center justify-center w-fit py-1 px-2 bg-black rounded-[0.5rem] text-xs text-white font-semibold">
                    {mission.requiredLotto}
                  </div>
                  ใบจำนวน
                  <div className="flex items-center justify-center w-fit py-1 px-2 bg-black rounded-[0.5rem] text-xs text-white font-semibold">
                    {mission.requiredOrder}
                  </div>
                  ออเดอร์
                </div>

                <div className="flex gap-2 items-center">
                  โควต้าคงเหลือทั้งหมด
                  <div className="flex items-center justify-center w-fit py-1 px-2 bg-black rounded-[0.5rem] text-xs text-white font-semibold">
                    {mission.limitClaim}
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  โควต้าต่อคน
                  <div className="flex items-center justify-center w-fit py-1 px-2 bg-black rounded-[0.5rem] text-xs text-white font-semibold">
                    {mission.limitPerUser}
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  คูปองรางวัล
                  <div className="flex items-center justify-center w-fit py-1 px-2 bg-black rounded-[0.5rem] text-xs text-white font-semibold">
                    {mission.reward?.name}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div></div>
      )}
    </div>
  );
};

const CreateMission = ({
  isOpen,
  coupons,
  createMission,
  body,
  onBodyChange,
  updateMission,
  onToggleSheet,
}) => {
  return (
    <Sheet open={isOpen} onOpenChange={() => onToggleSheet()}>
      <SheetTrigger className="flex justify-center items-center gap-2 w-full p-4 my-4 bg-gray-950 hover:bg-primary font-bold text-lg text-white rounded-2xl duration-100 hover:text-xl hover:drop-shadow-xl">
        <AiFillPlusCircle />
        {body.id ? "แก้ไขภารกิจ" : "สร้างภารกิจ"}
      </SheetTrigger>
      <SheetContent className="max-w-full w-full md:w-1/2 sm:w-2/3">
        <p className="text-4xl font-bold">
          {body.id ? "แก้ไขภารกิจ" : "สร้างภารกิจ"}
        </p>
        <div className="mt-12">
          <p className="text-lg font-bold">ชื่อภารกิจ</p>
          <Input
            className="my-1"
            placeholder="ชื่อภารกิจ"
            value={body.name}
            onChange={(e) => onBodyChange("name", e.target.value)}
          />
        </div>
        <div className="mt-4">
          <p className="text-lg font-bold">ประเภท</p>

          <Select
            value={body.type}
            onValueChange={(e) => onBodyChange("type", e)}
          >
            <SelectTrigger className="my-1 text-md font-medium">
              <SelectValue placeholder="-- โปรดเลือก --" />
            </SelectTrigger>
            <SelectContent className="bg-black">
              <SelectItem
                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                value="amountInOneOrder"
              >
                ไม่ระบุเลขท้าย
              </SelectItem>
              <SelectItem
                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                value="lastTwoInOneOrder"
              >
                ระบุเลขท้าย
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        {body.type === "lastTwoInOneOrder" && (
          <div className="mt-4">
            <p className="text-lg font-medium">เลขท้าย 2 ตัว</p>
            <Input
              className="mt-1"
              placeholder="10"
              type="number"
              min={1}
              max={99}
              value={body.lastTwo}
              onChange={(e) => {
                if (e.target.value > 99) {
                  return onBodyChange("lastTwo", 99);
                }
                onBodyChange("lastTwo", e.target.value);
              }}
            />
          </div>
        )}
        <div className="grid grid-flow-col gap-4">
          <div className="mt-4">
            <p className="text-lg font-bold">จำนวนใบ / ออเดอร์</p>
            <Input
              className="mt-1"
              placeholder="10"
              type="number"
              defaultValue={body.requiredLotto}
              onChange={(e) => onBodyChange("requiredLotto", e.target.value)}
            />
          </div>
          <div className="mt-4">
            <p className="text-lg font-bold">จำนวนออเดอร์ / ภารกิจ</p>
            <Input
              className="mt-1"
              placeholder="10"
              type="number"
              defaultValue={body.requiredOrder}
              onChange={(e) => onBodyChange("requiredOrder", e.target.value)}
            />
          </div>
        </div>

        <div className="mt-4">
          <p className="text-lg font-bold">จำกัดจำนวนการทำภารกิจ (ทั้งหมด)</p>
          <Input
            className="mt-1"
            placeholder="10"
            type="number"
            defaultValue={body.limitClaim}
            onChange={(e) => onBodyChange("limitClaim", e.target.value)}
          />
        </div>
        <div className="mt-4">
          <p className="text-lg font-bold">จำกัดจำนวนการทำภารกิจ (ต่อคน)</p>
          <Input
            className="mt-1"
            placeholder="10"
            type="number"
            defaultValue={body.limitPerUser}
            onChange={(e) => onBodyChange("limitPerUser", e.target.value)}
          />
        </div>
        <div className="mt-4">
          <p className="text-lg font-bold">สถานะ</p>

          <Select
            value={body.status}
            onValueChange={(e) => onBodyChange("status", e)}
          >
            <SelectTrigger className="my-1 text-md font-medium">
              <SelectValue placeholder="-- โปรดเลือก --" />
            </SelectTrigger>
            <SelectContent className="bg-black">
              <SelectItem
                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                value="active"
              >
                เปิดใช้งาน
              </SelectItem>
              <SelectItem
                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                value="inactive"
              >
                ปิดใช้งานชั่วคราว
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="mt-4">
          <p className="text-lg font-bold">คูปองรางวัล</p>
          <Select
            value={body.reward?.id}
            onValueChange={(e) => {
              const filterCoupon = coupons.filter((c) => c.id === e)[0];
              onBodyChange("reward", {
                id: filterCoupon.id,
                name: filterCoupon.name,
              });
            }}
          >
            <SelectTrigger className="my-1 text-md font-medium">
              <SelectValue placeholder="-- โปรดเลือก --" />
            </SelectTrigger>
            <SelectContent className="bg-black">
              {coupons.map((coupon) => {
                return (
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value={coupon.id}
                  >
                    {coupon.name}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
        <div className="mt-4">
          <p className="text-lg font-bold">เงื่อนไขภารกิจ</p>
          <Input
            className="mt-1"
            placeholder="คุณต้องซื้อลอตเตอรี่ชุด 2 ใบ จำนวน 5 ครั้ง ภายในระยะเวลาที่กำหนด เมื่อท่านชำระเงิน แล้ว ระบบจะทำการบันทึกข้อมูลลงภารกิจให้ โดยอัตโนมัติ"
            type="text"
            defaultValue={body.info || ""}
            onChange={(e) => onBodyChange("info", e.target.value)}
          />
        </div>
        <button
          disabled={Object.values(body).some(
            (value) => value === null || value === ""
          )}
          class="w-full h-12 my-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => (body.id ? updateMission(body) : createMission(body))}
        >
          {body.id ? "แก้ไขภารกิจ" : "สร้างภารกิจ"}
        </button>
      </SheetContent>
    </Sheet>
  );
};
