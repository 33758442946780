import React, { useState } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";

import * as R from "ramda";

export const ReportFilteredRewardwinner = () => {
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [financeList, setFinanceList] = useState({});
  const [scannerList, setScannerList] = useState([]);
  const [prizeType, setPrizeType] = useState("lastTwo");

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    const getRewardByType = await callApi("userPrizes-getUserPrizeByType", {
      roundDate: roundDate,
      prizeType: prizeType,
    });

    const scannerPrizeList = await callApi("prizes-getAllLotteriesWithPrize", {
      roundDate: roundDate,
      prizeType: prizeType,
    });

    const filteredScannerPrizeList = scannerPrizeList.reduce((acc, data) => {
      if (data.prizes.some((x) => x.prizeType === "lastTwo")) {
        acc.push(data.id);
      }
      return acc;
    }, []);

    const filteredFinancePizeList = getRewardByType.reduce((acc, data) => {
      data.prizes.forEach((prize) => {
        if (prize.prizeType === "lastTwo") {
          acc.push(prize.lotteryId);
        }
      });

      return acc;
    }, []);

    let symDifference = filteredFinancePizeList
      .filter((x) => !filteredScannerPrizeList.includes(x))
      .concat(
        filteredScannerPrizeList.filter(
          (x) => !filteredFinancePizeList.includes(x)
        )
      );
    console.log(symDifference);
    setScannerList(filteredScannerPrizeList);
    setFinanceList(filteredFinancePizeList);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานผู้ถูกรางวัลตามประเภท</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>

      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      <Flex>
        {!R.isEmpty(financeList) && (
          <div style={{ width: "300px" }}>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>เลข</th>
                </tr>
              </thead>
              <tbody>
                {financeList
                  .sort((a, b) => b - a)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>{item}</th>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        )}
        {!R.isEmpty(scannerList) && (
          <div style={{ width: "300px" }}>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>เลข</th>
                </tr>
              </thead>
              <tbody>
                {scannerList
                  .sort((a, b) => b - a)
                  .map((item, index) => {
                    return (
                      <tr>
                        <th>{index + 1}</th>
                        <th>{item}</th>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        )}
      </Flex>
    </Container>
  );
};

const Button = styled.button`
  margin: 2em 0;
`;
