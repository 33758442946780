import { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  Switch,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import constant from "../../constants";
import { CSVLink, CSVDownload } from "react-csv";

export const ReportReserveOrder = () => {
  const downloadRef = useRef(null);
  const { callApi, updateConfig, configs } = Context();
  const [selectedRoundDate, setSelectedRoundDate] = useState(configs.roundDate);
  const [orderData, setOrderData] = useState([]);
  const [filterByUser, setFilterByUser] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvData.length > 0) {
      downloadRef.current.childNodes[0].click();
    }
    // setDownloaded((old) => [...old, csvFileName]);
  }, [csvFileName]);

  const fetchData = async () => {
    const result = await callApi("orders-getAll", {
      roundDate: selectedRoundDate,
      queries: [["isReserveOrder", true]],
      limit: 50000,
      orderBy: ["createdAt", "desc"],
    });

    setOrderData(result);

    const filterByUser = result.reduce((acc, order) => {
      const { reservedBy, status } = order;
      if (acc[reservedBy]) {
        if (acc[reservedBy][status]) {
          acc[reservedBy][status] += 1;
        } else {
          acc[reservedBy] = { ...acc[reservedBy], [status]: 1 };
        }
        acc[reservedBy].total += 1;
      } else {
        acc[reservedBy] = { [status]: 1, total: 1 };
      }
      return acc;
    }, {});

    setFilterByUser(filterByUser);
  };

  const handleDownload = async (data, type) => {
    let acc = [];

    if (type === "mini") {
      acc.push(["รหัสผู้สร้าง", "ออเดอร์ทั้งหมด", "อนุมัติ", "ไม่อนุมัติ"]);
      const datax = Object.entries(data).map((entry) => {
        return [
          entry[0],
          entry[1].total,
          entry[1].approved || 0,
          (entry[1].canceled || 0) + (entry[1].pending || 0),
        ];
      });

      acc.push(...datax);
      setCsvFileName(
        `สรุปรายงานออเดอร์จองสิทธิ์แยกตามผู้สร้าง_${selectedRoundDate}.csv`
      );
    } else {
      acc = orderData.map((x) => {
        return [
          new Date(parseInt(x.createdAt)).toLocaleString() === "Invalid Date"
            ? x.createdAtDate.split("-")[2] +
              "/" +
              x.createdAtDate.split("-")[1] +
              "/" +
              x.createdAtDate.split("-")[0]
            : new Date(parseInt(x.createdAt)).toLocaleString(),
          x.shortId,
          x.status,
          x.reservedBy,
        ];
      });
      acc.unshift(["วันที่", "รหัสออเดอร์", "สถานะ", "รหัสผู้สร้าง"]);

      setCsvFileName(`รายงานออเดอร์จองสิทธิ์_${selectedRoundDate}.csv`);
    }

    console.log(acc);
    setCsvData(acc);
  };
  return (
    <div className="bg-white max-w-[1200px] mb-24  p-8 rounded-2xl drop-shadow-xl">
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>
      <p className="text-3xl font-semibold">รายงานออเดอร์จองสิทธิ์</p>
      <div className="flex items-center gap-4 mt-4 max-w-80">
        <p className="text-lg font-bold">งวด</p>
        <Select
          value={selectedRoundDate}
          onValueChange={(e) => setSelectedRoundDate(e)}
        >
          <SelectTrigger className="my-1 text-md font-medium">
            <SelectValue placeholder="-- โปรดเลือก --" />
          </SelectTrigger>
          <SelectContent className="bg-black">
            {configs.roundDates.map((roundDate) => {
              return (
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value={roundDate}
                >
                  {roundDate}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <button
          className="h-12 my-4 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
          onClick={fetchData}
        >
          ค้นหา
        </button>
      </div>
      <div
        className={`max-w-[789px] mt-16 mb-24 ${
          orderData.length === 0 && "hidden"
        }`}
      >
        <div className="flex gap-4 mb-12 items-center">
          <p className="text-xl font-semibold">
            สรุปรายงานออเดอร์จองสิทธิ์แยกตามผู้สร้าง
          </p>
          <button
            className="h-8 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white text-sm font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
            onClick={() => handleDownload(filterByUser, "mini")}
          >
            ดาวน์โหลด
          </button>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="">รหัสผู้สร้าง</TableHead>
              <TableHead className="text-center">ออเดอร์ทั้งหมด</TableHead>
              <TableHead className="text-center">อนุมัติ</TableHead>
              <TableHead className="text-center">ไม่อนุมัติ</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(filterByUser).map(([id, order]) => {
              return (
                <TableRow key={id}>
                  <TableCell className="font-medium">
                    <div
                      className={`
                       flex items-center bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                      onClick={() =>
                        window.open(
                          `https://admin.hongthongofficial.com/users/${id}`,
                          "__blank"
                        )
                      }
                    >
                      {id}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      className={`
                       flex items-center mx-auto bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                    >
                      {order.total || 0}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      className={`flex items-center bg-green-600 mx-auto justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold`}
                    >
                      {order.approved || 0}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      className={`
                       flex items-center bg-danger justify-center mx-auto w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                    >
                      {order.canceled + (order.pending || 0) || 0}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div
        className={`max-w-[789px] mt-16  ${orderData.length === 0 && "hidden"}`}
      >
        <div className="flex gap-4 mb-12 items-center">
          <p className="text-xl font-semibold">
            รายงานออเดอร์จองสิทธิ์ ({orderData.length} ออเดอร์)
          </p>
          <button
            className="h-8 px-4 bg-gray-950 hover:bg-primary  disabled:hover:bg-gray-950 duration-150 text-white text-sm font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
            onClick={() => handleDownload(orderData)}
          >
            ดาวน์โหลด
          </button>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="">วัน - เวลาที่สร้าง</TableHead>
              <TableHead className="text-center">รหัสออเดอร์</TableHead>
              <TableHead className="text-center">สถานะ</TableHead>
              <TableHead className="text-right">รหัสผู้สร้าง</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orderData.map((order) => {
              return (
                <TableRow key={order.shortId}>
                  <TableCell className="font-medium">
                    {new Date(parseInt(order.createdAt)).toLocaleString() ===
                    "Invalid Date"
                      ? order.createdAtDate.split("-")[2] +
                        "/" +
                        order.createdAtDate.split("-")[1] +
                        "/" +
                        order.createdAtDate.split("-")[0]
                      : new Date(parseInt(order.createdAt)).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <div
                      className={`
                       flex items-center mx-auto bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                      onClick={() =>
                        window.open(
                          `https://admin.hongthongofficial.com/orders/working/${order.id}`,
                          "__blank"
                        )
                      }
                    >
                      {order.shortId}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      className={`${
                        order.status === "approved"
                          ? "bg-green-600"
                          : order.status === "pending"
                          ? "bg-primary"
                          : "bg-danger"
                      } flex items-center mx-auto justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold`}
                    >
                      {constant.statusesTranslate[order.status]}
                    </div>
                  </TableCell>
                  <TableCell className="flex justify-end items-center">
                    <div
                      className={`
                       flex items-center bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                      onClick={() =>
                        window.open(
                          `https://admin.hongthongofficial.com/users/${order.reservedBy}`,
                          "__blank"
                        )
                      }
                    >
                      {order.reservedBy}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
