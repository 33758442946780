import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import ButtonLoading from "../../components/ButtonLoading";
import { Table, Collapse } from "reactstrap";
import { Flex, Label } from "../../components";
import { thaiDate } from "../../util";
import { isNil, isEmpty } from "ramda";
import styled from "styled-components";
import themes from "../../themes";
import { useSearchParams, useLocation } from "react-router-dom";
import { Badge } from "reactstrap";

const LotteriesDetail = () => {
  const { callApi, profileState, configs } = Context();
  const [Lottories, setLottories] = useState([]);
  const [data, setData] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const fileNameId = searchParams.get("fileNameId");
  let location = useLocation();
  const getData = async () => {
    const data = await callApi("lotteries-getAll", {
      queries: [
        ["fileNameId", "==", fileNameId],
        ["roundDate", "==", configs.roundDate],
      ],

      limit: 200,
    });

    const getData = data.reduce(
      (acc, lotto) => {
        if (lotto.isSold) {
          acc["isSold"] += 1;
        }

        if (lotto.isOnBlock) {
          acc["isOnBlock"] += 1;
        }

        if (!acc["uploader"].includes(lotto.uploader)) {
          acc["uploader"].push(lotto.uploader);
        }
        return acc;
      },
      { isSold: 0, isOnBlock: 0, uploader: [] }
    );

    setData(getData);
    setLottories(data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (Lottories.length === 0) {
    return (
      <>
        <Container>
          <Label.H1 weight={700}>ไม่พบไฟล์ {fileNameId} ในระบบ </Label.H1>
        </Container>
      </>
    );
  }
  return (
    <>
      <Container>
        <Label.H1 weight={700}>ข้อมูลเล่ม {fileNameId}</Label.H1>
        <br />
        <Label.H4 weight={500}>
          จำนวนหวยทั้งหมด <Badge color="success">{Lottories.length} </Badge> ใบ
        </Label.H4>
        <br />
        <Label.H4 weight={500}>
          อยู่ในระบบหน้าบ้านแล้ว <Badge color="success">{data.isOnBlock}</Badge>{" "}
          ใบ
        </Label.H4>
        <br />
        <Label.H4 weight={500}>
          อยู่ในระบบหลังบ้าน{" "}
          <Badge color="danger">{Lottories.length - data.isOnBlock}</Badge> ใบ
        </Label.H4>
        <br />
        <Label.H4 weight={500}>
          ขายไปแล้ว <Badge color="info">{data.isSold}</Badge> ใบ คิดเป็น{" "}
          <Badge color="success">
            {" "}
            {(data.isSold / Lottories.length) * 100}
          </Badge>{" "}
          %
        </Label.H4>
        <br />

        <Label.H4 weight={500}>
          ผู้รับผิดชอบ{" "}
          {data.uploader?.map((upder, index) => (
            <Badge className="mx-1">{upder}</Badge>
          ))}
        </Label.H4>
        <br />
        {/* <button onClick={getData}>Refresh</button>
      <Flex margin="3em 0" align="center" width="100%">
        <Flex direction="column">
          <div style={{ width: "100%", display: "flex" }}>
            <Label.H2 margin="0 1em 0 0" weight={500}>
              จำนวนเลขที่พร้อมขายทั้งหมด{" "}
              {Object.values(Lotto)
                .filter((x) => x.newLotteries !== 0)
                .length.toLocaleString()}{" "}
              เล่ม (
              {Object.values(unimported)
                .reduce((acc, x) => {
                  return acc + x.size;
                }, 0)
                .toLocaleString()}
              ) ใบ
            </Label.H2>
          </div>
        </Flex>
      </Flex> */}
        <FullScrollBar>
          <Table striped hover>
            <thead>
              <tr>
                <Th>ลำดับที่</Th>
                <Th>ชื่อไฟล์</Th>
                <Th>สถานะการขาย</Th>
                <Th>สถานะระบบ</Th>
                <Th>รูป</Th>
              </tr>
            </thead>
            <tbody>
              {Lottories.sort((a, b) => a.id.slice(-2) - b.id.slice(-2)).map(
                (lot, index) => {
                  return (
                    <tr key={index}>
                      <Td>{index + 1}</Td>

                      <Td>{lot.id}</Td>
                      <Td>
                        {lot.isSold ? (
                          <LableStatus className="green">ขายไปแล้ว</LableStatus>
                        ) : (
                          <LableStatus className="red">ยังไม่ขาย</LableStatus>
                        )}
                      </Td>
                      <Td>
                        {lot.isOnBlock ? (
                          <LableStatus className="blue">อยู่ในระบบ</LableStatus>
                        ) : (
                          <LableStatus className="orange">
                            ไม่อยู่ในระบบ
                          </LableStatus>
                        )}
                      </Td>
                      <Td>
                        <Img src={lot.image} alt={lot.id} width="150px" />
                      </Td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        </FullScrollBar>
        <br />
      </Container>
    </>
  );
};
const Img = styled.img`
  :hover {
    transform: scale(2);
  }
`;
const Th = styled.th`
  text-align: -webkit-center;
  vertical-align: middle;
`;
const Td = styled.td`
  text-align: -webkit-center;
  vertical-align: middle;
`;
const LableStatus = styled.div`
  padding: 1em;
  border-radius: 0.375em;
  width: 100px;
  font-weight: 500;
  &.red {
    background: ${themes.colors.dangerRed};
    color: white;
  }

  &.green {
    background: ${themes.colors.success};
    color: white;
  }

  &.blue {
    background: ${themes.colors.brightBlue};
    color: white;
  }

  &.orange {
    background: ${themes.colors.mainLight1};
    color: white;
  }
`;
const Container = styled.div`
  background-color: white;
  padding: 2em;
  border-radius: 0.375em;
`;
const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const FullScrollBar = styled(ScrollBar)`
  width: 100% !important;
  max-width: 100% !important;
  max-height: 70vh;
`;
export default LotteriesDetail;
