import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { useNavigate, useParams } from "react-router-dom";
import HtDataManager from "../../components/HtDataManager";
import { thaiDate, roundDateToDate } from "../../util";
import constant from "../../constants";
import styled from "styled-components";
import { childPages } from "../../pages";

const Admins = () => {
  const { callApi } = Context();
  const { userId } = useParams();
  const dataLeftRef = useRef(null);
  const [dataLeft, setDataLeft] = useState({});
  console.log("afsfasdfsdadsfa");
  const getUserData = async () => {
    setDataLeft({});
    callApi("users-getUserInfoAdmin", { userId }).then((user) => {
      const {
        permissions,
        accName,
        accNo,
        activated,
        admin,
        bankName,
        buyPoint,
        email,
        firstName,
        lastName,
        password,
        phone,
        referPoint,
        userNo,
        address,
      } = user;
      setDataLeft({
        boxes: [
          {
            sections: [
              {
                id: userId,
                updateChange: async (body) => {
                  return await callApi("users-edit", body);
                },
                header: "รหัสสมาชิก " + userNo,
                size: 1,
                card: {
                  size: 3,
                  cards: [
                    {
                      title: "ชื่อจริง",
                      value: {
                        component: "Text",
                        value: firstName,
                        field: "firstName",
                      },
                    },
                    {
                      title: "นามสกุล",
                      value: {
                        component: "Text",
                        value: lastName,
                        field: "lastName",
                      },
                    },
                    {
                      title: "โทรศัพท์",
                      value: {
                        component: "Text",
                        value: phone,
                        field: "lastName",
                      },
                    },
                    {
                      title: "ยืนยันเบอร์มือถือ",
                      value: {
                        component: "Text",
                        value: activated,
                        choices: [true, false],
                        field: "activated",
                      },
                    },
                  ],
                },
              },
            ],
          },
          {
            sections: [
              {
                id: userId,
                updateChange: async (body) => {
                  return await callApi("users-edit", body);
                },
                header: "สิทธิ์การเข้าถึง",
                size: 1,
                card: {
                  size: 1,
                  cards: [
                    {
                      title: "Admin",
                      value: {
                        component: "Choice",
                        field: "admin",
                        value: admin,
                        choices: [true, false],
                        field: "admin",
                      },
                    },
                    {
                      title: "การเข้าถึง",
                      value: {
                        component: "CheckBox",
                        field: "permissions",
                        value: permissions,
                        choices: childPages
                          .filter((x) => !x.path.includes("/tests"))
                          .map((x) => {
                            return { key: x.path, value: x.path, text: x.name };
                          }),
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      });
    });
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div style={{ verticalAlign: "top", height: "100%" }} ref={dataLeftRef}>
      <div
        style={{ width: "100%", display: "inline-block", verticalAlign: "top" }}
      >
        <HtDataManager data={dataLeft} />
      </div>
    </div>
  );
};
export default Admins;

const Select = styled.select`
  z-index: 9000;
  position: absolute;
  top: 55px;
  right: 120px;
`;
const Button = styled.button`
  z-index: 9000;
  position: absolute;
  top: 50px;
  right: 50px;
`;
