import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Badge } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";

import constansts from "../../constants";

export const ReportCoupon = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = async (e) => {
    setRoundDate(e.target.value);
  };

  const handleSearch = async () => {
    const data = await callApi("coupons-getAllTransaction", {
      queries: [
        ["status", "==", constansts.statuses.APPROVE],
        ["roundDate", "==", roundDate],
      ],
      startAfter: "",
    });
    const sumPrice = data.reduce((acc, couponOrder) => {
      acc += couponOrder.price;
      return acc;
    }, 0);
    setTotalPrice(sumPrice);
    setData(data);
  };

  const handleDownload = async () => {
    const acc = [];
    acc.push([
      "ลำดับที่",
      "วันที่",
      "รหัสออเดอร์",
      "ราคา",
      "ชื่อ",
      "ถูกใช้โดยออเดอร์",
      "สถานะ",
    ]);

    await data.map((couponData, index) => {
      acc.push([
        index + 1,
        couponData.createdAtDate,
        couponData.shortId,
        couponData.price,
        couponData.coupon?.name,
        couponData.coupon?.usedBy || "",
        constansts.statusesTranslate[couponData.coupon.status],
      ]);
    });

    setCsvData(acc);
    setCsvFileName(`รายงานคูปอง_${roundDate}.csv`);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานคูปอง</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.reverse().map((roundDate) => (
          <option key={roundDate}>{roundDate}</option>
        ))}
      </Input>

      <br />
      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
        Download Excel
      </Button>

      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      <br />
      {data.length > 0 && (
        <>
          <Label.H3>จำนวนออเดอร์ทั้งหมด {data.length} ออเดอร์</Label.H3>

          <Label.H3>
            จำนวนยอดขายทั้งหมด {totalPrice.toLocaleString()} บาท
          </Label.H3>

          <br />
          <Table>
            <thead>
              <tr>
                <th>ลำดับที่</th>
                <th>วันที่ซื้อ</th>
                <th>รหัสออเดอร์</th>
                <th>ราคา</th>
                <th>ชื่อคูปอง</th>
                <th>ถูกใช้โดยออเดอร์</th>
                <th>สถานะคูปอง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((couponOrder, index) => {
                return (
                  <tr key={couponOrder.id}>
                    <th>{index + 1}</th>
                    <th>{couponOrder.createdAtDate}</th>
                    <th>{couponOrder.shortId}</th>
                    <th>{couponOrder.price.toLocaleString()}</th>
                    <th>{couponOrder.coupon?.name}</th>
                    <th
                      onClick={() =>
                        couponOrder.coupon?.usedBy &&
                        window.open(
                          "/orders/working/" + couponOrder.coupon.usedBy,
                          "_blank"
                        )
                      }
                      style={{
                        cursor: couponOrder.coupon?.usedBy
                          ? "pointer"
                          : "no-drop",
                      }}
                    >
                      {couponOrder.coupon?.usedBy || ""}
                    </th>
                    <th>
                      <Badge
                        background={
                          constansts.statusColor[couponOrder.coupon?.status]
                        }
                      >
                        {
                          constansts.statusesTranslate[
                            couponOrder.coupon?.status
                          ]
                        }
                      </Badge>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  );
};

const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 250px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const Button = styled.button``;
