import styled from "styled-components";

export const ScrollBar = ({ children }) => {
  return <ScrollBarStyle>{children}</ScrollBarStyle>;
};
const ScrollBarStyle = styled.div`
  max-height: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
