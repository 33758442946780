import React from "react";
import themes from "../themes";
import styled from "styled-components";

const LabelStyle = styled.p`
  display: flex;
  align-items: center;
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size || themes.fontSize.medium};
  color: ${(props) => props.color || themes.colors.black};
  margin: ${(props) => props.margin || "0"};
  text-align: ${(props) => props.textAlign};
  letter-spacing: 0.25px;
  transition: 0.2s;
  width: ${(props) => props.width};
  &.value {
    font-size: ${themes.fontSize.medium};
    margin: 0.25em 0;
  }
  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

export const Label = (props) => {
  const {
    children,
    margin,
    weight,
    size,
    width,
    color,
    textAlign,
    className,
    hoverColor,
  } = props;
  return (
    <LabelStyle {...props} size={size}>
      {children}
    </LabelStyle>
  );
};

export const LabelH1 = (props) => {
  const { children, weight, size, color, textAlign, className } = props;
  return (
    <LabelStyle {...props} size={themes.fontSize.xLarge}>
      {children}
    </LabelStyle>
  );
};

export const LabelH2 = (props) => {
  const { children, weight, size, color, textAlign, className } = props;
  return (
    <LabelStyle {...props} size={themes.fontSize.large}>
      {children}
    </LabelStyle>
  );
};

export const LabelH3 = (props) => {
  const { children, weight, size, color, textAlign, className } = props;
  return (
    <LabelStyle {...props} size={themes.fontSize.medium}>
      {children}
    </LabelStyle>
  );
};

export const LabelH4 = (props) => {
  const { children, weight, size, color, textAlign, className } = props;
  return (
    <LabelStyle {...props} size={themes.fontSize.default}>
      {children}
    </LabelStyle>
  );
};

export const LabelH5 = (props) => {
  const { children, weight, size, color, textAlign, className } = props;
  return (
    <LabelStyle {...props} size={themes.fontSize.small}>
      {children}
    </LabelStyle>
  );
};

export const LabelH6 = (props) => {
  const { children, weight, size, color, textAlign, className } = props;
  return (
    <LabelStyle {...props} size={themes.fontSize.xSmall}>
      {children}
    </LabelStyle>
  );
};

Label.H1 = LabelH1;
Label.H2 = LabelH2;
Label.H3 = LabelH3;
Label.H4 = LabelH4;
Label.H5 = LabelH5;
Label.H6 = LabelH6;
