import Context from "../../Context";
import React, { useState, useRef, useDebugValue } from "react";
const { splitEvery } = require("ramda");

const ImageDownloader = () => {
  const { callApi, db } = Context();
  const [year, setYear] = useState("");
  const [round, setRound] = useState("");
  const [set, setSet] = useState("");
  const [fileName, setFileName] = useState("");
  const [book, setBook] = useState("");

  const [YY, setYY] = useState("xx");
  const [RR, setRR] = useState("xx");
  const [SS, setSS] = useState("xx");

  const [lotteries, setLotteries] = useState([]);

  const download = (id, url, bookNo) =>
    new Promise((resolve, reject) => {
      try {
        let [yy, rr, ss, number] = id.split("-");
        if (YY) yy = YY;
        if (RR) rr = RR;
        if (SS) ss = SS;
        const ids = [yy, rr, ss, number, bookNo].join("-");
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = document.createElement("img");
        img.crossOrigin = "anonymous";
        img.onload = async () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => {
            const link = document.createElement("a");
            link.download = ids + ".jpeg";
            link.href = URL.createObjectURL(blob);
            link.click();
            URL.revokeObjectURL(link.href);
          }, "image/jpeg");
          setTimeout(() => {
            resolve("");
          }, 100);
        };
        img.src = url;
      } catch (err) {
        console.error(err);
      }
    });
  const downloads = async () => {
    if (lotteries.length) {
      for await (let lotterie of lotteries) {
        await download(lotterie.id, lotterie.imagePublic, lotterie.bookNo);
      }
    }
    // if (lotteries.length) {
    //   const chuncks = splitEvery(10, lotteries);
    //   for await (let chunck of chuncks) {
    //     await Promise.all(
    //       chunck.map(({ id, imagePublic }) => download(id, imagePublic))
    //     );
    //   }
    // }
  };
  const getLotteries = async (type) => {
    let queries = [];
    if (type === "id") {
      if (year) {
        queries.push(["year", "==", year]);
      }
      if (round) {
        queries.push(["roundNo", "==", round]);
      }
      if (set) {
        queries.push(["setNo", "==", set]);
      }
    } else if (type === "fileName") {
      if (fileName) {
        queries.push(["fileNameId", "==", fileName]);
      }
    } else if (type === "book") {
      if (book) {
        queries.push(["bookNo", "==", book]);
      }
    }
    const result = await callApi("lotteries-download", {
      queries,
    });
    setLotteries(result.data);
  };
  return (
    <>
      <div style={{ margin: "10px" }}>
        <button
          onClick={() => {
            getLotteries("id");
          }}
        >
          ค้นหา
        </button>
        {" ปี "}
        <input
          style={{ textAlign: "center" }}
          type="text"
          size={2}
          maxLength={2}
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
        {" งวด "}
        <input
          style={{ textAlign: "center" }}
          type="text"
          size={2}
          maxLength={2}
          value={round}
          onChange={(e) => setRound(e.target.value)}
        />
        {" ชุด "}
        <input
          style={{ textAlign: "center" }}
          type="text"
          size={2}
          maxLength={2}
          value={set}
          onChange={(e) => setSet(e.target.value)}
        />
      </div>
      <div style={{ margin: "10px" }}>
        <button
          onClick={() => {
            getLotteries("fileName");
          }}
        >
          ค้นหา
        </button>
        {" ชื่อไฟล์ "}
        <input
          style={{ textAlign: "center" }}
          type="text"
          size={50}
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
      </div>
      <div style={{ margin: "10px" }}>
        <button
          onClick={() => {
            getLotteries("book");
          }}
        >
          ค้นหา
        </button>
        {" เลขเล่ม "}
        <input
          style={{ textAlign: "center" }}
          type="text"
          size={4}
          maxLength={4}
          value={book}
          onChange={(e) => setBook(e.target.value)}
        />
      </div>
      <hr />
      <div>
        <button onClick={downloads}>download</button>
        {" ปี "}
        <input
          style={{ textAlign: "center" }}
          type="text"
          size={2}
          maxLength={2}
          value={YY}
          onChange={(e) => setYY(e.target.value)}
        />
        {" งวด "}
        <input
          style={{ textAlign: "center" }}
          type="text"
          size={2}
          maxLength={2}
          value={RR}
          onChange={(e) => setRR(e.target.value)}
        />
        {" ชุด "}
        <input
          style={{ textAlign: "center" }}
          type="text"
          size={2}
          maxLength={2}
          value={SS}
          onChange={(e) => setSS(e.target.value)}
        />
      </div>
      <hr />
      {lotteries.map(({ id, bookNo, fileNameId }, i) => (
        <div>
          <b>{i + 1} </b>
          {id}
          {" - "}
          {bookNo}
          {" - "}
          {fileNameId}
        </div>
      ))}
    </>
  );
};

export default ImageDownloader;
