import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import HtTablePaging from "../../components/HtTablePaging";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";
import { Container, Label, Flex } from "../../components";

import { Input, Table, Badge } from "reactstrap";
import { thaiDate } from "../../util";
import { isEmpty } from "ramda";

import {
  IoCheckmarkCircleSharp,
  IoCloseCircleSharp,
  IoWallet,
  IoAlarmSharp,
  IoSearch,
} from "react-icons/io5";
import themes from "../../themes";

// import { qr } from "../../../public/images/qr.png";
const WithdrawList = () => {
  const { callApi, showModalItemDetail, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [orderData, setOrderData] = useState([]);
  const [queriesKey, setKey] = useState("");
  const [queriesValue, setValue] = useState("");
  const [startAfter, setStartAfter] = useState({ 1: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [orderCount, setOrderCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    handleSearch();
  }, [currentPage, roundDate]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };
  const handleSearch = async (body) => {
    const queries =
      isEmpty(queriesKey) || isEmpty(queriesValue)
        ? []
        : [[queriesKey, queriesValue]];

    const result = await callApi("cashes-getAll", {
      ...body,
      //   roundDate: roundDate,
      queries: queries,
      //   limit: 50,
      //   startAfter: startAfter[currentPage],
    });

    const sortResult = result.sort((a, b) => b.createdAt - a.createdAt);

    setOrderData(sortResult);
  };

  const getStatus = (status) => {
    if (status === "pending") {
      return (
        <IoAlarmSharp
          style={{ fontSize: "2em", color: themes.colors.primary }}
        />
      );
    } else if (status === "paid") {
      return (
        <IoWallet
          style={{ fontSize: "2em", color: themes.colors.brightBlue }}
        />
      );
    } else if (status === "approved") {
      return (
        <IoCheckmarkCircleSharp
          style={{ fontSize: "2em", color: themes.colors.success }}
        />
      );
    } else if (status === "canceled") {
      return (
        <IoCloseCircleSharp
          style={{
            fontSize: "2em",
            fontWeight: 500,
            color: themes.colors.dangerRed,
          }}
        />
      );
    }
  };

  const filterKey = {
    เลขที่ใบสั่ง: "shortId",
    ชื่อ: "firstName",
    นามสกุล: "lastName",
    เบอร์โทร: "phone",
    สถานะ: "status",
  };

  const nextPage = () => {
    const startAfterId = orderData.slice(-1)[0].id;
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    setStartAfter((prevState) => {
      return { ...prevState, [nextPage]: startAfterId };
    });
  };

  const approveRequest = async (shortId) => {
    await callApi("cashes-approveRequest", { shortId });
    handleSearch();
  };
  const prevPage = () => {
    let prevPage = currentPage - 1;
    setCurrentPage(prevPage);
  };
  return (
    <Container>
      <Label.H1 weight={600}>คำขอถอนเงินทั้งหมด</Label.H1>
      <br />

      <Flex align="center" justify="space-between">
        <Label.H4 weight={500}>
          ทั้งหมด
          <Badge style={{ margin: "0 5px" }}>
            {orderData.length || "กำลังนับ"}
          </Badge>
          รายการ
        </Label.H4>
      </Flex>
      <br />
      <Table striped hover>
        <thead>
          <tr>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              วันเวลาคำขอ
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              เลขที่คำขอ
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              หมายเลขผู้ขอ
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              ธนาคาร
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              เลขที่บัญชี
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              ชื่อบัญชี
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              จำนวน
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              สถานะ
            </th>
            <th
              className="align-middle text-center"
              style={{ backgroundColor: "rgba(40,60,150,1)", color: "white" }}
            >
              ดูข้อมูลผู้ใช้
            </th>
          </tr>
        </thead>
        <tbody>
          {orderData.map((order) => {
            return (
              <tr key={order.shortId}>
                <td className="align-middle text-center" scope="row">
                  {thaiDate(new Date(parseInt(order.createdAt)))}
                </td>
                <th className="align-middle text-center">{order.shortId}</th>
                <td className="align-middle text-center">{order.userId}</td>
                <td className="align-middle text-center">{order.bankName}</td>
                <td className="align-middle text-center">{order.accNo}</td>
                <td className="align-middle text-center">{order.accName}</td>
                <td className="align-middle text-center">{order.withdraw}</td>
                <td className="align-middle text-center">
                  {order.status === "approved" ? (
                    getStatus(order.status)
                  ) : (
                    <Input
                      type="select"
                      style={{ width: "80px", margin: "0 auto" }}
                      onChange={() => approveRequest(order.shortId)}
                    >
                      <option value={"pending"}>
                        <p>รอดำเนินการ</p>
                      </option>
                      <option value={"approved"}>
                        <p>อนุมัติ</p>
                      </option>
                    </Input>
                  )}
                </td>
                <td className="align-middle text-center">
                  <button
                    style={{
                      backgroundColor: "rgba(245,194,64,1)",
                      border: "0",
                      borderRadius: "5px",
                      padding: "5px 10px",
                    }}
                    onClick={() => {
                      // navigate("/orders/working/" + props.row["id"]);
                      window.open("/users/" + order.userId, "_blank");
                    }}
                  >
                    <IoSearch />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <button
        style={{
          border: "1px solid rgba(245,194,64,1)",
          backgroundColor: "white",
          color: "rgba(245,194,64,1)",
          borderRadius: "5px",
          padding: "5px 10px",
          float: "right",
        }}
        onClick={() => {
          nextPage();
        }}
      >
        หน้าต่อไป
      </button>
      {currentPage !== 1 && (
        <button
          style={{
            border: "1px solid rgb(219,9,52)",
            backgroundColor: "white",
            color: "rgb(219,9,52)",
            borderRadius: "5px",
            padding: "5px 10px",
            float: "right",
            margin: "0 10px",
          }}
          onClick={() => {
            prevPage();
          }}
        >
          ย้อนกลับ
        </button>
      )}
    </Container>
  );
};
export default WithdrawList;
